import React from 'react';
// import Snackbar from '../Snackbar/Snackbar';
import { Snackbar, Button } from '@material-ui/core';

interface UpdateAvailableNotificationProps {
  open: boolean;
  onClick: () => void;
  onClose: () => void;
}

export default function UpdateAvailableNotification({ open, onClick, onClose }: UpdateAvailableNotificationProps) {
  // const roomState = useRoomState();

  return (
    // <Snackbar
    //   variant="error"
    //   headline="New content is available"
    //   message="Reconnecting to room..."
    //   // open={roomState === 'reconnecting'}
    // />
    <Snackbar
      open={open}
      message="A new version is available!"
      onClick={onClick}
      data-test-id="update-available-snackbar"
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      action={
        <Button color="inherit" size="small" onClick={onClick}>
          Reload
        </Button>
      }
    />
  );
}
