import React, { createContext, useContext, /*useReducer, */ useState, useEffect } from 'react';

export function getPhoneCode() {
  const match = window.location.search.match(/phoneCode=(.*)&?/);
  const phoneNumber = match ? match[1] : window.localStorage.getItem('phoneCode');

  return phoneNumber;
}

export function getPhoneNumber() {
  const match = window.location.search.match(/phoneNumber=(.*)&?/);
  const phoneNumber = match ? match[1] : window.localStorage.getItem('phoneNumber');

  return phoneNumber;
}

export interface StateContextType {
  authState: string;
  setAuthState: React.Dispatch<React.SetStateAction<string>>;
  isAppleSignIn: boolean;
  setIsAppleSignIn: React.Dispatch<React.SetStateAction<boolean>>;
  userPhoneCode: string;
  setUserPhoneCode: React.Dispatch<React.SetStateAction<string>>;
  userPhoneNumber: string;
  setUserPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  userPassword: string;
  setUserPassword: React.Dispatch<React.SetStateAction<string>>;
  userFirstName: string;
  setUserFirstName: React.Dispatch<React.SetStateAction<string>>;
  userFamilyName: string;
  setUserFamilyName: React.Dispatch<React.SetStateAction<string>>;
  userEmail: string;
  setUserEmail: React.Dispatch<React.SetStateAction<string>>;
  resetCode: string;
  setResetCode: React.Dispatch<React.SetStateAction<string>>;
  // identityToken: string;
  // setIdentityToken: React.Dispatch<React.SetStateAction<string>>;
  authorizationCode: string;
  setAuthorizationCode: React.Dispatch<React.SetStateAction<string>>;
  // error: /*TwilioError | */ Error | null;
  // setError(error: /*TwilioError | */ Error | null): void;
  // getBookings(params: any): Promise<{ content: Booking[]; totalElements: number }>;
  // getUpcomingBookings(params?: any): Promise<{ data: { content: Booking[]; totalElements: number } }>;
  // getInProgressBookings(params?: any): Promise<{ data: { content: Booking[]; totalElements: number } }>;
  // getCompletedBookings(params?: any): Promise<{ data: { content: Booking[]; totalElements: number } }>;
  // getBooking(bookingId: number, isPartner?: boolean): Promise<{ data: Booking }>;
  // getIncome(partnerId: string): Promise<{ monthIncome: number; totalIncome: number }>;
  // // getToken(name: string, room: string, passcode?: string): Promise<{ room_type: RoomType; token: string }>;
  // user?: null | User;
  // setUser(user: User): void;
  // setBooking(booking: Booking | null): void;
  // incomeInfo: Income;
  // setIncomeInfo(income: Income | null): void;
  // booking: Booking;
  // bookings?: Booking[];
  // upcomingBookings?: Booking[] | null;
  // inProgressBookings?: Booking[] | null;
  // completedBookings?: Booking[] | null;
  // numberOfCompletedBookings?: number;
  // hasMoreBookings?: boolean;
  // pageLoaded?: number;
  // dayAvailability?: { hash: string | number; content: Availability /*Availability[]*/ };
  // bookedTimeSlots?: Availability; //Availability[];
  // locations?: string[];
  // // getBookedTimeSlots?: { hash: string | number; content: Availability[] };
  // setDayAvailability: React.Dispatch<
  //   React.SetStateAction<{ hash: string | number; content: Availability /*Availability[]*/ }>
  // >;
  // signIn?(phoneCode: string, phoneNumber: string, password: string): Promise<void>;
  // signOut?(): Promise<void>;
  // isAuthReady?: boolean;
  // isFetching: boolean;
  // isBookingsFetching?: boolean;
  // isAvailabilityFetching: boolean; // We need different flags otherwise,
  // isAvailabilityLoading: boolean; // pop-up overlay from Availability will be shown
  // isAvailabilityLoaded: boolean; // for getBookedTimeSlots to know when to hide the loader
  // // activeSinkId: string;
  // // setActiveSinkId(sinkId: string): void;
  // // settings: Settings;
  // // dispatchSetting: React.Dispatch<SettingsAction>;
  // // roomType?: RoomType;
  // // updateRecordingRules(room_sid: string, rules: RecordingRules): Promise<object>;
  // loadPartnerCoverage(partnerId: string): Promise<{ data: string[] }>;
  // updatePartnerCoverage(partnerId: string, areas: CoveredAreas): Promise<object>;
  // runningLate(partnerId: string, bookingId: number, delayInMinutes?: number): Promise<object>;
  // // setRunningLate(partnerId: string, bookingId: string, delayInMinutes?: number): Promise<object>;
  // rateCustomer(
  //   partnerId: string,
  //   bookingId: number,
  //   strength: number,
  //   endurance: number,
  //   fitness: number,
  //   overall: number,
  //   comment: string
  // ): Promise<UserRating>;
  // startSession(partnerId: string, bookingId: number): Promise<object>;
  // stopSession(partnerId: string, bookingId: number): Promise<object>;
  // declineSession(partnerId: string, bookingId: number): Promise<object>;
  // cancelSession(partnerId: string, bookingId: number): Promise<object>;
  // getAvailability(partnerId: string): Promise<{ data: PartnerAvailability[] }>;
  // getBookedTimeSlots(partnerId: string): Promise<{ data: PartnerAvailability[] }>;
  // updateAvailability(partnerId: string, timeslots: PartnerAvailability[]): Promise<void>;
  // recoverPassword(email: string): Promise<void>;
  // code?: number | string;
  // passwordReset: boolean;
  // sendResetCode(phoneCode: string, phoneNumber: string): Promise<void>;
  // verifyPhoneNumberPasswordReset(phoneCode: string, phoneNumber: string, code: string): Promise<void>;
  // // setBookingCancelled: React.Dispatch<React.SetStateAction<boolean>>;
  // // isGalleryViewActive: boolean;
  // // setIsGalleryViewActive: React.Dispatch<React.SetStateAction<boolean>>;
  // // maxGalleryViewParticipants: number;
  // // setMaxGalleryViewParticipants: React.Dispatch<React.SetStateAction<number>>;
}

export const StateContext = createContext<StateContextType>(null!);

export default function SignUpStateProvider(props: React.PropsWithChildren<{}>) {
  const [authState, setAuthState] = useState<string>('passwordReset');
  // const [authState, setAuthState] = useState<string>('');
  const [isAppleSignIn, setIsAppleSignIn] = useState<boolean>(false);

  const [userPhoneCode, setUserPhoneCode] = useState<string>('+1');
  const [userPhoneNumber, setUserPhoneNumber] = useState<string>('');
  const [userPassword, setUserPassword] = useState<string>('');
  const [userFirstName, setUserFirstName] = useState<string>('');
  const [userFamilyName, setUserFamilyName] = useState<string>('');
  const [userEmail, setUserEmail] = useState<string>('');
  const [resetCode, setResetCode] = useState<string>('');
  // const [identityToken, setIdentityToken] = useState(null);
  const [authorizationCode, setAuthorizationCode] = useState<string>('');

  let contextValue = {
    authState,
    setAuthState,
    isAppleSignIn,
    setIsAppleSignIn,
    userPhoneCode,
    setUserPhoneCode,
    userPhoneNumber,
    setUserPhoneNumber,
    userPassword,
    setUserPassword,
    userFirstName,
    setUserFirstName,
    userFamilyName,
    setUserFamilyName,
    userEmail,
    setUserEmail,
    resetCode,
    setResetCode,
    // identityToken,
    // setIdentityToken,
    authorizationCode,
    setAuthorizationCode,
  } as StateContextType;

  useEffect(() => {
    const phoneCode = getPhoneCode();
    const phoneNumber = getPhoneNumber();

    if (phoneCode && phoneNumber) {
      setUserPhoneCode(phoneCode);
      setUserPhoneNumber(phoneNumber);
    }
  }, []);

  return (
    <StateContext.Provider
      value={{
        ...contextValue,
      }}
    >
      {props.children}
    </StateContext.Provider>
  );
}

export function useSignUpState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useSignUpState must be used within the AppStateProvider');
  }
  return context;
}
