import React from 'react';

export default function FileIcon() {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1680_3217)">
        <path d="M19.4478 1.45312H12.258C11.7779 0.587402 10.8549 0 9.79688 0C8.73889 0 7.81586 0.587402 7.33575 1.45312H6.04688C4.49597 1.45312 3.23438 2.71472 3.23438 4.26562V21.1875C3.23438 22.7384 4.49597 24 6.04688 24H19.448C20.9987 24 22.2605 22.7384 22.2605 21.1875V4.26562C22.2603 2.71472 20.9987 1.45312 19.4478 1.45312ZM20.3853 21.1875C20.3853 21.7044 19.9647 22.125 19.4478 22.125H6.04688C5.52997 22.125 5.10938 21.7044 5.10938 21.1875V4.26562C5.10938 3.74872 5.52997 3.32812 6.04688 3.32812H10.7344V10.0312C10.7344 10.5482 10.3138 10.9688 9.79688 10.9688C9.27997 10.9688 8.85938 10.5482 8.85938 10.0312V5.53125H6.98438V10.0312C6.98438 11.5822 8.24597 12.8438 9.79688 12.8438C11.3478 12.8438 12.6094 11.5822 12.6094 10.0312V3.32812H19.448C19.9649 3.32812 20.3855 3.74872 20.3855 4.26562V21.1875H20.3853ZM6.98438 18.375H14.7188V20.25H6.98438V18.375ZM6.98438 14.6719H18.4688V16.5469H6.98438V14.6719ZM14.4375 11.0156H18.4688V12.8906H14.4375V11.0156ZM14.4375 7.3125H18.4688V9.1875H14.4375V7.3125Z" fill="#6B7178"/>
      </g>
      <defs>
        <clipPath id="clip0_1680_3217">
          <rect width="24" height="24" fill="white" transform="translate(0.75)"/>
        </clipPath>
      </defs>
    </svg>
  );
}
