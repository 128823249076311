import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { User, BookingOptions } from '../../types';
import * as Sentry from '@sentry/browser';

// Utils
import { preparePhoneNumber, toQueryString } from '../../utils';
import { __API__, PAGE_SIZE } from '../../constants';

import useAnalyticsEventTracker from '../../hooks/useAnalyticsEventTracker/useAnalyticsEventTracker';

// const endpoint = ; // process.env.REACT_APP_TOKEN_ENDPOINT || '/token';

export function getAuthToken() {
  const match = window.location.search.match(/auth_token=(.*)&?/);
  const token = match ? match[1] : window.localStorage.getItem('auth_token');
  return token;
}

export function getUserId() {
  const match = window.location.search.match(/userId=(.*)&?/);
  const token = match ? match[1] : window.localStorage.getItem('userId');
  return token;
}

export function getPhoneCode() {
  const match = window.location.search.match(/phoneCode=(.*)&?/);
  const phoneCode = match ? match[1] : window.localStorage.getItem('phoneCode');

  return phoneCode;
}

export function getPhoneNumber() {
  const match = window.location.search.match(/phoneNumber=(.*)&?/);
  const phoneNumber = match ? match[1] : window.localStorage.getItem('phoneNumber');

  return phoneNumber;
}

export function getEmail() {
  const match = window.location.search.match(/email=(.*)&?/);
  const token = match ? match[1] : window.localStorage.getItem('email');
  return token;
}

export function getPassword() {
  const match = window.location.search.match(/password=(.*)&?/);
  const token = match ? match[1] : window.localStorage.getItem('password');
  return token;
}

export function authenticate(
  phoneCode: string,
  phoneNumber: string,
  // email: string,
  password: string
) {
  return fetch(`${process.env.REACT_APP_API_BASE_URL}/profiles/v1/auth/partners/login`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      phoneCode,
      phoneNumber: preparePhoneNumber(phoneNumber),
      // email,
      password,
    }),
  });
}

export function fetchUser(authToken: string, userId: string) {
  return fetch(`${__API__}/partners/${userId}`, {
    // method: 'POST',
    headers: {
      'content-type': 'application/json',
      auth_token: authToken,
    },
    // body: JSON.stringify({
    // email,
    // password,
    // }),
  });
}

function fetchBooking(userId: string, bookingId: number, accessToken: string, isPartner: boolean = true) {
  // const endpointUrl = `${__API__}/${isPartner ? 'partners' : 'users'}/${userId}/bookings/${bookingId}`; // process.env.REACT_APP_TOKEN_ENDPOINT || '/token';
  const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/bookings/v1/bookings/${bookingId}`;

  return fetch(endpointUrl, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      // auth_token: authToken,
      Authorization: `Bearer ${accessToken}`,
    },
    // body: JSON.stringify({
    //   email,
    //   password,
    // }),
  });
}

// function fetchBookings(
//   userId: string,
//   bookingId: number,
//   authToken: string,
//   isPartner: boolean = true
// ) {
//   const endpointUrl = `${__API__}/${isPartner ? 'partners' : 'users'}/${userId}/bookings`; // process.env.REACT_APP_TOKEN_ENDPOINT || '/token';

//   return fetch(endpointUrl, {
//     method: 'GET',
//     headers: {
//       'content-type': 'application/json',
//       'auth_token': authToken,
//     },
//     // body: JSON.stringify({
//     //   email,
//     //   password,
//     // }),
//   });
// }

// getBooking: async (userId: string, bookingId: string, isPartner?: boolean) => {
//         const endpoint = `${__API__}/users/${userId}/bookings/${bookingId}`; // process.env.REACT_APP_TOKEN_ENDPOINT || '/token';

//         return fetch(endpoint, {
//           method: 'GET',
//           headers: {
//             'content-type': 'application/json',
//             'auth_token': authToken,
//           },
//           // body: JSON.stringify({
//           //   user_identity,
//           //   room_name,
//           //   create_conversation: process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true',
//           // }),
//         }).then(res => res.json());
//       },

export function verifyToken(authToken: string, userId: string) {
  return fetchUser(authToken, userId).then(async res => {
    const jsonResponse = await res.json();
    if (res.status === 401) {
      return { isValid: false, error: jsonResponse.message };
    }

    // if (res.status === 422) {
    //   return { isValid: false, error: 'Incorrect email or password.' };
    // }

    if (jsonResponse.message) {
      return { isValid: false, error: jsonResponse.message };
    }

    if (res.ok && jsonResponse.authToken) {
      return {
        isValid: true,
        id: jsonResponse.id,
        fullName: `${jsonResponse.firstName} ${jsonResponse.lastName}`,
        firstName: jsonResponse.firstName,
        familyName: jsonResponse.lastName,
        authToken: jsonResponse.authToken.id,
        profilePicture: jsonResponse.userDetail.pictureUrl,
        // photoWithBackgroundUrl: verification.photoWithBackground,
        numberOfSessions: jsonResponse.numberOfSessions,
        numberOfRatedSessions: jsonResponse.numberOfRatedSessions,
        areas: jsonResponse.areas,
        partnerRating: jsonResponse.partnerRatingRecap.average,
        loginEmail: jsonResponse.email,
        region: jsonResponse.region,
        // dayAvailability:
      };
    }
  });
}

export function verifyCredentials(phoneCode: string, phoneNumber: string, password: string) {
  return authenticate(phoneCode, phoneNumber, password).then(async res => {
    const jsonResponse = await res.json();
    if (res.status === 401) {
      return { isValid: false, error: jsonResponse.message };
    }

    if (res.status === 422) {
      return { isValid: false, error: 'Incorrect email or password.' };
    }

    if (jsonResponse.message) {
      return { isValid: false, error: jsonResponse.message };
    }

    if (res.ok && jsonResponse.data.accessToken) {
      return {
        isValid: true,
        id: jsonResponse.data.customer.id,
        fullName: `${jsonResponse.data.customer.firstName} ${jsonResponse.data.customer.lastName}`,
        firstName: jsonResponse.data.customer.firstName,
        familyName: jsonResponse.data.customer.lastName,
        accessToken: jsonResponse.data.accessToken,
        profilePicture: jsonResponse.data.customer.photo,
        photoWithBackground: jsonResponse.data.customer.photoWithBackground,
        // numberOfSessions: jsonResponse.data.customer.numberOfSessions,
        // numberOfRatedSessions: jsonResponse.data.customer.numberOfRatedSessions,
        // areas: jsonResponse.data.customer.areas,
        // partnerRating: jsonResponse.data.customer.partnerRatingRecap.average,
        email: jsonResponse.data.customer.email,
        region: jsonResponse.data.customer.region,
      };
    }
  });
}

/**
 * Send customer a code to phone
 */
export function sendCode(phoneCode: string, phoneNumber: string, isAppleSignIn: boolean) {
  return fetch(
    `${process.env.REACT_APP_API_BASE_URL}/profiles/v1/auth/customers/code${isAppleSignIn ? '/apple' : ''}`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        phoneCode,
        phoneNumber: preparePhoneNumber(phoneNumber),
      }),
    }
  ).then(res => {
    if (!res.ok) {
      const error = new Error('There was an error verifying phone number');

      return Promise.reject(error);
    }
  });
}

/**
 * Customer verifies password reset code (second step for phone number verification)
 */
export function verifyPasswordResetCode(phoneCode: string, phoneNumber: string, code: string) {
  Sentry.captureMessage(
    `verifyPasswordResetCode - phoneCode#: ${phoneCode}, phoneNumber#: ${phoneNumber}, code#: ${code}`
  );

  return fetch(`${process.env.REACT_APP_API_BASE_URL}/profiles/v1/auth/partners/reset/verify`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      phoneCode,
      phoneNumber: preparePhoneNumber(phoneNumber),
      code,
    }),
  }).then(async res => {
    const jsonResponse = await res.json();

    Sentry.captureMessage(`jsonResponse#: ${JSON.stringify(jsonResponse)}`);

    if (!res.ok) {
      const error = new Error(`There was an error verifying code or code is incorrect (${code})`);

      return Promise.reject(error);
    }

    return {
      isValid: jsonResponse.data.verified,
      resetCode: jsonResponse.data.resetCode,
    };
  });
}

export function getErrorMessage(message: string) {
  switch (message) {
    case 'wrong number':
      return 'Check your phone number and try again';
    case 'code incorrect':
      return 'Code is incorrect, try again';
    case 'password or login incorrect':
      return 'Password or login is incorrect';
    case 'send reset code failed':
      return 'Send password reset code failed, try again';
    case 'reset password failed':
      return 'Reset password failed, try again';
    case 'check apple failed':
      return 'Something went wrong, please try again later.';
    case 'apple login failed':
      return 'Login with Apple failed, try again';
    default:
      return message;
  }
}

export default function useAuth() {
  const history = useHistory();
  const [, , , onSignIn] = useAnalyticsEventTracker();

  const [user, setUser] = useState<User | null>(null);
  const [userEmail, setUserEmail] = useState<string>('');
  // const [isBookingFetching, setIsBookingFetching] = useState(false);
  const [isAuthReady, setIsAuthReady] = useState(false);
  const [isPasswordResetReady, setIsPasswordResetReady] = useState<boolean>(false);
  const [resetCode, setResetCode] = useState<string | undefined>();

  const getBooking = useCallback(
    (bookingId: number, isPartner?: boolean) => {
      // setIsBookingFetching(true); // ???
      // return contextValue
      //   .getToken(name, room)
      //   .then(res => {
      //     setRoomType(res.room_type);
      //     setIsFetching(false);
      //     return res;
      //   })
      //   .catch(err => {
      //     setError(err);
      //     setIsFetching(false);
      //     return Promise.reject(err);

      return fetchBooking(user?.id!, bookingId, user?.accessToken!, isPartner)
        .then(async res => {
          if (res.ok) {
            return res;
          }
          const json = await res.json();
          const errorMessage = getErrorMessage(json.message || res.statusText);
          throw Error(errorMessage);
        })
        .then(res => res.json());
      // .finally(() => setIsBookingFetching(false)) // ???
    },
    [user]
  );

  // const getToken = useCallback(
  //   (name: string, room: string) => {
  //     return fetchToken(name, room, user!.passcode)
  //       .then(async res => {
  //         if (res.ok) {
  //           return res;
  //         }
  //         const json = await res.json();
  //         const errorMessage = getErrorMessage(json.error?.message || res.statusText);
  //         throw Error(errorMessage);
  //       })
  //       .then(res => res.json());
  //   },
  //   [user]
  // );

  // const updateRecordingRules = useCallback(
  //   async (room_sid, rules) => {
  //     return fetch('/recordingrules', {
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({ room_sid, rules, passcode: user?.authToken }),
  //       method: 'POST',
  //     }).then(async res => {
  //       const jsonResponse = await res.json();

  //       if (!res.ok) {
  //         const error = new Error(jsonResponse.error?.message || 'There was an error updating recording rules');
  //         error.code = jsonResponse.error?.code;

  //         return Promise.reject(error);
  //       }

  //       return jsonResponse;
  //     });
  //   },
  //   [user]
  // );

  const getBookings = useCallback(
    async params => {
      // async (params) => {
      // const endpointUrl = `${__API__}/partners/${user?.id}/bookings`;
      const endpointUrl = `${__API__}/partners/${user?.id}/bookings${
        params && Object.keys(params).length ? '?' + params.toQueryString(params) : ''
      }`;

      return fetch(endpointUrl, {
        headers: {
          'Content-Type': 'application/json',
          auth_token: user?.authToken!,
        },
        // body: JSON.stringify({ room_sid, rules, passcode: user?.authToken }),
        // method: 'POST',
      }).then(async res => {
        const jsonResponse = await res.json();

        if (!res.ok) {
          const error = new Error(jsonResponse.error?.message || 'There was an error getting bokings');
          error.code = jsonResponse.error?.code;

          return Promise.reject(error);
        }

        return jsonResponse;
      });
    },
    [user]
  );

  const getUpcomingBookings = useCallback(
    async (params?: BookingOptions) => {
      var filter = '(status==HAS_A_PARTNER);status!=*CANCELLED*;status!=UNKNOWN';
      var statuses = 'PAID,ACTIVE';

      if (!params) {
        params = {
          partnerId: user?.id!,
          // filter,
          // statuses,
          status: 'CREATED,PAID,ACTIVE', // 'CREATED'
          size: 300,
          // sort: 'startTime,asc',
          sort: 'start',
        };
      } else {
        params = {
          // @ts-ignore
          partnerId: user?.id!,
          // filter,
          // statuses,
          status: 'CREATED,PAID,ACTIVE', // 'CREATED'
          size: PAGE_SIZE,
          // sort: 'startTime,asc',
          sort: 'start',
          ...params,
        };
      }

      const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/bookings/v1/bookings?${toQueryString(params)}`;

      return fetch(endpointUrl, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.accessToken}`,
          // 'auth_token': user?.authToken!,
        },
        // body: JSON.stringify({ room_sid, rules, passcode: user?.authToken }),
        // method: 'POST',
      }).then(async res => {
        const jsonResponse = await res.json();

        if (!res.ok) {
          const error = new Error(jsonResponse.error?.message || 'There was an error getting bokings');
          error.code = jsonResponse.error?.code;

          return Promise.reject(error);
        }

        // setUpcomingBookings(jsonResponse.content as Booking[]);

        return jsonResponse;
      });
    },
    [user]
  );

  const getInProgressBookings = useCallback(
    async (params?: BookingOptions) => {
      var filter = '(status==IN_PROGRESS);status!=*CANCELLED*;status!=UNKNOWN';

      if (!params) {
        params = {
          partnerId: user?.id!,
          // filter,
          // statuses,
          status: 'ACTIVE', // 'CREATED'
          size: 300,
          // sort: 'startTime,asc',
          sort: 'start',
          region: 'US',
        };
      } else {
        params = {
          // @ts-ignore
          partnerId: user?.id!,
          // filter,
          // statuses,
          status: 'ACTIVE', // 'CREATED'
          size: PAGE_SIZE,
          // sort: 'startTime,asc',
          sort: 'start',
          ...params,
        };
      }

      const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/bookings/v1/bookings?${toQueryString(params)}`;
      // const endpointUrl = `${__API__}/partners/${user?.id}/bookings?${toQueryString(params)}`;

      return fetch(endpointUrl, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.accessToken}`,
          // auth_token: user?.authToken!,
        },
        // body: JSON.stringify({ room_sid, rules, passcode: user?.authToken }),
        // method: 'POST',
      }).then(async res => {
        const jsonResponse = await res.json();

        if (!res.ok) {
          const error = new Error(jsonResponse.error?.message || 'There was an error getting upcoming bokings');
          error.code = jsonResponse.error?.code;

          return Promise.reject(error);
        }

        // setInProgressBookings(jsonResponse.content as Booking[]);

        return jsonResponse;
      });
    },
    [user]
  );

  const getCompletedBookings = useCallback(
    async (params?: BookingOptions) => {
      let filter = '(status==COMPLETED,status==*CANCELLED*);status!=CANCELLED_REFUNDED;status!=UNKNOWN';

      if (!user) {
        return;
      }

      // if (!params) {
      //   params = {
      //     filter,
      //     size: PAGE_SIZE,
      //     sort: 'startTime,desc',
      //   };
      // } else {
      //   params = {
      //     filter,
      //     size: PAGE_SIZE,
      //     sort: 'startTime,desc',
      //     ...params,
      //   };
      // }

      if (!params) {
        params = {
          partnerId: user?.id!,
          status: 'COMPLETED', //,CANCELLED', // 'CREATED'
          size: 300,
          sort: 'start,desc',
          region: 'US',
        };
      } else {
        params = {
          // @ts-ignore
          partnerId: user?.id!,
          status: 'COMPLETED', //,CANCELLED', // 'CREATED'
          size: PAGE_SIZE,
          sort: 'start,desc',
          ...params,
        };
      }

      const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/bookings/v1/bookings?${toQueryString(params)}`;

      return fetch(endpointUrl, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.accessToken}`,
          // auth_token: user?.authToken!,
        },
        // body: JSON.stringify({ room_sid, rules, passcode: user?.authToken }),
        // method: 'POST',
      }).then(async res => {
        const jsonResponse = await res.json();

        if (!res.ok) {
          const error = new Error(jsonResponse.error?.message || 'There was an error getting completed bokings');
          error.code = jsonResponse.error?.code;

          return Promise.reject(error);
        }

        // setCompletedBookings(jsonResponse.content as Booking[]);

        return jsonResponse;
      });
    },
    [user]
  );

  const getIncome = useCallback(
    async (partnerId: string) => {
      const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/payments/v1/salaries?partnerId=${partnerId}`;

      return fetch(endpointUrl, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.accessToken}`,
        },
        // body: JSON.stringify({ room_sid, rules, passcode: user?.authToken }),
        // method: 'POST',
      }).then(async res => {
        const jsonResponse = await res.json();

        if (!res.ok) {
          const error = new Error(jsonResponse?.message || 'There was an error getting income info');
          error.code = jsonResponse?.code;

          return Promise.reject(error);
        }

        // setInProgressBookings(jsonResponse.content as Booking[]);

        return jsonResponse;
      });
    },
    [user]
  );

  // Get partner contacts (phoneCode, phoneNumber, email)

  const getPartnerContacts = useCallback(
    async (partnerId: string) => {
      const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/profiles/v1/partners/${partnerId}/contacts`;

      return fetch(endpointUrl, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.accessToken}`,
        },
        // body: JSON.stringify({ room_sid, rules, passcode: user?.authToken }),
        // method: 'POST',
      }).then(async res => {
        const jsonResponse = await res.json();

        if (!res.ok) {
          const error = new Error(jsonResponse?.message || 'There was an error getting partner contacts info');
          error.code = jsonResponse?.code;

          return Promise.reject(error);
        }

        setUserEmail(jsonResponse.data.email);

        return jsonResponse;
      });
    },
    [user]
  );

  useEffect(() => {
    // const authToken = getAuthToken();
    // const userId = getUserId();
    const phoneCode = getPhoneCode();
    const phoneNumber = getPhoneNumber();
    const email = getEmail();
    const password = getPassword();

    if (phoneCode && phoneNumber && password) {
      verifyCredentials(phoneCode, phoneNumber, password)
        .then(verification => {
          if (verification?.isValid) {
            setUser({
              id: verification.id,
              accessToken: verification.accessToken,
              displayName: verification.fullName,
              firstName: verification.firstName,
              familyName: verification.familyName,
              photoURL: verification.profilePicture,
              photoWithBackgroundUrl: verification.photoWithBackground,
              // numberOfSessions: verification.numberOfSessions,
              // numberOfRatedSessions: verification.numberOfSessions,
              // areas: verification.areas,
              // partnerRating: verification.partnerRating,
              loginEmail: verification.email,
              region: verification.region,
            } as any);

            window.localStorage.setItem('userId', verification.id);
            window.localStorage.setItem('accessToken', verification.accessToken);
            window.localStorage.setItem('phoneCode', phoneCode);
            window.localStorage.setItem('phoneNumber', phoneNumber);
            // window.localStorage.setItem('email', email);
            window.localStorage.setItem('password', password);

            Sentry.setUser({
              id: verification.id,
              // email: verification.email
            });

            // @ts-ignore
            onSignIn(verification.id, window.location.pathname);

            history.replace(window.location.pathname);
          } else {
            window.localStorage.removeItem('userId');
            window.localStorage.removeItem('accessToken');
          }
        })
        .then(() => setIsAuthReady(true))
        .catch(err => {
          console.log(err);
        });
    } else {
      setIsAuthReady(true);
    }
  }, [history]);

  /**
   * Get a reset code after successful customer's phone verification (second step)
   */
  const verifyPhoneNumberPasswordReset = useCallback((phoneCode: string, phoneNumber: string, code: string) => {
    setIsPasswordResetReady(false);

    return verifyPasswordResetCode(phoneCode, phoneNumber, code).then(verification => {
      if (verification.isValid) {
        setIsPasswordResetReady(true);
        setResetCode(verification.resetCode);
      } else {
        throw new Error(getErrorMessage('code incorrect'));
      }
    });
  }, []);

  /**
   * Customer requests for a password reset (first step phone number validation)
   */
  const sendResetCode = useCallback((phoneCode: string, phoneNumber: string) => {
    // const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/bookings/v1/bookings?${toQueryString(params)}`;

    return fetch(`${process.env.REACT_APP_API_BASE_URL}/profiles/v1/auth/partners/reset/code`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        phoneCode,
        phoneNumber: preparePhoneNumber(phoneNumber),
      }),
    }).then(async res => {
      const json = await res.json();

      if (res.status === 400 || res.status === 404) {
        const errorMessage = json.message || getErrorMessage(res.statusText || 'send reset code failed');
        throw Error(errorMessage);
      }

      if (!res.ok) {
        const error = new Error(getErrorMessage(res.statusText || 'send reset code failed'));

        return Promise.reject(error);
      }

      return json;
    });
  }, []);

  /**
   * Customer requests for a password reset (set new password after verification)
   */
  const resetPassword = useCallback(
    (phoneCode: string, phoneNumber: string, password: string) => {
      return fetch(`${process.env.REACT_APP_API_BASE_URL}/profiles/v1/auth/partners/reset`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          phoneCode,
          phoneNumber: preparePhoneNumber(phoneNumber),
          resetCode,
          password,
        }),
      }).then(async res => {
        const json = await res.json();

        if (res.status === 400) {
          const errorMessage = json.message || getErrorMessage(res.statusText || 'reset password failed');
          throw Error(errorMessage);
        }

        if (!res.ok) {
          const error = new Error(getErrorMessage(res.statusText || 'reset password failed'));

          return Promise.reject(error);
        }

        return json;
      });
    },
    [resetCode]
  );

  const signIn = useCallback((phoneCode: string, phoneNumber: string, password: string) => {
    return verifyCredentials(phoneCode, phoneNumber, password).then(verification => {
      if (verification?.isValid) {
        setUser({
          id: verification.id,
          accessToken: verification.accessToken,
          displayName: verification.fullName,
          firstName: verification.firstName,
          familyName: verification.familyName,
          photoURL: verification.profilePicture,
          photoWithBackgroundUrl: verification.photoWithBackground,
          // numberOfSessions: verification.numberOfSessions,
          // numberOfRatedSessions: verification.numberOfRatedSessions,
          // areas: verification.areas,
          // partnerRating: verification.partnerRating,
          loginEmail: verification.email,
          region: verification.region,
        } as any);

        window.localStorage.setItem('userId', verification.id);
        window.localStorage.setItem('accessToken', verification.accessToken);
        window.localStorage.setItem('phoneCode', phoneCode);
        window.localStorage.setItem('phoneNumber', phoneNumber);
        // window.localStorage.setItem('email', email);
        window.localStorage.setItem('password', password);

        Sentry.setUser({
          id: verification.id,
          // email: verification.email
        });

        // @ts-ignore
        onSignIn(verification.id, window.location.pathname);
      } else {
        window.localStorage.removeItem('userId');
        window.localStorage.removeItem('accessToken');

        throw new Error(getErrorMessage(verification?.error));
      }
    });
  }, []);

  const signOut = useCallback(() => {
    setUser(null);
    window.localStorage.removeItem('passcode');
    window.localStorage.removeItem('userId');
    window.localStorage.removeItem('auth_token');
    window.localStorage.removeItem('email');
    window.localStorage.removeItem('password');
    return Promise.resolve();
  }, []);

  return {
    user,
    setUser,
    userEmail,
    // bookings,
    // upcomingBookings,
    // inProgressBookings,
    isAuthReady,
    // isBookingFetching,
    // setIsBookingFetching,
    isPasswordResetReady,
    getBooking,
    getBookings,
    getUpcomingBookings,
    getInProgressBookings,
    getCompletedBookings,
    getIncome,
    getPartnerContacts,
    signIn,
    signOut,
    verifyPhoneNumberPasswordReset,
    sendResetCode,
    resetPassword,
    resetCode,
    // updateRecordingRules,
  };
}
