import React, { FC } from 'react';

import UpdateAvailableNotification from '../UpdateAvailableNotification/UpdateAvailableNotification';

import { register } from '../../serviceWorkerRegistration';
import { useSubscribe } from '../../hooks/useSubscribe/useSubscribe';
// import * as serviceWorkerRegistration from;

const ServiceWorkerWrapper: FC<{ onSWRegister?: any }> = ({ onSWRegister }) => {
  const [reloadShown, setReloadShown] = React.useState(false);
  const [, setInstallingWorker] = React.useState<ServiceWorker | null>(null);
  const [waitingWorker, setWaitingWorker] = React.useState<ServiceWorker | null>(null);
  const [pushId, setPushId] = React.useState<string>('');
  const [subscribeId, setSubscribeId] = React.useState<string>('');

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setReloadShown(true);
    setInstallingWorker(registration.installing);
    setWaitingWorker(registration.waiting);
  };

  React.useEffect(() => {
    register({ onUpdate: onSWUpdate, onSuccessRegister: onSWRegister });
  }, []);

  // Show a Snackbar only on `waiting` state of the SW.
  const reloadPage = () => {
    // installingWorker?.postMessage({ type: 'SKIP_WAITING' });
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    setReloadShown(false);
    // window.location.reload();
  };

  return (
    <UpdateAvailableNotification
      open={reloadShown}
      onClick={reloadPage}
      onClose={() => setReloadShown(false)}
      // data-test-id="screens-new-version-snackbar"
    />
  );
};

export default ServiceWorkerWrapper;
