import React, { PropsWithChildren, useCallback, useState, useEffect, ChangeEvent, MouseEvent } from 'react';
import {
  View,
  // ScrollView,
  useWindowDimensions,
  // NativeSyntheticEvent,
  // NativeScrollEvent,
  // Dimensions,
  StyleSheet,
} from 'react-native';
import { Area, AreaUK } from '../../types';
import clsx from 'clsx';
import { useAppState } from '../../state';
// import useWindowWidth from '../../hooks/useWindowWidth/useWindowWidth';

import { List as ListVirtualized, ScrollParams } from 'react-virtualized';

import Button from '@material-ui/core/Button';
// import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
// import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
// import { ReactComponent as GoogleLogo } from './google-logo.svg';
import { /*InputLabel, */ Theme } from '@material-ui/core';
// import IntroContainer from '../IntroContainer/IntroContainer';
// import RoundedContainer from '../RoundedContainer/RoundedContainer';

// import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';

// import TextField from '@material-ui/core/TextField';
// import Typography from '@material-ui/core/Typography';

// import LocationIcon from '../../icons/LocationIcon';
// import ArrowRightIcon from '../../icons/ArrowRightIcon';
// import PlusIcon from '../../icons/PlusIcon';
// import CloseIcon from '../../icons/Close2Icon';
import CheckBoxIcon from '../../icons/CheckBoxIcon';
import CheckBoxUncheckedIcon from '../../icons/CheckBoxUncheckedIcon';
import LoaderSpinnerIcon from '../../icons/LoaderSpinnerIcon';
import PlacesIcon from '../../icons/PlacesIcon';

import MapView from 'react-native-maps';

// import BottomNavigation from '../BottomNavigation/BottomNavigation';

import { makeStyles } from '@material-ui/core/styles';
// import { useLocation, useHistory } from 'react-router-dom';

// import { getDimentions } from '../../utils';
// import useHeight from '../../hooks/useHeight/useHeight';

import { areas } from '../../areas';
import { areas as areasUK /* : { areas : AreaUK[] }*/ } from '../../areas-uk';

const OVERFLOWED_ROW_HEIGHTS: { [key: string]: number } = {
  HP14: 130, // 203: 104,
  HP17: 107, // 206: 104,
  HP18: 154, // 207: 104,
  HP22: 154, // 211: 104,
  // 'HP23': 104, // 212: 104,
  HP27: 107, // 213: 104,
  LU2: 107, // 250: 104,
  LU5: 107, // 253: 104,
  // 254: 104,
  LU7: 199, // 255: 104,
  OX14: 107, // 329: 104,
  OX15: 199, // 330: 104,
  OX17: 222, // 332: 104,
  OX18: 176, // 333: 104,
  OX25: 154, // 335: 104,
  OX27: 130, // 337: 104,
  OX29: 176, // 339: 104,
  OX33: 107, // 340: 104,
  // 'RH10': 104, // 353: 104,
  RH17: 107, // 360: 104,
  // 420: 104,
  SL6: 154, // 422

  WD3: 104, // 566: 104,
};
const useStyles = makeStyles((theme: Theme) => ({
  // googleButton: {
  //   background: 'white',
  //   color: 'rgb(0, 94, 166)',
  //   'border-radius': '4px',
  //   border: '2px solid rgb(2, 122, 197)',
  //   margin: '1.8em 0 0.7em',
  //   'text-transform': 'none',
  //   'box-shadow': 'none',
  //   padding: '0.3em 1em',
  //   [theme.breakpoints.down('sm')]: {
  //     width: '100%',
  //   },
  //   '&:hover': {
  //     background: 'white',
  //     'box-shadow': 'none',
  //   },
  // },
  // errorMessage: {
  //   color: 'red',
  //   display: 'flex',
  //   'align-items': 'center',
  //   margin: '1em 0 0.2em',
  //   '& svg': {
  //     'margin-right': '0.4em',
  //   },
  // },
  // gutterBottom: {
  //   'margin-bottom': '1em',
  // },
  // gutterTopDoubleMedium: {
  //   'margin-top': '2em',
  // },
  // gutterBottomDoubleMedium: {
  //   'margin-bottom': '2em',
  // },
  // gutterBottomExtraExtraLarge: {
  //   'margin-bottom': 80
  // },
  // passcodeContainer: {
  //   'min-height': '120px',
  // },
  submitButton: {
    // [theme.breakpoints.down('sm')]: {
    //   width: '100%',
    // },
  },
  // whiteBasicColor: {
  //   color: theme.whiteBasic,
  // },
  // trueblackBasicColor: {
  //   color: theme.trueblackBasic,
  // },
  // redColor: {
  //   color: theme.red,
  // },
  // greyColor: {
  //   color: theme.grey,
  // },
  // greyMainColor: {
  //   color: theme.greyMain,
  // },
  // root: {
  //   'justify-content': 'center',
  //   color: theme.trueblackBasic,
  //   'min-width': 24
  // },
  // closeBackgroundSelection: {
  //   cursor: 'pointer',
  //   display: 'flex',
  //   background: 'transparent',
  //   border: '0',
  //   padding: '0',
  //   color: theme.red,
  // },

  loaderSpinner: {
    '& > svg': {
      right: '19px',
      top: '19px',
      position: 'absolute',
      'pointer-events': 'none',
      animation: '$spin 1s linear infinite',
      display: 'inline-block',
      'vertical-align': 'text-bottom',
      fill: 'currentColor',
      'z-index': 2,
    },
  },

  '@keyframes spin': {
    '100%': {
      'webkit-transform': 'rotate(360deg)',
      transform: 'rotate(360deg)',
    },
  },
}));

interface AddAreaProps {
  // open: boolean;
  isFetching: boolean;
  isFullyOpen: boolean;
  height: number;
  scrollEnabled?: boolean;
  refCallback?: any;
  filteredAreas: Area[];
  // onMomentumScrollEnd?: (event: NativeSyntheticEvent<NativeScrollEvent>) => void;
  onScroll?: (params: ScrollParams) => any;
  onClose(): void;
}

// const { width, height } = getDimentions();

export default function AddArea({
  isFetching,
  filteredAreas,
  height,
  scrollEnabled,
  refCallback,
  onScroll,
  onClose,
}: PropsWithChildren<AddAreaProps>) {
  const classes = useStyles();
  const { user, setUser, locations: partnerLocations, updatePartnerCoverage } = useAppState();
  // const history = useHistory();
  // const location = useLocation<{ from: Location }>();
  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');
  // const [passcode, setPasscode] = useState('');
  // const [districtFilter, setDistrictFilter] = useState('');
  // const [filteredAreas, setFilteredAreas] = useState(areas);
  const [selectedAreas, setSelectedAreas] = useState<Area[]>([]);
  // const [authError, setAuthError] = useState<Error | null>(null);

  // Why here?
  const [latLng, setLatLng] = useState({ latitude: 37.78825, longitude: -122.4324 });

  // const isAuthEnabled = true; // Boolean(process.env.REACT_APP_SET_AUTH);

  const { width } = useWindowDimensions();
  // const deviceHeight = useHeight();

  // console.log('AddArea height: ', height);

  // useWindowWidth();

  // // contains the list of all users
  // var originalList=[users]

  // // contains list of unselected users , initally will be originalList
  // var unselectedList=[]

  const listRef = React.useRef<ListVirtualized>(null);

  useEffect(() => {
    if (!partnerLocations || !partnerLocations.length || !user) {
      return;
    }

    const allAreas = getAreasBasedOnRegion(user!.region!);

    const userAreas = allAreas.filter(area => partnerLocations.find(v => v.replace('CA ', '') === area.zipCode));
    // var selectedAreas = areas.filter {textfield1:val, textfield2:val}

    setSelectedAreas(userAreas);
  }, [partnerLocations, user]);

  // contains object of selected users

  // const login = () => {
  //   setAuthError(null);
  //   signIn?.(email, password)
  //     .then(() => {
  //       history.replace(location?.state?.from || { pathname: '/', search: window.location.search });
  //     })
  //     .catch(err => {
  //       setAuthError(err);
  //       console.log(err);
  //     });
  // };

  const handleSubmit = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();

    let areasToSend = selectedAreas.map(v => v.zipCode);

    if (!areasToSend || !areasToSend.length) {
      return;
    }

    areasToSend = [...new Set(areasToSend)]; //.filter(area => !user?.areas?.includes(area));

    const allAreas = getAreasBasedOnRegion(user!.region!);

    areasToSend = allAreas.filter(area => areasToSend.includes(area.zipCode)).map(v => v.zipCode);

    areasToSend = [].concat.apply(
      [],
      // @ts-ignore
      areasToSend.map(v => v.split(', '))
    );
    // .map(v => `${parseInt(v)}`);

    updatePartnerCoverage(user?.id!, areasToSend).then(() => {
      setUser({
        ...user,
        areas: areasToSend,
      });

      onClose();
    });
  };

  // if (user || !isAuthEnabled) {
  //   history.replace('/');
  // }

  // if (!isAuthReady) {
  //   return null;
  // }

  // // const filterBySearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
  // const handleDisctrictInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
  //   // setDistrictFilter(e.target.value)
  //   // Access input value
  //   const query = e.target.value;

  //   // Create copy of item list
  //   var updatedList = [...areas];

  //   // Include all elements which includes the search query
  //   updatedList = areas.filter(v => v.outward.toLowerCase().includes(query.toLowerCase()) || v.town.toLowerCase().includes(query.toLowerCase()));

  //   // updatedList = updatedList.filter((item) =>
  //   //   return item.toLowerCase().indexOf(query.toLowerCase()) !== -1;
  //   // });

  //   // Trigger render with updated values
  //   setFilteredAreas(updatedList);
  // }, [setFilteredAreas]);

  const toggleArea = useCallback(
    (area, checked?) => {
      return (e: ChangeEvent<HTMLInputElement> | MouseEvent<HTMLDivElement>) => {
        e.preventDefault(); // we can all this directly here now!
        // e.stopPropagation(); // do we need it here?

        // Create copy of item list
        var updatedList = [...selectedAreas];

        // Include/remove chosen element
        // @ts-ignore
        if (checked || e.target.checked) {
          updatedList.push(area);
        } else {
          updatedList = updatedList.filter(v => v.zipCode !== area.zipCode);
        }

        // updatedList = areas.filter(v => v.outward.includes(query) || v.town.includes(query));

        // let categoryUpdate =  await updateCategory(catId);

        // let timeCheck = checkTime(timeAdded);

        // if(!categoryUpdate) {
        //     return console.log("Error, category was not updated")
        // }
        // if(!timeCheck) {
        //     return console.log("Error, item has been inside your cart for too long, now you gotta buy it")
        // }

        // let newItems = items.filter( i => i.id != id)
        // setItems(newItems)

        // console.log(e.target.checked);
        // this.setState({ isTrue: e.target.checked });

        setSelectedAreas(updatedList);

        listRef?.current?.forceUpdateGrid();
      };
    },
    [selectedAreas, setSelectedAreas]
  );

  const listData: Area[] = filteredAreas.filter(area => area.zipCode || (area.zipCode === '' && area.region));
  // const listData = filteredAreas.filter(area => area.outward);

  const renderRow = ({ index, key, style }: { index: number; key: any; style: any }) => {
    const listDataItem = listData[index];
    const checked = !!selectedAreas.find(v => v.zipCode === listDataItem.zipCode);

    return (
      <ListItem
        button
        style={{
          ...style,
          'padding-left': '0', // 16,
          'padding-right': '0', // 16,
          // 'margin-left': 16,
          // 'margin-right': 16,
          'background-color': 'white',
          // display="flex"
          // // alignItems="center"
          // alignItems="start"
          // justifyContent="space-between"
          // flex="1"
          //   // maxHeight: '80px',
          //   overflow: 'hidden',
        }}
        key={`${key}_${checked}`}
        onClick={toggleArea(listDataItem, !checked)}
      >
        {/*<ListItemIcon
          classes={{
            root: classes.root,
          }}
        >
          <LocationIcon />
        </ListItemIcon>*/}
        <ListItemText
          primaryTypographyProps={{
            variant: 'button',
            display: 'inline',
            color: 'primary',
          }}
          secondaryTypographyProps={{
            variant: listDataItem && listDataItem.zipCode === '' && listDataItem.region ? 'button' : 'subtitle1',
            display: 'inline',
            color: 'primary',
          }}
          primary={`${listDataItem && listDataItem.zipCode}, `}
          secondary={listDataItem && listDataItem.city ? listDataItem.city : listDataItem.county}
          // style={{ marginLeft: 16 }}
        />
        {listDataItem && listDataItem.zipCode ? (
          <Checkbox
            checked={checked}
            onChange={toggleArea(listDataItem)}
            // value="checkedA"
            checkedIcon={<CheckBoxIcon />}
            icon={<CheckBoxUncheckedIcon />}
            // inputProps={{ 'aria-label': 'Checkbox A' }}
            // onToggle={handle
            style={{
              alignSelf: 'center',
            }}
          />
        ) : null}

        <Divider style={{ position: 'absolute', left: '0', right: '0', bottom: '0' }} />
      </ListItem>
    );
  };

  // console.log('AddArea.scrollEnabled: ', scrollEnabled);

  return (
    <Box
      // px={3}
      // pt={3}
      pb={12}
      // display="flex"
      flexGrow={1}
    >
      <Container maxWidth="md" disableGutters>
        <ListVirtualized
          // key={`${scrollEnabled}`}
          // key={`${selectedAreas.length}`}
          // ref={refCallback}
          ref={listRef}
          width={width}
          height={height - 250}
          rowHeight={({ index }: { index: number }) =>
            // @ts-ignore
            OVERFLOWED_ROW_HEIGHTS[listData[index].zipCode] ? OVERFLOWED_ROW_HEIGHTS[listData[index].zipCode] : 96
          }
          rowRenderer={renderRow}
          rowCount={listData.length}
          // onScroll={onScroll}
          style={{
            paddingLeft: '16px',
            paddingRight: '16px',
          }}
          // style={{ overflow: scrollEnabled ? 'hidden auto' : 'hidden' }}
        />
      </Container>

      <View style={styles.ctaContainer}>
        <Button
          disabled={isFetching}
          variant="contained"
          color="primary"
          type="submit"
          className={classes.submitButton}
          onClick={handleSubmit}
        >
          Save
          {isFetching && (
            <Box
              display="flex"
              className={clsx(classes.loaderSpinner)}
              sx={{
                marginRight: '4px',
              }}
            >
              <LoaderSpinnerIcon />
            </Box>
          )}
        </Button>
      </View>
    </Box>
  );
}

const styles = StyleSheet.create({
  ctaContainer: {
    right: '0',
    left: '0',
    position: 'absolute',
    bottom: '0',
    padding: '16px',
    'background-color': 'rgb(255, 255, 255)',
  },
});

const getAreasBasedOnRegion = (region: string) => {
  const allAreas: Area[] = region === 'US' ? areas : areasUK.map(mapAreaUKtoArea);

  return allAreas;
};

function mapAreaUKtoArea(val: AreaUK): Area {
  return {
    zipCode: val.outward, // default or derive from AreaUK properties
    city: val.town, // assume town is equivalent to city
    county: val.county,
    neighbourhood: undefined, // not present in AreaUK
    region: undefined, // not present in AreaUK
    coordinates: val.coordinates,
  };
}
