import React /*ChangeEvent, useState, useRef, FormEvent*/ from 'react';
import // Platform,
// View,
// Dimensions
'react-native';
import clsx from 'clsx';
import { useAppState } from '../../state';

import ScrollToTopOnMount from '../ScrollToTopOnMount/ScrollToTopOnMount';

// import Button from '@material-ui/core/Button';
// import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
// import Grid from '@material-ui/core/Grid';
// import Container from '@material-ui/core/Container';
// import Box from '@material-ui/core/Box';
// import { ReactComponent as GoogleLogo } from './google-logo.svg';
import { Theme } from '@material-ui/core';
// import IntroContainer from '../IntroContainer/IntroContainer';
import RoundedContainer from '../RoundedContainer/RoundedContainer';

// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
// import Divider from '@material-ui/core/Divider';

// import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// import SlidingUpPanel from 'rn-sliding-up-panel';
import AvailabilityPicker from '../AvailabilityPicker/AvailabilityPicker';

// import LocationIcon from '../../icons/LocationIcon';
// import ArrowRightIcon from '../../icons/ArrowRightIcon';
// import PlusIcon from '../../icons/PlusIcon';

import BottomNavigation from '../BottomNavigation/BottomNavigation';

import { makeStyles } from '@material-ui/core/styles';
// import { useLocation, useHistory } from 'react-router-dom';

// import { getViewportHeight, isIosDevice, } from '../../utils';

// import { areas } from '../../areas';

// const ios = Platform.OS === 'ios' || isIosDevice;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    'padding-top': '24px',
    'padding-bottom': '32px',
    'text-align': 'center',
  },
  // googleButton: {
  //   background: 'white',
  //   color: 'rgb(0, 94, 166)',
  //   'border-radius': '4px',
  //   border: '2px solid rgb(2, 122, 197)',
  //   margin: '1.8em 0 0.7em',
  //   'text-transform': 'none',
  //   'box-shadow': 'none',
  //   padding: '0.3em 1em',
  //   [theme.breakpoints.down('sm')]: {
  //     width: '100%',
  //   },
  //   '&:hover': {
  //     background: 'white',
  //     'box-shadow': 'none',
  //   },
  // },
  // errorMessage: {
  //   color: 'red',
  //   display: 'flex',
  //   'align-items': 'center',
  //   margin: '1em 0 0.2em',
  //   '& svg': {
  //     'margin-right': '0.4em',
  //   },
  // },
  // gutterBottom: {
  //   'margin-bottom': '1em',
  // },
  // gutterTopDoubleMedium: {
  //   'margin-top': '2em',
  // },
  gutterBottomDoubleMedium: {
    'margin-bottom': '2em',
  },
  // gutterBottomExtraExtraLarge: {
  //   'margin-bottom': 80
  // },
  // passcodeContainer: {
  //   'min-height': '120px',
  // },
  // submitButton: {
  //   [theme.breakpoints.down('sm')]: {
  //     width: '100%',
  //   },
  // },
  // addAreaCtaButton: {
  //   'margin-bottom': 8,
  //   [theme.breakpoints.down('sm')]: {
  //     width: '100%',
  //   },
  // },
  // whiteBasicColor: {
  //   color: theme.whiteBasic,
  // },
  // trueblackBasicColor: {
  //   color: theme.trueblackBasic,
  // },
  // redColor: {
  //   color: theme.red,
  // },
  // greyColor: {
  //   color: theme.grey,
  // },
  greyMainColor: {
    color: theme.greyMain,
  },
  root: {
    'justify-content': 'center',
    color: theme.trueblackBasic,
    'min-width': '24px',
  },
}));

// const styles = {
//   container: {
//     flex: 1,
//     'background-color': 'rgb(255, 255, 255)',
//     'align-items': 'center',
//     'justify-content': 'center',
//     'border-radius': '8px',
//     height: '100%',
//   },
// };

// const panelStyles = {
//   backdrop: {
//       position: 'fixed',
//       'z-index': 1000,
//   },
//   container: {
//     position: 'fixed',
//     // flex: 1,
//     // 'background-color': 'rgb(255, 255, 255)',
//     // 'align-items': 'center',
//     // 'justify-content': 'center',
//     'z-index': 1000,
//   },
// };

// interface AreasPageProps {
//   // open: boolean;
//   // onClose(): void;
// }

// const height = getViewportHeight();

export default function AvailabilityCalendarPage() {
  const classes = useStyles();

  const { user } = useAppState();
  // const history = useHistory();
  // const location = useLocation<{ from: Location }>();
  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');
  // const [passcode, setPasscode] = useState('');
  // const [authError, setAuthError] = useState<Error | null>(null);

  // const isAuthEnabled = true; // Boolean(process.env.REACT_APP_SET_AUTH);

  // const login = () => {
  //   setAuthError(null);
  //   signIn?.(email, password)
  //     .then(() => {
  //       history.replace(location?.state?.from || { pathname: '/', search: window.location.search });
  //     })
  //     .catch(err => {
  //       setAuthError(err);
  //       console.log(err);
  //     });
  // };

  // const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();
  //   login();
  // };

  // if (user || !isAuthEnabled) {
  //   history.replace('/');
  // }

  // if (!isAuthReady) {
  //   return null;
  // }

  // const panelRef = useRef<SlidingUpPanel>(null);

  // const userAreas = user && user.areas ? user.areas.map(area => areas.find(v => v.outward === area)) : []

  return (
    <>
      <ScrollToTopOnMount />
      {/*ios && (
      <View style={{
          position: 'absolute',
          height: 1000,
          top: -1000,
          'background-color': theme.greyLight,
          width: '100%',

        }}
      />
    )*/}
      <div>
        <div className={classes.container}>
          <Typography
            variant="button"
            className={clsx(classes.gutterBottomDoubleMedium, classes.greyMainColor)}
            style={{
              marginTop: 0,
            }}
          >
            Availability Calendar
          </Typography>
        </div>

        <RoundedContainer
          style={{
            // position: 'initial',
            // height: '100%',
            // height: 'calc(100vh - 83px)',
            // 'min-height': '-webkit-fill-available',
            // 'margin-bottom': 'env(safe-area-inset-bottom)',
            paddingBottom: '82px',
          }}
        >
          {/*<Typography variant="h3" className={clsx(classes.gutterBottom, classes.greyColor)}>
        {userAreas.length} postcodes covered:
    </Typography>*/}

          {/*<div style={{ 'margin-bottom': '32px' }}>*/}
          <AvailabilityPicker region={user ? user.region : 'US'} />
          {/*</div>*/}

          {/*<Grid container justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          type="submit"
          onClick={() => panelRef.current && panelRef.current.show()}
          // disabled={!password.length}
          className={classes.addAreaCtaButton}
        >
          <PlusIcon />
          Add Area
        </Button>
      </Grid>*/}

          {/*<form onSubmit={handleSubmit}>
        <Grid container justifyContent="space-between">
          <div className={classes.passcodeContainer}>
            <InputLabel shrink htmlFor="input-passcode">
              Email
            </InputLabel>
            <TextField
              id="input-email"
              onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
              type="text"
              variant="outlined"
              size="small"
            />
            <InputLabel shrink htmlFor="input-passcode">
              Password
            </InputLabel>
            <TextField
              id="input-passcode"
              onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
              type="password"
              variant="outlined"
              size="small"
            />
            <div>
              {authError && (
                <Typography variant="caption" className={classes.errorMessage}>
                  <ErrorOutlineIcon />
                  {authError.message}
                </Typography>
              )}
            </div>
          </div>
        </Grid>
        <Grid container justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!password.length}
            className={classes.submitButton}
          >
            Submit
          </Button>
        </Grid>
      </form>*/}
        </RoundedContainer>
      </div>
      <BottomNavigation />
      {/*ios && (
      <View style={{
          position: 'absolute',
          height: 1000,
          bottom: -1000,
          'background-color': theme.greyLight,
          width: '100%',

        }}
      />
    )*/}
    </>
  );
}
