/* global google */
import React, { useState, useCallback } from 'react';
import {
  View,
  // ScrollView,
  StyleSheet,
} from 'react-native';
import clsx from 'clsx';
import { CoveredArea, Area, AreaUK } from '../../types';
import { useAppState } from '../../state';

import ScrollToTopOnMount from '../ScrollToTopOnMount/ScrollToTopOnMount';

import Button from '@material-ui/core/Button';
// import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
// import Box from '@material-ui/core/Box';
// import { ReactComponent as GoogleLogo } from './google-logo.svg';
import { /*InputLabel,*/ Theme } from '@material-ui/core';
// import IntroContainer from '../IntroContainer/IntroContainer';
import RoundedContainer from '../RoundedContainer/RoundedContainer';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

// import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// import SlidingUpPanel from 'rn-sliding-up-panel';
// import { Drawer as TruBeDrawer } from '../Drawer/Drawer';
import { AddAreaPopup } from '../AddAreaPopup/AddAreaPopup';

// Hooks
import useGeocoder from '../../hooks/useGeocoder/useGeocoder';

import LocationIcon from '../../icons/LocationIcon';
// import ArrowRightIcon from '../../icons/ArrowRightIcon';
import PlusIcon from '../../icons/PlusIcon';
import PlacesIcon from '../../icons/PlacesIcon';

import MapView, { LatLng, Point } from 'react-native-maps';

import BottomNavigation from '../BottomNavigation/BottomNavigation';

import { makeStyles } from '@material-ui/core/styles';
// import { useLocation, useHistory } from 'react-router-dom';

// import { getViewportHeight } from '../../utils';

import { areas } from '../../areas';
import { areas as areasUK /* : { areas : AreaUK[] }*/ } from '../../areas-uk';

const useStyles = makeStyles((theme: Theme) => ({
  // googleButton: {
  //   background: 'white',
  //   color: 'rgb(0, 94, 166)',
  //   'border-radius': '4px',
  //   border: '2px solid rgb(2, 122, 197)',
  //   margin: '1.8em 0 0.7em',
  //   'text-transform': 'none',
  //   'box-shadow': 'none',
  //   padding: '0.3em 1em',
  //   [theme.breakpoints.down('sm')]: {
  //     width: '100%',
  //   },
  //   '&:hover': {
  //     background: 'white',
  //     'box-shadow': 'none',
  //   },
  // },
  // errorMessage: {
  //   color: 'red',
  //   display: 'flex',
  //   'align-items': 'center',
  //   margin: '1em 0 0.2em',
  //   '& svg': {
  //     'margin-right': '0.4em',
  //   },
  // },
  gutterBottom: {
    'margin-bottom': '1em',
  },
  gutterTopDoubleMedium: {
    'margin-top': '2em',
  },
  gutterBottomDoubleMedium: {
    'margin-bottom': '2em',
  },
  gutterBottomExtraExtraLarge: {
    'margin-bottom': '80px',
  },
  passcodeContainer: {
    'min-height': '120px',
  },
  submitButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  addAreaCtaButton: {
    // 'margin-bottom': 16,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  whiteBasicColor: {
    color: theme.whiteBasic,
  },
  trueblackBasicColor: {
    color: theme.trueblackBasic,
  },
  redColor: {
    color: theme.red,
  },
  greyColor: {
    color: theme.grey,
  },
  greyMainColor: {
    color: theme.greyMain,
  },
  root: {
    'justify-content': 'center',
    color: theme.trueblackBasic,
    'min-width': '24px',
  },
}));

// const styles = {
//   container: {
//     flex: 1,
//     'background-color': 'rgb(255, 255, 255)',
//     'align-items': 'center',
//     'justify-content': 'center',
//     'border-radius': '8px',
//     height: '100%',
//   },
// };

// const panelStyles = {
//   backdrop: {
//       position: 'fixed',
//       'z-index': 1000,
//   },
//   container: {
//     position: 'fixed',
//     // flex: 1,
//     // 'background-color': 'rgb(255, 255, 255)',
//     // 'align-items': 'center',
//     // 'justify-content': 'center',
//     'z-index': 1000,
//   },
// };

//interface AreasPageProps {
// open: boolean;
// onClose(): void;
//}

// const height = getViewportHeight();

const MapMarker = ({ area, onDragEnd, ...rest }: { area: CoveredArea; onDragEnd: any }) => {
  const handleDragEnd = useCallback(
    (e: any) => {
      onDragEnd(e, area);
    },
    [area]
  );

  return (
    <>
      {/* @ts-ignore */}
      <MapView.Marker
        {...rest}
        coordinate={area.coordinate}
        onDragEnd={handleDragEnd}
        // title={"title"}
        // description={"description"}
      >
        <PlacesIcon />
        {/* @ts-ignore */}
      </MapView.Marker>
    </>
  );
};

export default function AreasPage() {
  const classes = useStyles();
  const { user, locations, loadPartnerCoverage, isFetching } = useAppState();

  const [geocoder, setGeocoder] = useState(null);

  // const history = useHistory();
  // const location = useLocation<{ from: Location }>();
  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');
  // const [passcode, setPasscode] = useState('');
  const [addAreaDrawerOpen, setAddAreaDrawerOpen] = useState<boolean>(false);
  const [partnerAreas, setPartnerAreas] = useState<Area[]>([]);
  // const [authError, setAuthError] = useState<Error | null>(null);

  const [latLng, setLatLng] = useState({ latitude: 37.78825, longitude: -122.4324 });
  const [addressInfo, setAddressInfo] = useState();
  const [_areas, setAreas] = useState<CoveredArea[]>([]);

  const [, lookupAddress] = useGeocoder(geocoder);

  const areasMapRef = React.useRef(new Map());

  React.useEffect(() => {
    if (!user) {
      return;
    }

    // @ts-ignore
    loadPartnerCoverage(user.id);

    // const userAreas = areas.filter(area => user && user.areas && user.areas.find(v => v === `CA ${area.zipCode}`));
    // var selectedAreas = areas.filter {textfield1:val, textfield2:val}

    // setSelectedAreas(userAreas);
  }, [user]);

  React.useEffect(() => {
    if (!locations!.length || !user) {
      return;
    }

    const allAreas: Area[] = user.region === 'US' ? areas : areasUK.map(mapAreaUKtoArea);

    function mapAreaUKtoArea(val: AreaUK): Area {
      return {
        zipCode: val.outward, // default or derive from AreaUK properties
        city: val.town, // assume town is equivalent to city
        county: val.county,
        neighbourhood: undefined, // not present in AreaUK
        region: undefined, // not present in AreaUK
        coordinates: val.coordinates,
      };
    }

    // const userAreas = areas.filter(area => user && user.areas && user.areas.find(v => v === `CA ${area.zipCode}`));
    const partnerLocations = allAreas.filter(area =>
      locations?.find(v =>
        area.zipCode
          .split(', ')
          // .map(val => `${parseInt(val)}`)
          .includes(v.replace('CA ', ''))
      )
    );
    // var selectedAreas = areas.filter {textfield1:val, textfield2:val}

    setPartnerAreas(partnerLocations);
  }, [locations, user]);

  // areasMapRef.current = ;

  // const isAuthEnabled = true; // Boolean(process.env.REACT_APP_SET_AUTH);

  // const login = () => {
  //   setAuthError(null);
  //   signIn?.(email, password)
  //     .then(() => {
  //       history.replace(location?.state?.from || { pathname: '/', search: window.location.search });
  //     })
  //     .catch(err => {
  //       setAuthError(err);
  //       console.log(err);
  //     });
  // };

  // const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();
  //   login();
  // };

  // if (user || !isAuthEnabled) {
  //   history.replace('/');
  // }

  // if (!isAuthReady) {
  //   return null;
  // }

  const handleMapReady = useCallback(() => {
    // @ts-ignore
    setGeocoder(new google.maps.Geocoder());
  }, []);

  const handleMapClick = useCallback(
    e => {
      const newAreas = [..._areas];

      const newArea = {
        coordinate: {
          latitude: e.latLng.lat(),
          longitude: e.latLng.lng(),
        },
        postalCode: '',
      };

      // @ts-ignore
      lookupAddress(e.latLng).then((res: any) => {
        if (!res) {
          return;
        }

        var postalCodeAddressComponent = res.address_components.find((val: any) => val.types.includes('postal_code'));
        var postalCodeSuffixAddressComponent = res.address_components.find((val: any) =>
          val.types.includes('postal_code_suffix')
        );
        var neighborhoodAddressComponent = res.address_components.find((val: any) =>
          val.types.includes('neighborhood')
        );

        if (postalCodeAddressComponent) {
          // const lastAreas = Array.from(areasMapRef.current, ([coordinate, postalCode, postalCodeSuffix, neighborhood]) => ({ coordinate, postalCode, postalCodeSuffix, neighborhood }));
          const lastAreas = Array.from(areasMapRef.current, ([key, value]) => ({ ...value }));

          if (!lastAreas.find(val => val.postalCode === postalCodeAddressComponent.long_name)) {
            newArea.postalCode = postalCodeAddressComponent.long_name;
            // @ts-ignore
            newArea.postalCodeSuffix = postalCodeSuffixAddressComponent?.long_name;
            // @ts-ignore
            newArea.neighborhood = neighborhoodAddressComponent?.long_name;

            newAreas.push(newArea);

            setAreas(newAreas);

            newAreas.forEach(area => {
              areasMapRef.current.set(area, area);
            });
          }
        } else {
          console.error('Postal code was not found to this coordinates!!');
        }
      });
    },
    [_areas, lookupAddress]
  );

  const handleDragEnd = (e: any, area: CoveredArea) => {
    if (!e || !e.latLng) {
      return;
    }

    // setLatLng({ latitude: e.latLng.lat(), longitude: e.latLng.lng() });

    area.coordinate.latitude = e.latLng.lat();
    area.coordinate.longitude = e.latLng.lng();

    // @ts-ignore
    lookupAddress(e.latLng).then((res: any) => {
      if (res) {
        setAddressInfo(res);

        var postalCodeAddressComponent = res.address_components.find((val: any) => val.types.includes('postal_code'));
        var postalCodeSuffixAddressComponent = res.address_components.find((val: any) =>
          val.types.includes('postal_code_suffix')
        );
        var neighborhoodAddressComponent = res.address_components.find((val: any) =>
          val.types.includes('neighborhood')
        );

        if (postalCodeAddressComponent) {
          // let areaWithTheSamePostalCode =

          // const lastAreas = Array.from(areasMapRef.current, ([coordinate, postalCode, postalCodeSuffix, neighborhood]) => ({ coordinate, postalCode, postalCodeSuffix, neighborhood }));

          const lastAreas = Array.from(areasMapRef.current, ([key, value]) => ({ ...value }));

          if (lastAreas.find(val => val !== area && val.postalCode === postalCodeAddressComponent.long_name)) {
            areasMapRef.current.delete(area); // [..._areas].filter(val => val.postalCode !== area.postalCode);

            // let newAreas = Array.from(areasMapRef.current, ([coordinate, postalCode, postalCodeSuffix, neighborhood]) => ({ coordinate, postalCode, postalCodeSuffix, neighborhood }));

            let newAreas = Array.from(areasMapRef.current, ([key, value]) => ({ ...value }));

            setAreas(newAreas);

            // newAreas.forEach((area) => {
            //   areasMapRef.current.set(area, area);
            // });
          } else {
            // area.coordinate.latitude = res.geometry.location.lat();
            // area.coordinate.longitude = res.geometry.location.lng();
            area.postalCode = postalCodeAddressComponent.long_name;
            area.postalCodeSuffix = postalCodeSuffixAddressComponent?.long_name;
            area.neighborhood = neighborhoodAddressComponent?.long_name;

            setAreas([..._areas]);

            // _areas.forEach((area) => {
            //   areasMapRef.current.set(area, area);
            // });
          }
        } else {
          console.error('Postal code was not found to this coordinates!!');
        }
      }
    });
  }; //, [lookupAddress, _areas]
  //);

  const handleAddAreaClick = useCallback(() => {
    setAddAreaDrawerOpen(true);
  }, [setAddAreaDrawerOpen]);

  const closeAreaDrawer = useCallback(() => {
    setAddAreaDrawerOpen(false);
  }, [setAddAreaDrawerOpen]);

  // const panelRef = useRef<SlidingUpPanel>(null);

  // const userAreas = user && user.areas ? user.areas.map(area => areas.find(v => `CA ${v.zipCode}` === area)) : []

  return (
    <>
      <ScrollToTopOnMount />
      <div>
        <Container style={{ paddingTop: '32px' }}>
          <Typography
            variant="h2"
            className={clsx(classes.gutterBottomDoubleMedium, classes.greyMainColor)}
            style={{ marginTop: '0', marginBottom: '32px' }}
          >
            AREAS COVERED
          </Typography>
        </Container>

        <RoundedContainer
          style={{
            // height: '100%',

            height: 'calc(100% - 104px)',
            // 'min-height': '-webkit-fill-available',
            // 'margin-bottom': 'env(safe-area-inset-bottom)',
            'padding-bottom': '82px',
            // 'padding-bottom': '86px'
          }}
          innerContainerStyle={{
            overflow: 'scroll',
            // 'padding-bottom': 16
          }}
        >
          <Container style={{ paddingTop: '32px', paddingBottom: '96px' }}>
            <Typography variant="h3" className={clsx(classes.gutterBottom, classes.greyColor)}>
              {partnerAreas.length ? `${partnerAreas.length} zip codes covered:` : 'No zip codes covered.'}
            </Typography>

            {/*<Container maxWidth="sm" disableGutters>*/}
            <Grid container justifyContent="flex-end" style={{ flexShrink: 0 }}>
              <div
              // mt={3}
              // mb={3}
              // sx={{
              //   position: 'relative',
              //   height: '154px'
              // }}
              // style={{
              //   position: 'relative',
              //   // marginTop: "24px",
              //   // marginBottom: "8px",
              //   width: '100%',
              //   height: '460px',
              //   borderRadius: '8px',
              //   overflow: 'hidden',
              // }}
              >
                {/*<MapView*/}
                {/*// width="343pxh"*/}
                {/*initialRegion={{*/}
                {/*latitude: 37.78825,*/}
                {/*longitude: -122.4324,*/}
                {/*latitudeDelta: 0.0922,*/}
                {/*longitudeDelta: 0.0421,*/}
                {/*}}*/}
                {/*onMapReady={handleMapReady}*/}
                {/*onPress={handleMapClick}*/}
                {/*// onPress={({ latLng }) => setLatLng({ latitude: latLng.lat(), longitude: latLng.lng() })}*/}
                {/*// ref={mapViewRef}*/}
                {/*>*/}
                {/*{_areas.map(area => (*/}
                {/*<>*/}
                {/* @ts-ignore*/}
                {/*<MapMarker*/}
                {/*key={area.postalCode}*/}
                {/*area={area}*/}
                {/*coordinate={latLng}*/}
                {/*draggable*/}
                {/*onDragEnd={handleDragEnd}*/}
                {/*// title={"title"}*/}
                {/*// description={"description"}*/}
                {/*>*/}
                {/*<PlacesIcon />*/}
                {/*</MapMarker>*/}
                {/*</>*/}
                {/*))}*/}
                {/*</MapView>*/}
              </div>

              <List
                component="nav"
                aria-label="mailbox folders"
                disablePadding
                // className={classes.gutterBottomExtraExtraLarge}
                style={{
                  width: '100%',
                  // 'margin-bottom': 96
                }}
              >
                {partnerAreas.map(area => (
                  <>
                    <ListItem
                      button
                      style={{
                        paddingLeft: '0',
                        paddingRight: '0',
                        // justifyContent: "space-between",
                        // flex: "1",
                      }}
                      key={area.zipCode}
                    >
                      <ListItemIcon
                        classes={{
                          root: classes.root,
                        }}
                      >
                        <LocationIcon />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{
                          variant: 'button',
                          display: 'inline',
                          color: 'primary',
                        }}
                        secondaryTypographyProps={{
                          variant: 'subtitle1',
                          display: 'inline',
                          color: 'primary',
                        }}
                        primary={`${area.zipCode}, `}
                        secondary={`${area.city}${area.county ? ' (' + area.county + ')' : ''}`}
                        style={{ marginLeft: '16px' }}
                      />
                    </ListItem>
                    <Divider />
                  </>
                ))}
                <Divider light />
              </List>
              {/*</Container>*/}
            </Grid>
          </Container>
        </RoundedContainer>
      </div>
      <View style={styles.ctaContainer}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          startIcon={<PlusIcon />}
          onClick={handleAddAreaClick}
          // disabled={!password.length}
          className={classes.addAreaCtaButton}
        >
          Add Area
        </Button>
      </View>
      <BottomNavigation />

      {/*{addAreaDrawerOpen && (*/}
      <AddAreaPopup
        // disabled={false}
        title="Add area"
        region={user?.region}
        isFetching={isFetching}
        isOpen={addAreaDrawerOpen}
        allowDragging={true}
        // activeState={this.props.bookingPanelState}
        // bookingDate={this.state.bookingDate}
        // onDateSelect={this.handleDateTimeChange}
        // onDateSelect={this.handleDirectBookingTimeChange}
        onClose={closeAreaDrawer}
        // onNextClick={this.handleBookingPanelCtaClick}
      ></AddAreaPopup>
      {/*)}*/}
    </>
  );
}

const styles = StyleSheet.create({
  ctaContainer: {
    right: '0',
    left: '0',
    // @ts-ignore
    position: 'fixed', //'absolute',
    bottom: '82px', // 0,
    padding: '16px',
    // 'background-color': theme.whiteBasic,
  },
});
