import React, { SVGProps } from 'react';

export default function TimeTable(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="343" height="390" viewBox="0 0 343 390" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
      <rect width="52.1667" height="60" rx="8" fill="#F8F8F8"/>
      <rect x="58.1667" width="52.1667" height="60" rx="8" fill="#F8F8F8"/>
      <rect x="116.333" width="52.1667" height="60" rx="8" fill="#F8F8F8"/>
      <rect x="174.5" width="52.1667" height="60" rx="8" fill="#F8F8F8"/>
      <rect x="232.667" width="52.1667" height="60" rx="8" fill="#F8F8F8"/>
      <rect x="290.833" width="52.1667" height="60" rx="8" fill="#F8F8F8"/>
      <rect y="66" width="52.1667" height="60" rx="8" fill="#F8F8F8"/>
      <rect x="58.1667" y="66" width="52.1667" height="60" rx="8" fill="#F8F8F8"/>
      <rect x="116.333" y="66" width="52.1667" height="60" rx="8" fill="#F8F8F8"/>
      <rect x="174.5" y="66" width="52.1667" height="60" rx="8" fill="#F8F8F8"/>
      <rect x="232.667" y="66" width="52.1667" height="60" rx="8" fill="#F8F8F8"/>
      <rect x="290.833" y="66" width="52.1667" height="60" rx="8" fill="#F8F8F8"/>
      <rect y="132" width="52.1667" height="60" rx="8" fill="#F8F8F8"/>
      <rect x="58.1667" y="132" width="52.1667" height="60" rx="8" fill="#F8F8F8"/>
      <rect x="116.333" y="132" width="52.1667" height="60" rx="8" fill="#F8F8F8"/>
      <rect x="174.5" y="132" width="52.1667" height="60" rx="8" fill="#F8F8F8"/>
      <rect x="232.667" y="132" width="52.1667" height="60" rx="8" fill="#F8F8F8"/>
      <rect x="290.833" y="132" width="52.1667" height="60" rx="8" fill="#F8F8F8"/>
      <rect y="198" width="52.1667" height="60" rx="8" fill="#F8F8F8"/>
      <rect x="58.1667" y="198" width="52.1667" height="60" rx="8" fill="#F8F8F8"/>
      <rect x="116.333" y="198" width="52.1667" height="60" rx="8" fill="#F8F8F8"/>
      <rect x="174.5" y="198" width="52.1667" height="60" rx="8" fill="#F8F8F8"/>
      <rect x="232.667" y="198" width="52.1667" height="60" rx="8" fill="#F8F8F8"/>
      <rect x="290.833" y="198" width="52.1667" height="60" rx="8" fill="#F8F8F8"/>
      <rect x="108" y="72" width="128" height="128" fill="url(#pattern0)"/>
      <rect y="264" width="52.1667" height="60" rx="8" fill="#F8F8F8"/>
      <rect x="58.1667" y="264" width="52.1667" height="60" rx="8" fill="#F8F8F8"/>
      <rect x="116.333" y="264" width="52.1667" height="60" rx="8" fill="#F8F8F8"/>
      <rect x="174.5" y="264" width="52.1667" height="60" rx="8" fill="#F8F8F8"/>
      <rect x="232.667" y="264" width="52.1667" height="60" rx="8" fill="#F8F8F8"/>
      <rect x="290.833" y="264" width="52.1667" height="60" rx="8" fill="#F8F8F8"/>
      <rect y="330" width="52.1667" height="60" rx="8" fill="#F8F8F8"/>
      <rect x="58.1667" y="330" width="52.1667" height="60" rx="8" fill="#F8F8F8"/>
      <rect x="116.333" y="330" width="52.1667" height="60" rx="8" fill="#F8F8F8"/>
      <rect x="174.5" y="330" width="52.1667" height="60" rx="8" fill="#F8F8F8"/>
      <rect x="232.667" y="330" width="52.1667" height="60" rx="8" fill="#F8F8F8"/>
      <rect x="290.833" y="330" width="52.1667" height="60" rx="8" fill="#F8F8F8"/>
    </svg>
  );
}

