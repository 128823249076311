import React from 'react';

interface Icon {
  size?: number | string;
}

export default function PasswordHideIcon({ size }: Icon) {
  return (
    <svg width={size || 24} height={size || 24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0036 8.94737C14.0118 8.94737 15.6417 10.5979 15.6417 12.6316C15.6417 13.1105 15.5471 13.56 15.3797 13.98L17.5043 16.1316C18.603 15.2032 19.4688 14.0021 20 12.6316C18.7412 9.39684 15.6344 7.10526 11.9964 7.10526C10.9777 7.10526 10.0027 7.28947 9.1005 7.62105L10.6721 9.21263C11.0869 9.04316 11.5307 8.94737 12.0036 8.94737ZM4.7276 6.93579L6.38654 8.61579L6.72124 8.95474C5.51342 9.90526 4.56753 11.1726 4 12.6316C5.25875 15.8663 8.36562 18.1579 12.0036 18.1579C13.1314 18.1579 14.2083 17.9368 15.1905 17.5389L15.4961 17.8484L17.628 20L18.5521 19.0642L5.65166 6L4.7276 6.93579ZM8.75125 11.0105L9.87904 12.1526C9.84266 12.3074 9.82083 12.4695 9.82083 12.6316C9.82083 13.8547 10.7958 14.8421 12.0036 14.8421C12.1637 14.8421 12.3238 14.82 12.4766 14.7832L13.6044 15.9253C13.1169 16.1684 12.5784 16.3158 12.0036 16.3158C9.99545 16.3158 8.36562 14.6653 8.36562 12.6316C8.36562 12.0495 8.51114 11.5042 8.75125 11.0105ZM11.8872 10.4358L14.1792 12.7568L14.1937 12.6389C14.1937 11.4158 13.2187 10.4284 12.0109 10.4284L11.8872 10.4358Z"
        fill="currentColor"
      />
    </svg>
  );
}
