import React from 'react';

export default function CalendarPlusIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1493_1196)">
      	<path d="M17.25 2.00006H15.9999V0.999939C15.9999 0.448059 15.553 0 15 0C14.447 0 14.0001 0.448059 14.0001 0.999939V2.00006H6V0.999939C6 0.448059 5.55304 0 5.00006 0C4.44708 0 3.99994 0.448059 3.99994 0.999939V2.00006H2.75006C1.23303 2.00006 0 3.23291 0 4.74994V15.9999C0 17.6539 1.34601 18.9999 3 18.9999H8.00006C8.55304 18.9999 9 18.5521 9 18C9 17.4479 8.55304 17.0001 8.00006 17.0001H3C2.44794 17.0001 2.00006 16.5511 2.00006 15.9999V8.00006H18C18 8.55194 18.447 9 18.9999 9C19.5529 9 20.0001 8.55194 20.0001 8.00006V4.74994C20.0001 3.23291 18.767 2.00006 17.25 2.00006Z" fill="currentColor"/>
      	<path d="M17.4999 11.0001C13.916 11.0001 11.0001 13.9161 11.0001 17.5C11.0001 21.0841 13.916 24.0001 17.4999 24.0001C21.084 24.0001 24 21.0841 24 17.5C24 13.9161 21.084 11.0001 17.4999 11.0001ZM20.0001 18.5001H18.5001V20.0001C18.5001 20.552 18.052 21.0001 17.4999 21.0001C16.9481 21.0001 16.5 20.552 16.5 20.0001V18.5001H15C14.4479 18.5001 14.0001 18.0521 14.0001 17.5C14.0001 16.9481 14.4479 16.5001 15 16.5001H16.5V15.0001C16.5 14.448 16.9481 14.0001 17.4999 14.0001C18.052 14.0001 18.5001 14.448 18.5001 15.0001V16.5001H20.0001C20.5519 16.5001 21 16.9481 21 17.5C21 18.0521 20.5519 18.5001 20.0001 18.5001Z" fill="currentColor"/>
      </g>
      <defs>
      	<clipPath id="clip0_1493_1196">
      	  <rect width="24" height="24" fill="white"/>
		</clipPath>
	  </defs>
    </svg>
  );
}
