import React from 'react';
import { View } from 'react-native';
import clsx from 'clsx';
import { Theme } from '@material-ui/core';

// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { makeStyles /*, withStyles*/ } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  bottomNavigationContainer: {
    position: 'fixed',
    bottom: '0',
    'background-color': theme.whiteBasic,
    'box-shadow': '0px 0px 32px rgba(0, 0, 0, 0.1)',
    'z-index': 1003,
  },
  flexContainer: {
    display: 'flex',
    'flex-direction': 'row',
    'justify-content': 'space-evenly',
    'flex-grow': 1,
    padding: '0',
  },
  root: {
    'justify-content': 'center',
  },

  navLink: {
    width: '100%',
    'text-decoration': 'none',
    'text-align': 'center',
    // "&:hover": {
    //   color: '#E94E32',
    // }
  },

  navLinkActive: {
    color: '#E94E32',

    '&$navLink .MuiListItemIcon-root': {
      color: theme.red,
    },
  },
  navLinkUnselected: {
    color: '#303740',
  },
}));

export default function BottomNavLink({
  className,
  to,
  linkIcon,
  text,
  onClick,
}: {
  className?: any;
  to: string;
  linkIcon: any;
  text: string;
  onClick?: any;
}) {
  const classes = useStyles();

  return (
    <NavLink
      to={to}
      exact={true}
      isActive={(match, location) => {
        if (!match) {
          return false;
        }

        return true;
      }}
      className={isActive =>
        'nav-link ' + clsx(classes.navLink, isActive ? classes.navLinkActive : classes.navLinkUnselected)
      }
    >
      <View
        style={{
          justifyContent: 'center',
        }}
      >
        <ListItemIcon
          classes={{
            root: classes.root,
          }}
        >
          {linkIcon}
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{ variant: 'subtitle1' }}
          primary={text}
          style={{ marginTop: '8px', marginBottom: '0', textDecoration: 'none' }}
        />
      </View>
    </NavLink>
  );
}
