import React from 'react';
import { Theme } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import UserPlaceholderOnline from '../../icons/UserPlaceholderOnline';
// import SessionOnlineIcon from '../../icons/SessionOnlineIcon';

import sessionOnlineIcon from './session-online-icon.png';
import sessionOnlineIcon2x from './session-online-icon@2x.png';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
	sessionTypeIcon: {
		display: 'flex',
		position: 'absolute',
		bottom: '0',
		left: '0',
	},
  root: {
  	position: 'relative',
    'justify-content': 'center',
    color: theme.trueblackBasic,
    'min-width': 24
  },
  userPictureImgContainer: {
    width: '52px',
    height: '52px',
    'border-radius': '50%',
    border: `2px solid ${theme.violetBasic}`,
    overflow: 'hidden',
  },
  userPictureImg: {
    width: '100%',
    height: 'auto',
    'text-indent': '100%',
    'white-space': 'nowrap',

  },
  sessionOnlineImg: {
    // maxWidth: '100%',
    // height: 'auto',
    width: 24,
    height: 24,
  }
}));

export default function SessionOnlineListItemIcon({ pictureUrl }: { pictureUrl?: string }) {
	const classes = useStyles();

  return (
	  <ListItemIcon
      classes={{
        root: classes.root,
      }}
    >
      {pictureUrl ?
        <div className={classes.userPictureImgContainer}>
          <img
            className={classes.userPictureImg}
            itemProp='image'
            src={pictureUrl + '?size=128x128'}
            srcSet={`${pictureUrl}?size=64x64 1x, ${pictureUrl}?size=128x128 2x`}
            alt={`Customer profile`}
          />
        </div> :
        <UserPlaceholderOnline />
      }
      <div className={classes.sessionTypeIcon}>
      	<img
          className={classes.sessionOnlineImg}
          itemProp='image'
          src={sessionOnlineIcon}
          srcSet={`${sessionOnlineIcon} 1x, ${sessionOnlineIcon2x} 2x`}
          alt={`Online session`}
        />
      </div>
    </ListItemIcon>
  );
}
