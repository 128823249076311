import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useAppState } from '../../state';
import { useSubscribe } from '../../hooks/useSubscribe/useSubscribe';

const Notifications: React.FC<{ registration: any }> = ({ registration }) => {
  const { user } = useAppState();

  // @ts-ignore
  const { getSubscription } = useSubscribe({ publicKey: process.env.REACT_APP_PUSH_SERVER_PUBLIC_KEY });

  useEffect(() => {
    if (!user || !registration) {
      return;
    }

    const subscribe = async () => {
      try {
        // @ts-ignore
        // By this point registration is already there
        const subscription = await getSubscription(registration);
        const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/profiles/v1/partners/${user.id}/push`;

        await fetch(endpointUrl, {
          headers: {
            Authorization: `Bearer ${user?.accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            subscription: subscription.endpoint,
            // @ts-ignore
            auth: btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('auth')))),
            // @ts-ignore
            key: btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('p256dh')))),
          }),
          method: 'PUT',
        });
      } catch (e) {
        console.warn(e);
      } finally {
      }
    };

    subscribe();
  }, [user, registration]);

  return <div />;
};

export default Notifications;
