import React from 'react';

export default function UserPlaceholderInPerson() {
  return (
	  <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
	  	<circle cx="26" cy="26" r="24" fill="white"/>
	  	<circle cx="26" cy="26" r="24" fill="#F8F8F8"/>
	  	<mask id="mask0_1199_1925" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="2" y="2" width="48" height="48">
	  		<circle cx="26" cy="26" r="24" fill="white"/>
	  	</mask>
	  	<g mask="url(#mask0_1199_1925)">
	  		<path opacity="0.6" fillRule="evenodd" clipRule="evenodd" d="M26 34C30.6963 34 34.5 30.1962 34.5 25.5C34.5 20.8038 30.6963 17 26 17C21.3038 17 17.5 20.8038 17.5 25.5C17.5 30.1962 21.3038 34 26 34ZM26 38.25C20.3263 38.25 9 41.0975 9 46.75V51H43V46.75C43 41.0975 31.6737 38.25 26 38.25Z" fill="#CBCFD5"/>
	  	</g>
	  	<rect x="1" y="1" width="50" height="50" rx="25" stroke="#E94E32" strokeWidth="2"/>
	  </svg>
	);
}
