import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
// import Swoosh from './swoosh';
// import VideoLogo from './VideoLogo';
// import TwilioLogo from './TwilioLogo';
// import { useAppState } from '../../state';
// import UserMenu from './UserMenu/UserMenu';
// import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    display: 'flex',
    // 'align-items': 'center',
    overflow: 'hidden',
    position: 'relative',
    'justify-content': 'center',

    // background: 'rgb(40, 42, 43)',
    // height: '100%',
    // 'box-shadow': '0px 0px 32px rgba(0, 0, 0, 0.1)'
    'border-top-right-radius': '8px',
    'border-top-left-radius': '8px',
  },
  container: {
    position: 'relative',
    display: 'flex',
    // 'align-items': 'center',
    flex: 1,
    overflow: 'hidden',
    'justify-content': 'center',
    width: '100%',
    // background: 'rgb(40, 42, 43)',
    // height: '100%',
    'box-shadow': '0px 0px 32px rgba(0, 0, 0, 0.1)',
    'border-top-right-radius': '8px',
    'border-top-left-radius': '8px',
  },
  // innerContainer: {
    // display: 'flex',
    // width: '888px',
    // height: '379px',
    // 'border-radius': '8px',
    // 'box-shadow': '0px 2px 4px 0px rgba(40, 42, 43, 0.3)',
    // overflow: 'hidden',
    // position: 'relative',
    // margin: 'auto',
    // [theme.breakpoints.down('sm')]: {
    //   display: 'block',
    //   height: 'auto',
    //   width: 'calc(100% - 40px)',
    //   margin: 'auto',
    //   maxWidth: '400px',
    // },
  // },
  innerContainer: {
    position: 'relative',
    flex: '1',
    'margin-top': '-8px',
    'padding-top': '24px',
    'padding-left': '16px',
    'padding-right': '16px',
    'padding-bottom': '86px',
    'border-top-right-radius': '8px',
    'border-top-left-radius': '8px',
    'box-shadow': '0px 0px 32px rgba(0, 0, 0, 0.1)',
    'background-color': 'rgb(255, 255, 255)',
  },
  // swooshContainer: {
  //   position: 'relative',
  //   display: 'flex',
  //   'align-items': 'center',
  //   'justify-content': 'center',
  //   'background-image': Swoosh,
  //   'background-size': 'cover',
  //   width: '296px',
  //   [theme.breakpoints.down('sm')]: {
  //     width: '100%',
  //     height: '100px',
  //     backgroundPositionY: '140px',
  //   },
  // },
  // logoContainer: {
  //   position: 'absolute',
  //   width: '210px',
  //   'text-align': 'center',
  //   [theme.breakpoints.down('sm')]: {
  //     display: 'flex',
  //     'align-items': 'center',
  //     width: '90%',
  //     'text-align': 'initial',
  //     '& svg': {
  //       height: '64px',
  //     },
  //   },
  // },
  // twilioLogo: {
  //   position: 'absolute',
  //   top: 0,
  //   left: '0',
  //   margin: '20px',
  // },
  content: {
    width: '100%',
    // padding: '24px',
    // 'padding-bottom': '96px',
    // background: white;
    'background-color': theme.whiteBasic,
    // height: '100%',
    // padding: '4em',
    flex: 1,
    display: 'flex',
    'flex-direction':  'column',
    'overflow-x':  'hidden', // 'auto',
    // 'overflow-y': 'scroll',
    [theme.breakpoints.down('sm')]: {
      // padding: '2em',
    },
  },
  title: {
    color: 'white',
    margin: '1em 0 0',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      'font-size': '1.1rem',
    },
  },
}));

interface RoundedContainerProps {
  children: React.ReactNode;
  className?: any;
  style?: any;
  innerContainerStyle?: any;
  contentStyle?: any;
  attachRef?: any;
}

const RoundedContainer = (props: RoundedContainerProps) => {
  const classes = useStyles();
  // const { user } = useAppState();
  // const location = useLocation();

  return (
    <div className={clsx(props.className, classes.container)} style={props.style}>
      {/*<TwilioLogo className={classes.twilioLogo} />*/}
      {/*{user && location.pathname !== '/login' && <UserMenu />}*/}
      {/*<div className={classes.container}>*/}
        {/*<div className={classes.innerContainer} style={props.innerContainerStyle}>*/}
          {/*<div className={classes.swooshContainer}>
            <div className={classes.logoContainer}>
              <VideoLogo />
              <Typography variant="h6" className={classes.title}>
                Twilio Programmable Video
              </Typography>
            </div>
          </div>*/}
          <div ref={props.attachRef} className={classes.content} style={props.contentStyle}>{props.children}</div>
        {/*</div>*/}
      {/*</div>*/}
    </div>
  );
};

export default RoundedContainer;
