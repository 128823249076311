import React from 'react';

export default function ArrowLeftIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.3334 10L19.3334 8L10.6667 16.6667L19.3334 25.3333L21.3334 23.3333L14.6667 16.6667L21.3334 10Z"
        fill="currentColor"
      />
    </svg>
  );
}
