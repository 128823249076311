import React from 'react';

interface Icon {
  size?: number | string;
}

export default function PasswordShowIcon({ size }: Icon) {
  return (
    <svg width={size || 24} height={size || 24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 7C8.36364 7 5.25818 9.28067 4 12.5C5.25818 15.7193 8.36364 18 12 18C15.6364 18 18.7418 15.7193 20 12.5C18.7418 9.28067 15.6364 7 12 7ZM12 16.1667C9.99273 16.1667 8.36364 14.524 8.36364 12.5C8.36364 10.476 9.99273 8.83333 12 8.83333C14.0073 8.83333 15.6364 10.476 15.6364 12.5C15.6364 14.524 14.0073 16.1667 12 16.1667ZM12 10.3C10.7927 10.3 9.81818 11.2827 9.81818 12.5C9.81818 13.7173 10.7927 14.7 12 14.7C13.2073 14.7 14.1818 13.7173 14.1818 12.5C14.1818 11.2827 13.2073 10.3 12 10.3Z"
        fill="currentColor"
      />
    </svg>
  );
}
