import React, { PropsWithChildren, MouseEvent, useState, useEffect, useCallback } from 'react';
import { View, ScrollView, NativeSyntheticEvent, NativeScrollEvent } from 'react-native';
import { Booking, WorkoutType } from '../../types';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { useAppState } from '../../state';

import Button from '@material-ui/core/Button';
// import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
// import { ReactComponent as GoogleLogo } from './google-logo.svg';
import { InputLabel, Theme } from '@material-ui/core';
// import IntroContainer from '../IntroContainer/IntroContainer';
// import RoundedContainer from '../RoundedContainer/RoundedContainer';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import UserPlaceholder from '../../icons/UserPlaceholder64';
// import LocationIcon from '../../icons/LocationIcon';
// import CloseIcon from '../../icons/Close2Icon';
import ArrowRightIcon from '../../icons/ArrowRightIcon';
// import PlusIcon from '../../icons/PlusIcon';
// import UserPlaceholderInPerson from '../../icons/UserPlaceholderInPerson';
// import UserPlaceholderOnline from '../../icons/UserPlaceholderOnline';
import PhoneIcon from '../../icons/PhoneIcon';
import CalendarIcon from '../../icons/CalendarIcon';
import QuestionOIcon from '../../icons/QuestionOIcon';
import Calendar64Icon from '../../icons/Calendar64Icon';
import Location64Icon from '../../icons/Location64Icon';
import SessionOnline64Icon from '../../icons/SessionOnline64Icon';
import WoBoxingIcon from '../../icons/WoBoxingIcon';
import WoKickboxingIcon from '../../icons/WoKickboxingIcon';
import WoYogaIcon from '../../icons/WoYogaIcon';
import WoPilatesIcon from '../../icons/WoPilatesIcon';
import WoBalletFitIcon from '../../icons/WoBalletFitIcon';
import WoPersonalTrainingIcon from '../../icons/WoPersonalTrainingIcon';
import WoPrePostNatalIcon from '../../icons/WoPrePostNatalIcon';
import WoMeditationIcon from '../../icons/WoMeditationIcon';
import WoStretchingIcon from '../../icons/WoStretchingIcon';
import WoRunningIcon from '../../icons/WoRunningIcon';
import LoaderSpinnerIcon from '../../icons/LoaderSpinnerIcon';

import CopyIcon from '../../icons/CopyIcon';
import PlacesIcon from '../../icons/PlacesIcon';

import MapView from 'react-native-maps';
// import MapCircle from 'react-native-maps/MapCircle';
// import { Marker } from 'react-native-maps';

// import { ScrollParams } from 'react-virtualized';

import TBKTooltip from '../TBKTooltip/TBKTooltip';

// import SessionInPersonListItemIcon from './SessionInPersonListItemIcon';
// import SessionOnlineListItemIcon from './SessionOnlineListItemIcon';

import { makeUrlString, copy, makeCancelable } from '../../utils';
import { getTz } from '../../utils/date';
import ics from '../../lib/icsFormatter';
// import { copy } from '../../../utils';
import { CALENDAR_FORMATS } from '../../constants';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { styled } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';

import useAnalyticsEventTracker, {
  EVENT_SESSION_START,
  EVENT_SESSION_STOP,
} from '../../hooks/useAnalyticsEventTracker/useAnalyticsEventTracker';

import Address from '../../helpers/Address';

// import sessionDetailsMapImg from './session-details-map@2x.png';

const AUTOHIDE_TIME = 3000;

const woIconComponents = {
  boxing: WoBoxingIcon,
  kickboxing: WoKickboxingIcon,
  yoga: WoYogaIcon,
  pilates: WoPilatesIcon,
  'ballet-fit' /*balletFit*/: WoBalletFitIcon,
  'personal-training' /*personalTraining*/: WoPersonalTrainingIcon,
  'prepost-natal' /*prePostNatal*/: WoPrePostNatalIcon,
  meditation: WoMeditationIcon,
  stretching: WoStretchingIcon,
  running: WoRunningIcon,
};

const useStyles = makeStyles((theme: Theme) => ({
  // googleButton: {
  //   background: 'white',
  //   color: 'rgb(0, 94, 166)',
  //   'border-radius': '4px',
  //   border: '2px solid rgb(2, 122, 197)',
  //   margin: '1.8em 0 0.7em',
  //   'text-transform': 'none',
  //   'box-shadow': 'none',
  //   padding: '0.3em 1em',
  //   [theme.breakpoints.down('sm')]: {
  //     width: '100%',
  //   },
  //   '&:hover': {
  //     background: 'white',
  //     'box-shadow': 'none',
  //   },
  // },
  // errorMessage: {
  //   color: 'red',
  //   display: 'flex',
  //   'align-items': 'center',
  //   margin: '1em 0 0.2em',
  //   '& svg': {
  //     'margin-right': '0.4em',
  //   },
  // },
  gutterBottom: {
    'margin-bottom': '.5rem',
  },
  gutterBottomBase: {
    'margin-bottom': '1rem',
  },
  gutterBottomExtraLarge: {
    'margin-bottom': '1.5em',
  },
  // gutterTopDoubleMedium: {
  //   'margin-top': '2em',
  // },
  // gutterBottomDoubleMedium: {
  //   'margin-bottom': '2em',
  // },
  gutterTopFull: {
    'margin-top': '64px',
  },
  gutterBottomFull: {
    'margin-bottom': '64px',
  },
  // gutterBottomExtraExtraLarge: {
  //   'margin-bottom': 80
  // },
  // passcodeContainer: {
  //   'min-height': '120px',
  // },
  bottomCtaContainer: {
    position: 'fixed',
    bottom: '0', // 100,
    left: '0',
    'background-color': theme.whiteBasic,
    'box-shadow': '0px 0px 32px rgba(0, 0, 0, 0.1)',
    'z-index': 1099,
  },
  submitButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  ctaButtonContainer: {
    position: 'relative',
    width: '100%',
    // 'margin-bottom': '40px'
    // 'margin-right': '1em',
  },
  ctaButtonLoadingSpinner: {
    '& > svg': {
      right: '11px',
      bottom: '20px',
      position: 'absolute',
      'pointer-events': 'none',
      animation: '$spin 1s linear infinite',
      display: 'inline-block',
      'vertical-align': 'text-bottom',
      fill: 'white',
      'z-index': 2,
    },
  },
  // addAreaCtaButton: {
  //   'margin-bottom': 8,
  //   [theme.breakpoints.down('sm')]: {
  //     width: '100%',
  //   },
  // },
  // whiteBasicColor: {
  //   color: theme.whiteBasic,
  // },
  // trueblackBasicColor: {
  //   color: theme.trueblackBasic,
  // },
  redColor: {
    color: theme.red,
  },
  greyColor: {
    color: theme.grey,
  },
  greyMainColor: {
    color: theme.greyMain,
  },
  greenMainColor: {
    color: theme.greenMain,
  },
  root: {
    'justify-content': 'center',
    color: theme.trueblackBasic,
    'min-width': '24px',
  },
  rootArrow: {
    color: theme.red,
    'min-width': '24px',
  },
  // closeSessionDetails: {
  //   cursor: 'pointer',
  //   display: 'flex',
  //   background: 'transparent',
  //   border: '0',
  //   padding: '0',
  //   color: theme.red, // '#E94E32'
  // },
  declineButton: {
    cursor: 'pointer',
    display: 'flex',
    'justify-content': 'center',
    background: 'transparent',
    border: '0',
    padding: '0',
    width: '100%',
    'text-transform': 'initial',
  },

  buttonsDuet: {
    cursor: 'pointer',
    display: 'flex',
    width: '100%',
    // background: 'transparent',
    border: '0',
    // padding: '0',
    'background-color': theme.whiteBasic,
    padding: '8px 16px',
    'border-radius': '8px',

    '&:hover': {
      // 'background-color': 'rgb(0, 0, 0, 0.4)',
      'background-color': theme.whiteBasic,
    },
  },

  linkLikeButton: {
    'text-decoration': 'underline',
  },
  withTooltip: {
    position: 'relative',
  },

  userProfilePictureContainer: {
    width: '64px',
    height: '64px',
    'border-radius': '50%',
    overflow: 'hidden',

    // width: '64px',
    // height: '64px',
    // // margin: 0,
    // // borderWidth: 1px;
    // // border: 2px solid #e5e5e5;
    // 'border-radius': '50%',
    // // margin: 0 auto;
  },

  userPictureImg: {
    width: '100%',
    height: 'auto',
    'text-indent': '100%',
    'white-space': 'nowrap',
  },

  '@keyframes spin': {
    '100%': {
      'webkit-transform': 'rotate(360deg)',
      transform: 'rotate(360deg)',
    },
  },

  '@keyframes flash': {
    from: {
      opacity: 1,
    },
    '50%': {
      opacity: 0.1,
    },
    to: {
      opacity: 1,
    },
  },

  animatedPlaceholder: {
    animation: '$flash 2s ease infinite',
  },
}));

// const MyBox = withStyles((theme) => ({
//   root: {
//     display: "flex",
//     // 'border-top-left-radius': '8px',
//     // 'border-top-right-radius': '8px',
//     // 'background-color': theme.greyMain,
//     // 'padding-top': 10,
//     // 'padding-bottom': 10,
//   }
// }))(Box);

// TODO: make component
const GreyRoundedBox = withStyles(theme => ({
  root: {
    // height: "100%",
    // 'border-top-left-radius': '8px',
    // 'border-top-right-radius': '8px',
    'border-radius': '8px',
    'background-color': theme.greyLight,
    // 'padding-top': 10,
    // 'padding-bottom': 10,
  },
}))(Box);

// Fixed number of columns
// const gridContainer = {
//   display: "grid",
//   // gridTemplateColumns: "repeat(2, 1fr)",
//   gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
//   gridColumnGap: "16px",
// };

// // Variable number of columns
// const gridContainer2 = {
//   display: "grid",
//   gridAutoColumns: "1fr",
//   gridAutoFlow: "column"
// };

const gridItem = {
  height: '100%',
  // margin: "8px",
  // border: "1px solid red"
};

export const IconContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  // width: '1.5em',
  // marginRight: '0.3em',
});

interface SessionDetailsProps {
  // open: boolean;
  refCallback?: any;
  booking: Booking;
  status: string;
  declineStatus: boolean;
  // sessionType: string;
  culture?: string;
  isOpen?: boolean;
  isFullyOpen?: boolean;
  scrollEnabled?: boolean;
  close?: () => void;
  onMomentumScrollEnd?: (e: NativeSyntheticEvent<NativeScrollEvent>) => void;
  onClick(e: MouseEvent<HTMLButtonElement>): void;
  onRunningLateClick(e: MouseEvent<HTMLButtonElement>, delayInMinutes?: number): void;
  onPhoneNumberClick(e: MouseEvent<HTMLAnchorElement | HTMLButtonElement>): void;
  onContactSupportClick(e: MouseEvent<HTMLDivElement>): void;
  onDeclineSessionClick(): void;
  onClose(): void;
}

/*<MapView.Marker // @ts-ignore
                // key={index}
                coordinate={{
                  latitude: booking?.location?.latitude,
                  longitude: booking?.location?.longitude,
                }}
                // title={marker.title}
                // description={marker.description}
              />*/

export default function SessionDetails({
  booking,
  declineStatus,
  culture,
  isOpen,
  isFullyOpen,
  scrollEnabled,
  refCallback,
  onMomentumScrollEnd,
  onRunningLateClick,
  onDeclineSessionClick,
  onPhoneNumberClick,
  onContactSupportClick,
  onClick,
  onClose,
}: PropsWithChildren<SessionDetailsProps>) {
  const classes = useStyles();
  const {
    user,
    // runningLate,
    startSession,
    stopSession,
    // declineSession,
    // cancelSession,
    isFetching,
    // isAuthReady,
  } = useAppState();
  const history = useHistory();
  const location = useLocation<{ from: Location }>();
  const [, , onPartnerAction] = useAnalyticsEventTracker();
  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');
  // const [passcode, setPasscode] = useState('');
  const [address, setAddress] = useState('');
  const [addressSubline, setAddressSubline] = useState('');
  // const [addressDetails, setAddressDetails] = useState('');
  // const [authError, setAuthError] = useState<Error | null>(null);
  const [isCopyTooltipShow, setIsCopyTooltipShow] = useState<boolean>(false);
  const [latLng, setLatLng] = useState('');

  // const isAuthEnabled = true; // Boolean(process.env.REACT_APP_SET_AUTH);

  const copyButtonRef = React.useRef<HTMLButtonElement | null>(null);
  const hideCancelablePromiseRef = React.useRef<{ promise: Promise<unknown>; cancel(): void } | null>(null);
  const mapViewRef = React.useRef<MapView>();

  let WorkoutIcon;

  // Correct! JSX type can be a capitalized variable.
  if (booking) {
    WorkoutIcon = woIconComponents[makeUrlString(booking?.product?.name) as WorkoutType];
  }

  WorkoutIcon = WoPersonalTrainingIcon;
  // const login = () => {
  //   setAuthError(null);
  //   signIn?.(email, password)
  //     .then(() => {
  //       history.replace(location?.state?.from || { pathname: '/', search: window.location.search });
  //     })
  //     .catch(err => {
  //       setAuthError(err);
  //       console.log(err);
  //     });
  // };

  // const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();
  //   login();
  // };

  useEffect(() => {
    if (booking && !booking.isOnline) {
      // let _address = new Address(booking?.location);
      // setAddress(_address.shortAddress());
      // setAddressSubline(_address.boroughAndPostCode());
      // const setEnabled = () => setIsEnabled(true);
      // const setDisabled = () => setIsEnabled(false);
      // publication.on('trackEnabled', setEnabled);
      // publication.on('trackDisabled', setDisabled);
      // return () => {
      //   publication.off('trackEnabled', setEnabled);
      //   publication.off('trackDisabled', setDisabled);
      // };
    }

    // Disabled for now
    if (booking?.status === 'COMPLETED' && !booking?.partnerReview) {
      history.replace({ pathname: '/rate-session', state: { from: location.pathname } });
    }
  }, [history, booking]);

  const handleAddToCalClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      // // const { location } = booking;
      // var workoutAddress;

      // // setAddress(address.fullAddress(true));

      // // if (location) {
      // workoutAddress = new Address(booking?.location);
      // // }

      const title = `Your ${booking?.product?.name} session with ${booking?.customer?.firstName}`;
      const place = `${booking?.location?.address}${
        booking?.location?.detail ? ` (${booking?.location?.detail})` : ''
      }`; // (workoutAddress && workoutAddress.fullAddress().replace(/,/g, '\\,')) || '';
      const begin = moment.utc(booking?.start);
      const end = begin.clone().add(1, 'h');

      const clientNotes = booking?.note || '';

      ics?.removeAllEvents();
      ics?.addEvent(title, clientNotes, place, begin.toISOString(), end.toISOString());

      ics?.saveFile('Booking-Event');

      // if (isIosDevice()) {
      // @ts-ignore
      // } else {
      // ics?.download('calendar');
      // }
    },
    [booking, culture]
  );

  const handleLocationOpenClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      const isOnline = false; //booking?.product.id.includes('_LS');

      // var point1;
      // var point2;

      // google.maps.event.addListener(mapViewRef.current, 'tap', function (e) {
      //   var radius = 100;

      //   if (!point1) {

      //     point1 = e.latLng;
      //   } else {
      //     point2 = e.latLng;

      //     radius = calculateDistance(point1, point2);

      //     var circle = new google.maps.Circle({
      //       center: e.latLng,
      //       radius: radius,
      //       strokeColor: "#0000FF",
      //       strokeOpacity: 0.8,
      //       strokeWeight: 2,
      //       fillColor: "#0000FF",
      //       fillOpacity: 0.4,
      //       clickable: false
      //     });
      //     circle.setMap(mapViewRef.current);
      //   }

      //   var currMarker = new google.maps.Marker({
      //     position: mapViewRef.current.getCenter(),
      //     draggable: true,
      //     icon: {
      //       url: "https://maps.gstatic.com/intl/en_us/mapfiles/markers2/measle_blue.png",
      //       size: new google.maps.Size(7, 7),
      //       anchor: new google.maps.Point(4, 4)
      //     },
      //     map: mapRef.current
      //   });
      //   circle.bindTo('center', currMarker, 'position');

      //   // Draw a circle around the radius

      //   var start = 2;
      //   var speedup = 4;
      //   // var grow = function () {
      //   //   radius = radius + 50;
      //   //   circle.setRadius(radius);
      //   //   var t = setTimeout(grow, start);
      //   //   start = start / speedup;
      //   // };
      //   // grow();
      // });

      if (!isOnline) {
        window.open(
          `https://maps.google.com/?q=${booking?.location?.latitude},${booking?.location?.longitude}`,
          '_blank' // <- This is what makes it open in a new window.
        );
        // window.location.href = `https://maps.google.com/?q=${booking?.location?.latitude},${booking?.location?.longitude}`;
      }
    },
    [booking]
  );

  const handleStartStopCtaClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      if (user && booking) {
        // booking.status === 'IN_PROGRESS' .status === 'IN_PROGRESS' ? 'Stop Session' : 'Start Session'}

        if (booking?.status === 'ACTIVE') {
          stopSession(user?.id!, booking?.id!).then(() => {
            // Disabled for now
            history.push({ pathname: '/rate-session', state: { from: location.pathname } });

            // @ts-ignore
            onPartnerAction(booking?.id!, EVENT_SESSION_STOP);
          });
        } else {
          startSession(user?.id!, booking?.id!);

          // @ts-ignore
          onPartnerAction(booking?.id!, EVENT_SESSION_START);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [history, user, booking]
  );

  // const handleStopSessionClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
  //   if (user && booking) {
  //     stopSession(user?.id!, booking?.id!);
  //   }
  // }, [user, booking]);

  // const handleDeclineSessionClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
  //   if (user && booking) {
  //     declineSession(user?.id!, booking?.id!);
  //   }
  // }, [user, booking]);

  // const handleCancelSessionClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
  //   if (user && booking) {
  //     cancelSession(user?.id!, booking?.id!);
  //   }
  // }, [user, booking]);

  const hideCopyTooltip = useCallback(() => {
    if (hideCancelablePromiseRef.current) {
      hideCancelablePromiseRef.current.cancel();
      hideCancelablePromiseRef.current = null;
    }

    setIsCopyTooltipShow(false);
  }, []);

  const showCopyTooltip = useCallback(() => {
    if (hideCancelablePromiseRef.current) {
      return;
    }

    // Auto hide afer 4 sec
    let hidePromise = new Promise((resolve, reject) => {
      setIsCopyTooltipShow(true);

      setTimeout(() => {
        resolve(null);
      }, AUTOHIDE_TIME);
    });

    hideCancelablePromiseRef.current = makeCancelable(hidePromise);

    return hideCancelablePromiseRef.current.promise.then(() => {
      hideCancelablePromiseRef.current = null;
      hideCopyTooltip();
    });
  }, [hideCopyTooltip]);

  const handleLocationCopyClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      // if (booking?.isOnline) {
      //   if (copy(booking?.roomUrl)) {
      //     showCopyTooltip();
      //   }
      // } else {
      if (copy(booking?.location?.address)) {
        showCopyTooltip();
      }
      // }
    },
    [booking, address, showCopyTooltip]
  );

  // if (user || !isAuthEnabled) {
  //   history.replace('/');
  // }

  // if (!isAuthReady) {
  //   return null;
  // }

  // const getProductType = (productId: string) => {
  //   return productId.includes('_LS') ? 'LS' : 'F2F';
  // };

  const isOnline = false; // booking?.product.id.includes('_LS');

  const date = booking
    ? moment.utc(booking?.start).tz(getTz(user?.region!))
    : moment(new Date()).tz(getTz(user?.region!));

  const cancelledByCustomer = booking?.status.toLowerCase().startsWith('cancelled');

  const mainCtaBackgroundColor = booking?.status === 'ACTIVE' ? '#303740' : '#E94E32';

  const medicalInformationList = booking?.user?.medicalInformationList;
  const medicalInfo = booking?.customer?.medicalInformation; // medicalInformationList && medicalInformationList.length ? medicalInformationList[0].body : '';
  const clientNotes = booking?.note || '';

  /*<Container>
      <Typography variant="h2" className={clsx(classes.gutterTopDoubleMedium, classes.gutterBottomDoubleMedium, classes.greyMainColor)}>
        Upcoming sessions
      </Typography>
    </Container>*/
  return (
    <div
      // pt={3}
      // // mt={3}
      // pb={booking?.status === 'IN_PROGRESS' ? 16 : 14}
      // // display="flex"
      // flexGrow={1}
      style={{
        flexGrow: 1,
        paddingTop: '24px',
        paddingBottom: booking?.status === 'ACTIVE' ? '128px' : '112px',
        // backgroundColor: "transparent",
      }}
    >
      {booking && isFullyOpen ? (
        <ScrollView
          // key={`${scrollEnabled}`}
          // ref={refCallback}
          // onScroll={onMomentumScrollEnd}
          style={{
            width: '100%',
            overflow: 'scroll', //scrollEnabled ? 'scroll' : 'hidden'
          }}
        >
          <Container>
            <div
              // display="flex"
              // alignItems="center"
              // justifyContent="space-between"
              // mb={3}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '32px',
              }}
            >
              <div
                // flexShrink="0"
                // mr={2}
                style={{
                  display: 'flex',
                  flexShrink: 0,
                  marginRight: '16px',
                }}
              >
                {booking?.customer?.photo ? (
                  <div className={classes.userProfilePictureContainer}>
                    <img
                      className={classes.userPictureImg}
                      itemProp="image"
                      src={booking?.customer?.photo + '?size=128x128'}
                      srcSet={`${booking?.customer?.photo}?size=64x64 1x, ${booking?.customer?.photo}?size=128x128 2x`}
                      alt={`Customer profile`}
                    />
                  </div>
                ) : (
                  <UserPlaceholder style={{ verticalAlign: 'top' }} />
                )}
              </div>
              <div
                style={{
                  flexGrow: 1,
                }}
              >
                <div
                  // display="flex"
                  // alignItems="center"
                  // mb={1}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '8px',
                  }}
                >
                  <div
                    className={classes.greyColor}
                    style={{
                      display: 'flex',
                      marginRight: '4px',
                    }}
                  >
                    <WorkoutIcon />
                  </div>

                  <Typography
                    variant="caption"
                    color="primary"
                    className={classes.greyColor}
                    style={{ textTransform: 'uppercase' }}
                  >
                    {booking?.product.name} session with
                  </Typography>
                </div>

                <Typography variant="h2" color="primary" className={''} style={{ lineHeight: '32px' }}>
                  {/*{booking?.user?.firstName} {booking?.user?.lastName}*/}
                  {booking?.customer
                    ? `${booking?.customer?.firstName} ${booking?.customer?.lastName}`
                    : `${booking?.user?.firstName} ${booking?.user?.lastName}`}
                </Typography>

                {/*<Typography variant="body1" className={classes.greyColor} >
                    32, male
                </Typography>*/}
              </div>
            </div>

            {booking?.phoneNumber ? (
              <GreyRoundedBox
                // display="flex"
                // py={1}
                // mb={2}
                style={{
                  display: 'flex',
                  paddingTop: '8px',
                  paddingBottom: '8px',
                  marginBottom: '16px',
                }}
                className={classes.greyMainColor}
              >
                <PhoneIcon />
                <div
                  style={{
                    // display: "flex",
                    marginLeft: '16px',
                  }}
                >
                  <Typography variant="caption" color="primary" style={{ textTransform: 'uppercase' }}>
                    Mobile:
                  </Typography>
                  <Button
                    // href={`tel:${booking?.user?.userDetail?.phoneNumber || '+44(0) 745 978 3573'}`}
                    // target='_self'
                    style={{ display: 'flex', padding: '0', userSelect: 'text' }}
                    onClick={onPhoneNumberClick}
                  >
                    <Typography variant="h5" color="primary">
                      {booking?.phoneNumber}
                    </Typography>
                  </Button>
                </div>
              </GreyRoundedBox>
            ) : null}

            <div
              style={{
                display: 'grid',
                // gridTemplateColumns: "repeat(2, 1fr)",
                gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
                gridColumnGap: '16px',
                marginBottom: '16px',
              }}
              // container spacing={2}
              // sx={{

              //   display: "flex"
              // }}
              // className={clsx(classes.greyColor)}
              // mr={1}
            >
              <div style={gridItem}>
                <GreyRoundedBox
                  // display="flex"
                  // alignItems="center"
                  // flexDirection="column"
                  // py={2}
                  // px={5}
                  // // mr={1}
                  style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    paddingTop: '16px',
                    paddingBottom: '16px',
                    paddingLeft: '40px',
                    paddingRight: '40px',
                    // mr={1}
                  }}
                >
                  <div
                    className={classes.greenMainColor}
                    // style={{  }}
                  >
                    <Calendar64Icon />
                  </div>
                  <Typography variant="h4" color="primary" style={{ whiteSpace: 'nowrap' }}>
                    {date.calendar(undefined, CALENDAR_FORMATS)}
                  </Typography>
                  <Typography variant="h2" color="primary" style={{ whiteSpace: 'nowrap' }}>
                    {date.format('h:mm A')}
                  </Typography>
                </GreyRoundedBox>
              </div>

              <div style={gridItem}>
                <GreyRoundedBox
                  // display="flex"
                  // alignItems="center"
                  // flexDirection="column"
                  // py={2}
                  // px={5}
                  // // mr={1}
                  style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    paddingTop: '16px',
                    paddingBottom: '16px',
                    paddingLeft: '40px',
                    paddingRight: '40px',
                    // mr={1}
                  }}
                >
                  <div
                    className={classes.redColor}
                    // style={{  }}
                  >
                    {isOnline ? <SessionOnline64Icon /> : <Location64Icon />}
                  </div>
                  <Typography variant="h4" color="primary" style={{ whiteSpace: 'nowrap' }}>
                    {isOnline ? 'Online' : 'In-person'}
                  </Typography>
                  <Typography variant="h4" color="primary">
                    session
                  </Typography>
                </GreyRoundedBox>
              </div>
            </div>

            <GreyRoundedBox
              // // display="flex"
              // py={1}
              // mb={3}
              style={{
                // paddingTop: "8px",
                paddingBottom: '8px',
                marginBottom: '24px',
              }}
            >
              {isOnline ? (
                <div
                  // pt={0}
                  // pb={0}
                  // px={2}
                  // flexGrow={1}
                  // display="flex"
                  // flexDirection="column"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '8px 16px 0 16px',
                    // pt={0}
                    // pb={0}
                    // px={2}
                    flexGrow: 1,
                  }}
                >
                  <InputLabel shrink htmlFor="input-live-stream-link">
                    Link for stream
                  </InputLabel>
                  <TextField
                    id="input-live-stream-link"
                    // onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                    type="text"
                    value={booking?.roomUrl}
                    variant="outlined"
                    size="small"
                    style={{
                      flexGrow: 1,
                    }}
                    // readOnly
                  />
                </div>
              ) : (
                <div
                  // mt={3}
                  // mb={3}
                  // sx={{
                  //   position: 'relative',
                  //   height: '154px'
                  // }}
                  style={{
                    position: 'relative',
                    // marginTop: "24px",
                    // marginBottom: "8px",
                    height: '154px',
                    borderRadius: '8px',
                    overflow: 'hidden',
                  }}
                >
                  {booking?.location?.latitude ? (
                    <>
                      <MapView
                        // width="343pxh"
                        initialRegion={{
                          latitude: booking?.location?.latitude, // 37.78825,
                          longitude: booking?.location?.longitude, // -122.4324,
                          latitudeDelta: 0.0922,
                          longitudeDelta: 0.0421,
                        }}
                        // onPress={({ latLng }) => setLatLng({ latitude: latLng.lat(), longitude: latLng.lng() })}
                        // ref={mapViewRef}
                      >
                        {/* @ts-ignore */}
                        <MapView.Marker
                          coordinate={{
                            latitude: booking?.location?.latitude,
                            longitude: booking?.location?.longitude,
                          }}
                          // title={"title"}
                          // description={"description"}
                        >
                          <PlacesIcon />
                          {/* @ts-ignore */}
                        </MapView.Marker>
                      </MapView>
                      {/*<MyBox
                      sx={{
                        left: '50%',
                        top: '50%',
                        position: 'absolute',
                      }}
                      style={{
                        transform: 'translate(-50%, -50%)',
                      }}
                    >
                      <PlacesIcon />
                    </MyBox>*/}
                    </>
                  ) : null}
                </div>
              )}

              <div
                // p={2}
                style={{
                  padding: '16px',
                }}
              >
                {!isOnline && (
                  <div>
                    <Typography variant="subtitle1" color="primary" style={{ marginBottom: '8px' }}>
                      Address:
                    </Typography>
                    <Typography variant="body1" color="primary" style={{ marginBottom: '16px' }}>
                      {booking?.location?.address}
                      <br />
                      {addressSubline}
                      {booking?.location?.detail ? (
                        <div style={{ marginTop: '8px' }}>{booking?.location?.detail}</div>
                      ) : null}
                    </Typography>
                  </div>
                )}

                <div
                  // display="flex"
                  // justifyContent="space-evenly"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                  }}
                >
                  <div
                    // display="inline-flex"
                    // alignItems="center"
                    // // py={1}
                    // // px={2}
                    // mr={2}
                    // flexGrow={1}
                    style={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      // py: {1}
                      // px: {2}
                      marginRight: '16px',
                      flexGrow: 1,
                      // 'background-color': 'rgb(255, 255, 255)',
                      // padding: '8px 16px',
                      // cursor: 'pointer',
                      // userSelect: 'none',
                      // 'border-radius': 8
                    }}
                    onClick={handleLocationCopyClick}
                  >
                    <div className={classes.withTooltip} style={{ width: '100%' }}>
                      <TBKTooltip
                        // className='k-text-regular text-center'
                        element={copyButtonRef}
                        // adjust
                        inside
                        placement="top right"
                        isShow={isCopyTooltipShow}
                        onClick={hideCopyTooltip}
                      >
                        <Typography variant="body1" style={{ color: '#FFFFFF' }}>
                          Copied!
                        </Typography>
                      </TBKTooltip>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        ref={copyButtonRef}
                        className={classes.buttonsDuet}
                      >
                        <Typography variant="body1" color="primary">
                          Copy
                        </Typography>

                        <IconContainer className={classes.redColor}>
                          <CopyIcon />
                        </IconContainer>
                      </Button>
                    </div>
                  </div>

                  <div
                    // display="inline-flex"
                    // alignItems="center"
                    // py={1}
                    // px={2}
                    // flexGrow={1}
                    style={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      // py: {1}
                      // px: {2}
                      flexGrow: 1,
                      // 'background-color': 'rgb(255, 255, 255)',
                      // padding: '8px 16px',
                      // cursor: 'pointer',
                      // userSelect: 'none',
                      // 'border-radius': 8
                    }}
                    // onClick={handleLocationOpenClick}
                  >
                    {isOnline ? (
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        // href={booking?.roomUrl}
                        className={classes.buttonsDuet}
                        // target="_blank"
                      >
                        <Typography variant="body1" color="primary">
                          Open
                        </Typography>
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        className={classes.buttonsDuet}
                        onClick={handleLocationOpenClick}
                      >
                        <Typography variant="body1" color="primary">
                          Open in Maps
                        </Typography>
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </GreyRoundedBox>

            <Container
              maxWidth="sm"
              disableGutters
              className={medicalInfo || clientNotes ? '' : classes.gutterBottomFull}
            >
              <List component="nav" aria-label="mailMybox folders" className={classes.gutterBottomBase}>
                <ListItem
                  button
                  divider
                  style={{ paddingLeft: '0', paddingRight: '0' }}
                  // display="flex"
                  //   alignItems="center"
                  //   justifyContent="space-between"
                  //   flex="1"
                  onClick={handleAddToCalClick}
                >
                  <CalendarIcon />
                  <ListItemText primary="Add to calendar" style={{ marginLeft: '16px' }} />
                  <IconContainer className={classes.redColor}>
                    <ArrowRightIcon />
                  </IconContainer>
                </ListItem>
                <ListItem button style={{ paddingLeft: '0', paddingRight: '0' }} onClick={onContactSupportClick}>
                  <QuestionOIcon />
                  <ListItemText primary="Contact Support" style={{ marginLeft: '16px' }} />
                  <IconContainer className={classes.redColor}>
                    <ArrowRightIcon />
                  </IconContainer>
                </ListItem>
                <Divider light />
              </List>
            </Container>

            {clientNotes && (
              <>
                <Typography variant="h3" className={classes.gutterBottom} style={{ color: '#6B7178' }}>
                  Client’s notes
                </Typography>

                <Typography variant="body1" color="primary" className={classes.gutterBottomExtraLarge}>
                  {clientNotes}
                </Typography>

                <Divider />
              </>
            )}

            {medicalInfo && (
              <>
                <Typography
                  variant="h3"
                  className={classes.gutterBottom}
                  style={{ marginTop: clientNotes ? '24px' : 0, color: '#6B7178' }}
                >
                  Medical information
                </Typography>

                <Typography variant="body1" color="primary" className={classes.gutterBottomExtraLarge}>
                  {medicalInfo}
                </Typography>

                <Divider />
              </>
            )}

            {false && booking?.status === 'PAID' && !declineStatus && (
              <button
                className={clsx(classes.declineButton, classes.gutterTopFull, classes.gutterBottomExtraLarge)}
                onClick={onDeclineSessionClick}
              >
                <Typography
                  variant="subtitle1"
                  color="primary"
                  className={clsx(classes.linkLikeButton, classes.greyColor)}
                  style={{
                    textAlign: 'center',
                  }}
                >
                  Need to decline the session?
                </Typography>
              </button>
            )}

            {booking?.status === 'PAID' && (
              <Button
                variant="outlined"
                color="primary"
                type="submit"
                // disabled={!password.length}
                className={clsx(classes.submitButton, classes.gutterBottom)}
                style={
                  {
                    // 'background-color': status === 'IN_PROGRESS' ? '#303740' : '#E94E32'
                  }
                }
                onClick={onRunningLateClick}
              >
                Running late?
              </Button>
            )}
          </Container>
        </ScrollView>
      ) : (
        <Container>
          <div
            // display="flex"
            // alignItems="center"
            // justifyContent="space-between"
            // // mt={3}
            // mb={4}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              // mt: {3}
              marginBottom: '32px',
            }}
          >
            <div
              // flexShrink="0"
              // mr={2}
              className={classes.animatedPlaceholder}
              style={{
                flexShrink: 0,
                marginRight: '16px',
              }}
            >
              <UserPlaceholder style={{ verticalAlign: 'top' }} />
            </div>
            <div
              // flexGrow="1"
              className={classes.animatedPlaceholder}
              style={{
                flexGrow: 1,
                width: '100%',
                height: '64px',
                backgroundColor: 'rgb(248, 248, 248)', // #F8F8F8
                borderRadius: '8px',
              }}
            ></div>
          </div>
          <div
            // flexGrow="1"
            // mb={2}
            className={classes.animatedPlaceholder}
            style={{
              width: '100%',
              height: '80px',
              marginBottom: '16px',
              flexGrow: 1,
              backgroundColor: 'rgb(248, 248, 248)', // #F8F8F8
              borderRadius: '8px',
            }}
          ></div>
          <div
            // display="flex"
            // flexGrow="1"
            // mb={2}
            style={{
              display: 'grid',
              flexGrow: 1,
              marginBottom: '16px',
              gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
              // gap: '15px',
              gridColumnGap: '15px',
            }}
          >
            <div
              // flexGrow="1"
              className={classes.animatedPlaceholder}
              style={{
                width: '100%',
                height: '164px',
                flexGrow: 1,
                backgroundColor: 'rgb(248, 248, 248)', // #F8F8F8
                borderRadius: '8px',
              }}
            ></div>
            <div
              // flexGrow="1"
              className={classes.animatedPlaceholder}
              style={{
                width: '100%',
                height: '164px',
                flexGrow: 1,
                backgroundColor: 'rgb(248, 248, 248)', // #F8F8F8
                borderRadius: '8px',
              }}
            ></div>
          </div>

          <div
            // flexGrow="1"
            className={classes.animatedPlaceholder}
            style={{
              width: '100%',
              height: '80px',
              flexGrow: 1,
              backgroundColor: 'rgb(248, 248, 248)', // #F8F8F8
              borderRadius: '8px',
            }}
          ></div>
        </Container>
      )}

      {/* Medium Grey */}

      {/*color: #6B7178;*/}

      {/*<Grid container justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          type="submit"
          // disabled={!password.length}
          className={classes.addAreaCtaButton}
        >
          <PlusIcon />
          Add Area
        </Button>
      </Grid>*/}

      {/*<form onSubmit={handleSubmit}>
        <Grid container justifyContent="space-between">
          <div className={classes.passcodeContainer}>
            <InputLabel shrink htmlFor="input-passcode">
              Email
            </InputLabel>
            <TextField
              id="input-email"
              onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
              type="text"
              variant="outlined"
              size="small"
            />
            <InputLabel shrink htmlFor="input-passcode">
              Password
            </InputLabel>
            <TextField
              id="input-passcode"
              onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
              type="password"
              variant="outlined"
              size="small"
            />
            <div>
              {authError && (
                <Typography variant="caption" className={classes.errorMessage}>
                  <ErrorOutlineIcon />
                  {authError.message}
                </Typography>
              )}
            </div>
          </div>
        </Grid>
        <Grid container justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!password.length}
            className={classes.submitButton}
          >
            Submit
          </Button>
        </Grid>
      </form>*/}

      {(cancelledByCustomer || booking?.status === 'PAID' || booking?.status === 'ACTIVE' || !booking) && (
        <Container maxWidth="xs" className={classes.bottomCtaContainer} disableGutters>
          {booking && isFullyOpen ? (
            <Box
              // pt={booking?.status === 'IN_PROGRESS' ? 1 : 2}
              // pb={2}
              // px={2}
              // sx={{
              //   padding: "16px"
              // }}
              // textAlign="center"
              // position='fixed'
              style={{
                paddingTop: booking?.status === 'ACTIVE' ? '8px' : '16px',
                paddingLeft: '16px',
                paddingRight: '16px',
                paddingBottom: '16px',
                backgroundColor: booking?.status === 'ACTIVE' ? '#3BC693' : '#ffffff',
                textAlign: 'center',
              }}
            >
              <Grid
                container
                justifyContent="center"
                // justifyContent="flex-end"
                className={classes.ctaButtonContainer}
              >
                {booking?.status === 'ACTIVE' && (
                  <Typography
                    variant="caption"
                    color="secondary"
                    className={clsx(classes.gutterBottom)}
                    style={{ flexGrow: 1, textTransform: 'uppercase' }}
                  >
                    Session started
                  </Typography>
                )}
                {(cancelledByCustomer || booking?.status === 'PAID' || booking?.status === 'ACTIVE') && (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={cancelledByCustomer || isFetching}
                      className={classes.submitButton}
                      style={{
                        backgroundColor: !cancelledByCustomer && !isFetching ? mainCtaBackgroundColor : '',
                      }}
                      onClick={handleStartStopCtaClick}
                    >
                      {cancelledByCustomer
                        ? 'Cancelled'
                        : booking?.status === 'ACTIVE'
                        ? 'Stop Session'
                        : 'Start Session'}
                    </Button>
                    {isFetching && (
                      <Box
                        display="flex"
                        className={clsx(classes.ctaButtonLoadingSpinner)}
                        sx={{
                          marginRight: '4px',
                        }}
                      >
                        <LoaderSpinnerIcon />
                      </Box>
                    )}
                  </>
                )}
              </Grid>
            </Box>
          ) : (
            <div
              // pt={2}
              // pb={2}
              // px={2}
              // sx={{
              //   padding: "16px"
              // }}
              // textAlign="center"
              // // position='fixed'
              style={{
                padding: '16px',
                backgroundColor: '#ffffff',
                textAlign: 'center',
              }}
            >
              <div
                // flexGrow="1"
                className={classes.animatedPlaceholder}
                style={{
                  width: '100%',
                  height: '64px',
                  flexGrow: 1,
                  backgroundColor: '#CBCFD5',
                  borderRadius: '8px',
                }}
              ></div>
            </div>
          )}
        </Container>
      )}
      {/*<BottomNavigation />*/}
    </div>
  );

  // if (bd.cancelledByCustomer()) {
  //           ((View) customerCancelledButton.getParent()).setVisibility(View.VISIBLE);
  //       } else if (bd.sessionHasStarted() && !bd.sessionHasStopped()) {
  //           ((View) stopSessionButton.getParent()).setVisibility(View.VISIBLE);
  //       } else if (!bd.sessionHasStarted()) {
  //           ((View) startSessionButton.getParent()).setVisibility(View.VISIBLE);
  //       }
}

// function calculateDistance(pointA, pointB) {

//   // http://www.movable-type.co.uk/scripts/latlong.html
//   const lat1 = pointA.coordinate.latitude;
//   const lon1 = pointA.coordinate.longitude;

//   const lat2 = pointB.coordinate.latitude;
//   const lon2 = pointB.coordinate.longitude;

//   const R = 6371e3; // earth radius in meters
//   const φ1 = lat1 * (Math.PI / 180);
//   const φ2 = lat2 * (Math.PI / 180);
//   const Δφ = (lat2 - lat1) * (Math.PI / 180);
//   const Δλ = (lon2 - lon1) * (Math.PI / 180);

//   const a = (Math.sin(Δφ / 2) * Math.sin(Δφ / 2)) +
//             ((Math.cos(φ1) * Math.cos(φ2)) * (Math.sin(Δλ / 2) * Math.sin(Δλ / 2)));

//   const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

//   const distance = R * c;
//   return distance; // in meters
// }
