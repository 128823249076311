import React from 'react';
import { Theme } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import UserPlaceholderInPerson from '../../icons/UserPlaceholderInPerson';
// import SessionInPersonIcon from '../../icons/SessionInPersonIcon';

import sessionInPersonIcon from './session-in-person-icon.png';
import sessionInPersonIcon2x from './session-in-person-icon@2x.png';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
	sessionTypeIcon: {
		display: 'flex',
		position: 'absolute',
		bottom: '0',
		left: '0',
	},
  root: {
  	position: 'relative',
    'justify-content': 'center',
    color: theme.trueblackBasic,
    'min-width': '24px'
  },
  userPictureImgContainer: {
    width: '52px',
    height: '52px',
    'border-radius': '50%',
    border: `2px solid ${theme.violetBasic}`,
    overflow: 'hidden',
  },
  userPictureImg: {
    width: '100%',
    height: 'auto',
    'text-indent': '100%',
    'white-space': 'nowrap',

  },

  sessionInPersonImg: {
    // maxWidth: '100%',
    // height: 'auto',
    width: '24px',
    height: '24px',
  }
}));

export default function SessionInPersonListItemIcon({ pictureUrl }: { pictureUrl?: string }) {
	const classes = useStyles();

  return (
	  <ListItemIcon
      classes={{
        root: classes.root,
      }}
    >
      {pictureUrl ?
        <div className={classes.userPictureImgContainer}>
          <img
            className={classes.userPictureImg}
            itemProp='image'
            src={pictureUrl + '?size=128x128'}
            srcSet={`${pictureUrl}?size=64x64 1x, ${pictureUrl}?size=128x128 2x`}
            alt={`Customer profile`}
          />
        </div> :
        <UserPlaceholderInPerson />
      }
      <div className={classes.sessionTypeIcon}>
      	<img
          className={classes.sessionInPersonImg}
          itemProp='image'
          src={sessionInPersonIcon}
          srcSet={`${sessionInPersonIcon} 1x, ${sessionInPersonIcon2x} 2x`}
          alt={`In-Person session`}
        />
      </div>
    </ListItemIcon>
  );
}
