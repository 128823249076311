export const areas = [
  {
    zipCode: '94501',
    city: 'Alameda',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94502',
    city: 'Alameda',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94706',
    city: 'Albany',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94701',
    city: 'Berkeley',
    county: 'Alameda',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94702',
    city: 'Berkeley',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94703',
    city: 'Berkeley',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94704',
    city: 'Berkeley',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94705',
    city: 'Berkeley',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94707',
    city: 'Berkeley',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94708',
    city: 'Berkeley',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94709',
    city: 'Berkeley',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94710',
    city: 'Berkeley',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94712',
    city: 'Berkeley',
    county: 'Alameda',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94720',
    city: 'Berkeley',
    county: 'Alameda',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94546',
    city: 'Castro Valley',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94552',
    city: 'Castro Valley',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94568',
    city: 'Dublin',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94608',
    city: 'Emeryville',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94662',
    city: 'Emeryville',
    county: 'Alameda',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94536',
    city: 'Fremont',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94537',
    city: 'Fremont',
    county: 'Alameda',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94538',
    city: 'Fremont',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94539',
    city: 'Fremont',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94555',
    city: 'Fremont',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94540',
    city: 'Hayward',
    county: 'Alameda',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94541',
    city: 'Hayward',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94542',
    city: 'Hayward',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94543',
    city: 'Hayward',
    county: 'Alameda',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94544',
    city: 'Hayward',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94545',
    city: 'Hayward',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94557',
    city: 'Hayward',
    county: 'Alameda',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94550',
    city: 'Livermore',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94551',
    city: 'Livermore',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94560',
    city: 'Newark',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94601',
    city: 'Oakland',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94602',
    city: 'Oakland',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94603',
    city: 'Oakland',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94604',
    city: 'Oakland',
    county: 'Alameda',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94605',
    city: 'Oakland',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94606',
    city: 'Oakland',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94607',
    city: 'Oakland',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94609',
    city: 'Oakland',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94610',
    city: 'Oakland',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94611',
    city: 'Oakland',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94612',
    city: 'Oakland',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94613',
    city: 'Oakland',
    county: 'Alameda',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94614',
    city: 'Oakland',
    county: 'Alameda',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94615',
    city: 'Oakland',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94617',
    city: 'Oakland',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94618',
    city: 'Oakland',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94619',
    city: 'Oakland',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94621',
    city: 'Oakland',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94622',
    city: 'Oakland',
    county: 'Alameda',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94623',
    city: 'Oakland',
    county: 'Alameda',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94624',
    city: 'Oakland',
    county: 'Alameda',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94649',
    city: 'Oakland',
    county: 'Alameda',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94659',
    city: 'Oakland',
    county: 'Alameda',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94660',
    city: 'Oakland',
    county: 'Alameda',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94661',
    city: 'Oakland',
    county: 'Alameda',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94666',
    city: 'Oakland',
    county: 'Alameda',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94620',
    city: 'Piedmont',
    county: 'Alameda',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94566',
    city: 'Pleasanton',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94588',
    city: 'Pleasanton',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94577',
    city: 'San Leandro',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94578',
    city: 'San Leandro',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94579',
    city: 'San Leandro',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94580',
    city: 'San Lorenzo',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94586',
    city: 'Sunol',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94587',
    city: 'Union City',
    county: 'Alameda',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95646',
    city: 'Kirkwood',
    county: 'Alpine',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96120',
    city: 'Markleeville',
    county: 'Alpine',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95601',
    city: 'Amador City',
    county: 'Amador',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95699',
    city: 'Drytown',
    county: 'Amador',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95629',
    city: 'Fiddletown',
    county: 'Amador',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95640',
    city: 'Ione',
    county: 'Amador',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95642',
    city: 'Jackson',
    county: 'Amador',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95644',
    city: 'Kit Carson',
    county: 'Amador',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95654',
    city: 'Martell',
    county: 'Amador',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95665',
    city: 'Pine Grove',
    county: 'Amador',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95666',
    city: 'Pioneer',
    county: 'Amador',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95669',
    city: 'Plymouth',
    county: 'Amador',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95675',
    city: 'River Pines',
    county: 'Amador',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95685',
    city: 'Sutter Creek',
    county: 'Amador',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95689',
    city: 'Volcano',
    county: 'Amador',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95914',
    city: 'Bangor',
    county: 'Butte',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95916',
    city: 'Berry Creek',
    county: 'Butte',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95917',
    city: 'Biggs',
    county: 'Butte',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95926',
    city: 'Chico',
    county: 'Butte',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95927',
    city: 'Chico',
    county: 'Butte',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95928',
    city: 'Chico',
    county: 'Butte',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95929',
    city: 'Chico',
    county: 'Butte',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95973',
    city: 'Chico',
    county: 'Butte',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95976',
    city: 'Chico',
    county: 'Butte',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95930',
    city: 'Clipper Mills',
    county: 'Butte',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95938',
    city: 'Durham',
    county: 'Butte',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95940',
    city: 'Feather Falls',
    county: 'Butte',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95941',
    city: 'Forbestown',
    county: 'Butte',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95942',
    city: 'Forest Ranch',
    county: 'Butte',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95948',
    city: 'Gridley',
    county: 'Butte',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95954',
    city: 'Magalia',
    county: 'Butte',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95958',
    city: 'Nelson',
    county: 'Butte',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95965',
    city: 'Oroville',
    county: 'Butte',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95966',
    city: 'Oroville',
    county: 'Butte',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95968',
    city: 'Palermo',
    county: 'Butte',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95967',
    city: 'Paradise',
    county: 'Butte',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95969',
    city: 'Paradise',
    county: 'Butte',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95974',
    city: 'Richvale',
    county: 'Butte',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95978',
    city: 'Stirling City',
    county: 'Butte',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95221',
    city: 'Altaville',
    county: 'Calaveras',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95222',
    city: 'Angels Camp',
    county: 'Calaveras',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95223',
    city: 'Arnold',
    county: 'Calaveras',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95224',
    city: 'Avery',
    county: 'Calaveras',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95225',
    city: 'Burson',
    county: 'Calaveras',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95226',
    city: 'Campo Seco',
    county: 'Calaveras',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95228',
    city: 'Copperopolis',
    county: 'Calaveras',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95229',
    city: 'Douglas Flat',
    county: 'Calaveras',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95232',
    city: 'Glencoe',
    county: 'Calaveras',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95233',
    city: 'Hathaway Pines',
    county: 'Calaveras',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95245',
    city: 'Mokelumne Hill',
    county: 'Calaveras',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95246',
    city: 'Mountain Ranch',
    county: 'Calaveras',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95247',
    city: 'Murphys',
    county: 'Calaveras',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95248',
    city: 'Rail Road Flat',
    county: 'Calaveras',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95249',
    city: 'San Andreas',
    county: 'Calaveras',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95251',
    city: 'Vallecito',
    county: 'Calaveras',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95252',
    city: 'Valley Springs',
    county: 'Calaveras',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95254',
    city: 'Wallace',
    county: 'Calaveras',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95255',
    city: 'West Point',
    county: 'Calaveras',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95257',
    city: 'Wilseyville',
    county: 'Calaveras',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95912',
    city: 'Arbuckle',
    county: 'Colusa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95932',
    city: 'Colusa',
    county: 'Colusa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95950',
    city: 'Grimes',
    county: 'Colusa',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95955',
    city: 'Maxwell',
    county: 'Colusa',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95970',
    city: 'Princeton',
    county: 'Colusa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95979',
    city: 'Stonyford',
    county: 'Colusa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95987',
    city: 'Williams',
    county: 'Colusa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94507',
    city: 'Alamo',
    county: 'Contra Costa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94509',
    city: 'Antioch',
    county: 'Contra Costa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94531',
    city: 'Antioch',
    county: 'Contra Costa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94511',
    city: 'Bethel Island',
    county: 'Contra Costa',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94513',
    city: 'Brentwood',
    county: 'Contra Costa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94514',
    city: 'Byron',
    county: 'Contra Costa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94516',
    city: 'Canyon',
    county: 'Contra Costa',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94517',
    city: 'Clayton',
    county: 'Contra Costa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94518',
    city: 'Concord',
    county: 'Contra Costa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94519',
    city: 'Concord',
    county: 'Contra Costa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94520',
    city: 'Concord',
    county: 'Contra Costa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94521',
    city: 'Concord',
    county: 'Contra Costa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94522',
    city: 'Concord',
    county: 'Contra Costa',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94524',
    city: 'Concord',
    county: 'Contra Costa',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94527',
    city: 'Concord',
    county: 'Contra Costa',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94529',
    city: 'Concord',
    county: 'Contra Costa',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94525',
    city: 'Crockett',
    county: 'Contra Costa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94506',
    city: 'Danville',
    county: 'Contra Costa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94526',
    city: 'Danville',
    county: 'Contra Costa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94528',
    city: 'Diablo',
    county: 'Contra Costa',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94505',
    city: 'Discovery Bay',
    county: 'Contra Costa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94530',
    city: 'El Cerrito',
    county: 'Contra Costa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94803',
    city: 'El Sobrante',
    county: 'Contra Costa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94820',
    city: 'El Sobrante',
    county: 'Contra Costa',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94547',
    city: 'Hercules',
    county: 'Contra Costa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94548',
    city: 'Knightsen',
    county: 'Contra Costa',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94549',
    city: 'Lafayette',
    county: 'Contra Costa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94553',
    city: 'Martinez',
    county: 'Contra Costa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94556',
    city: 'Moraga',
    county: 'Contra Costa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94570',
    city: 'Moraga',
    county: 'Contra Costa',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94575',
    city: 'Moraga',
    county: 'Contra Costa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94561',
    city: 'Oakley',
    county: 'Contra Costa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94563',
    city: 'Orinda',
    county: 'Contra Costa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94564',
    city: 'Pinole',
    county: 'Contra Costa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94565',
    city: 'Pittsburg',
    county: 'Contra Costa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94523',
    city: 'Pleasant Hill',
    county: 'Contra Costa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94569',
    city: 'Port Costa',
    county: 'Contra Costa',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94801',
    city: 'Richmond',
    county: 'Contra Costa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94802',
    city: 'Richmond',
    county: 'Contra Costa',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94804',
    city: 'Richmond',
    county: 'Contra Costa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94805',
    city: 'Richmond',
    county: 'Contra Costa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94807',
    city: 'Richmond',
    county: 'Contra Costa',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94808',
    city: 'Richmond',
    county: 'Contra Costa',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94850',
    city: 'Richmond',
    county: 'Contra Costa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94572',
    city: 'Rodeo',
    county: 'Contra Costa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94806',
    city: 'San Pablo',
    county: 'Contra Costa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94582',
    city: 'San Ramon',
    county: 'Contra Costa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94583',
    city: 'San Ramon',
    county: 'Contra Costa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94595',
    city: 'Walnut Creek',
    county: 'Contra Costa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94596',
    city: 'Walnut Creek',
    county: 'Contra Costa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94597',
    city: 'Walnut Creek',
    county: 'Contra Costa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94598',
    city: 'Walnut Creek',
    county: 'Contra Costa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95531',
    city: 'Crescent City',
    county: 'Del Norte',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95532',
    city: 'Crescent City',
    county: 'Del Norte',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95538',
    city: 'Fort Dick',
    county: 'Del Norte',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95543',
    city: 'Gasquet',
    county: 'Del Norte',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95548',
    city: 'Klamath',
    county: 'Del Norte',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95567',
    city: 'Smith River',
    county: 'Del Norte',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95709',
    city: 'Camino',
    county: 'El Dorado',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95613',
    city: 'Coloma',
    county: 'El Dorado',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95614',
    city: 'Cool',
    county: 'El Dorado',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95619',
    city: 'Diamond Springs',
    county: 'El Dorado',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95721',
    city: 'Echo Lake',
    county: 'El Dorado',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95623',
    city: 'El Dorado',
    county: 'El Dorado',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95762',
    city: 'El Dorado Hills',
    county: 'El Dorado',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95633',
    city: 'Garden Valley',
    county: 'El Dorado',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95634',
    city: 'Georgetown',
    county: 'El Dorado',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95635',
    city: 'Greenwood',
    county: 'El Dorado',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95636',
    city: 'Grizzly Flats',
    county: 'El Dorado',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95720',
    city: 'Kyburz',
    county: 'El Dorado',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95651',
    city: 'Lotus',
    county: 'El Dorado',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95656',
    city: 'Mount Aukum',
    county: 'El Dorado',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95664',
    city: 'Pilot Hill',
    county: 'El Dorado',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95667',
    city: 'Placerville',
    county: 'El Dorado',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95726',
    city: 'Pollock Pines',
    county: 'El Dorado',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95672',
    city: 'Rescue',
    county: 'El Dorado',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95682',
    city: 'Shingle Springs',
    county: 'El Dorado',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95684',
    city: 'Somerset',
    county: 'El Dorado',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96150',
    city: 'South Lake Tahoe',
    county: 'El Dorado',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96151',
    city: 'South Lake Tahoe',
    county: 'El Dorado',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96152',
    city: 'South Lake Tahoe',
    county: 'El Dorado',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96154',
    city: 'South Lake Tahoe',
    county: 'El Dorado',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96155',
    city: 'South Lake Tahoe',
    county: 'El Dorado',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96156',
    city: 'South Lake Tahoe',
    county: 'El Dorado',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96157',
    city: 'South Lake Tahoe',
    county: 'El Dorado',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96158',
    city: 'South Lake Tahoe',
    county: 'El Dorado',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96142',
    city: 'Tahoma',
    county: 'El Dorado',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95735',
    city: 'Twin Bridges',
    county: 'El Dorado',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93602',
    city: 'Auberry',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93605',
    city: 'Big Creek',
    county: 'Fresno',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93606',
    city: 'Biola',
    county: 'Fresno',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93607',
    city: 'Burrel',
    county: 'Fresno',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93608',
    city: 'Cantua Creek',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93609',
    city: 'Caruthers',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93611',
    city: 'Clovis',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93612',
    city: 'Clovis',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93613',
    city: 'Clovis',
    county: 'Fresno',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93619',
    city: 'Clovis',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93210',
    city: 'Coalinga',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93616',
    city: 'Del Rey',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93621',
    city: 'Dunlap',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93622',
    city: 'Firebaugh',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93624',
    city: 'Five Points',
    county: 'Fresno',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93625',
    city: 'Fowler',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93650',
    city: 'Fresno',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93701',
    city: 'Fresno',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93702',
    city: 'Fresno',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93703',
    city: 'Fresno',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93704',
    city: 'Fresno',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93705',
    city: 'Fresno',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93706',
    city: 'Fresno',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93707',
    city: 'Fresno',
    county: 'Fresno',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93708',
    city: 'Fresno',
    county: 'Fresno',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93709',
    city: 'Fresno',
    county: 'Fresno',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93710',
    city: 'Fresno',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93711',
    city: 'Fresno',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93712',
    city: 'Fresno',
    county: 'Fresno',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93714',
    city: 'Fresno',
    county: 'Fresno',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93715',
    city: 'Fresno',
    county: 'Fresno',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93716',
    city: 'Fresno',
    county: 'Fresno',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93717',
    city: 'Fresno',
    county: 'Fresno',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93718',
    city: 'Fresno',
    county: 'Fresno',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93720',
    city: 'Fresno',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93721',
    city: 'Fresno',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93722',
    city: 'Fresno',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93723',
    city: 'Fresno',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93724',
    city: 'Fresno',
    county: 'Fresno',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93725',
    city: 'Fresno',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93726',
    city: 'Fresno',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93727',
    city: 'Fresno',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93728',
    city: 'Fresno',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93729',
    city: 'Fresno',
    county: 'Fresno',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93730',
    city: 'Fresno',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93737',
    city: 'Fresno',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93740',
    city: 'Fresno',
    county: 'Fresno',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93741',
    city: 'Fresno',
    county: 'Fresno',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93744',
    city: 'Fresno',
    county: 'Fresno',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93745',
    city: 'Fresno',
    county: 'Fresno',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93747',
    city: 'Fresno',
    county: 'Fresno',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93750',
    city: 'Fresno',
    county: 'Fresno',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93755',
    city: 'Fresno',
    county: 'Fresno',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93760',
    city: 'Fresno',
    county: 'Fresno',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93761',
    city: 'Fresno',
    county: 'Fresno',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93764',
    city: 'Fresno',
    county: 'Fresno',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93765',
    city: 'Fresno',
    county: 'Fresno',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93771',
    city: 'Fresno',
    county: 'Fresno',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93772',
    city: 'Fresno',
    county: 'Fresno',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93773',
    city: 'Fresno',
    county: 'Fresno',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93774',
    city: 'Fresno',
    county: 'Fresno',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93775',
    city: 'Fresno',
    county: 'Fresno',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93776',
    city: 'Fresno',
    county: 'Fresno',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93777',
    city: 'Fresno',
    county: 'Fresno',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93778',
    city: 'Fresno',
    county: 'Fresno',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93779',
    city: 'Fresno',
    county: 'Fresno',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93786',
    city: 'Fresno',
    county: 'Fresno',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93790',
    city: 'Fresno',
    county: 'Fresno',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93791',
    city: 'Fresno',
    county: 'Fresno',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93792',
    city: 'Fresno',
    county: 'Fresno',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93793',
    city: 'Fresno',
    county: 'Fresno',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93794',
    city: 'Fresno',
    county: 'Fresno',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93844',
    city: 'Fresno',
    county: 'Fresno',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93888',
    city: 'Fresno',
    county: 'Fresno',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93626',
    city: 'Friant',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93627',
    city: 'Helm',
    county: 'Fresno',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93628',
    city: 'Hume',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93234',
    city: 'Huron',
    county: 'Fresno',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93630',
    city: 'Kerman',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93631',
    city: 'Kingsburg',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93634',
    city: 'Lakeshore',
    county: 'Fresno',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93242',
    city: 'Laton',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93640',
    city: 'Mendota',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93641',
    city: 'Miramonte',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93642',
    city: 'Mono Hot Springs',
    county: 'Fresno',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93646',
    city: 'Orange Cove',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93648',
    city: 'Parlier',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93649',
    city: 'Piedra',
    county: 'Fresno',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93651',
    city: 'Prather',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93652',
    city: 'Raisin City',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93654',
    city: 'Reedley',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93656',
    city: 'Riverdale',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93660',
    city: 'San Joaquin',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93657',
    city: 'Sanger',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93662',
    city: 'Selma',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93664',
    city: 'Shaver Lake',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93675',
    city: 'Squaw Valley',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93667',
    city: 'Tollhouse',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93668',
    city: 'Tranquillity',
    county: 'Fresno',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95913',
    city: 'Artois',
    county: 'Glenn',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95920',
    city: 'Butte City',
    county: 'Glenn',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95939',
    city: 'Elk Creek',
    county: 'Glenn',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95943',
    city: 'Glenn',
    county: 'Glenn',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95951',
    city: 'Hamilton City',
    county: 'Glenn',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95963',
    city: 'Orland',
    county: 'Glenn',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95988',
    city: 'Willows',
    county: 'Glenn',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95511',
    city: 'Alderpoint',
    county: 'Humboldt',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95518',
    city: 'Arcata',
    county: 'Humboldt',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95521',
    city: 'Arcata',
    county: 'Humboldt',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95524',
    city: 'Bayside',
    county: 'Humboldt',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95514',
    city: 'Blocksburg',
    county: 'Humboldt',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95525',
    city: 'Blue Lake',
    county: 'Humboldt',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95526',
    city: 'Bridgeville',
    county: 'Humboldt',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95528',
    city: 'Carlotta',
    county: 'Humboldt',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95534',
    city: 'Cutten',
    county: 'Humboldt',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95501',
    city: 'Eureka',
    county: 'Humboldt',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95502',
    city: 'Eureka',
    county: 'Humboldt',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95503',
    city: 'Eureka',
    county: 'Humboldt',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95536',
    city: 'Ferndale',
    county: 'Humboldt',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95537',
    city: 'Fields Landing',
    county: 'Humboldt',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95540',
    city: 'Fortuna',
    county: 'Humboldt',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95542',
    city: 'Garberville',
    county: 'Humboldt',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95545',
    city: 'Honeydew',
    county: 'Humboldt',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95546',
    city: 'Hoopa',
    county: 'Humboldt',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95547',
    city: 'Hydesville',
    county: 'Humboldt',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95549',
    city: 'Kneeland',
    county: 'Humboldt',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95550',
    city: 'Korbel',
    county: 'Humboldt',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95551',
    city: 'Loleta',
    county: 'Humboldt',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95519',
    city: 'Mckinleyville',
    county: 'Humboldt',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95553',
    city: 'Miranda',
    county: 'Humboldt',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95554',
    city: 'Myers Flat',
    county: 'Humboldt',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95555',
    city: 'Orick',
    county: 'Humboldt',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95556',
    city: 'Orleans',
    county: 'Humboldt',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95558',
    city: 'Petrolia',
    county: 'Humboldt',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95559',
    city: 'Phillipsville',
    county: 'Humboldt',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95569',
    city: 'Redcrest',
    county: 'Humboldt',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95560',
    city: 'Redway',
    county: 'Humboldt',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95562',
    city: 'Rio Dell',
    county: 'Humboldt',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95564',
    city: 'Samoa',
    county: 'Humboldt',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95565',
    city: 'Scotia',
    county: 'Humboldt',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95570',
    city: 'Trinidad',
    county: 'Humboldt',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95571',
    city: 'Weott',
    county: 'Humboldt',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95589',
    city: 'Whitethorn',
    county: 'Humboldt',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95573',
    city: 'Willow Creek',
    county: 'Humboldt',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92222',
    city: 'Bard',
    county: 'Imperial',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92227',
    city: 'Brawley',
    county: 'Imperial',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92231',
    city: 'Calexico',
    county: 'Imperial',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92232',
    city: 'Calexico',
    county: 'Imperial',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92233',
    city: 'Calipatria',
    county: 'Imperial',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92243',
    city: 'El Centro',
    county: 'Imperial',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92244',
    city: 'El Centro',
    county: 'Imperial',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92249',
    city: 'Heber',
    county: 'Imperial',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92250',
    city: 'Holtville',
    county: 'Imperial',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92251',
    city: 'Imperial',
    county: 'Imperial',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92257',
    city: 'Niland',
    county: 'Imperial',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92259',
    city: 'Ocotillo',
    county: 'Imperial',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92266',
    city: 'Palo Verde',
    county: 'Imperial',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92275',
    city: 'Salton City',
    county: 'Imperial',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92273',
    city: 'Seeley',
    county: 'Imperial',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92281',
    city: 'Westmorland',
    county: 'Imperial',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92283',
    city: 'Winterhaven',
    county: 'Imperial',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93513',
    city: 'Big Pine',
    county: 'Inyo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93514',
    city: 'Bishop',
    county: 'Inyo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93515',
    city: 'Bishop',
    county: 'Inyo',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93522',
    city: 'Darwin',
    county: 'Inyo',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92328',
    city: 'Death Valley',
    county: 'Inyo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93526',
    city: 'Independence',
    county: 'Inyo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93530',
    city: 'Keeler',
    county: 'Inyo',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93542',
    city: 'Little Lake',
    county: 'Inyo',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93545',
    city: 'Lone Pine',
    county: 'Inyo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93549',
    city: 'Olancha',
    county: 'Inyo',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92384',
    city: 'Shoshone',
    county: 'Inyo',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92389',
    city: 'Tecopa',
    county: 'Inyo',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93203',
    city: 'Arvin',
    county: 'Kern',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93301',
    city: 'Bakersfield',
    county: 'Kern',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93302',
    city: 'Bakersfield',
    county: 'Kern',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93303',
    city: 'Bakersfield',
    county: 'Kern',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93304',
    city: 'Bakersfield',
    county: 'Kern',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93305',
    city: 'Bakersfield',
    county: 'Kern',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93306',
    city: 'Bakersfield',
    county: 'Kern',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93307',
    city: 'Bakersfield',
    county: 'Kern',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93308',
    city: 'Bakersfield',
    county: 'Kern',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93309',
    city: 'Bakersfield',
    county: 'Kern',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93311',
    city: 'Bakersfield',
    county: 'Kern',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93312',
    city: 'Bakersfield',
    county: 'Kern',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93313',
    city: 'Bakersfield',
    county: 'Kern',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93314',
    city: 'Bakersfield',
    county: 'Kern',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93380',
    city: 'Bakersfield',
    county: 'Kern',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93383',
    city: 'Bakersfield',
    county: 'Kern',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93384',
    city: 'Bakersfield',
    county: 'Kern',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93385',
    city: 'Bakersfield',
    county: 'Kern',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93386',
    city: 'Bakersfield',
    county: 'Kern',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93387',
    city: 'Bakersfield',
    county: 'Kern',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93388',
    city: 'Bakersfield',
    county: 'Kern',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93389',
    city: 'Bakersfield',
    county: 'Kern',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93390',
    city: 'Bakersfield',
    county: 'Kern',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93205',
    city: 'Bodfish',
    county: 'Kern',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93516',
    city: 'Boron',
    county: 'Kern',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93596',
    city: 'Boron',
    county: 'Kern',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93206',
    city: 'Buttonwillow',
    county: 'Kern',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93518',
    city: 'Caliente',
    county: 'Kern',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93504',
    city: 'California City',
    county: 'Kern',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93505',
    city: 'California City',
    county: 'Kern',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93519',
    city: 'Cantil',
    county: 'Kern',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93215',
    city: 'Delano',
    county: 'Kern',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93216',
    city: 'Delano',
    county: 'Kern',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93220',
    city: 'Edison',
    county: 'Kern',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93523',
    city: 'Edwards',
    county: 'Kern',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93524',
    city: 'Edwards',
    county: 'Kern',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93224',
    city: 'Fellows',
    county: 'Kern',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93225',
    city: 'Frazier Park',
    county: 'Kern',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93226',
    city: 'Glennville',
    county: 'Kern',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93527',
    city: 'Inyokern',
    county: 'Kern',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93528',
    city: 'Johannesburg',
    county: 'Kern',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93531',
    city: 'Keene',
    county: 'Kern',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93238',
    city: 'Kernville',
    county: 'Kern',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93240',
    city: 'Lake Isabella',
    county: 'Kern',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93241',
    city: 'Lamont',
    county: 'Kern',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93243',
    city: 'Lebec',
    county: 'Kern',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93249',
    city: 'Lost Hills',
    county: 'Kern',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93252',
    city: 'Maricopa',
    county: 'Kern',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93250',
    city: 'Mc Farland',
    county: 'Kern',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93251',
    city: 'Mc Kittrick',
    county: 'Kern',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93501',
    city: 'Mojave',
    county: 'Kern',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93502',
    city: 'Mojave',
    county: 'Kern',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93255',
    city: 'Onyx',
    county: 'Kern',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93222',
    city: 'Pine Mountain Club',
    county: 'Kern',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93554',
    city: 'Randsburg',
    county: 'Kern',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93558',
    city: 'Red Mountain',
    county: 'Kern',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93555',
    city: 'Ridgecrest',
    county: 'Kern',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93556',
    city: 'Ridgecrest',
    county: 'Kern',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93560',
    city: 'Rosamond',
    county: 'Kern',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93263',
    city: 'Shafter',
    county: 'Kern',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93268',
    city: 'Taft',
    county: 'Kern',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93561',
    city: 'Tehachapi',
    county: 'Kern',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93581',
    city: 'Tehachapi',
    county: 'Kern',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93276',
    city: 'Tupman',
    county: 'Kern',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93280',
    city: 'Wasco',
    county: 'Kern',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93283',
    city: 'Weldon',
    county: 'Kern',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93285',
    city: 'Wofford Heights',
    county: 'Kern',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93287',
    city: 'Woody',
    county: 'Kern',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93202',
    city: 'Armona',
    county: 'Kings',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93204',
    city: 'Avenal',
    county: 'Kings',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93212',
    city: 'Corcoran',
    county: 'Kings',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93230',
    city: 'Hanford',
    county: 'Kings',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93232',
    city: 'Hanford',
    county: 'Kings',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93239',
    city: 'Kettleman City',
    county: 'Kings',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93245',
    city: 'Lemoore',
    county: 'Kings',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93246',
    city: 'Lemoore',
    county: 'Kings',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93266',
    city: 'Stratford',
    county: 'Kings',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95422',
    city: 'Clearlake',
    county: 'Lake',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95423',
    city: 'Clearlake Oaks',
    county: 'Lake',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95424',
    city: 'Clearlake Park',
    county: 'Lake',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95426',
    city: 'Cobb',
    county: 'Lake',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95435',
    city: 'Finley',
    county: 'Lake',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95443',
    city: 'Glenhaven',
    county: 'Lake',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95467',
    city: 'Hidden Valley Lake',
    county: 'Lake',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95451',
    city: 'Kelseyville',
    county: 'Lake',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95453',
    city: 'Lakeport',
    county: 'Lake',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95457',
    city: 'Lower Lake',
    county: 'Lake',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95458',
    city: 'Lucerne',
    county: 'Lake',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95461',
    city: 'Middletown',
    county: 'Lake',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95464',
    city: 'Nice',
    county: 'Lake',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95485',
    city: 'Upper Lake',
    county: 'Lake',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95493',
    city: 'Witter Springs',
    county: 'Lake',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96009',
    city: 'Bieber',
    county: 'Lassen',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96109',
    city: 'Doyle',
    county: 'Lassen',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96113',
    city: 'Herlong',
    county: 'Lassen',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96114',
    city: 'Janesville',
    county: 'Lassen',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96117',
    city: 'Litchfield',
    county: 'Lassen',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96119',
    city: 'Madeline',
    county: 'Lassen',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96121',
    city: 'Milford',
    county: 'Lassen',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96068',
    city: 'Nubieber',
    county: 'Lassen',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96123',
    city: 'Ravendale',
    county: 'Lassen',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96128',
    city: 'Standish',
    county: 'Lassen',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96127',
    city: 'Susanville',
    county: 'Lassen',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96130',
    city: 'Susanville',
    county: 'Lassen',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96132',
    city: 'Termo',
    county: 'Lassen',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96136',
    city: 'Wendel',
    county: 'Lassen',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96137',
    city: 'Westwood',
    county: 'Lassen',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93510',
    city: 'Acton',
    county: 'Los Angeles',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91301',
    city: 'Agoura Hills',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Agora Hills',
  },
  {
    zipCode: '91376',
    city: 'Agoura Hills',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91801',
    city: 'Alhambra',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'Alhambra',
  },
  {
    zipCode: '91802',
    city: 'Alhambra',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91803',
    city: 'Alhambra',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'Alhambra',
  },
  {
    zipCode: '91804',
    city: 'Alhambra',
    county: 'Los Angeles',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91896',
    city: 'Alhambra',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91899',
    city: 'Alhambra',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91001',
    city: 'Altadena',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'Altadena',
  },
  {
    zipCode: '91003',
    city: 'Altadena',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91006',
    city: 'Arcadia',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'Arcadia, Mayflower Village',
  },
  {
    zipCode: '91007',
    city: 'Arcadia',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'Arcadia',
  },
  {
    zipCode: '91066',
    city: 'Arcadia',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91077',
    city: 'Arcadia',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90701',
    city: 'Artesia',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'Artesia',
  },
  {
    zipCode: '90702',
    city: 'Artesia',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90704',
    city: 'Avalon',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91702',
    city: 'Azusa',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'Azusa & Angeles Nat. Forest',
  },
  {
    zipCode: '91706',
    city: 'Baldwin Park',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'Baldwin Park, Irwindale',
  },
  {
    zipCode: '90202',
    city: 'Bell',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90201',
    city: 'Bell Gardens',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'Bell Gardens',
  },
  {
    zipCode: '90706',
    city: 'Bellflower',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'Bellflower',
  },
  {
    zipCode: '90707',
    city: 'Bellflower',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90209',
    city: 'Beverly Hills',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90210',
    city: 'Beverly Hills',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'West Side Cities',
    neighbourhood: 'Beverly Hills',
  },
  {
    zipCode: '90211',
    city: 'Beverly Hills',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'West Side Cities',
    neighbourhood: 'Beverly Hills',
  },
  {
    zipCode: '90212',
    city: 'Beverly Hills',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'West Side Cities',
    neighbourhood: 'Beverly Hills',
  },
  {
    zipCode: '90213',
    city: 'Beverly Hills',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91501',
    city: 'Burbank',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Burbank',
  },
  {
    zipCode: '91502',
    city: 'Burbank',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Burbank',
  },
  {
    zipCode: '91503',
    city: 'Burbank',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91504',
    city: 'Burbank',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Burbank',
  },
  {
    zipCode: '91505',
    city: 'Burbank',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Burbank',
  },
  {
    zipCode: '91506',
    city: 'Burbank',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Burbank',
  },
  {
    zipCode: '91507',
    city: 'Burbank',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91508',
    city: 'Burbank',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91510',
    city: 'Burbank',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91521',
    city: 'Burbank',
    county: 'Los Angeles',
    type: 'Unique',
    region: 'San Fernando Valley',
    neighbourhood: 'Walt Disney Studios, Burbank',
  },
  {
    zipCode: '91522',
    city: 'Burbank',
    county: 'Los Angeles',
    type: 'Unique',
    region: 'San Fernando Valley',
    neighbourhood: 'Warner Bros. Studios, Burbank',
  },
  {
    zipCode: '91523',
    city: 'Burbank',
    county: 'Los Angeles',
    type: 'Unique',
    region: 'San Fernando Valley',
    neighbourhood: 'Burbank Studios, Burbank',
  },
  {
    zipCode: '91526',
    city: 'Burbank',
    county: 'Los Angeles',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91302',
    city: 'Calabasas',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Calabasas',
  },
  {
    zipCode: '91372',
    city: 'Calabasas',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91303',
    city: 'Canoga Park',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Canoga Park',
  },
  {
    zipCode: '91304',
    city: 'Canoga Park',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Canoga Park',
  },
  {
    zipCode: '91305',
    city: 'Canoga Park',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91309',
    city: 'Canoga Park',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91351',
    city: 'Canyon Country',
    county: 'Los Angeles',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91386',
    city: 'Canyon Country',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91387',
    city: 'Canyon Country',
    county: 'Los Angeles',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90745',
    city: 'Carson',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'South Bay',
    neighbourhood: 'Carson',
  },
  {
    zipCode: '90746',
    city: 'Carson',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'South Bay',
    neighbourhood: 'Carson',
  },
  {
    zipCode: '90747',
    city: 'Carson',
    county: 'Los Angeles',
    type: 'Unique',
    region: 'South Bay',
    neighbourhood: 'CSU Dominguez Hills, Carson',
  },
  {
    zipCode: '90749',
    city: 'Carson',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90895',
    city: 'Carson',
    county: 'Los Angeles',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91310',
    city: 'Castaic',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91384',
    city: 'Castaic',
    county: 'Los Angeles',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90703',
    city: 'Cerritos',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'Cerritos',
  },
  {
    zipCode: '91311',
    city: 'Chatsworth',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Chatsworth, Fern Ann Falls',
  },
  {
    zipCode: '91313',
    city: 'Chatsworth',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91714',
    city: 'City Of Industry',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91715',
    city: 'City Of Industry',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91716',
    city: 'City Of Industry',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91711',
    city: 'Claremont',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'Clairemont',
  },
  {
    zipCode: '90220',
    city: 'Compton',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'Compton',
  },
  {
    zipCode: '90221',
    city: 'Compton',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'Compton',
  },
  {
    zipCode: '90222',
    city: 'Compton',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'Willowbrook',
  },
  {
    zipCode: '90223',
    city: 'Compton',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90224',
    city: 'Compton',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91722',
    city: 'Covina',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'Covina',
  },
  {
    zipCode: '91723',
    city: 'Covina',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'Covina',
  },
  {
    zipCode: '91724',
    city: 'Covina',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'Covina',
  },
  {
    zipCode: '90230',
    city: 'Culver City',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'West Side Cities',
    neighbourhood: 'Culver City',
  },
  {
    zipCode: '90231',
    city: 'Culver City',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90232',
    city: 'Culver City',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'West Side Cities',
    neighbourhood: 'Culver City',
  },
  {
    zipCode: '91765',
    city: 'Diamond Bar',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'Diamond Bar',
  },
  {
    zipCode: '90239',
    city: 'Downey',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90240',
    city: 'Downey',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'Downey',
  },
  {
    zipCode: '90241',
    city: 'Downey',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'Downey',
  },
  {
    zipCode: '90242',
    city: 'Downey',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'Downey',
  },
  {
    zipCode: '91008',
    city: 'Duarte',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'Bradbury',
  },
  {
    zipCode: '91009',
    city: 'Duarte',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91010',
    city: 'Duarte',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'Duerte',
  },
  {
    zipCode: '91731',
    city: 'El Monte',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'El Monte',
  },
  {
    zipCode: '91732',
    city: 'El Monte',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'El Monte',
  },
  {
    zipCode: '91734',
    city: 'El Monte',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91735',
    city: 'El Monte',
    county: 'Los Angeles',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90245',
    city: 'El Segundo',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'South Bay',
    neighbourhood: 'El Segundo',
  },
  {
    zipCode: '91316',
    city: 'Encino',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Encino',
  },
  {
    zipCode: '91416',
    city: 'Encino',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91426',
    city: 'Encino',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91436',
    city: 'Encino',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Encino',
  },
  {
    zipCode: '90247',
    city: 'Gardena',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'South Bay',
    neighbourhood: 'Gardena',
  },
  {
    zipCode: '90248',
    city: 'Gardena',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'Gardena',
  },
  {
    zipCode: '90249',
    city: 'Gardena',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'South Bay',
    neighbourhood: 'Gardena',
  },
  {
    zipCode: '91201',
    city: 'Glendale',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Glendale',
  },
  {
    zipCode: '91202',
    city: 'Glendale',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Glendale',
  },
  {
    zipCode: '91203',
    city: 'Glendale',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Glendale',
  },
  {
    zipCode: '91204',
    city: 'Glendale',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Glendale',
  },
  {
    zipCode: '91205',
    city: 'Glendale',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Mariposa, Glendale',
  },
  {
    zipCode: '91206',
    city: 'Glendale',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Emerald Isle, Glenoaks Canyon',
  },
  {
    zipCode: '91207',
    city: 'Glendale',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Glendale',
  },
  {
    zipCode: '91208',
    city: 'Glendale',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Montecito Park',
  },
  {
    zipCode: '91209',
    city: 'Glendale',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91210',
    city: 'Glendale',
    county: 'Los Angeles',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91221',
    city: 'Glendale',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91222',
    city: 'Glendale',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91225',
    city: 'Glendale',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91226',
    city: 'Glendale',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91740',
    city: 'Glendora',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'Glendora',
  },
  {
    zipCode: '91741',
    city: 'Glendora',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'Glendora',
  },
  {
    zipCode: '91344',
    city: 'Granada Hills',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Granada Hills',
  },
  {
    zipCode: '91394',
    city: 'Granada Hills',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91745',
    city: 'Hacienda Heights',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'Hacienda Heights, City Of LA',
  },
  {
    zipCode: '90710',
    city: 'Harbor City',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'South Bay',
    neighbourhood: 'Harbor City',
  },
  {
    zipCode: '90716',
    city: 'Hawaiian Gardens',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'Hawaiian Gardens',
  },
  {
    zipCode: '90250',
    city: 'Hawthorne',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'South Bay',
    neighbourhood: 'Hawthorne',
  },
  {
    zipCode: '90251',
    city: 'Hawthorne',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90254',
    city: 'Hermosa Beach',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'South Bay',
    neighbourhood: 'Hermosa Beach',
  },
  {
    zipCode: '90255',
    city: 'Huntington Park',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'Huntington Park',
  },
  {
    zipCode: '90301',
    city: 'Inglewood',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'South Bay',
    neighbourhood: 'Inglewood',
  },
  {
    zipCode: '90302',
    city: 'Inglewood',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'South Bay',
    neighbourhood: 'Inglewood',
  },
  {
    zipCode: '90303',
    city: 'Inglewood',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'South Bay',
    neighbourhood: 'City Of LA',
  },
  {
    zipCode: '90304',
    city: 'Inglewood',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'South Bay',
    neighbourhood: 'Lennox',
  },
  {
    zipCode: '90305',
    city: 'Inglewood',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'South Bay',
    neighbourhood: 'Inglewood',
  },
  {
    zipCode: '90306',
    city: 'Inglewood',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90307',
    city: 'Inglewood',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90308',
    city: 'Inglewood',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90309',
    city: 'Inglewood',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90310',
    city: 'Inglewood',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90311',
    city: 'Inglewood',
    county: 'Los Angeles',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90312',
    city: 'Inglewood',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91011',
    city: 'La Canada Flintridge',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'La Canada Flintridge',
  },
  {
    zipCode: '91012',
    city: 'La Canada Flintridge',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91214',
    city: 'La Crescenta',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'La Crescenta-Montrose',
  },
  {
    zipCode: '91224',
    city: 'La Crescenta',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90637',
    city: 'La Mirada',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90638',
    city: 'La Mirada',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'La Mirada',
  },
  {
    zipCode: '90639',
    city: 'La Mirada',
    county: 'Los Angeles',
    type: 'Unique',
    region: 'Gateway Cities',
    neighbourhood: 'Biola University, La Mirada',
  },
  {
    zipCode: '91744',
    city: 'La Puente',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'La Puente',
  },
  {
    zipCode: '91746',
    city: 'La Puente',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'Bassett',
  },
  {
    zipCode: '91747',
    city: 'La Puente',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91749',
    city: 'La Puente',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91750',
    city: 'La Verne',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'La Verne',
  },
  {
    zipCode: '93532',
    city: 'Lake Hughes',
    county: 'Los Angeles',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90711',
    city: 'Lakewood',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90712',
    city: 'Lakewood',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'Lakewood',
  },
  {
    zipCode: '90713',
    city: 'Lakewood',
    county: 'Los Angeles',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90714',
    city: 'Lakewood',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90715',
    city: 'Lakewood',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'Lakewood',
  },
  {
    zipCode: '93534',
    city: 'Lancaster',
    county: 'Los Angeles',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93535',
    city: 'Lancaster',
    county: 'Los Angeles',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93536',
    city: 'Lancaster',
    county: 'Los Angeles',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93539',
    city: 'Lancaster',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93584',
    city: 'Lancaster',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93586',
    city: 'Lancaster',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90260',
    city: 'Lawndale',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'South Bay',
    neighbourhood: 'Lawndale',
  },
  {
    zipCode: '90261',
    city: 'Lawndale',
    county: 'Los Angeles',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93543',
    city: 'Littlerock',
    county: 'Los Angeles',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93544',
    city: 'Llano',
    county: 'Los Angeles',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90717',
    city: 'Lomita',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'South Bay',
    neighbourhood: 'Lomita',
  },
  {
    zipCode: '90801',
    city: 'Long Beach',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90802',
    city: 'Long Beach',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities, South Bay',
    neighbourhood: 'Downtown Long Beach, Port Of Long Beach',
  },
  {
    zipCode: '90803',
    city: 'Long Beach',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'Belmont Park',
  },
  {
    zipCode: '90804',
    city: 'Long Beach',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'City Of Long Beach',
  },
  {
    zipCode: '90805',
    city: 'Long Beach',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'North Long Beach',
  },
  {
    zipCode: '90806',
    city: 'Long Beach',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'Long Beach Airport',
  },
  {
    zipCode: '90807',
    city: 'Long Beach',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'City Of Long Beach',
  },
  {
    zipCode: '90808',
    city: 'Long Beach',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'City Of Long Beach',
  },
  {
    zipCode: '90809',
    city: 'Long Beach',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90810',
    city: 'Long Beach',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'South Bay',
    neighbourhood: 'West Side',
  },
  {
    zipCode: '90813',
    city: 'Long Beach',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'Downtown Long Beach',
  },
  {
    zipCode: '90814',
    city: 'Long Beach',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'City Of Long Beach',
  },
  {
    zipCode: '90815',
    city: 'Long Beach',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'El Dorado South',
  },
  {
    zipCode: '90822',
    city: 'Long Beach',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'Long Beach Medical Center',
  },
  {
    zipCode: '90831',
    city: 'Long Beach',
    county: 'Los Angeles',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90832',
    city: 'Long Beach',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90833',
    city: 'Long Beach',
    county: 'Los Angeles',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90840',
    city: 'Long Beach',
    county: 'Los Angeles',
    type: 'Unique',
    region: 'Gateway Cities',
    neighbourhood: 'CSU Long Beach',
  },
  {
    zipCode: '90842',
    city: 'Long Beach',
    county: 'Los Angeles',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90844',
    city: 'Long Beach',
    county: 'Los Angeles',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90846',
    city: 'Long Beach',
    county: 'Los Angeles',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90847',
    city: 'Long Beach',
    county: 'Los Angeles',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90848',
    city: 'Long Beach',
    county: 'Los Angeles',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90853',
    city: 'Long Beach',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90001',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'Florence-Graham, City Of LA',
  },
  {
    zipCode: '90002',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'Hacienda Village, City Of LA',
  },
  {
    zipCode: '90003',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'City Of LA',
  },
  {
    zipCode: '90004',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Central LA',
    neighbourhood: 'Oakwood',
  },
  {
    zipCode: '90005',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Central LA',
    neighbourhood: 'City Of LA',
  },
  {
    zipCode: '90006',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Central LA',
    neighbourhood: 'City Of LA',
  },
  {
    zipCode: '90007',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Central LA',
    neighbourhood: 'City Of LA',
  },
  {
    zipCode: '90008',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Central LA',
    neighbourhood: 'Baldwin Village',
  },
  {
    zipCode: '90009',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90010',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90011',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'Central Alameda, City Of LA',
  },
  {
    zipCode: '90012',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Central LA',
    neighbourhood: 'Chinatown',
  },
  {
    zipCode: '90013',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Central LA',
    neighbourhood: 'Downtown Los Angeles',
  },
  {
    zipCode: '90014',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Central LA',
    neighbourhood: 'Downtown Los Angeles',
  },
  {
    zipCode: '90015',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Central LA',
    neighbourhood: 'Downtown Los Angeles',
  },
  {
    zipCode: '90016',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Central LA',
    neighbourhood: 'Baldwin Hills',
  },
  {
    zipCode: '90017',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Central LA',
    neighbourhood: 'City Of LA',
  },
  {
    zipCode: '90018',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Central LA',
    neighbourhood: 'Jefferson Park',
  },
  {
    zipCode: '90019',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Central LA',
    neighbourhood: 'City Of LA',
  },
  {
    zipCode: '90020',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Central LA',
    neighbourhood: 'City Of LA',
  },
  {
    zipCode: '90021',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Central LA',
    neighbourhood: 'Downtown Los Angeles',
  },
  {
    zipCode: '90022',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'East Los Angeles',
  },
  {
    zipCode: '90023',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'City Of LA',
  },
  {
    zipCode: '90024',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'West Side Cities',
    neighbourhood: 'Westwood',
  },
  {
    zipCode: '90025',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'West Side Cities',
    neighbourhood: 'West Los Angeles',
  },
  {
    zipCode: '90026',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Central LA',
    neighbourhood: 'Silver Lake',
  },
  {
    zipCode: '90027',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Central LA',
    neighbourhood: 'Los Feliz',
  },
  {
    zipCode: '90028',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Central LA',
    neighbourhood: 'Hollywood',
  },
  {
    zipCode: '90029',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Central LA',
    neighbourhood: 'City Of LA',
  },
  {
    zipCode: '90030',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90031',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'Lincoln Heights',
  },
  {
    zipCode: '90032',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'Omaha Heights',
  },
  {
    zipCode: '90033',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'Lincoln Heights',
  },
  {
    zipCode: '90034',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'West Side Cities',
    neighbourhood: 'Palms',
  },
  {
    zipCode: '90035',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'West Side Cities',
    neighbourhood: 'Beverlywood',
  },
  {
    zipCode: '90036',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Central LA',
    neighbourhood: 'La Brea',
  },
  {
    zipCode: '90037',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Central LA',
    neighbourhood: 'Vermont Harbor',
  },
  {
    zipCode: '90038',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Central LA',
    neighbourhood: 'Hollywood',
  },
  {
    zipCode: '90039',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Central LA',
    neighbourhood: 'City Of LA',
  },
  {
    zipCode: '90040',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'Commerce',
  },
  {
    zipCode: '90041',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'Eagle Rock',
  },
  {
    zipCode: '90042',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'Highland Park',
  },
  {
    zipCode: '90043',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Central LA',
    neighbourhood: 'View Park-Windsor Hills',
  },
  {
    zipCode: '90044',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'South Bay',
    neighbourhood: 'City Of LA',
  },
  {
    zipCode: '90045',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'South Bay',
    neighbourhood: 'Los Angeles Int. Airport, Westchester',
  },
  {
    zipCode: '90046',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Central LA',
    neighbourhood: 'Hollywood Hills',
  },
  {
    zipCode: '90047',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'South Bay',
    neighbourhood: 'City Of LA',
  },
  {
    zipCode: '90048',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Central LA',
    neighbourhood: 'West Hollywood',
  },
  {
    zipCode: '90049',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'West Side Cities',
    neighbourhood: 'Brentwood',
  },
  {
    zipCode: '90050',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90051',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90052',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90053',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90054',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90055',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90056',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Central LA',
    neighbourhood: 'Ladera Heights',
  },
  {
    zipCode: '90057',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90058',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'Vernon',
  },
  {
    zipCode: '90059',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'Watts, City Of LA',
  },
  {
    zipCode: '90060',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90061',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'City Of LA',
  },
  {
    zipCode: '90062',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Central LA',
    neighbourhood: 'City Of LA',
  },
  {
    zipCode: '90063',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'City Terrac',
  },
  {
    zipCode: '90064',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'West Side Cities',
    neighbourhood: 'West Los Angeles',
  },
  {
    zipCode: '90065',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'Cypress Park',
  },
  {
    zipCode: '90066',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'West Side Cities',
    neighbourhood: 'Mar Vista',
  },
  {
    zipCode: '90067',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'West Side Cities',
    neighbourhood: 'Century City',
  },
  {
    zipCode: '90068',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Central LA',
    neighbourhood: 'City Of LA',
  },
  {
    zipCode: '90070',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90071',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Central LA',
    neighbourhood: 'Downtown Los Angeles',
  },
  {
    zipCode: '90072',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90073',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90074',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90075',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90076',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90077',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'West Side Cities',
    neighbourhood: 'Bel Air',
  },
  {
    zipCode: '90078',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90079',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90080',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90081',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90082',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90083',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90084',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90086',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90087',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90088',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90089',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Unique',
    region: 'Central LA',
    neighbourhood: 'USC',
  },
  {
    zipCode: '90091',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90093',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90095',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Unique',
    region: 'West Side Cities',
    neighbourhood: 'UCLA',
  },
  {
    zipCode: '90096',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90099',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90134',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90189',
    city: 'Los Angeles',
    county: 'Los Angeles',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90262',
    city: 'Lynwood',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'Lynwood',
  },
  {
    zipCode: '90263',
    city: 'Malibu',
    county: 'Los Angeles',
    type: 'Unique',
    region: 'West Side Cities',
    neighbourhood: 'Pepperdine University, Malibu',
  },
  {
    zipCode: '90264',
    city: 'Malibu',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90265',
    city: 'Malibu',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'West Side Cities',
    neighbourhood: 'Malibu',
  },
  {
    zipCode: '90266',
    city: 'Manhattan Beach',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'South Bay',
    neighbourhood: 'Manhattan Beach',
  },
  {
    zipCode: '90267',
    city: 'Manhattan Beach',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90292',
    city: 'Marina Del Rey',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'West Side Cities',
    neighbourhood: 'Marina Del Rey',
  },
  {
    zipCode: '90295',
    city: 'Marina Del Rey',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90270',
    city: 'Maywood',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'Maywood',
  },
  {
    zipCode: '91345',
    city: 'Mission Hills',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Mission Hills',
  },
  {
    zipCode: '91346',
    city: 'Mission Hills',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91395',
    city: 'Mission Hills',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91016',
    city: 'Monrovia',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'Monrovia',
  },
  {
    zipCode: '91017',
    city: 'Monrovia',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90640',
    city: 'Montebello',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'Montebello',
  },
  {
    zipCode: '91754',
    city: 'Monterey Park',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'Monterey Park',
  },
  {
    zipCode: '91755',
    city: 'Monterey Park',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'Monterey Park',
  },
  {
    zipCode: '91756',
    city: 'Monterey Park',
    county: 'Los Angeles',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91020',
    city: 'Montrose',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'La Crescenta-Montrose',
  },
  {
    zipCode: '91021',
    city: 'Montrose',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91023',
    city: 'Mount Wilson',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91321',
    city: 'Newhall',
    county: 'Los Angeles',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91322',
    city: 'Newhall',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91343',
    city: 'North Hills',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'North Hills',
  },
  {
    zipCode: '91393',
    city: 'North Hills',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91601',
    city: 'North Hollywood',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'North Hollywood',
  },
  {
    zipCode: '91602',
    city: 'North Hollywood',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Universal City',
  },
  {
    zipCode: '91603',
    city: 'North Hollywood',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91605',
    city: 'North Hollywood',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'North Hollywood',
  },
  {
    zipCode: '91606',
    city: 'North Hollywood',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'North Hollywood',
  },
  {
    zipCode: '91609',
    city: 'North Hollywood',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91611',
    city: 'North Hollywood',
    county: 'Los Angeles',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91612',
    city: 'North Hollywood',
    county: 'Los Angeles',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91615',
    city: 'North Hollywood',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91616',
    city: 'North Hollywood',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91618',
    city: 'North Hollywood',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91324',
    city: 'Northridge',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Northridge',
  },
  {
    zipCode: '91325',
    city: 'Northridge',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Northridge',
  },
  {
    zipCode: '91327',
    city: 'Northridge',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91328',
    city: 'Northridge',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91329',
    city: 'Northridge',
    county: 'Los Angeles',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91330',
    city: 'Northridge',
    county: 'Los Angeles',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90650',
    city: 'Norwalk',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'Norwalk',
  },
  {
    zipCode: '90651',
    city: 'Norwalk',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90652',
    city: 'Norwalk',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90272',
    city: 'Pacific Palisades',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'West Side Cities',
    neighbourhood: 'Pacific Palisades',
  },
  {
    zipCode: '91331',
    city: 'Pacoima',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Pacoima',
  },
  {
    zipCode: '91333',
    city: 'Pacoima',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91334',
    city: 'Pacoima',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93550',
    city: 'Palmdale',
    county: 'Los Angeles',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93551',
    city: 'Palmdale',
    county: 'Los Angeles',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93552',
    city: 'Palmdale',
    county: 'Los Angeles',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93590',
    city: 'Palmdale',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93591',
    city: 'Palmdale',
    county: 'Los Angeles',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93599',
    city: 'Palmdale',
    county: 'Los Angeles',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90274',
    city: 'Palos Verdes Peninsula',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'South Bay',
    neighbourhood: 'Palos Verdes Estates, Rolling Hills, Rolling Hills Estates',
  },
  {
    zipCode: '91402',
    city: 'Panorama City',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Panorama City',
  },
  {
    zipCode: '91412',
    city: 'Panorama City',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90723',
    city: 'Paramount',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'Paramount',
  },
  {
    zipCode: '91101',
    city: 'Pasadena',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'Pasadena',
  },
  {
    zipCode: '91102',
    city: 'Pasadena',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91103',
    city: 'Pasadena',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'Pasadena',
  },
  {
    zipCode: '91104',
    city: 'Pasadena',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'Pasadena',
  },
  {
    zipCode: '91105',
    city: 'Pasadena',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'Pasadena',
  },
  {
    zipCode: '91106',
    city: 'Pasadena',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'Pasadena',
  },
  {
    zipCode: '91107',
    city: 'Pasadena',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'Pasadena',
  },
  {
    zipCode: '91109',
    city: 'Pasadena',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91110',
    city: 'Pasadena',
    county: 'Los Angeles',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91114',
    city: 'Pasadena',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91115',
    city: 'Pasadena',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91116',
    city: 'Pasadena',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91117',
    city: 'Pasadena',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91121',
    city: 'Pasadena',
    county: 'Los Angeles',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91123',
    city: 'Pasadena',
    county: 'Los Angeles',
    type: 'Unique',
    region: 'San Gabriel Valley',
    neighbourhood: 'Pasadena',
  },
  {
    zipCode: '91124',
    city: 'Pasadena',
    county: 'Los Angeles',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91125',
    city: 'Pasadena',
    county: 'Los Angeles',
    type: 'Unique',
    region: 'San Gabriel Valley',
    neighbourhood: 'Cal Tech, Pasadena',
  },
  {
    zipCode: '91126',
    city: 'Pasadena',
    county: 'Los Angeles',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91129',
    city: 'Pasadena',
    county: 'Los Angeles',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91182',
    city: 'Pasadena',
    county: 'Los Angeles',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91184',
    city: 'Pasadena',
    county: 'Los Angeles',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91185',
    city: 'Pasadena',
    county: 'Los Angeles',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91188',
    city: 'Pasadena',
    county: 'Los Angeles',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91189',
    city: 'Pasadena',
    county: 'Los Angeles',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91199',
    city: 'Pasadena',
    county: 'Los Angeles',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93553',
    city: 'Pearblossom',
    county: 'Los Angeles',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90660',
    city: 'Pico Rivera',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'Pico Rivera',
  },
  {
    zipCode: '90661',
    city: 'Pico Rivera',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90662',
    city: 'Pico Rivera',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90293',
    city: 'Playa Del Rey',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'South Bay',
    neighbourhood: 'Playa Del Rey',
  },
  {
    zipCode: '90296',
    city: 'Playa Del Rey',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90094',
    city: 'Playa Vista',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'South Bay',
    neighbourhood: 'Playa Vista',
  },
  {
    zipCode: '91766',
    city: 'Pomona',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'Pomona',
  },
  {
    zipCode: '91767',
    city: 'Pomona',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'North Pomona, Pomona',
  },
  {
    zipCode: '91768',
    city: 'Pomona',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'Pomona',
  },
  {
    zipCode: '91769',
    city: 'Pomona',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91326',
    city: 'Porter Ranch',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Porter Ranch',
  },
  {
    zipCode: '90275',
    city: 'Rancho Palos Verdes',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'South Bay',
    neighbourhood: 'Rancho Palos Verdes',
  },
  {
    zipCode: '90277',
    city: 'Redondo Beach',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'South Bay',
    neighbourhood: 'Redondo Beach',
  },
  {
    zipCode: '90278',
    city: 'Redondo Beach',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'South Bay',
    neighbourhood: 'North Redondo',
  },
  {
    zipCode: '91335',
    city: 'Reseda',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Reseda',
  },
  {
    zipCode: '91337',
    city: 'Reseda',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91770',
    city: 'Rosemead',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'Rosemead',
  },
  {
    zipCode: '91771',
    city: 'Rosemead',
    county: 'Los Angeles',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91772',
    city: 'Rosemead',
    county: 'Los Angeles',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91748',
    city: 'Rowland Heights',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'Rowland Heights, City Of LA',
  },
  {
    zipCode: '91773',
    city: 'San Dimas',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'San Dimas',
  },
  {
    zipCode: '91340',
    city: 'San Fernando',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'San Fernando',
  },
  {
    zipCode: '91341',
    city: 'San Fernando',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91775',
    city: 'San Gabriel',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'East San Gabriel',
  },
  {
    zipCode: '91776',
    city: 'San Gabriel',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'San Gabriel',
  },
  {
    zipCode: '91778',
    city: 'San Gabriel',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91108',
    city: 'San Marino',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'San Marino',
  },
  {
    zipCode: '91118',
    city: 'San Marino',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90731',
    city: 'San Pedro',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'South Bay',
    neighbourhood: 'San Pedro',
  },
  {
    zipCode: '90732',
    city: 'San Pedro',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'South Bay',
    neighbourhood: 'San Pedro',
  },
  {
    zipCode: '90733',
    city: 'San Pedro',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90734',
    city: 'San Pedro',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91350',
    city: 'Santa Clarita',
    county: 'Los Angeles',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91380',
    city: 'Santa Clarita',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91382',
    city: 'Santa Clarita',
    county: 'Los Angeles',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91383',
    city: 'Santa Clarita',
    county: 'Los Angeles',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91390',
    city: 'Santa Clarita',
    county: 'Los Angeles',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90670',
    city: 'Santa Fe Springs',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'Santa Fe Springs',
  },
  {
    zipCode: '90671',
    city: 'Santa Fe Springs',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90401',
    city: 'Santa Monica',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'West Side Cities',
    neighbourhood: 'Santa Monica',
  },
  {
    zipCode: '90402',
    city: 'Santa Monica',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'West Side Cities',
    neighbourhood: 'Santa Monica',
  },
  {
    zipCode: '90403',
    city: 'Santa Monica',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'West Side Cities',
    neighbourhood: 'Santa Monica',
  },
  {
    zipCode: '90404',
    city: 'Santa Monica',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'West Side Cities',
    neighbourhood: 'Santa Monica',
  },
  {
    zipCode: '90405',
    city: 'Santa Monica',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'West Side Cities',
    neighbourhood: 'Santa Monica',
  },
  {
    zipCode: '90406',
    city: 'Santa Monica',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90407',
    city: 'Santa Monica',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90408',
    city: 'Santa Monica',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90409',
    city: 'Santa Monica',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90410',
    city: 'Santa Monica',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90411',
    city: 'Santa Monica',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91403',
    city: 'Sherman Oaks',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Sherman Oaks',
  },
  {
    zipCode: '91413',
    city: 'Sherman Oaks',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91423',
    city: 'Sherman Oaks',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Sherman Oaks',
  },
  {
    zipCode: '91495',
    city: 'Sherman Oaks',
    county: 'Los Angeles',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91024',
    city: 'Sierra Madre',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'Sierra Madre',
  },
  {
    zipCode: '91025',
    city: 'Sierra Madre',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90755',
    city: 'Signal Hill',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'Signal Hill',
  },
  {
    zipCode: '91733',
    city: 'South El Monte',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'South El Monte',
  },
  {
    zipCode: '90280',
    city: 'South Gate',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'South Gate',
  },
  {
    zipCode: '91030',
    city: 'South Pasadena',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'South Pasadena',
  },
  {
    zipCode: '91031',
    city: 'South Pasadena',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91381',
    city: 'Stevenson Ranch',
    county: 'Los Angeles',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91604',
    city: 'Studio City',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Studio City',
  },
  {
    zipCode: '91614',
    city: 'Studio City',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91352',
    city: 'Sun Valley',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Sun Valley',
  },
  {
    zipCode: '91353',
    city: 'Sun Valley',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91040',
    city: 'Sunland',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Sunland-Tujunga',
  },
  {
    zipCode: '91041',
    city: 'Sunland',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91342',
    city: 'Sylmar',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Lake View Terrace, Sylmar',
  },
  {
    zipCode: '91392',
    city: 'Sylmar',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91356',
    city: 'Tarzana',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Tarzana',
  },
  {
    zipCode: '91357',
    city: 'Tarzana',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91780',
    city: 'Temple City',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'Temple City',
  },
  {
    zipCode: '91610',
    city: 'Toluca Lake',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90290',
    city: 'Topanga',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'West Side Cities',
    neighbourhood: 'Old Topanga, Sylvia Park, Topanga',
  },
  {
    zipCode: '90501',
    city: 'Torrance',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'South Bay',
    neighbourhood: 'Torrance',
  },
  {
    zipCode: '90502',
    city: 'Torrance',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'South Bay',
    neighbourhood: 'Torrance',
  },
  {
    zipCode: '90503',
    city: 'Torrance',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'South Bay',
    neighbourhood: 'Torrance',
  },
  {
    zipCode: '90504',
    city: 'Torrance',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'South Bay',
    neighbourhood: 'Torrance',
  },
  {
    zipCode: '90505',
    city: 'Torrance',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'South Bay',
    neighbourhood: 'Torrance',
  },
  {
    zipCode: '90506',
    city: 'Torrance',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'South Bay',
    neighbourhood: 'El Camino College, Torrance',
  },
  {
    zipCode: '90507',
    city: 'Torrance',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90508',
    city: 'Torrance',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90509',
    city: 'Torrance',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90510',
    city: 'Torrance',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91042',
    city: 'Tujunga',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Sunland-Tujunga',
  },
  {
    zipCode: '91043',
    city: 'Tujunga',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91608',
    city: 'Universal City',
    county: 'Los Angeles',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91354',
    city: 'Valencia',
    county: 'Los Angeles',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91355',
    city: 'Valencia',
    county: 'Los Angeles',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91385',
    city: 'Valencia',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91607',
    city: 'Valley Village',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Valley Village',
  },
  {
    zipCode: '91617',
    city: 'Valley Village',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93563',
    city: 'Valyermo',
    county: 'Los Angeles',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91401',
    city: 'Van Nuys',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Van Nuys',
  },
  {
    zipCode: '91404',
    city: 'Van Nuys',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91405',
    city: 'Van Nuys',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Van Nuys',
  },
  {
    zipCode: '91406',
    city: 'Van Nuys',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Lake Balboa',
  },
  {
    zipCode: '91407',
    city: 'Van Nuys',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91408',
    city: 'Van Nuys',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91409',
    city: 'Van Nuys',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91410',
    city: 'Van Nuys',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91411',
    city: 'Van Nuys',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Van Nuys',
  },
  {
    zipCode: '91470',
    city: 'Van Nuys',
    county: 'Los Angeles',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91482',
    city: 'Van Nuys',
    county: 'Los Angeles',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91496',
    city: 'Van Nuys',
    county: 'Los Angeles',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91499',
    city: 'Van Nuys',
    county: 'Los Angeles',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90291',
    city: 'Venice',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'West Side Cities',
    neighbourhood: 'Venice',
  },
  {
    zipCode: '90294',
    city: 'Venice',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91046',
    city: 'Verdugo City',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91788',
    city: 'Walnut',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91789',
    city: 'Walnut',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'Walnut',
  },
  {
    zipCode: '91790',
    city: 'West Covina',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'West Covina',
  },
  {
    zipCode: '91791',
    city: 'West Covina',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'West Covina',
  },
  {
    zipCode: '91792',
    city: 'West Covina',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'Woodside Village',
  },
  {
    zipCode: '91793',
    city: 'West Covina',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91307',
    city: 'West Hills',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'West Hills',
  },
  {
    zipCode: '91308',
    city: 'West Hills',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90069',
    city: 'West Hollywood',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Central LA',
    neighbourhood: 'City Of LA',
  },
  {
    zipCode: '90601',
    city: 'Whittier',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'Whittier',
  },
  {
    zipCode: '90602',
    city: 'Whittier',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'Whittier',
  },
  {
    zipCode: '90603',
    city: 'Whittier',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'East Whittier',
  },
  {
    zipCode: '90604',
    city: 'Whittier',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'East Whittier',
  },
  {
    zipCode: '90605',
    city: 'Whittier',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'South Whittier',
  },
  {
    zipCode: '90606',
    city: 'Whittier',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'Whittier',
  },
  {
    zipCode: '90607',
    city: 'Whittier',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90608',
    city: 'Whittier',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90609',
    city: 'Whittier',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90610',
    city: 'Whittier',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90744',
    city: 'Wilmington',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'South Bay',
    neighbourhood: 'Mington',
  },
  {
    zipCode: '90748',
    city: 'Wilmington',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91306',
    city: 'Winnetka',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Winnetka',
  },
  {
    zipCode: '91396',
    city: 'Winnetka',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91364',
    city: 'Woodland Hills',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Woodland Hills',
  },
  {
    zipCode: '91365',
    city: 'Woodland Hills',
    county: 'Los Angeles',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91367',
    city: 'Woodland Hills',
    county: 'Los Angeles',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Woodland Hills',
  },
  {
    zipCode: '91371',
    city: 'Woodland Hills',
    county: 'Los Angeles',
    type: 'Unique',
    region: 'San Fernando Valley',
    neighbourhood: 'Pierce College, Woodland Hills',
  },
  {
    zipCode: '93601',
    city: 'Ahwahnee',
    county: 'Madera',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93604',
    city: 'Bass Lake',
    county: 'Madera',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93610',
    city: 'Chowchilla',
    county: 'Madera',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93614',
    city: 'Coarsegold',
    county: 'Madera',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93636',
    city: 'Madera',
    county: 'Madera',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93637',
    city: 'Madera',
    county: 'Madera',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93638',
    city: 'Madera',
    county: 'Madera',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93639',
    city: 'Madera',
    county: 'Madera',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93643',
    city: 'North Fork',
    county: 'Madera',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93645',
    city: 'O Neals',
    county: 'Madera',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93644',
    city: 'Oakhurst',
    county: 'Madera',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93653',
    city: 'Raymond',
    county: 'Madera',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93669',
    city: 'Wishon',
    county: 'Madera',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94920',
    city: 'Belvedere Tiburon',
    county: 'Marin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94924',
    city: 'Bolinas',
    county: 'Marin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94925',
    city: 'Corte Madera',
    county: 'Marin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94976',
    city: 'Corte Madera',
    county: 'Marin',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94929',
    city: 'Dillon Beach',
    county: 'Marin',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94930',
    city: 'Fairfax',
    county: 'Marin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94978',
    city: 'Fairfax',
    county: 'Marin',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94933',
    city: 'Forest Knolls',
    county: 'Marin',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94904',
    city: 'Greenbrae',
    county: 'Marin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94937',
    city: 'Inverness',
    county: 'Marin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94914',
    city: 'Kentfield',
    county: 'Marin',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94938',
    city: 'Lagunitas',
    county: 'Marin',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94939',
    city: 'Larkspur',
    county: 'Marin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94977',
    city: 'Larkspur',
    county: 'Marin',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94940',
    city: 'Marshall',
    county: 'Marin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94941',
    city: 'Mill Valley',
    county: 'Marin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94942',
    city: 'Mill Valley',
    county: 'Marin',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94946',
    city: 'Nicasio',
    county: 'Marin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94945',
    city: 'Novato',
    county: 'Marin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94947',
    city: 'Novato',
    county: 'Marin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94948',
    city: 'Novato',
    county: 'Marin',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94949',
    city: 'Novato',
    county: 'Marin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94998',
    city: 'Novato',
    county: 'Marin',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94950',
    city: 'Olema',
    county: 'Marin',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94956',
    city: 'Point Reyes Station',
    county: 'Marin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94957',
    city: 'Ross',
    county: 'Marin',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94960',
    city: 'San Anselmo',
    county: 'Marin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94979',
    city: 'San Anselmo',
    county: 'Marin',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94963',
    city: 'San Geronimo',
    county: 'Marin',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94964',
    city: 'San Quentin',
    county: 'Marin',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94974',
    city: 'San Quentin',
    county: 'Marin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94901',
    city: 'San Rafael',
    county: 'Marin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94903',
    city: 'San Rafael',
    county: 'Marin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94912',
    city: 'San Rafael',
    county: 'Marin',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94913',
    city: 'San Rafael',
    county: 'Marin',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94915',
    city: 'San Rafael',
    county: 'Marin',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94965',
    city: 'Sausalito',
    county: 'Marin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94966',
    city: 'Sausalito',
    county: 'Marin',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94970',
    city: 'Stinson Beach',
    county: 'Marin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94971',
    city: 'Tomales',
    county: 'Marin',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94973',
    city: 'Woodacre',
    county: 'Marin',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95306',
    city: 'Catheys Valley',
    county: 'Mariposa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95311',
    city: 'Coulterville',
    county: 'Mariposa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95318',
    city: 'El Portal',
    county: 'Mariposa',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93623',
    city: 'Fish Camp',
    county: 'Mariposa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95325',
    city: 'Hornitos',
    county: 'Mariposa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95338',
    city: 'Mariposa',
    county: 'Mariposa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95345',
    city: 'Midpines',
    county: 'Mariposa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95389',
    city: 'Yosemite National Park',
    county: 'Mariposa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95410',
    city: 'Albion',
    county: 'Mendocino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95415',
    city: 'Boonville',
    county: 'Mendocino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95417',
    city: 'Branscomb',
    county: 'Mendocino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95418',
    city: 'Calpella',
    county: 'Mendocino',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95420',
    city: 'Caspar',
    county: 'Mendocino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95427',
    city: 'Comptche',
    county: 'Mendocino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95428',
    city: 'Covelo',
    county: 'Mendocino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95429',
    city: 'Dos Rios',
    county: 'Mendocino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95432',
    city: 'Elk',
    county: 'Mendocino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95437',
    city: 'Fort Bragg',
    county: 'Mendocino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95445',
    city: 'Gualala',
    county: 'Mendocino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95449',
    city: 'Hopland',
    county: 'Mendocino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95454',
    city: 'Laytonville',
    county: 'Mendocino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95585',
    city: 'Leggett',
    county: 'Mendocino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95456',
    city: 'Little River',
    county: 'Mendocino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95459',
    city: 'Manchester',
    county: 'Mendocino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95460',
    city: 'Mendocino',
    county: 'Mendocino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95463',
    city: 'Navarro',
    county: 'Mendocino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95466',
    city: 'Philo',
    county: 'Mendocino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95587',
    city: 'Piercy',
    county: 'Mendocino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95468',
    city: 'Point Arena',
    county: 'Mendocino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95469',
    city: 'Potter Valley',
    county: 'Mendocino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95470',
    city: 'Redwood Valley',
    county: 'Mendocino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95481',
    city: 'Talmage',
    county: 'Mendocino',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95482',
    city: 'Ukiah',
    county: 'Mendocino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95488',
    city: 'Westport',
    county: 'Mendocino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95490',
    city: 'Willits',
    county: 'Mendocino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95494',
    city: 'Yorkville',
    county: 'Mendocino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95301',
    city: 'Atwater',
    county: 'Merced',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95303',
    city: 'Ballico',
    county: 'Merced',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95312',
    city: 'Cressey',
    county: 'Merced',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95315',
    city: 'Delhi',
    county: 'Merced',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93620',
    city: 'Dos Palos',
    county: 'Merced',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95317',
    city: 'El Nido',
    county: 'Merced',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95322',
    city: 'Gustine',
    county: 'Merced',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95324',
    city: 'Hilmar',
    county: 'Merced',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95333',
    city: 'Le Grand',
    county: 'Merced',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95334',
    city: 'Livingston',
    county: 'Merced',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93635',
    city: 'Los Banos',
    county: 'Merced',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95340',
    city: 'Merced',
    county: 'Merced',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95341',
    city: 'Merced',
    county: 'Merced',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95343',
    city: 'Merced',
    county: 'Merced',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95344',
    city: 'Merced',
    county: 'Merced',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95348',
    city: 'Merced',
    county: 'Merced',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95365',
    city: 'Planada',
    county: 'Merced',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93661',
    city: 'Santa Rita Park',
    county: 'Merced',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95369',
    city: 'Snelling',
    county: 'Merced',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93665',
    city: 'South Dos Palos',
    county: 'Merced',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95374',
    city: 'Stevinson',
    county: 'Merced',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95388',
    city: 'Winton',
    county: 'Merced',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96006',
    city: 'Adin',
    county: 'Modoc',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96101',
    city: 'Alturas',
    county: 'Modoc',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96015',
    city: 'Canby',
    county: 'Modoc',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96104',
    city: 'Cedarville',
    county: 'Modoc',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96108',
    city: 'Davis Creek',
    county: 'Modoc',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96110',
    city: 'Eagleville',
    county: 'Modoc',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96112',
    city: 'Fort Bidwell',
    county: 'Modoc',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96115',
    city: 'Lake City',
    county: 'Modoc',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96116',
    city: 'Likely',
    county: 'Modoc',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96054',
    city: 'Lookout',
    county: 'Modoc',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93512',
    city: 'Benton',
    county: 'Mono',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93517',
    city: 'Bridgeport',
    county: 'Mono',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96107',
    city: 'Coleville',
    county: 'Mono',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93529',
    city: 'June Lake',
    county: 'Mono',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93541',
    city: 'Lee Vining',
    county: 'Mono',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93546',
    city: 'Mammoth Lakes',
    county: 'Mono',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96133',
    city: 'Topaz',
    county: 'Mono',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95004',
    city: 'Aromas',
    county: 'Monterey',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93920',
    city: 'Big Sur',
    county: 'Monterey',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93426',
    city: 'Bradley',
    county: 'Monterey',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93922',
    city: 'Carmel',
    county: 'Monterey',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93923',
    city: 'Carmel',
    county: 'Monterey',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93921',
    city: 'Carmel By The Sea',
    county: 'Monterey',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93924',
    city: 'Carmel Valley',
    county: 'Monterey',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95012',
    city: 'Castroville',
    county: 'Monterey',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93925',
    city: 'Chualar',
    county: 'Monterey',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93926',
    city: 'Gonzales',
    county: 'Monterey',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93927',
    city: 'Greenfield',
    county: 'Monterey',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93928',
    city: 'Jolon',
    county: 'Monterey',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93930',
    city: 'King City',
    county: 'Monterey',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93932',
    city: 'Lockwood',
    county: 'Monterey',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93933',
    city: 'Marina',
    county: 'Monterey',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93940',
    city: 'Monterey',
    county: 'Monterey',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93942',
    city: 'Monterey',
    county: 'Monterey',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93943',
    city: 'Monterey',
    county: 'Monterey',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93944',
    city: 'Monterey',
    county: 'Monterey',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95039',
    city: 'Moss Landing',
    county: 'Monterey',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93950',
    city: 'Pacific Grove',
    county: 'Monterey',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93953',
    city: 'Pebble Beach',
    county: 'Monterey',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93901',
    city: 'Salinas',
    county: 'Monterey',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93902',
    city: 'Salinas',
    county: 'Monterey',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93905',
    city: 'Salinas',
    county: 'Monterey',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93906',
    city: 'Salinas',
    county: 'Monterey',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93907',
    city: 'Salinas',
    county: 'Monterey',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93908',
    city: 'Salinas',
    county: 'Monterey',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93912',
    city: 'Salinas',
    county: 'Monterey',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93915',
    city: 'Salinas',
    county: 'Monterey',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93450',
    city: 'San Ardo',
    county: 'Monterey',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93954',
    city: 'San Lucas',
    county: 'Monterey',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93955',
    city: 'Seaside',
    county: 'Monterey',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93960',
    city: 'Soledad',
    county: 'Monterey',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93962',
    city: 'Spreckels',
    county: 'Monterey',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94503',
    city: 'American Canyon',
    county: 'Napa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94508',
    city: 'Angwin',
    county: 'Napa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94515',
    city: 'Calistoga',
    county: 'Napa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94576',
    city: 'Deer Park',
    county: 'Napa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94558',
    city: 'Napa',
    county: 'Napa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94559',
    city: 'Napa',
    county: 'Napa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94581',
    city: 'Napa',
    county: 'Napa',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94562',
    city: 'Oakville',
    county: 'Napa',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94567',
    city: 'Pope Valley',
    county: 'Napa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94573',
    city: 'Rutherford',
    county: 'Napa',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94574',
    city: 'Saint Helena',
    county: 'Napa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94599',
    city: 'Yountville',
    county: 'Napa',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95924',
    city: 'Cedar Ridge',
    county: 'Nevada',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95712',
    city: 'Chicago Park',
    county: 'Nevada',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96111',
    city: 'Floriston',
    county: 'Nevada',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95945',
    city: 'Grass Valley',
    county: 'Nevada',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95949',
    city: 'Grass Valley',
    county: 'Nevada',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95959',
    city: 'Nevada City',
    county: 'Nevada',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95724',
    city: 'Norden',
    county: 'Nevada',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95960',
    city: 'North San Juan',
    county: 'Nevada',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95946',
    city: 'Penn Valley',
    county: 'Nevada',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95975',
    city: 'Rough And Ready',
    county: 'Nevada',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95977',
    city: 'Smartsville',
    county: 'Nevada',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95728',
    city: 'Soda Springs',
    county: 'Nevada',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96160',
    city: 'Truckee',
    county: 'Nevada',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96161',
    city: 'Truckee',
    county: 'Nevada',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96162',
    city: 'Truckee',
    county: 'Nevada',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95986',
    city: 'Washington',
    county: 'Nevada',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92656',
    city: 'Aliso Viejo',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92698',
    city: 'Aliso Viejo',
    county: 'Orange',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92801',
    city: 'Anaheim',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92802',
    city: 'Anaheim',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92803',
    city: 'Anaheim',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92804',
    city: 'Anaheim',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92805',
    city: 'Anaheim',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92806',
    city: 'Anaheim',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92807',
    city: 'Anaheim',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92808',
    city: 'Anaheim',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92809',
    city: 'Anaheim',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92812',
    city: 'Anaheim',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92814',
    city: 'Anaheim',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92815',
    city: 'Anaheim',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92816',
    city: 'Anaheim',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92817',
    city: 'Anaheim',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92825',
    city: 'Anaheim',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92850',
    city: 'Anaheim',
    county: 'Orange',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92899',
    city: 'Anaheim',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92811',
    city: 'Atwood',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92821',
    city: 'Brea',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92822',
    city: 'Brea',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92823',
    city: 'Brea',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90620',
    city: 'Buena Park',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90621',
    city: 'Buena Park',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90622',
    city: 'Buena Park',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90624',
    city: 'Buena Park',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92624',
    city: 'Capistrano Beach',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92625',
    city: 'Corona Del Mar',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92626',
    city: 'Costa Mesa',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92627',
    city: 'Costa Mesa',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92628',
    city: 'Costa Mesa',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90630',
    city: 'Cypress',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92629',
    city: 'Dana Point',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92650',
    city: 'East Irvine',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92609',
    city: 'El Toro',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92610',
    city: 'Foothill Ranch',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92708',
    city: 'Fountain Valley',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92728',
    city: 'Fountain Valley',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92831',
    city: 'Fullerton',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92832',
    city: 'Fullerton',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92833',
    city: 'Fullerton',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92834',
    city: 'Fullerton',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92835',
    city: 'Fullerton',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92836',
    city: 'Fullerton',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92837',
    city: 'Fullerton',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92838',
    city: 'Fullerton',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92840',
    city: 'Garden Grove',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92841',
    city: 'Garden Grove',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92842',
    city: 'Garden Grove',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92843',
    city: 'Garden Grove',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92844',
    city: 'Garden Grove',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92845',
    city: 'Garden Grove',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92846',
    city: 'Garden Grove',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92605',
    city: 'Huntington Beach',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92615',
    city: 'Huntington Beach',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92646',
    city: 'Huntington Beach',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92647',
    city: 'Huntington Beach',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92648',
    city: 'Huntington Beach',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92649',
    city: 'Huntington Beach',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92602',
    city: 'Irvine',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92603',
    city: 'Irvine',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92604',
    city: 'Irvine',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92606',
    city: 'Irvine',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92612',
    city: 'Irvine',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92614',
    city: 'Irvine',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92616',
    city: 'Irvine',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92617',
    city: 'Irvine',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92618',
    city: 'Irvine',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92619',
    city: 'Irvine',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92620',
    city: 'Irvine',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92623',
    city: 'Irvine',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92697',
    city: 'Irvine',
    county: 'Orange',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90631',
    city: 'La Habra',
    county: 'Orange',
    type: 'Standard',
    region: 'Gateway Cities',
    neighbourhood: 'La Habra Heights',
  },
  {
    zipCode: '90632',
    city: 'La Habra',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90633',
    city: 'La Habra',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90623',
    city: 'La Palma',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92694',
    city: 'Ladera Ranch',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92651',
    city: 'Laguna Beach',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92652',
    city: 'Laguna Beach',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92653',
    city: 'Laguna Hills',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92654',
    city: 'Laguna Hills',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92607',
    city: 'Laguna Niguel',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92677',
    city: 'Laguna Niguel',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92637',
    city: 'Laguna Woods',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92630',
    city: 'Lake Forest',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90720',
    city: 'Los Alamitos',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90721',
    city: 'Los Alamitos',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92655',
    city: 'Midway City',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92690',
    city: 'Mission Viejo',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92691',
    city: 'Mission Viejo',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92692',
    city: 'Mission Viejo',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92658',
    city: 'Newport Beach',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92659',
    city: 'Newport Beach',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92660',
    city: 'Newport Beach',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92661',
    city: 'Newport Beach',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92662',
    city: 'Newport Beach',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92663',
    city: 'Newport Beach',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92657',
    city: 'Newport Coast',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92856',
    city: 'Orange',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92857',
    city: 'Orange',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92859',
    city: 'Orange',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92862',
    city: 'Orange',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92863',
    city: 'Orange',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92864',
    city: 'Orange',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92865',
    city: 'Orange',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92866',
    city: 'Orange',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92867',
    city: 'Orange',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92868',
    city: 'Orange',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92869',
    city: 'Orange',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92870',
    city: 'Placentia',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92871',
    city: 'Placentia',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92688',
    city: 'Rancho Santa Margarita',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92672',
    city: 'San Clemente',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92673',
    city: 'San Clemente',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92674',
    city: 'San Clemente',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92675',
    city: 'San Juan Capistrano',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92693',
    city: 'San Juan Capistrano',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92701',
    city: 'Santa Ana',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92702',
    city: 'Santa Ana',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92703',
    city: 'Santa Ana',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92704',
    city: 'Santa Ana',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92705',
    city: 'Santa Ana',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92706',
    city: 'Santa Ana',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92707',
    city: 'Santa Ana',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92711',
    city: 'Santa Ana',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92712',
    city: 'Santa Ana',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92735',
    city: 'Santa Ana',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92799',
    city: 'Santa Ana',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90740',
    city: 'Seal Beach',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92676',
    city: 'Silverado',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90680',
    city: 'Stanton',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90742',
    city: 'Sunset Beach',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '90743',
    city: 'Surfside',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92678',
    city: 'Trabuco Canyon',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92679',
    city: 'Trabuco Canyon',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92780',
    city: 'Tustin',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92781',
    city: 'Tustin',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92782',
    city: 'Tustin',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92861',
    city: 'Villa Park',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92683',
    city: 'Westminster',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92684',
    city: 'Westminster',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92685',
    city: 'Westminster',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92885',
    city: 'Yorba Linda',
    county: 'Orange',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92886',
    city: 'Yorba Linda',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92887',
    city: 'Yorba Linda',
    county: 'Orange',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95701',
    city: 'Alta',
    county: 'Placer',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95703',
    city: 'Applegate',
    county: 'Placer',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95602',
    city: 'Auburn',
    county: 'Placer',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95603',
    city: 'Auburn',
    county: 'Placer',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95604',
    city: 'Auburn',
    county: 'Placer',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96140',
    city: 'Carnelian Bay',
    county: 'Placer',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95713',
    city: 'Colfax',
    county: 'Placer',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95714',
    city: 'Dutch Flat',
    county: 'Placer',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95715',
    city: 'Emigrant Gap',
    county: 'Placer',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95631',
    city: 'Foresthill',
    county: 'Placer',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95717',
    city: 'Gold Run',
    county: 'Placer',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95746',
    city: 'Granite Bay',
    county: 'Placer',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96141',
    city: 'Homewood',
    county: 'Placer',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96143',
    city: 'Kings Beach',
    county: 'Placer',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95648',
    city: 'Lincoln',
    county: 'Placer',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95650',
    city: 'Loomis',
    county: 'Placer',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95722',
    city: 'Meadow Vista',
    county: 'Placer',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95658',
    city: 'Newcastle',
    county: 'Placer',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96146',
    city: 'Olympic Valley',
    county: 'Placer',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95663',
    city: 'Penryn',
    county: 'Placer',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95677',
    city: 'Rocklin',
    county: 'Placer',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95765',
    city: 'Rocklin',
    county: 'Placer',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95661',
    city: 'Roseville',
    county: 'Placer',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95678',
    city: 'Roseville',
    county: 'Placer',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95747',
    city: 'Roseville',
    county: 'Placer',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95681',
    city: 'Sheridan',
    county: 'Placer',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96145',
    city: 'Tahoe City',
    county: 'Placer',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96148',
    city: 'Tahoe Vista',
    county: 'Placer',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95736',
    city: 'Weimar',
    county: 'Placer',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96129',
    city: 'Beckwourth',
    county: 'Plumas',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95915',
    city: 'Belden',
    county: 'Plumas',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96103',
    city: 'Blairsden Graeagle',
    county: 'Plumas',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95923',
    city: 'Canyon Dam',
    county: 'Plumas',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96020',
    city: 'Chester',
    county: 'Plumas',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96105',
    city: 'Chilcoot',
    county: 'Plumas',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96106',
    city: 'Clio',
    county: 'Plumas',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95934',
    city: 'Crescent Mills',
    county: 'Plumas',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95947',
    city: 'Greenville',
    county: 'Plumas',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95956',
    city: 'Meadow Valley',
    county: 'Plumas',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96122',
    city: 'Portola',
    county: 'Plumas',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95971',
    city: 'Quincy',
    county: 'Plumas',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95980',
    city: 'Storrie',
    county: 'Plumas',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95983',
    city: 'Taylorsville',
    county: 'Plumas',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95984',
    city: 'Twain',
    county: 'Plumas',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96135',
    city: 'Vinton',
    county: 'Plumas',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92536',
    city: 'Aguanga',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92539',
    city: 'Anza',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92220',
    city: 'Banning',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92223',
    city: 'Beaumont',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92225',
    city: 'Blythe',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92226',
    city: 'Blythe',
    county: 'Riverside',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92230',
    city: 'Cabazon',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92320',
    city: 'Calimesa',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92234',
    city: 'Cathedral City',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92235',
    city: 'Cathedral City',
    county: 'Riverside',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92236',
    city: 'Coachella',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92877',
    city: 'Corona',
    county: 'Riverside',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92878',
    city: 'Corona',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92879',
    city: 'Corona',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92880',
    city: 'Corona',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92881',
    city: 'Corona',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92882',
    city: 'Corona',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92883',
    city: 'Corona',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92239',
    city: 'Desert Center',
    county: 'Riverside',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92240',
    city: 'Desert Hot Springs',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92241',
    city: 'Desert Hot Springs',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92543',
    city: 'Hemet',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92544',
    city: 'Hemet',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92545',
    city: 'Hemet',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92546',
    city: 'Hemet',
    county: 'Riverside',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92548',
    city: 'Homeland',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92549',
    city: 'Idyllwild',
    county: 'Riverside',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92210',
    city: 'Indian Wells',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92201',
    city: 'Indio',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92202',
    city: 'Indio',
    county: 'Riverside',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92203',
    city: 'Indio',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92509',
    city: 'Jurupa Valley',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92247',
    city: 'La Quinta',
    county: 'Riverside',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92248',
    city: 'La Quinta',
    county: 'Riverside',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92253',
    city: 'La Quinta',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92530',
    city: 'Lake Elsinore',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92531',
    city: 'Lake Elsinore',
    county: 'Riverside',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92532',
    city: 'Lake Elsinore',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92518',
    city: 'March Air Reserve Base',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92254',
    city: 'Mecca',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92584',
    city: 'Menifee',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92585',
    city: 'Menifee',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92586',
    city: 'Menifee',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92587',
    city: 'Menifee',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91752',
    city: 'Mira Loma',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92551',
    city: 'Moreno Valley',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92552',
    city: 'Moreno Valley',
    county: 'Riverside',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92553',
    city: 'Moreno Valley',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92554',
    city: 'Moreno Valley',
    county: 'Riverside',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92555',
    city: 'Moreno Valley',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92556',
    city: 'Moreno Valley',
    county: 'Riverside',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92557',
    city: 'Moreno Valley',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92561',
    city: 'Mountain Center',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92562',
    city: 'Murrieta',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92563',
    city: 'Murrieta',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92564',
    city: 'Murrieta',
    county: 'Riverside',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92860',
    city: 'Norco',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92258',
    city: 'North Palm Springs',
    county: 'Riverside',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92567',
    city: 'Nuevo',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92211',
    city: 'Palm Desert',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92255',
    city: 'Palm Desert',
    county: 'Riverside',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92260',
    city: 'Palm Desert',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92261',
    city: 'Palm Desert',
    county: 'Riverside',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92262',
    city: 'Palm Springs',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92263',
    city: 'Palm Springs',
    county: 'Riverside',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92264',
    city: 'Palm Springs',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92570',
    city: 'Perris',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92571',
    city: 'Perris',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92572',
    city: 'Perris',
    county: 'Riverside',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92599',
    city: 'Perris',
    county: 'Riverside',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92270',
    city: 'Rancho Mirage',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92501',
    city: 'Riverside',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92502',
    city: 'Riverside',
    county: 'Riverside',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92503',
    city: 'Riverside',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92504',
    city: 'Riverside',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92505',
    city: 'Riverside',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92506',
    city: 'Riverside',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92507',
    city: 'Riverside',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92508',
    city: 'Riverside',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92513',
    city: 'Riverside',
    county: 'Riverside',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92514',
    city: 'Riverside',
    county: 'Riverside',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92516',
    city: 'Riverside',
    county: 'Riverside',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92517',
    city: 'Riverside',
    county: 'Riverside',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92519',
    city: 'Riverside',
    county: 'Riverside',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92521',
    city: 'Riverside',
    county: 'Riverside',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92522',
    city: 'Riverside',
    county: 'Riverside',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92581',
    city: 'San Jacinto',
    county: 'Riverside',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92582',
    city: 'San Jacinto',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92583',
    city: 'San Jacinto',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92589',
    city: 'Temecula',
    county: 'Riverside',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92590',
    city: 'Temecula',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92591',
    city: 'Temecula',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92592',
    city: 'Temecula',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92593',
    city: 'Temecula',
    county: 'Riverside',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92274',
    city: 'Thermal',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92276',
    city: 'Thousand Palms',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92282',
    city: 'Whitewater',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92595',
    city: 'Wildomar',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92596',
    city: 'Winchester',
    county: 'Riverside',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95843',
    city: 'Antelope',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95608',
    city: 'Carmichael',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95609',
    city: 'Carmichael',
    county: 'Sacramento',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95610',
    city: 'Citrus Heights',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95611',
    city: 'Citrus Heights',
    county: 'Sacramento',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95621',
    city: 'Citrus Heights',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95615',
    city: 'Courtland',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95624',
    city: 'Elk Grove',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95757',
    city: 'Elk Grove',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95758',
    city: 'Elk Grove',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95759',
    city: 'Elk Grove',
    county: 'Sacramento',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95626',
    city: 'Elverta',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95628',
    city: 'Fair Oaks',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95630',
    city: 'Folsom',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95763',
    city: 'Folsom',
    county: 'Sacramento',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95632',
    city: 'Galt',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95638',
    city: 'Herald',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95639',
    city: 'Hood',
    county: 'Sacramento',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95641',
    city: 'Isleton',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95655',
    city: 'Mather',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95652',
    city: 'Mcclellan',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95660',
    city: 'North Highlands',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95662',
    city: 'Orangevale',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95670',
    city: 'Rancho Cordova',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95741',
    city: 'Rancho Cordova',
    county: 'Sacramento',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95742',
    city: 'Rancho Cordova',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95671',
    city: 'Represa',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95673',
    city: 'Rio Linda',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95680',
    city: 'Ryde',
    county: 'Sacramento',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94203',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94204',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94205',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94206',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94207',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94208',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94209',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94211',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94229',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94230',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94232',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94234',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94235',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94236',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94237',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94239',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94240',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94244',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94245',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94247',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94248',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94249',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94250',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94252',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94254',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94256',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94257',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94258',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94259',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94261',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94262',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94263',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94267',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94268',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94269',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94271',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94273',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94274',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94277',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94278',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94279',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94280',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94282',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94283',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94284',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94285',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94287',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94288',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94289',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94290',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94291',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94293',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94294',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94295',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94296',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94297',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94298',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94299',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95811',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95812',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95813',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95814',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95815',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95816',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95817',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95818',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95819',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95820',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95821',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95822',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95823',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95824',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95825',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95826',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95827',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95828',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95829',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95830',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95831',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95832',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95833',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95834',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95835',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95836',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95837',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95838',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95840',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95841',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95842',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95851',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95852',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95853',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95860',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95864',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95865',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95866',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95867',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95894',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95899',
    city: 'Sacramento',
    county: 'Sacramento',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95683',
    city: 'Sloughhouse',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95690',
    city: 'Walnut Grove',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95693',
    city: 'Wilton',
    county: 'Sacramento',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95023',
    city: 'Hollister',
    county: 'San Benito',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95024',
    city: 'Hollister',
    county: 'San Benito',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95043',
    city: 'Paicines',
    county: 'San Benito',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95045',
    city: 'San Juan Bautista',
    county: 'San Benito',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95075',
    city: 'Tres Pinos',
    county: 'San Benito',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92301',
    city: 'Adelanto',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92304',
    city: 'Amboy',
    county: 'San Bernardino',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92305',
    city: 'Angelus Oaks',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92307',
    city: 'Apple Valley',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92308',
    city: 'Apple Valley',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92309',
    city: 'Baker',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92311',
    city: 'Barstow',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92312',
    city: 'Barstow',
    county: 'San Bernardino',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92314',
    city: 'Big Bear City',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92315',
    city: 'Big Bear Lake',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92316',
    city: 'Bloomington',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92317',
    city: 'Blue Jay',
    county: 'San Bernardino',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92318',
    city: 'Bryn Mawr',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92321',
    city: 'Cedar Glen',
    county: 'San Bernardino',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92322',
    city: 'Cedarpines Park',
    county: 'San Bernardino',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91708',
    city: 'Chino',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91710',
    city: 'Chino',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91709',
    city: 'Chino Hills',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92323',
    city: 'Cima',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92324',
    city: 'Colton',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92325',
    city: 'Crestline',
    county: 'San Bernardino',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92327',
    city: 'Daggett',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92242',
    city: 'Earp',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92332',
    city: 'Essex',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92333',
    city: 'Fawnskin',
    county: 'San Bernardino',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92331',
    city: 'Fontana',
    county: 'San Bernardino',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92334',
    city: 'Fontana',
    county: 'San Bernardino',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92335',
    city: 'Fontana',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92336',
    city: 'Fontana',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92337',
    city: 'Fontana',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92339',
    city: 'Forest Falls',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92310',
    city: 'Fort Irwin',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92313',
    city: 'Grand Terrace',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92341',
    city: 'Green Valley Lake',
    county: 'San Bernardino',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91743',
    city: 'Guasti',
    county: 'San Bernardino',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92342',
    city: 'Helendale',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92340',
    city: 'Hesperia',
    county: 'San Bernardino',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92344',
    city: 'Hesperia',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92345',
    city: 'Hesperia',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92346',
    city: 'Highland',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92347',
    city: 'Hinkley',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92252',
    city: 'Joshua Tree',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92352',
    city: 'Lake Arrowhead',
    county: 'San Bernardino',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92285',
    city: 'Landers',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92350',
    city: 'Loma Linda',
    county: 'San Bernardino',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92354',
    city: 'Loma Linda',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92357',
    city: 'Loma Linda',
    county: 'San Bernardino',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92356',
    city: 'Lucerne Valley',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92338',
    city: 'Ludlow',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92358',
    city: 'Lytle Creek',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92359',
    city: 'Mentone',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91763',
    city: 'Montclair',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92256',
    city: 'Morongo Valley',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92366',
    city: 'Mountain Pass',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91759',
    city: 'Mt Baldy',
    county: 'San Bernardino',
    type: 'P.O.Box',
    region: 'San Gabriel Valley',
    neighbourhood: 'Mt. Baldy',
  },
  {
    zipCode: '92363',
    city: 'Needles',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92365',
    city: 'Newberry Springs',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92364',
    city: 'Nipton',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91758',
    city: 'Ontario',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91761',
    city: 'Ontario',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91762',
    city: 'Ontario',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91764',
    city: 'Ontario',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92368',
    city: 'Oro Grande',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92267',
    city: 'Parker Dam',
    county: 'San Bernardino',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92369',
    city: 'Patton',
    county: 'San Bernardino',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92329',
    city: 'Phelan',
    county: 'San Bernardino',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92371',
    city: 'Phelan',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92372',
    city: 'Pinon Hills',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92268',
    city: 'Pioneertown',
    county: 'San Bernardino',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91701',
    city: 'Rancho Cucamonga',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91729',
    city: 'Rancho Cucamonga',
    county: 'San Bernardino',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91730',
    city: 'Rancho Cucamonga',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91737',
    city: 'Rancho Cucamonga',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91739',
    city: 'Rancho Cucamonga',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92373',
    city: 'Redlands',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92374',
    city: 'Redlands',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92375',
    city: 'Redlands',
    county: 'San Bernardino',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92376',
    city: 'Rialto',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92377',
    city: 'Rialto',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92378',
    city: 'Rimforest',
    county: 'San Bernardino',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92382',
    city: 'Running Springs',
    county: 'San Bernardino',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92401',
    city: 'San Bernardino',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92402',
    city: 'San Bernardino',
    county: 'San Bernardino',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92403',
    city: 'San Bernardino',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92404',
    city: 'San Bernardino',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92405',
    city: 'San Bernardino',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92406',
    city: 'San Bernardino',
    county: 'San Bernardino',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92407',
    city: 'San Bernardino',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92408',
    city: 'San Bernardino',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92410',
    city: 'San Bernardino',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92411',
    city: 'San Bernardino',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92413',
    city: 'San Bernardino',
    county: 'San Bernardino',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92415',
    city: 'San Bernardino',
    county: 'San Bernardino',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92418',
    city: 'San Bernardino',
    county: 'San Bernardino',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92423',
    city: 'San Bernardino',
    county: 'San Bernardino',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92427',
    city: 'San Bernardino',
    county: 'San Bernardino',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92385',
    city: 'Skyforest',
    county: 'San Bernardino',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92386',
    city: 'Sugarloaf',
    county: 'San Bernardino',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93562',
    city: 'Trona',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93592',
    city: 'Trona',
    county: 'San Bernardino',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92277',
    city: 'Twentynine Palms',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92278',
    city: 'Twentynine Palms',
    county: 'San Bernardino',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92391',
    city: 'Twin Peaks',
    county: 'San Bernardino',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91784',
    city: 'Upland',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91785',
    city: 'Upland',
    county: 'San Bernardino',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91786',
    city: 'Upland',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92392',
    city: 'Victorville',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92393',
    city: 'Victorville',
    county: 'San Bernardino',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92394',
    city: 'Victorville',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92395',
    city: 'Victorville',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92280',
    city: 'Vidal',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92397',
    city: 'Wrightwood',
    county: 'San Bernardino',
    type: 'Standard',
    region: 'San Gabriel Valley',
    neighbourhood: 'Big Pines',
  },
  {
    zipCode: '92398',
    city: 'Yermo',
    county: 'San Bernardino',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92399',
    city: 'Yucaipa',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92284',
    city: 'Yucca Valley',
    county: 'San Bernardino',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92286',
    city: 'Yucca Valley',
    county: 'San Bernardino',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91901',
    city: 'Alpine',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91903',
    city: 'Alpine',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91902',
    city: 'Bonita',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91908',
    city: 'Bonita',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92003',
    city: 'Bonsall',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92004',
    city: 'Borrego Springs',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91905',
    city: 'Boulevard',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92055',
    city: 'Camp Pendleton',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91906',
    city: 'Campo',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92007',
    city: 'Cardiff By The Sea',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92008',
    city: 'Carlsbad',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92009',
    city: 'Carlsbad',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92010',
    city: 'Carlsbad',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92011',
    city: 'Carlsbad',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92013',
    city: 'Carlsbad',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92018',
    city: 'Carlsbad',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91909',
    city: 'Chula Vista',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91910',
    city: 'Chula Vista',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91911',
    city: 'Chula Vista',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91912',
    city: 'Chula Vista',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91913',
    city: 'Chula Vista',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91914',
    city: 'Chula Vista',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91915',
    city: 'Chula Vista',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91921',
    city: 'Chula Vista',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92118',
    city: 'Coronado',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92178',
    city: 'Coronado',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92014',
    city: 'Del Mar',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91916',
    city: 'Descanso',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91917',
    city: 'Dulzura',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92019',
    city: 'El Cajon',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92020',
    city: 'El Cajon',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92021',
    city: 'El Cajon',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92022',
    city: 'El Cajon',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92023',
    city: 'Encinitas',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92024',
    city: 'Encinitas',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92025',
    city: 'Escondido',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92026',
    city: 'Escondido',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92027',
    city: 'Escondido',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92029',
    city: 'Escondido',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92030',
    city: 'Escondido',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92033',
    city: 'Escondido',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92046',
    city: 'Escondido',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92028',
    city: 'Fallbrook',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92088',
    city: 'Fallbrook',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91931',
    city: 'Guatay',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91932',
    city: 'Imperial Beach',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91933',
    city: 'Imperial Beach',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91934',
    city: 'Jacumba',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91935',
    city: 'Jamul',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92036',
    city: 'Julian',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92037',
    city: 'La Jolla',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92038',
    city: 'La Jolla',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92039',
    city: 'La Jolla',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92092',
    city: 'La Jolla',
    county: 'San Diego',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92093',
    city: 'La Jolla',
    county: 'San Diego',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91941',
    city: 'La Mesa',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91942',
    city: 'La Mesa',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91943',
    city: 'La Mesa',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91944',
    city: 'La Mesa',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92040',
    city: 'Lakeside',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91945',
    city: 'Lemon Grove',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91946',
    city: 'Lemon Grove',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91948',
    city: 'Mount Laguna',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91950',
    city: 'National City',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91951',
    city: 'National City',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92049',
    city: 'Oceanside',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92051',
    city: 'Oceanside',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92052',
    city: 'Oceanside',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92054',
    city: 'Oceanside',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92056',
    city: 'Oceanside',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92057',
    city: 'Oceanside',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92058',
    city: 'Oceanside',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92059',
    city: 'Pala',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92060',
    city: 'Palomar Mountain',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92061',
    city: 'Pauma Valley',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91962',
    city: 'Pine Valley',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91963',
    city: 'Potrero',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92064',
    city: 'Poway',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92074',
    city: 'Poway',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92065',
    city: 'Ramona',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92066',
    city: 'Ranchita',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92067',
    city: 'Rancho Santa Fe',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92091',
    city: 'Rancho Santa Fe',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92101',
    city: 'San Diego',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92102',
    city: 'San Diego',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92103',
    city: 'San Diego',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92104',
    city: 'San Diego',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92105',
    city: 'San Diego',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92106',
    city: 'San Diego',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92107',
    city: 'San Diego',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92108',
    city: 'San Diego',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92109',
    city: 'San Diego',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92110',
    city: 'San Diego',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92111',
    city: 'San Diego',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92112',
    city: 'San Diego',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92113',
    city: 'San Diego',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92114',
    city: 'San Diego',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92115',
    city: 'San Diego',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92116',
    city: 'San Diego',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92117',
    city: 'San Diego',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92119',
    city: 'San Diego',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92120',
    city: 'San Diego',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92121',
    city: 'San Diego',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92122',
    city: 'San Diego',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92123',
    city: 'San Diego',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92124',
    city: 'San Diego',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92126',
    city: 'San Diego',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92127',
    city: 'San Diego',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92128',
    city: 'San Diego',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92129',
    city: 'San Diego',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92130',
    city: 'San Diego',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92131',
    city: 'San Diego',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92132',
    city: 'San Diego',
    county: 'San Diego',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92134',
    city: 'San Diego',
    county: 'San Diego',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92135',
    city: 'San Diego',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92136',
    city: 'San Diego',
    county: 'San Diego',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92137',
    city: 'San Diego',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92138',
    city: 'San Diego',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92139',
    city: 'San Diego',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92140',
    city: 'San Diego',
    county: 'San Diego',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92142',
    city: 'San Diego',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92145',
    city: 'San Diego',
    county: 'San Diego',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92147',
    city: 'San Diego',
    county: 'San Diego',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92149',
    city: 'San Diego',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92150',
    city: 'San Diego',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92152',
    city: 'San Diego',
    county: 'San Diego',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92153',
    city: 'San Diego',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92154',
    city: 'San Diego',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92155',
    city: 'San Diego',
    county: 'San Diego',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92158',
    city: 'San Diego',
    county: 'San Diego',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92159',
    city: 'San Diego',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92160',
    city: 'San Diego',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92161',
    city: 'San Diego',
    county: 'San Diego',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92163',
    city: 'San Diego',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92165',
    city: 'San Diego',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92166',
    city: 'San Diego',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92167',
    city: 'San Diego',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92168',
    city: 'San Diego',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92169',
    city: 'San Diego',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92170',
    city: 'San Diego',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92171',
    city: 'San Diego',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92172',
    city: 'San Diego',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92174',
    city: 'San Diego',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92175',
    city: 'San Diego',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92176',
    city: 'San Diego',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92177',
    city: 'San Diego',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92179',
    city: 'San Diego',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92182',
    city: 'San Diego',
    county: 'San Diego',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92186',
    city: 'San Diego',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92187',
    city: 'San Diego',
    county: 'San Diego',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92191',
    city: 'San Diego',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92192',
    city: 'San Diego',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92193',
    city: 'San Diego',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92195',
    city: 'San Diego',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92196',
    city: 'San Diego',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92197',
    city: 'San Diego',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92198',
    city: 'San Diego',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92199',
    city: 'San Diego',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92068',
    city: 'San Luis Rey',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92069',
    city: 'San Marcos',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92078',
    city: 'San Marcos',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92079',
    city: 'San Marcos',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92096',
    city: 'San Marcos',
    county: 'San Diego',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92143',
    city: 'San Ysidro',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92173',
    city: 'San Ysidro',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92070',
    city: 'Santa Ysabel',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92071',
    city: 'Santee',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92072',
    city: 'Santee',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92075',
    city: 'Solana Beach',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91976',
    city: 'Spring Valley',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91977',
    city: 'Spring Valley',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91978',
    city: 'Spring Valley',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91979',
    city: 'Spring Valley',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91980',
    city: 'Tecate',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91987',
    city: 'Tecate',
    county: 'San Diego',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92082',
    city: 'Valley Center',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92081',
    city: 'Vista',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92083',
    city: 'Vista',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92084',
    city: 'Vista',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92085',
    city: 'Vista',
    county: 'San Diego',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '92086',
    city: 'Warner Springs',
    county: 'San Diego',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94102',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94103',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94104',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94105',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94107',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94108',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94109',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94110',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94111',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94112',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94114',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94115',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94116',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94117',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94118',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94119',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94120',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94121',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94122',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94123',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94124',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94125',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94126',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94127',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94129',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94130',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94131',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94132',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94133',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94134',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94137',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94139',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94140',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94141',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94142',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94143',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94144',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94145',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94146',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94147',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94151',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94158',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94159',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94160',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94161',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94163',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94164',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94172',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94177',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94188',
    city: 'San Francisco',
    county: 'San Francisco',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95220',
    city: 'Acampo',
    county: 'San Joaquin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95227',
    city: 'Clements',
    county: 'San Joaquin',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95320',
    city: 'Escalon',
    county: 'San Joaquin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95230',
    city: 'Farmington',
    county: 'San Joaquin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95231',
    city: 'French Camp',
    county: 'San Joaquin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95234',
    city: 'Holt',
    county: 'San Joaquin',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95330',
    city: 'Lathrop',
    county: 'San Joaquin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95236',
    city: 'Linden',
    county: 'San Joaquin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95237',
    city: 'Lockeford',
    county: 'San Joaquin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95240',
    city: 'Lodi',
    county: 'San Joaquin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95241',
    city: 'Lodi',
    county: 'San Joaquin',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95242',
    city: 'Lodi',
    county: 'San Joaquin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95336',
    city: 'Manteca',
    county: 'San Joaquin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95337',
    city: 'Manteca',
    county: 'San Joaquin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95366',
    city: 'Ripon',
    county: 'San Joaquin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95201',
    city: 'Stockton',
    county: 'San Joaquin',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95202',
    city: 'Stockton',
    county: 'San Joaquin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95203',
    city: 'Stockton',
    county: 'San Joaquin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95204',
    city: 'Stockton',
    county: 'San Joaquin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95205',
    city: 'Stockton',
    county: 'San Joaquin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95206',
    city: 'Stockton',
    county: 'San Joaquin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95207',
    city: 'Stockton',
    county: 'San Joaquin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95208',
    city: 'Stockton',
    county: 'San Joaquin',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95209',
    city: 'Stockton',
    county: 'San Joaquin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95210',
    city: 'Stockton',
    county: 'San Joaquin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95211',
    city: 'Stockton',
    county: 'San Joaquin',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95212',
    city: 'Stockton',
    county: 'San Joaquin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95213',
    city: 'Stockton',
    county: 'San Joaquin',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95214',
    city: 'Stockton',
    county: 'San Joaquin',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95215',
    city: 'Stockton',
    county: 'San Joaquin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95219',
    city: 'Stockton',
    county: 'San Joaquin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95267',
    city: 'Stockton',
    county: 'San Joaquin',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95269',
    city: 'Stockton',
    county: 'San Joaquin',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95296',
    city: 'Stockton',
    county: 'San Joaquin',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95297',
    city: 'Stockton',
    county: 'San Joaquin',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95686',
    city: 'Thornton',
    county: 'San Joaquin',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95304',
    city: 'Tracy',
    county: 'San Joaquin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95376',
    city: 'Tracy',
    county: 'San Joaquin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95377',
    city: 'Tracy',
    county: 'San Joaquin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95378',
    city: 'Tracy',
    county: 'San Joaquin',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95391',
    city: 'Tracy',
    county: 'San Joaquin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95385',
    city: 'Vernalis',
    county: 'San Joaquin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95253',
    city: 'Victor',
    county: 'San Joaquin',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95258',
    city: 'Woodbridge',
    county: 'San Joaquin',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93420',
    city: 'Arroyo Grande',
    county: 'San Luis Obispo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93421',
    city: 'Arroyo Grande',
    county: 'San Luis Obispo',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93422',
    city: 'Atascadero',
    county: 'San Luis Obispo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93423',
    city: 'Atascadero',
    county: 'San Luis Obispo',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93424',
    city: 'Avila Beach',
    county: 'San Luis Obispo',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93428',
    city: 'Cambria',
    county: 'San Luis Obispo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93430',
    city: 'Cayucos',
    county: 'San Luis Obispo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93432',
    city: 'Creston',
    county: 'San Luis Obispo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93433',
    city: 'Grover Beach',
    county: 'San Luis Obispo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93483',
    city: 'Grover Beach',
    county: 'San Luis Obispo',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93435',
    city: 'Harmony',
    county: 'San Luis Obispo',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93402',
    city: 'Los Osos',
    county: 'San Luis Obispo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93412',
    city: 'Los Osos',
    county: 'San Luis Obispo',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93442',
    city: 'Morro Bay',
    county: 'San Luis Obispo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93443',
    city: 'Morro Bay',
    county: 'San Luis Obispo',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93444',
    city: 'Nipomo',
    county: 'San Luis Obispo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93445',
    city: 'Oceano',
    county: 'San Luis Obispo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93475',
    city: 'Oceano',
    county: 'San Luis Obispo',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93446',
    city: 'Paso Robles',
    county: 'San Luis Obispo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93447',
    city: 'Paso Robles',
    county: 'San Luis Obispo',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93448',
    city: 'Pismo Beach',
    county: 'San Luis Obispo',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93449',
    city: 'Pismo Beach',
    county: 'San Luis Obispo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93401',
    city: 'San Luis Obispo',
    county: 'San Luis Obispo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93403',
    city: 'San Luis Obispo',
    county: 'San Luis Obispo',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93405',
    city: 'San Luis Obispo',
    county: 'San Luis Obispo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93406',
    city: 'San Luis Obispo',
    county: 'San Luis Obispo',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93407',
    city: 'San Luis Obispo',
    county: 'San Luis Obispo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93408',
    city: 'San Luis Obispo',
    county: 'San Luis Obispo',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93409',
    city: 'San Luis Obispo',
    county: 'San Luis Obispo',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93410',
    city: 'San Luis Obispo',
    county: 'San Luis Obispo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93451',
    city: 'San Miguel',
    county: 'San Luis Obispo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93452',
    city: 'San Simeon',
    county: 'San Luis Obispo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93453',
    city: 'Santa Margarita',
    county: 'San Luis Obispo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93461',
    city: 'Shandon',
    county: 'San Luis Obispo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93465',
    city: 'Templeton',
    county: 'San Luis Obispo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94027',
    city: 'Atherton',
    county: 'San Mateo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94002',
    city: 'Belmont',
    county: 'San Mateo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94005',
    city: 'Brisbane',
    county: 'San Mateo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94010',
    city: 'Burlingame',
    county: 'San Mateo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94011',
    city: 'Burlingame',
    county: 'San Mateo',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94014',
    city: 'Daly City',
    county: 'San Mateo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94015',
    city: 'Daly City',
    county: 'San Mateo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94016',
    city: 'Daly City',
    county: 'San Mateo',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94017',
    city: 'Daly City',
    county: 'San Mateo',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94018',
    city: 'El Granada',
    county: 'San Mateo',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94019',
    city: 'Half Moon Bay',
    county: 'San Mateo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94020',
    city: 'La Honda',
    county: 'San Mateo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94021',
    city: 'Loma Mar',
    county: 'San Mateo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94025',
    city: 'Menlo Park',
    county: 'San Mateo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94026',
    city: 'Menlo Park',
    county: 'San Mateo',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94030',
    city: 'Millbrae',
    county: 'San Mateo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94037',
    city: 'Montara',
    county: 'San Mateo',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94038',
    city: 'Moss Beach',
    county: 'San Mateo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94044',
    city: 'Pacifica',
    county: 'San Mateo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94060',
    city: 'Pescadero',
    county: 'San Mateo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94028',
    city: 'Portola Valley',
    county: 'San Mateo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94061',
    city: 'Redwood City',
    county: 'San Mateo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94062',
    city: 'Redwood City',
    county: 'San Mateo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94063',
    city: 'Redwood City',
    county: 'San Mateo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94064',
    city: 'Redwood City',
    county: 'San Mateo',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94065',
    city: 'Redwood City',
    county: 'San Mateo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94066',
    city: 'San Bruno',
    county: 'San Mateo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94070',
    city: 'San Carlos',
    county: 'San Mateo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94128',
    city: 'San Francisco',
    county: 'San Mateo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94074',
    city: 'San Gregorio',
    county: 'San Mateo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94401',
    city: 'San Mateo',
    county: 'San Mateo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94402',
    city: 'San Mateo',
    county: 'San Mateo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94403',
    city: 'San Mateo',
    county: 'San Mateo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94404',
    city: 'San Mateo',
    county: 'San Mateo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94497',
    city: 'San Mateo',
    county: 'San Mateo',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94080',
    city: 'South San Francisco',
    county: 'San Mateo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94083',
    city: 'South San Francisco',
    county: 'San Mateo',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93427',
    city: 'Buellton',
    county: 'Santa Barbara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93013',
    city: 'Carpinteria',
    county: 'Santa Barbara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93014',
    city: 'Carpinteria',
    county: 'Santa Barbara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93429',
    city: 'Casmalia',
    county: 'Santa Barbara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93116',
    city: 'Goleta',
    county: 'Santa Barbara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93117',
    city: 'Goleta',
    county: 'Santa Barbara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93118',
    city: 'Goleta',
    county: 'Santa Barbara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93199',
    city: 'Goleta',
    county: 'Santa Barbara',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93434',
    city: 'Guadalupe',
    county: 'Santa Barbara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93436',
    city: 'Lompoc',
    county: 'Santa Barbara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93437',
    city: 'Lompoc',
    county: 'Santa Barbara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93438',
    city: 'Lompoc',
    county: 'Santa Barbara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93440',
    city: 'Los Alamos',
    county: 'Santa Barbara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93441',
    city: 'Los Olivos',
    county: 'Santa Barbara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93254',
    city: 'New Cuyama',
    county: 'Santa Barbara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93101',
    city: 'Santa Barbara',
    county: 'Santa Barbara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93102',
    city: 'Santa Barbara',
    county: 'Santa Barbara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93103',
    city: 'Santa Barbara',
    county: 'Santa Barbara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93105',
    city: 'Santa Barbara',
    county: 'Santa Barbara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93106',
    city: 'Santa Barbara',
    county: 'Santa Barbara',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93107',
    city: 'Santa Barbara',
    county: 'Santa Barbara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93108',
    city: 'Santa Barbara',
    county: 'Santa Barbara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93109',
    city: 'Santa Barbara',
    county: 'Santa Barbara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93110',
    city: 'Santa Barbara',
    county: 'Santa Barbara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93111',
    city: 'Santa Barbara',
    county: 'Santa Barbara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93120',
    city: 'Santa Barbara',
    county: 'Santa Barbara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93121',
    city: 'Santa Barbara',
    county: 'Santa Barbara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93130',
    city: 'Santa Barbara',
    county: 'Santa Barbara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93140',
    city: 'Santa Barbara',
    county: 'Santa Barbara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93150',
    city: 'Santa Barbara',
    county: 'Santa Barbara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93160',
    city: 'Santa Barbara',
    county: 'Santa Barbara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93190',
    city: 'Santa Barbara',
    county: 'Santa Barbara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93454',
    city: 'Santa Maria',
    county: 'Santa Barbara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93455',
    city: 'Santa Maria',
    county: 'Santa Barbara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93456',
    city: 'Santa Maria',
    county: 'Santa Barbara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93457',
    city: 'Santa Maria',
    county: 'Santa Barbara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93458',
    city: 'Santa Maria',
    county: 'Santa Barbara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93460',
    city: 'Santa Ynez',
    county: 'Santa Barbara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93463',
    city: 'Solvang',
    county: 'Santa Barbara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93464',
    city: 'Solvang',
    county: 'Santa Barbara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93067',
    city: 'Summerland',
    county: 'Santa Barbara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95002',
    city: 'Alviso',
    county: 'Santa Clara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95008',
    city: 'Campbell',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95009',
    city: 'Campbell',
    county: 'Santa Clara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95011',
    city: 'Campbell',
    county: 'Santa Clara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95013',
    city: 'Coyote',
    county: 'Santa Clara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95014',
    city: 'Cupertino',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95015',
    city: 'Cupertino',
    county: 'Santa Clara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95020',
    city: 'Gilroy',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95021',
    city: 'Gilroy',
    county: 'Santa Clara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95026',
    city: 'Holy City',
    county: 'Santa Clara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94022',
    city: 'Los Altos',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94023',
    city: 'Los Altos',
    county: 'Santa Clara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94024',
    city: 'Los Altos',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95030',
    city: 'Los Gatos',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95031',
    city: 'Los Gatos',
    county: 'Santa Clara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95032',
    city: 'Los Gatos',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95033',
    city: 'Los Gatos',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95035',
    city: 'Milpitas',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95036',
    city: 'Milpitas',
    county: 'Santa Clara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95037',
    city: 'Morgan Hill',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95038',
    city: 'Morgan Hill',
    county: 'Santa Clara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95140',
    city: 'Mount Hamilton',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94035',
    city: 'Mountain View',
    county: 'Santa Clara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94039',
    city: 'Mountain View',
    county: 'Santa Clara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94040',
    city: 'Mountain View',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94041',
    city: 'Mountain View',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94042',
    city: 'Mountain View',
    county: 'Santa Clara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94043',
    city: 'Mountain View',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95042',
    city: 'New Almaden',
    county: 'Santa Clara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94301',
    city: 'Palo Alto',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94302',
    city: 'Palo Alto',
    county: 'Santa Clara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94303',
    city: 'Palo Alto',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94304',
    city: 'Palo Alto',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94306',
    city: 'Palo Alto',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94309',
    city: 'Palo Alto',
    county: 'Santa Clara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95044',
    city: 'Redwood Estates',
    county: 'Santa Clara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95101',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95103',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95106',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95108',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95109',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95110',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95111',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95112',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95113',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95115',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95116',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95117',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95118',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95119',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95120',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95121',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95122',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95123',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95124',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95125',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95126',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95127',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95128',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95129',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95130',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95131',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95132',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95133',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95134',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95135',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95136',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95138',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95139',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95141',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95148',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95150',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95151',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95152',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95153',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95154',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95155',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95156',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95157',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95158',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95159',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95160',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95161',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95164',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95170',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95172',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95173',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95190',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95191',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95192',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95193',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95194',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95196',
    city: 'San Jose',
    county: 'Santa Clara',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95046',
    city: 'San Martin',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95050',
    city: 'Santa Clara',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95051',
    city: 'Santa Clara',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95052',
    city: 'Santa Clara',
    county: 'Santa Clara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95053',
    city: 'Santa Clara',
    county: 'Santa Clara',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95054',
    city: 'Santa Clara',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95055',
    city: 'Santa Clara',
    county: 'Santa Clara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95056',
    city: 'Santa Clara',
    county: 'Santa Clara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95070',
    city: 'Saratoga',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95071',
    city: 'Saratoga',
    county: 'Santa Clara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94305',
    city: 'Stanford',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94085',
    city: 'Sunnyvale',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94086',
    city: 'Sunnyvale',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94087',
    city: 'Sunnyvale',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94088',
    city: 'Sunnyvale',
    county: 'Santa Clara',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94089',
    city: 'Sunnyvale',
    county: 'Santa Clara',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95001',
    city: 'Aptos',
    county: 'Santa Cruz',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95003',
    city: 'Aptos',
    county: 'Santa Cruz',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95005',
    city: 'Ben Lomond',
    county: 'Santa Cruz',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95006',
    city: 'Boulder Creek',
    county: 'Santa Cruz',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95007',
    city: 'Brookdale',
    county: 'Santa Cruz',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95010',
    city: 'Capitola',
    county: 'Santa Cruz',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95017',
    city: 'Davenport',
    county: 'Santa Cruz',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95018',
    city: 'Felton',
    county: 'Santa Cruz',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95019',
    city: 'Freedom',
    county: 'Santa Cruz',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95041',
    city: 'Mount Hermon',
    county: 'Santa Cruz',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95060',
    city: 'Santa Cruz',
    county: 'Santa Cruz',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95061',
    city: 'Santa Cruz',
    county: 'Santa Cruz',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95062',
    city: 'Santa Cruz',
    county: 'Santa Cruz',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95063',
    city: 'Santa Cruz',
    county: 'Santa Cruz',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95064',
    city: 'Santa Cruz',
    county: 'Santa Cruz',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95065',
    city: 'Santa Cruz',
    county: 'Santa Cruz',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95066',
    city: 'Scotts Valley',
    county: 'Santa Cruz',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95067',
    city: 'Scotts Valley',
    county: 'Santa Cruz',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95073',
    city: 'Soquel',
    county: 'Santa Cruz',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95076',
    city: 'Watsonville',
    county: 'Santa Cruz',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95077',
    city: 'Watsonville',
    county: 'Santa Cruz',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96007',
    city: 'Anderson',
    county: 'Shasta',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96008',
    city: 'Bella Vista',
    county: 'Shasta',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96011',
    city: 'Big Bend',
    county: 'Shasta',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96013',
    city: 'Burney',
    county: 'Shasta',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96016',
    city: 'Cassel',
    county: 'Shasta',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96017',
    city: 'Castella',
    county: 'Shasta',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96022',
    city: 'Cottonwood',
    county: 'Shasta',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96028',
    city: 'Fall River Mills',
    county: 'Shasta',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96033',
    city: 'French Gulch',
    county: 'Shasta',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96040',
    city: 'Hat Creek',
    county: 'Shasta',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96047',
    city: 'Igo',
    county: 'Shasta',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96051',
    city: 'Lakehead',
    county: 'Shasta',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96056',
    city: 'Mcarthur',
    county: 'Shasta',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96062',
    city: 'Millville',
    county: 'Shasta',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96065',
    city: 'Montgomery Creek',
    county: 'Shasta',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96069',
    city: 'Oak Run',
    county: 'Shasta',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96070',
    city: 'Obrien',
    county: 'Shasta',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96071',
    city: 'Old Station',
    county: 'Shasta',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96073',
    city: 'Palo Cedro',
    county: 'Shasta',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96076',
    city: 'Platina',
    county: 'Shasta',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96001',
    city: 'Redding',
    county: 'Shasta',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96002',
    city: 'Redding',
    county: 'Shasta',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96003',
    city: 'Redding',
    county: 'Shasta',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96049',
    city: 'Redding',
    county: 'Shasta',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96099',
    city: 'Redding',
    county: 'Shasta',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96084',
    city: 'Round Mountain',
    county: 'Shasta',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96087',
    city: 'Shasta',
    county: 'Shasta',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96019',
    city: 'Shasta Lake',
    county: 'Shasta',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96079',
    city: 'Shasta Lake',
    county: 'Shasta',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96089',
    city: 'Shasta Lake',
    county: 'Shasta',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96088',
    city: 'Shingletown',
    county: 'Shasta',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96095',
    city: 'Whiskeytown',
    county: 'Shasta',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96096',
    city: 'Whitmore',
    county: 'Shasta',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95910',
    city: 'Alleghany',
    county: 'Sierra',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96124',
    city: 'Calpine',
    county: 'Sierra',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95936',
    city: 'Downieville',
    county: 'Sierra',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95944',
    city: 'Goodyears Bar',
    county: 'Sierra',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96118',
    city: 'Loyalton',
    county: 'Sierra',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96125',
    city: 'Sierra City',
    county: 'Sierra',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96126',
    city: 'Sierraville',
    county: 'Sierra',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96014',
    city: 'Callahan',
    county: 'Siskiyou',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96023',
    city: 'Dorris',
    county: 'Siskiyou',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96025',
    city: 'Dunsmuir',
    county: 'Siskiyou',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96027',
    city: 'Etna',
    county: 'Siskiyou',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96031',
    city: 'Forks Of Salmon',
    county: 'Siskiyou',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96032',
    city: 'Fort Jones',
    county: 'Siskiyou',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96034',
    city: 'Gazelle',
    county: 'Siskiyou',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96037',
    city: 'Greenview',
    county: 'Siskiyou',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96038',
    city: 'Grenada',
    county: 'Siskiyou',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96039',
    city: 'Happy Camp',
    county: 'Siskiyou',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96044',
    city: 'Hornbrook',
    county: 'Siskiyou',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96050',
    city: 'Klamath River',
    county: 'Siskiyou',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96058',
    city: 'Macdoel',
    county: 'Siskiyou',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96057',
    city: 'Mccloud',
    county: 'Siskiyou',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96064',
    city: 'Montague',
    county: 'Siskiyou',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96067',
    city: 'Mount Shasta',
    county: 'Siskiyou',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96085',
    city: 'Scott Bar',
    county: 'Siskiyou',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96086',
    city: 'Seiad Valley',
    county: 'Siskiyou',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95568',
    city: 'Somes Bar',
    county: 'Siskiyou',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96134',
    city: 'Tulelake',
    county: 'Siskiyou',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96094',
    city: 'Weed',
    county: 'Siskiyou',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96097',
    city: 'Yreka',
    county: 'Siskiyou',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94510',
    city: 'Benicia',
    county: 'Solano',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94512',
    city: 'Birds Landing',
    county: 'Solano',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95620',
    city: 'Dixon',
    county: 'Solano',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95625',
    city: 'Elmira',
    county: 'Solano',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94533',
    city: 'Fairfield',
    county: 'Solano',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94534',
    city: 'Fairfield',
    county: 'Solano',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94571',
    city: 'Rio Vista',
    county: 'Solano',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94585',
    city: 'Suisun City',
    county: 'Solano',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94535',
    city: 'Travis AFB',
    county: 'Solano',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95687',
    city: 'Vacaville',
    county: 'Solano',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95688',
    city: 'Vacaville',
    county: 'Solano',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95696',
    city: 'Vacaville',
    county: 'Solano',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94589',
    city: 'Vallejo',
    county: 'Solano',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94590',
    city: 'Vallejo',
    county: 'Solano',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94591',
    city: 'Vallejo',
    county: 'Solano',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94592',
    city: 'Vallejo',
    county: 'Solano',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95412',
    city: 'Annapolis',
    county: 'Sonoma',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94922',
    city: 'Bodega',
    county: 'Sonoma',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94923',
    city: 'Bodega Bay',
    county: 'Sonoma',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95416',
    city: 'Boyes Hot Springs',
    county: 'Sonoma',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95419',
    city: 'Camp Meeker',
    county: 'Sonoma',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95421',
    city: 'Cazadero',
    county: 'Sonoma',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95425',
    city: 'Cloverdale',
    county: 'Sonoma',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94931',
    city: 'Cotati',
    county: 'Sonoma',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95430',
    city: 'Duncans Mills',
    county: 'Sonoma',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95433',
    city: 'El Verano',
    county: 'Sonoma',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95431',
    city: 'Eldridge',
    county: 'Sonoma',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95436',
    city: 'Forestville',
    county: 'Sonoma',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95439',
    city: 'Fulton',
    county: 'Sonoma',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95441',
    city: 'Geyserville',
    county: 'Sonoma',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95442',
    city: 'Glen Ellen',
    county: 'Sonoma',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95444',
    city: 'Graton',
    county: 'Sonoma',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95446',
    city: 'Guerneville',
    county: 'Sonoma',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95448',
    city: 'Healdsburg',
    county: 'Sonoma',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95450',
    city: 'Jenner',
    county: 'Sonoma',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95452',
    city: 'Kenwood',
    county: 'Sonoma',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95462',
    city: 'Monte Rio',
    county: 'Sonoma',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95465',
    city: 'Occidental',
    county: 'Sonoma',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94951',
    city: 'Penngrove',
    county: 'Sonoma',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94952',
    city: 'Petaluma',
    county: 'Sonoma',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94953',
    city: 'Petaluma',
    county: 'Sonoma',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94954',
    city: 'Petaluma',
    county: 'Sonoma',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94955',
    city: 'Petaluma',
    county: 'Sonoma',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94975',
    city: 'Petaluma',
    county: 'Sonoma',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94999',
    city: 'Petaluma',
    county: 'Sonoma',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95471',
    city: 'Rio Nido',
    county: 'Sonoma',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94926',
    city: 'Rohnert Park',
    county: 'Sonoma',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94927',
    city: 'Rohnert Park',
    county: 'Sonoma',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94928',
    city: 'Rohnert Park',
    county: 'Sonoma',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95401',
    city: 'Santa Rosa',
    county: 'Sonoma',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95402',
    city: 'Santa Rosa',
    county: 'Sonoma',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95403',
    city: 'Santa Rosa',
    county: 'Sonoma',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95404',
    city: 'Santa Rosa',
    county: 'Sonoma',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95405',
    city: 'Santa Rosa',
    county: 'Sonoma',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95406',
    city: 'Santa Rosa',
    county: 'Sonoma',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95407',
    city: 'Santa Rosa',
    county: 'Sonoma',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95409',
    city: 'Santa Rosa',
    county: 'Sonoma',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95472',
    city: 'Sebastopol',
    county: 'Sonoma',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95473',
    city: 'Sebastopol',
    county: 'Sonoma',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95476',
    city: 'Sonoma',
    county: 'Sonoma',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95480',
    city: 'Stewarts Point',
    county: 'Sonoma',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95497',
    city: 'The Sea Ranch',
    county: 'Sonoma',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '94972',
    city: 'Valley Ford',
    county: 'Sonoma',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95486',
    city: 'Villa Grande',
    county: 'Sonoma',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95487',
    city: 'Vineburg',
    county: 'Sonoma',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95492',
    city: 'Windsor',
    county: 'Sonoma',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95307',
    city: 'Ceres',
    county: 'Stanislaus',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95313',
    city: 'Crows Landing',
    county: 'Stanislaus',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95316',
    city: 'Denair',
    county: 'Stanislaus',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95319',
    city: 'Empire',
    county: 'Stanislaus',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95323',
    city: 'Hickman',
    county: 'Stanislaus',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95326',
    city: 'Hughson',
    county: 'Stanislaus',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95328',
    city: 'Keyes',
    county: 'Stanislaus',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95329',
    city: 'La Grange',
    county: 'Stanislaus',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95350',
    city: 'Modesto',
    county: 'Stanislaus',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95351',
    city: 'Modesto',
    county: 'Stanislaus',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95352',
    city: 'Modesto',
    county: 'Stanislaus',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95353',
    city: 'Modesto',
    county: 'Stanislaus',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95354',
    city: 'Modesto',
    county: 'Stanislaus',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95355',
    city: 'Modesto',
    county: 'Stanislaus',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95356',
    city: 'Modesto',
    county: 'Stanislaus',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95357',
    city: 'Modesto',
    county: 'Stanislaus',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95358',
    city: 'Modesto',
    county: 'Stanislaus',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95397',
    city: 'Modesto',
    county: 'Stanislaus',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95360',
    city: 'Newman',
    county: 'Stanislaus',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95361',
    city: 'Oakdale',
    county: 'Stanislaus',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95363',
    city: 'Patterson',
    county: 'Stanislaus',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95367',
    city: 'Riverbank',
    county: 'Stanislaus',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95368',
    city: 'Salida',
    county: 'Stanislaus',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95380',
    city: 'Turlock',
    county: 'Stanislaus',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95381',
    city: 'Turlock',
    county: 'Stanislaus',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95382',
    city: 'Turlock',
    county: 'Stanislaus',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95386',
    city: 'Waterford',
    county: 'Stanislaus',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95387',
    city: 'Westley',
    county: 'Stanislaus',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95953',
    city: 'Live Oak',
    county: 'Sutter',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95957',
    city: 'Meridian',
    county: 'Sutter',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95659',
    city: 'Nicolaus',
    county: 'Sutter',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95668',
    city: 'Pleasant Grove',
    county: 'Sutter',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95674',
    city: 'Rio Oso',
    county: 'Sutter',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95676',
    city: 'Robbins',
    county: 'Sutter',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95982',
    city: 'Sutter',
    county: 'Sutter',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95991',
    city: 'Yuba City',
    county: 'Sutter',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95992',
    city: 'Yuba City',
    county: 'Sutter',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95993',
    city: 'Yuba City',
    county: 'Sutter',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96021',
    city: 'Corning',
    county: 'Tehama',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96029',
    city: 'Flournoy',
    county: 'Tehama',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96035',
    city: 'Gerber',
    county: 'Tehama',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96055',
    city: 'Los Molinos',
    county: 'Tehama',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96059',
    city: 'Manton',
    county: 'Tehama',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96061',
    city: 'Mill Creek',
    county: 'Tehama',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96063',
    city: 'Mineral',
    county: 'Tehama',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96074',
    city: 'Paskenta',
    county: 'Tehama',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96075',
    city: 'Paynes Creek',
    county: 'Tehama',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96078',
    city: 'Proberta',
    county: 'Tehama',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96080',
    city: 'Red Bluff',
    county: 'Tehama',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96090',
    city: 'Tehama',
    county: 'Tehama',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96092',
    city: 'Vina',
    county: 'Tehama',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96010',
    city: 'Big Bar',
    county: 'Trinity',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95527',
    city: 'Burnt Ranch',
    county: 'Trinity',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96024',
    city: 'Douglas City',
    county: 'Trinity',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96041',
    city: 'Hayfork',
    county: 'Trinity',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96046',
    city: 'Hyampom',
    county: 'Trinity',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96048',
    city: 'Junction City',
    county: 'Trinity',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96052',
    city: 'Lewiston',
    county: 'Trinity',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95552',
    city: 'Mad River',
    county: 'Trinity',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95563',
    city: 'Salyer',
    county: 'Trinity',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96091',
    city: 'Trinity Center',
    county: 'Trinity',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '96093',
    city: 'Weaverville',
    county: 'Trinity',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95595',
    city: 'Zenia',
    county: 'Trinity',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93201',
    city: 'Alpaugh',
    county: 'Tulare',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93603',
    city: 'Badger',
    county: 'Tulare',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93207',
    city: 'California Hot Springs',
    county: 'Tulare',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93208',
    city: 'Camp Nelson',
    county: 'Tulare',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93615',
    city: 'Cutler',
    county: 'Tulare',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93618',
    city: 'Dinuba',
    county: 'Tulare',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93218',
    city: 'Ducor',
    county: 'Tulare',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93219',
    city: 'Earlimart',
    county: 'Tulare',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93221',
    city: 'Exeter',
    county: 'Tulare',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93223',
    city: 'Farmersville',
    county: 'Tulare',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93227',
    city: 'Goshen',
    county: 'Tulare',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93235',
    city: 'Ivanhoe',
    county: 'Tulare',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93237',
    city: 'Kaweah',
    county: 'Tulare',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93633',
    city: 'Kings Canyon National Pk',
    county: 'Tulare',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93244',
    city: 'Lemon Cove',
    county: 'Tulare',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93247',
    city: 'Lindsay',
    county: 'Tulare',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93647',
    city: 'Orosi',
    county: 'Tulare',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93256',
    city: 'Pixley',
    county: 'Tulare',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93257',
    city: 'Porterville',
    county: 'Tulare',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93258',
    city: 'Porterville',
    county: 'Tulare',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93260',
    city: 'Posey',
    county: 'Tulare',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93261',
    city: 'Richgrove',
    county: 'Tulare',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93262',
    city: 'Sequoia National Park',
    county: 'Tulare',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93265',
    city: 'Springville',
    county: 'Tulare',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93267',
    city: 'Strathmore',
    county: 'Tulare',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93666',
    city: 'Sultana',
    county: 'Tulare',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93270',
    city: 'Terra Bella',
    county: 'Tulare',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93271',
    city: 'Three Rivers',
    county: 'Tulare',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93272',
    city: 'Tipton',
    county: 'Tulare',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93673',
    city: 'Traver',
    county: 'Tulare',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93274',
    city: 'Tulare',
    county: 'Tulare',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93275',
    city: 'Tulare',
    county: 'Tulare',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93277',
    city: 'Visalia',
    county: 'Tulare',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93278',
    city: 'Visalia',
    county: 'Tulare',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93279',
    city: 'Visalia',
    county: 'Tulare',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93290',
    city: 'Visalia',
    county: 'Tulare',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93291',
    city: 'Visalia',
    county: 'Tulare',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93292',
    city: 'Visalia',
    county: 'Tulare',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93282',
    city: 'Waukena',
    county: 'Tulare',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93286',
    city: 'Woodlake',
    county: 'Tulare',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93670',
    city: 'Yettem',
    county: 'Tulare',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95305',
    city: 'Big Oak Flat',
    county: 'Tuolumne',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95309',
    city: 'Chinese Camp',
    county: 'Tuolumne',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95310',
    city: 'Columbia',
    county: 'Tuolumne',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95321',
    city: 'Groveland',
    county: 'Tuolumne',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95327',
    city: 'Jamestown',
    county: 'Tuolumne',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95335',
    city: 'Long Barn',
    county: 'Tuolumne',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95346',
    city: 'Mi Wuk Village',
    county: 'Tuolumne',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95347',
    city: 'Moccasin',
    county: 'Tuolumne',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95364',
    city: 'Pinecrest',
    county: 'Tuolumne',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95370',
    city: 'Sonora',
    county: 'Tuolumne',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95372',
    city: 'Soulsbyville',
    county: 'Tuolumne',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95373',
    city: 'Standard',
    county: 'Tuolumne',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95375',
    city: 'Strawberry',
    county: 'Tuolumne',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95379',
    city: 'Tuolumne',
    county: 'Tuolumne',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95383',
    city: 'Twain Harte',
    county: 'Tuolumne',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93064',
    city: 'Brandeis',
    county: 'Ventura',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93010',
    city: 'Camarillo',
    county: 'Ventura',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93011',
    city: 'Camarillo',
    county: 'Ventura',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93012',
    city: 'Camarillo',
    county: 'Ventura',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93015',
    city: 'Fillmore',
    county: 'Ventura',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93016',
    city: 'Fillmore',
    county: 'Ventura',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93020',
    city: 'Moorpark',
    county: 'Ventura',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93021',
    city: 'Moorpark',
    county: 'Ventura',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91319',
    city: 'Newbury Park',
    county: 'Ventura',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91320',
    city: 'Newbury Park',
    county: 'Ventura',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91377',
    city: 'Oak Park',
    county: 'Ventura',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93022',
    city: 'Oak View',
    county: 'Ventura',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93023',
    city: 'Ojai',
    county: 'Ventura',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93024',
    city: 'Ojai',
    county: 'Ventura',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93030',
    city: 'Oxnard',
    county: 'Ventura',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93031',
    city: 'Oxnard',
    county: 'Ventura',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93032',
    city: 'Oxnard',
    county: 'Ventura',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93033',
    city: 'Oxnard',
    county: 'Ventura',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93034',
    city: 'Oxnard',
    county: 'Ventura',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93035',
    city: 'Oxnard',
    county: 'Ventura',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93036',
    city: 'Oxnard',
    county: 'Ventura',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93040',
    city: 'Piru',
    county: 'Ventura',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93042',
    city: 'Point Mugu Nawc',
    county: 'Ventura',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93041',
    city: 'Port Hueneme',
    county: 'Ventura',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93044',
    city: 'Port Hueneme',
    county: 'Ventura',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93043',
    city: 'Port Hueneme Cbc Base',
    county: 'Ventura',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93060',
    city: 'Santa Paula',
    county: 'Ventura',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93061',
    city: 'Santa Paula',
    county: 'Ventura',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93062',
    city: 'Simi Valley',
    county: 'Ventura',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93063',
    city: 'Simi Valley',
    county: 'Ventura',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93065',
    city: 'Simi Valley',
    county: 'Ventura',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93094',
    city: 'Simi Valley',
    county: 'Ventura',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93099',
    city: 'Simi Valley',
    county: 'Ventura',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93066',
    city: 'Somis',
    county: 'Ventura',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91358',
    city: 'Thousand Oaks',
    county: 'Ventura',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91360',
    city: 'Thousand Oaks',
    county: 'Ventura',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91362',
    city: 'Thousand Oaks',
    county: 'Ventura',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93001',
    city: 'Ventura',
    county: 'Ventura',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93002',
    city: 'Ventura',
    county: 'Ventura',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93003',
    city: 'Ventura',
    county: 'Ventura',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93004',
    city: 'Ventura',
    county: 'Ventura',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93005',
    city: 'Ventura',
    county: 'Ventura',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93006',
    city: 'Ventura',
    county: 'Ventura',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93007',
    city: 'Ventura',
    county: 'Ventura',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '93009',
    city: 'Ventura',
    county: 'Ventura',
    type: 'Unique',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91359',
    city: 'Westlake Village',
    county: 'Ventura',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '91361',
    city: 'Westlake Village',
    county: 'Ventura',
    type: 'Standard',
    region: 'San Fernando Valley',
    neighbourhood: 'Westlake Village',
  },
  {
    zipCode: '95606',
    city: 'Brooks',
    county: 'Yolo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95607',
    city: 'Capay',
    county: 'Yolo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95612',
    city: 'Clarksburg',
    county: 'Yolo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95616',
    city: 'Davis',
    county: 'Yolo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95617',
    city: 'Davis',
    county: 'Yolo',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95618',
    city: 'Davis',
    county: 'Yolo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95937',
    city: 'Dunnigan',
    county: 'Yolo',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95627',
    city: 'Esparto',
    county: 'Yolo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95637',
    city: 'Guinda',
    county: 'Yolo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95645',
    city: 'Knights Landing',
    county: 'Yolo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95653',
    city: 'Madison',
    county: 'Yolo',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95679',
    city: 'Rumsey',
    county: 'Yolo',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95605',
    city: 'West Sacramento',
    county: 'Yolo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95691',
    city: 'West Sacramento',
    county: 'Yolo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95798',
    city: 'West Sacramento',
    county: 'Yolo',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95799',
    city: 'West Sacramento',
    county: 'Yolo',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95694',
    city: 'Winters',
    county: 'Yolo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95695',
    city: 'Woodland',
    county: 'Yolo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95776',
    city: 'Woodland',
    county: 'Yolo',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95697',
    city: 'Yolo',
    county: 'Yolo',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95698',
    city: 'Zamora',
    county: 'Yolo',
    type: 'P.O.Box',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95903',
    city: 'Beale AFB',
    county: 'Yuba',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95918',
    city: 'Browns Valley',
    county: 'Yuba',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95919',
    city: 'Brownsville',
    county: 'Yuba',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95922',
    city: 'Camptonville',
    county: 'Yuba',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95925',
    city: 'Challenge',
    county: 'Yuba',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95935',
    city: 'Dobbins',
    county: 'Yuba',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95901',
    city: 'Marysville',
    county: 'Yuba',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95961',
    city: 'Olivehurst',
    county: 'Yuba',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95962',
    city: 'Oregon House',
    county: 'Yuba',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95972',
    city: 'Rackerby',
    county: 'Yuba',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95981',
    city: 'Strawberry Valley',
    county: 'Yuba',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
  {
    zipCode: '95692',
    city: 'Wheatland',
    county: 'Yuba',
    type: 'Standard',
    region: '',
    neighbourhood: '',
  },
];
