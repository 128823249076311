import React from 'react';

export default function UserIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 11.7376C14.6684 11.7376 16.8318 9.11006 16.8318 5.86882C16.8318 2.62752 16.1215 0 12 0C7.87852 0 7.16812 2.62752 7.16812 5.86882C7.16812 9.11006 9.33148 11.7376 12 11.7376Z" fill="currentColor" />
      <path d="M2.87377 20.6985C2.87295 20.5009 2.87212 20.6428 2.87377 20.6985V20.6985Z" fill="currentColor" />
      <path d="M21.1258 20.8528C21.1284 20.7987 21.1267 20.4775 21.1258 20.8528V20.8528Z" fill="currentColor" />
      <path d="M21.1156 20.4615C21.0261 14.8152 20.2887 13.2063 14.6457 12.1879C14.6457 12.1879 13.8514 13.2001 11.9999 13.2001C10.1485 13.2001 9.35404 12.1879 9.35404 12.1879C3.77267 13.1952 2.99041 14.7802 2.88755 20.2781C2.87912 20.727 2.87521 20.7506 2.8737 20.6985C2.87404 20.7961 2.87446 20.9768 2.87446 21.2917C2.87446 21.2917 4.21791 24 11.9999 24C19.7818 24 21.1254 21.2917 21.1254 21.2917C21.1254 21.0894 21.1256 20.9487 21.1258 20.8529C21.1243 20.8852 21.1212 20.8227 21.1156 20.4615Z" fill="currentColor" />
    </svg>
  );
}
