import React from 'react';

export default function UserPlaceholder64({ style }: { style: any }) {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ ...style }}>
      <circle cx="32" cy="32" r="32" fill="#F8F8F8"/>
      <circle cx="32" cy="32" r="32" fill="#F8F8F8"/>
      <mask id="mask0_1895_760" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="64" height="64">
        <circle cx="32" cy="32" r="32" fill="white"/>
      </mask>
      <g mask="url(#mask0_1895_760)">
        <path opacity="0.6" fillRule="evenodd" clipRule="evenodd" d="M31.9999 42.6667C38.2616 42.6667 43.3333 37.595 43.3333 31.3333C43.3333 25.0717 38.2616 20 31.9999 20C25.7383 20 20.6666 25.0717 20.6666 31.3333C20.6666 37.595 25.7383 42.6667 31.9999 42.6667ZM31.9999 48.3333C24.4349 48.3333 9.33325 52.13 9.33325 59.6667V65.3333H54.6666V59.6667C54.6666 52.13 39.5649 48.3333 31.9999 48.3333Z" fill="#CBCFD5"/>
      </g>
    </svg>
  );
}
