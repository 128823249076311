export const areas = [
  {
    area: 'AB',
    outward: 'AB10',
    town: 'Aberdeen',
    county: 'Aberdeenshire',
  },
  {
    area: 'AB',
    outward: 'AB11',
    town: 'Aberdeen',
    county: 'Aberdeenshire',
  },
  {
    area: 'AB',
    outward: 'AB12',
    town: 'Aberdeen',
    county: 'Aberdeenshire',
  },
  {
    area: 'AB',
    outward: 'AB13',
    town: 'Milltimber',
    county: 'Aberdeenshire',
  },
  {
    area: 'AB',
    outward: 'AB14',
    town: 'Peterculter',
    county: 'Aberdeenshire',
  },
  {
    area: 'AB',
    outward: 'AB15',
    town: 'Aberdeen',
    county: 'Aberdeenshire',
  },
  {
    area: 'AB',
    outward: 'AB16',
    town: 'Aberdeen',
    county: 'Aberdeenshire',
  },
  {
    area: 'AB',
    outward: 'AB21',
    town: 'Aberdeen',
    county: 'Aberdeenshire',
  },
  {
    area: 'AB',
    outward: 'AB22',
    town: 'Aberdeen',
    county: 'Aberdeenshire',
  },
  {
    area: 'AB',
    outward: 'AB23',
    town: 'Aberdeen',
    county: 'Aberdeenshire',
  },
  {
    area: 'AB',
    outward: 'AB24',
    town: 'Aberdeen',
    county: 'Aberdeenshire',
  },
  {
    area: 'AB',
    outward: 'AB25',
    town: 'Aberdeen',
    county: 'Aberdeenshire',
  },
  {
    area: 'AB',
    outward: 'AB30',
    town: 'Laurencekirk',
    county: 'Kincardineshire',
  },
  {
    area: 'AB',
    outward: 'AB31',
    town: 'Banchory',
    county: 'Kincardineshire',
  },
  {
    area: 'AB',
    outward: 'AB32',
    town: 'WESTHILL',
    county: 'Aberdeenshire',
  },
  {
    area: 'AB',
    outward: 'AB33',
    town: 'ALFORD',
    county: 'Aberdeenshire',
  },
  {
    area: 'AB',
    outward: 'AB34',
    town: 'Aboyne',
    county: 'Aberdeenshire',
  },
  {
    area: 'AB',
    outward: 'AB35',
    town: 'Ballater',
    county: 'Aberdeenshire',
  },
  {
    area: 'AB',
    outward: 'AB36',
    town: 'Strathdon',
    county: 'Aberdeenshire',
  },
  {
    area: 'AB',
    outward: 'AB37',
    town: 'Ballindalloch',
    county: 'Banffshire',
  },
  {
    area: 'AB',
    outward: 'AB38',
    town: 'Aberlour',
    county: 'Banffshire',
  },
  {
    area: 'AB',
    outward: 'AB39',
    town: 'Stonehaven',
    county: 'Kincardineshire',
  },
  {
    area: 'AB',
    outward: 'AB41',
    town: 'ELLON',
    county: 'Aberdeenshire',
  },
  {
    area: 'AB',
    outward: 'AB42',
    town: 'Peterhead',
    county: 'Aberdeenshire',
  },
  {
    area: 'AB',
    outward: 'AB43',
    town: 'Fraserburgh',
    county: 'Aberdeenshire',
  },
  {
    area: 'AB',
    outward: 'AB44',
    town: 'MACDUFF',
    county: 'Banffshire',
  },
  {
    area: 'AB',
    outward: 'AB45',
    town: 'BANFF',
    county: 'Banffshire',
  },
  {
    area: 'AB',
    outward: 'AB51',
    town: 'Inverurie',
    county: 'Aberdeenshire',
  },
  {
    area: 'AB',
    outward: 'AB52',
    town: 'Insch',
    county: 'Aberdeenshire',
  },
  {
    area: 'AB',
    outward: 'AB53',
    town: 'Turriff',
    county: 'Aberdeenshire',
  },
  {
    area: 'AB',
    outward: 'AB54',
    town: 'Huntly',
    county: 'Aberdeenshire',
  },
  {
    area: 'AB',
    outward: 'AB55',
    town: 'KEITH',
    county: 'Banffshire',
  },
  {
    area: 'AB',
    outward: 'AB56',
    town: 'Buckie',
    county: 'Banffshire',
  },
  {
    area: 'AB',
    outward: 'AB99',
    town: 'Non-geographic',
    county: 'Aberdeenshire',
  },
  {
    outward: 'AL1',
    town: 'St Albans',
    county: 'St Albans',
    coordinates: [51.747828, -0.30186478],
  },
  {
    outward: 'AL2',
    town:
      'St Albans, Bricket Wood, Colney Street, Frogmore, London Colney, Napsbury, Park Street, Potters Crouch, Chiswell Green',
    county: 'St Albans',
    coordinates: [51.716299, -0.28244635],
  },
  {
    outward: 'AL3',
    town: 'St Albans, Childwickbury, Flamstead, Gorhambury, Markyate, Porters Wood, Redbourn, Sandridge',
    county: 'St Albans',
    coordinates: [51.740921, -0.35425192],
  },
  {
    outward: 'AL4',
    town:
      'St Albans, Colney Heath, London Colney, Jersey Farm, Marshalswick, Oaklands, Sandridge, Smallford, Tyttenhanger, Wheathampstead',
    county: 'St Albans',
    coordinates: [51.740198, -0.30585874],
  },
  {
    outward: 'AL5',
    town: 'Harpenden',
    county: 'St Albans',
    coordinates: [51.81121, -0.33501026],
  },
  {
    outward: 'AL6',
    town: 'Welwyn, Ayot St Peter, Ayot St Lawrence, Digswell, Rabley Heath, Tewin',
    county: 'Welwyn Hatfield',
    coordinates: [51.81548, -0.1790068],
  },
  {
    outward: 'AL7',
    town: 'Welwyn Garden City',
    county: 'Welwyn Hatfield',
    coordinates: [51.804482, -0.19589835],
  },
  {
    area: 'AL',
    outward: 'AL7',
    town: 'Welwyn (shared)',
    county: 'Hertfordshire',
  },
  {
    outward: 'AL8',
    town: 'Welwyn Garden City, Lemsford',
    county: 'Welwyn Hatfield',
    coordinates: [51.802056, -0.2071922],
  },
  {
    outward: 'AL9',
    town: 'Hatfield, Brookmans Park, Essendon, North Mymms',
    county: 'Welwyn Hatfield',
    coordinates: [51.763895, -0.21555893],
  },
  {
    outward: 'AL10',
    town: 'Hatfield',
    county: 'Welwyn Hatfield',
    coordinates: [51.764308, -0.22619409],
  },
  {
    area: 'B',
    outward: 'B17',
    town: 'Birmingham',
    county: 'West Midlands',
  },
  {
    area: 'B',
    outward: 'B19',
    town: 'Birmingham',
    county: 'West Midlands',
  },
  {
    area: 'B',
    outward: 'B20',
    town: 'Birmingham',
    county: 'West Midlands',
  },
  {
    area: 'B',
    outward: 'B26',
    town: 'Birmingham',
    county: 'West Midlands',
  },
  {
    area: 'B',
    outward: 'B27',
    town: 'Birmingham',
    county: 'West Midlands',
  },
  {
    area: 'B',
    outward: 'B28',
    town: 'Birmingham',
    county: 'West Midlands',
  },
  {
    area: 'B',
    outward: 'B29',
    town: 'Birmingham',
    county: 'West Midlands',
  },
  {
    area: 'B',
    outward: 'B40',
    town: 'Birmingham',
    county: 'West Midlands',
  },
  {
    area: 'B',
    outward: 'B46',
    town: 'Birmingham',
    county: 'West Midlands',
  },
  {
    area: 'B',
    outward: 'B47',
    town: 'Birmingham',
    county: 'West Midlands',
  },
  {
    area: 'B',
    outward: 'B48',
    town: 'Birmingham',
    county: 'West Midlands',
  },
  {
    area: 'B',
    outward: 'B49',
    town: 'Alcester',
    county: 'Warwickshire',
  },
  {
    area: 'B',
    outward: 'B50',
    town: 'Alcester',
    county: 'Warwickshire',
  },
  {
    area: 'B',
    outward: 'B60',
    town: 'Bromsgrove',
    county: 'Worcestershire',
  },
  {
    area: 'B',
    outward: 'B61',
    town: 'Bromsgrove',
    county: 'Worcestershire',
  },
  {
    area: 'B',
    outward: 'B62',
    town: 'Halesowen',
    county: 'West Midlands',
  },
  {
    area: 'B',
    outward: 'B63',
    town: 'Halesowen',
    county: 'West Midlands',
  },
  {
    area: 'B',
    outward: 'B64',
    town: 'Cradley Heath',
    county: 'West Midlands',
  },
  {
    area: 'B',
    outward: 'B65',
    town: 'Rowley Regis',
    county: 'West Midlands',
  },
  {
    area: 'B',
    outward: 'B66',
    town: 'Smethwick',
    county: 'West Midlands',
  },
  {
    area: 'B',
    outward: 'B67',
    town: 'Smethwick',
    county: 'West Midlands',
  },
  {
    area: 'B',
    outward: 'B68',
    town: 'OLDBURY',
    county: 'West Midlands',
  },
  {
    area: 'B',
    outward: 'B69',
    town: 'OLDBURY',
    county: 'West Midlands',
  },
  {
    area: 'B',
    outward: 'B70',
    town: 'West Bromwich',
    county: 'West Midlands',
  },
  {
    area: 'B',
    outward: 'B71',
    town: 'West Bromwich',
    county: 'West Midlands',
  },
  {
    area: 'B',
    outward: 'B72',
    town: 'Sutton Coldfield',
    county: 'West Midlands',
  },
  {
    area: 'B',
    outward: 'B73',
    town: 'Sutton Coldfield',
    county: 'West Midlands',
  },
  {
    area: 'B',
    outward: 'B74',
    town: 'Sutton Coldfield',
    county: 'West Midlands',
  },
  {
    area: 'B',
    outward: 'B75',
    town: 'Sutton Coldfield',
    county: 'West Midlands',
  },
  {
    area: 'B',
    outward: 'B76',
    town: 'Sutton Coldfield',
    county: 'West Midlands',
  },
  {
    area: 'B',
    outward: 'B77',
    town: 'TAMWORTH',
    county: 'Staffordshire',
  },
  {
    area: 'B',
    outward: 'B78',
    town: 'TAMWORTH',
    county: 'Staffordshire',
  },
  {
    area: 'B',
    outward: 'B79',
    town: 'TAMWORTH',
    county: 'Staffordshire',
  },
  {
    area: 'B',
    outward: 'B80',
    town: 'STUDLEY',
    county: 'Warwickshire',
  },
  {
    area: 'B',
    outward: 'B90',
    town: 'Solihull',
    county: 'West Midlands',
  },
  {
    area: 'B',
    outward: 'B91',
    town: 'Solihull',
    county: 'West Midlands',
  },
  {
    area: 'B',
    outward: 'B92',
    town: 'Solihull',
    county: 'West Midlands',
  },
  {
    area: 'B',
    outward: 'B93',
    town: 'Solihull',
    county: 'West Midlands',
  },
  {
    area: 'B',
    outward: 'B94',
    town: 'Solihull',
    county: 'West Midlands',
  },
  {
    area: 'B',
    outward: 'B95',
    town: 'Henley-in-Arden',
    county: 'Warwickshire',
  },
  {
    area: 'B',
    outward: 'B96',
    town: 'Redditch',
    county: 'Worcestershire',
  },
  {
    area: 'B',
    outward: 'B97',
    town: 'Redditch',
    county: 'Worcestershire',
  },
  {
    area: 'B',
    outward: 'B98',
    town: 'Redditch',
    county: 'Worcestershire',
  },
  {
    area: 'BA',
    outward: 'BA1',
    town: 'BATH',
    county: 'Avon',
  },
  {
    area: 'BA',
    outward: 'BA2',
    town: 'BATH',
    county: 'Avon',
  },
  {
    area: 'BA',
    outward: 'BA3',
    town: 'Radstock',
    county: 'Avon',
  },
  {
    area: 'BA',
    outward: 'BA4',
    town: 'Shepton Mallet',
    county: 'Somerset',
  },
  {
    area: 'BA',
    outward: 'BA5',
    town: 'WELLS',
    county: 'Somerset',
  },
  {
    area: 'BA',
    outward: 'BA6',
    town: 'Glastonbury',
    county: 'Somerset',
  },
  {
    area: 'BA',
    outward: 'BA7',
    town: 'Castle Cary',
    county: 'Somerset',
  },
  {
    area: 'BA',
    outward: 'BA8',
    town: 'Templecombe',
    county: 'Somerset',
  },
  {
    area: 'BA',
    outward: 'BA9',
    town: 'Wincanton (shared)',
    county: 'Somerset',
  },
  {
    area: '',
    outward: 'BA10',
    town: 'Bruton',
    county: 'Somerset',
  },
  {
    area: 'BA',
    outward: 'BA11',
    town: 'Frome',
    county: 'Somerset',
  },
  {
    area: 'BA',
    outward: 'BA12',
    town: 'Warminster',
    county: 'Wiltshire',
  },
  {
    area: 'BA',
    outward: 'BA13',
    town: 'WESTBURY',
    county: 'Wiltshire',
  },
  {
    area: 'BA',
    outward: 'BA14',
    town: 'Trowbridge',
    county: 'Wiltshire',
  },
  {
    area: 'BA',
    outward: 'BA15',
    town: 'BRADFORD-ON-AVON',
    county: 'Wiltshire',
  },
  {
    area: 'BA',
    outward: 'BA16',
    town: 'STREET',
    county: 'Somerset',
  },
  {
    area: 'BA',
    outward: 'BA20',
    town: 'Yeovil',
    county: 'Somerset',
  },
  {
    area: 'BA',
    outward: 'BA21',
    town: 'Yeovil',
    county: 'Somerset',
  },
  {
    area: 'BA',
    outward: 'BA22',
    town: 'Yeovil',
    county: 'Somerset',
  },
  {
    area: 'BB',
    outward: 'BB1',
    town: 'Blackburn',
    county: 'Lancashire',
  },
  {
    area: 'BB',
    outward: 'BB2',
    town: 'Blackburn',
    county: 'Lancashire',
  },
  {
    area: 'BB',
    outward: 'BB3',
    town: 'Darwen',
    county: 'Lancashire',
  },
  {
    area: 'BB',
    outward: 'BB4',
    town: 'Rossendale',
    county: 'Lancashire',
  },
  {
    area: 'BB',
    outward: 'BB5',
    town: 'Accrington',
    county: 'Lancashire',
  },
  {
    area: 'BB',
    outward: 'BB6',
    town: 'Blackburn',
    county: 'Lancashire',
  },
  {
    area: 'BB',
    outward: 'BB7',
    town: 'Clitheroe',
    county: 'Lancashire',
  },
  {
    area: 'BB',
    outward: 'BB8',
    town: 'Colne',
    county: 'Lancashire',
  },
  {
    area: 'BB',
    outward: 'BB9',
    town: 'NELSON',
    county: 'Lancashire',
  },
  {
    area: 'BB',
    outward: 'BB10',
    town: 'Burnley',
    county: 'Lancashire',
  },
  {
    area: 'BB',
    outward: 'BB11',
    town: 'Burnley',
    county: 'Lancashire',
  },
  {
    area: 'BB',
    outward: 'BB12',
    town: 'Burnley',
    county: 'Lancashire',
  },
  {
    area: 'BB',
    outward: 'BB18',
    town: 'Barnoldswick',
    county: 'Lancashire',
  },
  {
    area: 'BB',
    outward: 'BB94',
    town: 'Non-geographic',
    county: 'Lancashire',
  },
  {
    area: 'BD',
    outward: 'BD1',
    town: 'Bradford',
    county: 'West Yorkshire',
  },
  {
    area: 'BD',
    outward: 'BD2',
    town: 'Bradford',
    county: 'West Yorkshire',
  },
  {
    area: 'BD',
    outward: 'BD3',
    town: 'Bradford',
    county: 'West Yorkshire',
  },
  {
    area: 'BD',
    outward: 'BD4',
    town: 'Bradford',
    county: 'West Yorkshire',
  },
  {
    area: 'BD',
    outward: 'BD5',
    town: 'Bradford',
    county: 'West Yorkshire',
  },
  {
    area: 'BD',
    outward: 'BD6',
    town: 'Bradford',
    county: 'West Yorkshire',
  },
  {
    area: 'BD',
    outward: 'BD7',
    town: 'Bradford',
    county: 'West Yorkshire',
  },
  {
    area: 'BD',
    outward: 'BD8',
    town: 'Bradford',
    county: 'West Yorkshire',
  },
  {
    area: 'BD',
    outward: 'BD9',
    town: 'Bradford',
    county: 'West Yorkshire',
  },
  {
    area: 'BD',
    outward: 'BD10',
    town: 'Bradford',
    county: 'West Yorkshire',
  },
  {
    area: 'BD',
    outward: 'BD11',
    town: 'Bradford',
    county: 'West Yorkshire',
  },
  {
    area: 'BD',
    outward: 'BD12',
    town: 'Bradford',
    county: 'West Yorkshire',
  },
  {
    area: 'BD',
    outward: 'BD13',
    town: 'Bradford',
    county: 'West Yorkshire',
  },
  {
    area: 'BD',
    outward: 'BD14',
    town: 'Bradford',
    county: 'West Yorkshire',
  },
  {
    area: 'BD',
    outward: 'BD15',
    town: 'Bradford',
    county: 'West Yorkshire',
  },
  {
    area: 'BD',
    outward: 'BD16',
    town: 'Bingley',
    county: 'West Yorkshire',
  },
  {
    area: 'BD',
    outward: 'BD17',
    town: 'SHIPLEY',
    county: 'West Yorkshire',
  },
  {
    area: 'BD',
    outward: 'BD18',
    town: 'SHIPLEY',
    county: 'West Yorkshire',
  },
  {
    area: 'BD',
    outward: 'BD19',
    town: 'Cleckheaton',
    county: 'West Yorkshire',
  },
  {
    area: 'BD',
    outward: 'BD20',
    town: 'Keighley',
    county: 'West Yorkshire',
  },
  {
    area: 'BD',
    outward: 'BD21',
    town: 'Keighley',
    county: 'West Yorkshire',
  },
  {
    area: 'BD',
    outward: 'BD22',
    town: 'Keighley',
    county: 'West Yorkshire',
  },
  {
    area: 'BD',
    outward: 'BD23',
    town: 'Skipton',
    county: 'North Yorkshire',
  },
  {
    area: 'BD',
    outward: 'BD24',
    town: 'Skipton (shared)',
    county: 'North Yorkshire',
  },
  {
    area: 'BD',
    outward: 'BD97',
    town: 'Non-geographic',
    county: 'West Yorkshire',
  },
  {
    area: 'BD',
    outward: 'BD98',
    town: 'non-geo shared',
    county: 'West Yorkshire',
  },
  {
    area: 'BD',
    outward: 'BD99',
    town: 'Non-geographic',
    county: 'West Yorkshire',
  },
  {
    area: 'BF',
    outward: 'BF1',
    town: 'BFPO',
    county: 'Non-geographic',
  },
  {
    area: 'BH',
    outward: 'BH1',
    town: 'Bournemouth',
    county: 'Dorset',
  },
  {
    area: 'BH',
    outward: 'BH2',
    town: 'Bournemouth',
    county: 'Dorset',
  },
  {
    area: 'BH',
    outward: 'BH3',
    town: 'Bournemouth',
    county: 'Dorset',
  },
  {
    area: 'BH',
    outward: 'BH4',
    town: 'Bournemouth',
    county: 'Dorset',
  },
  {
    area: 'BH',
    outward: 'BH5',
    town: 'Bournemouth',
    county: 'Dorset',
  },
  {
    area: 'BH',
    outward: 'BH6',
    town: 'Bournemouth',
    county: 'Dorset',
  },
  {
    area: 'BH',
    outward: 'BH7',
    town: 'Bournemouth',
    county: 'Dorset',
  },
  {
    area: 'BH',
    outward: 'BH8',
    town: 'Bournemouth',
    county: 'Dorset',
  },
  {
    area: 'BH',
    outward: 'BH9',
    town: 'Bournemouth',
    county: 'Dorset',
  },
  {
    area: 'BH',
    outward: 'BH10',
    town: 'Bournemouth',
    county: 'Dorset',
  },
  {
    area: 'BH',
    outward: 'BH11',
    town: 'Bournemouth',
    county: 'Dorset',
  },
  {
    area: 'BH',
    outward: 'BH12',
    town: 'Poole',
    county: 'Dorset',
  },
  {
    area: 'BH',
    outward: 'BH13',
    town: 'Poole',
    county: 'Dorset',
  },
  {
    area: 'BH',
    outward: 'BH14',
    town: 'Poole',
    county: 'Dorset',
  },
  {
    area: 'BH',
    outward: 'BH15',
    town: 'Poole',
    county: 'Dorset',
  },
  {
    area: 'BH',
    outward: 'BH16',
    town: 'Poole',
    county: 'Dorset',
  },
  {
    area: 'BH',
    outward: 'BH17',
    town: 'Poole',
    county: 'Dorset',
  },
  {
    area: 'BH',
    outward: 'BH18',
    town: 'BROADSTONE',
    county: 'Dorset',
  },
  {
    area: 'BH',
    outward: 'BH19',
    town: 'Swanage',
    county: 'Dorset',
  },
  {
    area: 'BH',
    outward: 'BH20',
    town: 'WAREHAM',
    county: 'Dorset',
  },
  {
    area: 'BH',
    outward: 'BH21',
    town: 'WIMBORNE',
    county: 'Dorset',
  },
  {
    area: 'BH',
    outward: 'BH22',
    town: 'Ferndown',
    county: 'Dorset',
  },
  {
    area: 'BH',
    outward: 'BH23',
    town: 'CHRISTCHURCH',
    county: 'Dorset',
  },
  {
    area: 'BH',
    outward: 'BH24',
    town: 'Ringwood',
    county: 'Hampshire',
  },
  {
    area: 'BH',
    outward: 'BH25',
    town: 'New Milton',
    county: 'Hampshire',
  },
  {
    area: 'BH',
    outward: 'BH31',
    town: 'Verwood',
    county: 'Dorset',
  },
  {
    area: 'BL',
    outward: 'BL0',
    town: 'Bury',
    county: 'Lancashire',
  },
  {
    area: 'BL',
    outward: 'BL1',
    town: 'Bolton',
    county: 'Lancashire',
  },
  {
    area: 'BL',
    outward: 'BL2',
    town: 'Bolton',
    county: 'Lancashire',
  },
  {
    area: 'BL',
    outward: 'BL3',
    town: 'Bolton',
    county: 'Lancashire',
  },
  {
    area: 'BL',
    outward: 'BL4',
    town: 'Bolton',
    county: 'Lancashire',
  },
  {
    area: 'BL',
    outward: 'BL5',
    town: 'Bolton',
    county: 'Lancashire',
  },
  {
    area: 'BL',
    outward: 'BL6',
    town: 'Bolton',
    county: 'Lancashire',
  },
  {
    area: 'BL',
    outward: 'BL7',
    town: 'Bolton',
    county: 'Lancashire',
  },
  {
    area: 'BL',
    outward: 'BL8',
    town: 'Bury',
    county: 'Lancashire',
  },
  {
    area: 'BL',
    outward: 'BL9',
    town: 'Bury',
    county: 'Lancashire',
  },
  {
    area: 'BL',
    outward: 'BL11',
    town: 'Non-geographic',
    county: 'Lancashire',
  },
  {
    area: 'BL',
    outward: 'BL78',
    town: 'Non-geographic',
    county: 'Lancashire',
  },
  {
    area: 'BN',
    outward: 'BN1',
    town: 'Brighton',
    county: 'East Sussex',
  },
  {
    area: 'BN',
    outward: 'BN2',
    town: 'Brighton',
    county: 'East Sussex',
  },
  {
    area: 'BN',
    outward: 'BN3',
    town: 'Hove',
    county: 'East Sussex',
  },
  {
    area: 'BN',
    outward: 'BN6',
    town: 'Hassocks',
    county: 'West Sussex',
  },
  {
    area: 'BN',
    outward: 'BN7',
    town: 'Lewes',
    county: 'East Sussex',
  },
  {
    area: 'BN',
    outward: 'BN9',
    town: 'NEWHAVEN',
    county: 'East Sussex',
  },
  {
    area: 'BN',
    outward: 'BN10',
    town: 'Peacehaven',
    county: 'East Sussex',
  },
  {
    area: 'BN',
    outward: 'BN11',
    town: 'Worthing',
    county: 'West Sussex',
  },
  {
    area: 'BN',
    outward: 'BN12',
    town: 'Worthing',
    county: 'West Sussex',
  },
  {
    area: 'BN',
    outward: 'BN13',
    town: 'Worthing',
    county: 'West Sussex',
  },
  {
    area: 'BN',
    outward: 'BN14',
    town: 'Worthing',
    county: 'West Sussex',
  },
  {
    area: 'BN',
    outward: 'BN15',
    town: 'LANCING',
    county: 'West Sussex',
  },
  {
    area: 'BN',
    outward: 'BN16',
    town: 'Littlehampton',
    county: 'West Sussex',
  },
  {
    area: 'BN',
    outward: 'BN17',
    town: 'Littlehampton',
    county: 'West Sussex',
  },
  {
    area: 'BN',
    outward: 'BN18',
    town: 'Arundel',
    county: 'West Sussex',
  },
  {
    area: 'BN',
    outward: 'BN20',
    town: 'Eastbourne',
    county: 'East Sussex',
  },
  {
    area: 'BN',
    outward: 'BN21',
    town: 'Eastbourne',
    county: 'East Sussex',
  },
  {
    area: 'BN',
    outward: 'BN22',
    town: 'Eastbourne',
    county: 'East Sussex',
  },
  {
    area: 'BN',
    outward: 'BN23',
    town: 'Eastbourne',
    county: 'East Sussex',
  },
  {
    area: 'BN',
    outward: 'BN24',
    town: 'Pevensey',
    county: 'East Sussex',
  },
  {
    area: 'BN',
    outward: 'BN25',
    town: 'SEAFORD',
    county: 'East Sussex',
  },
  {
    area: 'BN',
    outward: 'BN26',
    town: 'Polegate',
    county: 'East Sussex',
  },
  {
    area: 'BN',
    outward: 'BN27',
    town: 'Hailsham',
    county: 'East Sussex',
  },
  {
    area: 'BN',
    outward: 'BN41',
    town: 'Brighton',
    county: 'East Sussex',
  },
  {
    area: 'BN',
    outward: 'BN42',
    town: 'Brighton',
    county: 'East Sussex',
  },
  {
    area: 'BN',
    outward: 'BN43',
    town: 'Shoreham-by-Sea',
    county: 'West Sussex',
  },
  {
    area: 'BN',
    outward: 'BN44',
    town: 'Steyning',
    county: 'West Sussex',
  },
  {
    area: 'BN',
    outward: 'BN45',
    town: 'Brighton',
    county: 'East Sussex',
  },
  {
    area: 'BN',
    outward: 'BN50',
    town: 'Non-geographic',
    county: 'East Sussex',
  },
  {
    area: 'BN',
    outward: 'BN51',
    town: 'Non-geographic',
    county: 'East Sussex',
  },
  {
    area: '',
    outward: 'BN52',
    town: 'Non-geographic',
    county: 'East Sussex',
  },
  {
    area: 'BN',
    outward: 'BN88',
    town: 'Non-geographic',
    county: 'East Sussex',
  },
  {
    area: 'BN',
    outward: 'BN91',
    town: 'Non-geographic',
    county: 'West Sussex',
  },
  {
    area: 'BN',
    outward: 'BN99',
    town: 'non-geo shared',
    county: 'West Sussex',
  },
  {
    outward: 'BR1',
    town: 'Bromley, Bickley, Downham',
    county: 'Bromley',
    coordinates: [51.406271, 0.015176765],
  },
  {
    outward: 'BR2',
    town: 'Bickley, Hayes, Bromley Common, Shortlands',
    county: 'Bromley',
    coordinates: [51.407775, -0.0033205432],
  },
  {
    area: 'BR',
    outward: 'BR2',
    town: 'Bromley (shared)',
    county: 'Kent',
  },
  {
    outward: 'BR3',
    town: 'Beckenham, Eden Park, Elmers End, Park Langley, Shortlands',
    county: 'Bromley',
    coordinates: [51.407682, -0.032384606],
  },
  {
    outward: 'BR4',
    town: 'West Wickham',
    county: 'Bromley',
    coordinates: [51.380973, -0.01161506],
  },
  {
    outward: 'BR5',
    town: 'Orpington, St Mary Cray, Petts Wood',
    county: 'Bromley',
    coordinates: [51.38637, 0.064749625],
  },
  {
    outward: 'BR6',
    town: "Orpington, Farnborough, Downe, Pratt's Bottom, Chelsfield, Well Hill",
    county: 'Bromley',
    coordinates: [51.374644, 0.093074618],
  },
  {
    outward: 'BR7',
    town: 'Chislehurst, Elmstead',
    county: 'Bromley',
    coordinates: [51.416174, 0.067703211],
  },
  {
    outward: 'BR8',
    town: 'Swanley',
    county: 'Sevenoaks',
    coordinates: [51.395891, 0.17519484],
  },
  {
    outward: 'BR98',
    town: 'Non-geographic',
    county: 'Bromley',
  },
  {
    area: 'BS',
    outward: 'BS0',
    town: 'Bristol',
    county: 'Avon',
  },
  {
    area: 'BS',
    outward: 'BS1',
    town: 'Bristol',
    county: 'Avon',
  },
  {
    area: 'BS',
    outward: 'BS2',
    town: 'Bristol',
    county: 'Avon',
  },
  {
    area: 'BS',
    outward: 'BS3',
    town: 'Bristol',
    county: 'Avon',
  },
  {
    area: 'BS',
    outward: 'BS4',
    town: 'Bristol',
    county: 'Avon',
  },
  {
    area: 'BS',
    outward: 'BS5',
    town: 'Bristol',
    county: 'Avon',
  },
  {
    area: 'BS',
    outward: 'BS6',
    town: 'Bristol',
    county: 'Avon',
  },
  {
    area: 'BS',
    outward: 'BS7',
    town: 'Bristol',
    county: 'Avon',
  },
  {
    area: 'BS',
    outward: 'BS8',
    town: 'Bristol',
    county: 'Avon',
  },
  {
    area: 'BS',
    outward: 'BS9',
    town: 'Bristol',
    county: 'Avon',
  },
  {
    area: 'BS',
    outward: 'BS10',
    town: 'Bristol',
    county: 'Avon',
  },
  {
    area: 'BS',
    outward: 'BS11',
    town: 'Bristol',
    county: 'Avon',
  },
  {
    area: 'BS',
    outward: 'BS13',
    town: 'Bristol',
    county: 'Avon',
  },
  {
    area: 'BS',
    outward: 'BS14',
    town: 'Bristol',
    county: 'Avon',
  },
  {
    area: 'BS',
    outward: 'BS15',
    town: 'Bristol',
    county: 'Avon',
  },
  {
    area: 'BS',
    outward: 'BS16',
    town: 'Bristol',
    county: 'Avon',
  },
  {
    area: 'BS',
    outward: 'BS20',
    town: 'Bristol',
    county: 'Avon',
  },
  {
    area: 'BS',
    outward: 'BS21',
    town: 'Clevedon',
    county: 'Avon',
  },
  {
    area: 'BS',
    outward: 'BS22',
    town: 'Weston-super-Mare',
    county: 'Avon',
  },
  {
    area: 'BS',
    outward: 'BS23',
    town: 'Weston-super-Mare',
    county: 'Avon',
  },
  {
    area: 'BS',
    outward: 'BS24',
    town: 'Weston-super-Mare',
    county: 'Avon',
  },
  {
    area: 'BS',
    outward: 'BS25',
    town: 'Winscombe',
    county: 'Avon',
  },
  {
    area: 'BS',
    outward: 'BS26',
    town: 'Axbridge',
    county: 'Somerset',
  },
  {
    area: 'BS',
    outward: 'BS27',
    town: 'CHEDDAR',
    county: 'Somerset',
  },
  {
    area: 'BS',
    outward: 'BS28',
    town: 'Wedmore',
    county: 'Somerset',
  },
  {
    area: 'BS',
    outward: 'BS29',
    town: 'Banwell',
    county: 'Avon',
  },
  {
    area: 'BS',
    outward: 'BS30',
    town: 'Bristol',
    county: 'Avon',
  },
  {
    area: 'BS',
    outward: 'BS31',
    town: 'Bristol',
    county: 'Avon',
  },
  {
    area: 'BS',
    outward: 'BS32',
    town: 'Bristol',
    county: 'Avon',
  },
  {
    area: 'BS',
    outward: 'BS34',
    town: 'Bristol',
    county: 'Avon',
  },
  {
    area: 'BS',
    outward: 'BS35',
    town: 'Bristol',
    county: 'Avon',
  },
  {
    area: 'BS',
    outward: 'BS36',
    town: 'Bristol',
    county: 'Avon',
  },
  {
    area: 'BS',
    outward: 'BS37',
    town: 'Bristol',
    county: 'Avon',
  },
  {
    area: 'BS',
    outward: 'BS39',
    town: 'Bristol',
    county: 'Avon',
  },
  {
    area: 'BS',
    outward: 'BS40',
    town: 'Bristol',
    county: 'Avon',
  },
  {
    area: 'BS',
    outward: 'BS41',
    town: 'Bristol',
    county: 'Avon',
  },
  {
    area: 'BS',
    outward: 'BS48',
    town: 'Bristol',
    county: 'Avon',
  },
  {
    area: 'BS',
    outward: 'BS49',
    town: 'Bristol',
    county: 'Avon',
  },
  {
    area: 'BS',
    outward: 'BS80',
    town: 'Non-geographic',
    county: 'Avon',
  },
  {
    area: 'BS',
    outward: 'BS98',
    town: 'Non-geographic',
    county: 'Avon',
  },
  {
    area: 'BS',
    outward: 'BS99',
    town: 'Non-geographic',
    county: 'Avon',
  },
  {
    area: 'BT',
    outward: 'BT1',
    town: 'Belfast',
    county: 'County Antrim',
  },
  {
    area: 'BT',
    outward: 'BT2',
    town: 'Belfast',
    county: 'County Antrim',
  },
  {
    area: 'BT',
    outward: 'BT3',
    town: 'Belfast',
    county: 'County Antrim',
  },
  {
    area: 'BT',
    outward: 'BT4',
    town: 'Belfast',
    county: 'County Antrim',
  },
  {
    area: 'BT',
    outward: 'BT5',
    town: 'Belfast',
    county: 'County Antrim',
  },
  {
    area: 'BT',
    outward: 'BT6',
    town: 'Belfast',
    county: 'County Antrim',
  },
  {
    area: 'BT',
    outward: 'BT7',
    town: 'Belfast',
    county: 'County Antrim',
  },
  {
    area: 'BT',
    outward: 'BT8',
    town: 'Belfast',
    county: 'County Antrim',
  },
  {
    area: 'BT',
    outward: 'BT9',
    town: 'Belfast',
    county: 'County Antrim',
  },
  {
    area: 'BT',
    outward: 'BT10',
    town: 'Belfast',
    county: 'County Antrim',
  },
  {
    area: 'BT',
    outward: 'BT11',
    town: 'Belfast',
    county: 'County Antrim',
  },
  {
    area: 'BT',
    outward: 'BT12',
    town: 'Belfast',
    county: 'County Antrim',
  },
  {
    area: 'BT',
    outward: 'BT13',
    town: 'Belfast',
    county: 'County Antrim',
  },
  {
    area: 'BT',
    outward: 'BT14',
    town: 'Belfast',
    county: 'County Antrim',
  },
  {
    area: 'BT',
    outward: 'BT15',
    town: 'Belfast',
    county: 'County Antrim',
  },
  {
    area: 'BT',
    outward: 'BT16',
    town: 'Belfast',
    county: 'County Antrim',
  },
  {
    area: 'BT',
    outward: 'BT17',
    town: 'Belfast',
    county: 'County Antrim',
  },
  {
    area: 'BT',
    outward: 'BT18',
    town: 'Holywood',
    county: 'County Down',
  },
  {
    area: 'BT',
    outward: 'BT19',
    town: 'BANGOR',
    county: 'County Down',
  },
  {
    area: 'BT',
    outward: 'BT20',
    town: 'BANGOR',
    county: 'County Down',
  },
  {
    area: 'BT',
    outward: 'BT21',
    town: 'Donaghadee',
    county: 'County Down',
  },
  {
    area: 'BT',
    outward: 'BT22',
    town: 'Newtownards',
    county: 'County Down',
  },
  {
    area: 'BT',
    outward: 'BT23',
    town: 'Newtownards',
    county: 'County Down',
  },
  {
    area: 'BT',
    outward: 'BT24',
    town: 'BALLYNAHINCH',
    county: 'County Down',
  },
  {
    area: 'BT',
    outward: 'BT25',
    town: 'DROMORE',
    county: 'County Down',
  },
  {
    area: 'BT',
    outward: 'BT26',
    town: 'HILLSBOROUGH',
    county: 'County Down',
  },
  {
    area: 'BT',
    outward: 'BT27',
    town: 'Lisburn',
    county: 'County Antrim',
  },
  {
    area: 'BT',
    outward: 'BT28',
    town: 'Lisburn',
    county: 'County Antrim',
  },
  {
    area: 'BT',
    outward: 'BT29',
    town: 'Belfast',
    county: 'County Antrim',
  },
  {
    area: 'BT',
    outward: 'BT30',
    town: 'Downpatrick',
    county: 'County Down',
  },
  {
    area: 'BT',
    outward: 'BT31',
    town: 'Castlewellan',
    county: 'County Down',
  },
  {
    area: 'BT',
    outward: 'BT32',
    town: 'Banbridge',
    county: 'County Down',
  },
  {
    area: 'BT',
    outward: 'BT33',
    town: 'NEWCASTLE',
    county: 'County Down',
  },
  {
    area: 'BT',
    outward: 'BT34',
    town: 'Newry',
    county: 'County Down',
  },
  {
    area: 'BT',
    outward: 'BT35',
    town: 'Newry',
    county: 'County Armagh',
  },
  {
    area: 'BT',
    outward: 'BT36',
    town: 'Newtownabbey',
    county: 'County Antrim',
  },
  {
    area: 'BT',
    outward: 'BT37',
    town: 'Newtownabbey',
    county: 'County Antrim',
  },
  {
    area: 'BT',
    outward: 'BT38',
    town: 'Carrickfergus',
    county: 'County Antrim',
  },
  {
    area: 'BT',
    outward: 'BT39',
    town: 'Ballyclare',
    county: 'County Antrim',
  },
  {
    area: 'BT',
    outward: 'BT40',
    town: 'Larne',
    county: 'County Antrim',
  },
  {
    area: 'BT',
    outward: 'BT41',
    town: 'ANTRIM',
    county: 'County Antrim',
  },
  {
    area: 'BT',
    outward: 'BT42',
    town: 'Ballymena',
    county: 'County Antrim',
  },
  {
    area: 'BT',
    outward: 'BT43',
    town: 'Ballymena',
    county: 'County Antrim',
  },
  {
    area: 'BT',
    outward: 'BT44',
    town: 'Ballymena',
    county: 'County Antrim',
  },
  {
    area: 'BT',
    outward: 'BT45',
    town: 'Magherafelt',
    county: 'County Londonderry',
  },
  {
    area: 'BT',
    outward: 'BT46',
    town: 'Maghera',
    county: 'County Londonderry',
  },
  {
    area: 'BT',
    outward: 'BT47',
    town: 'LONDONDERRY',
    county: 'County Londonderry',
  },
  {
    area: 'BT',
    outward: 'BT48',
    town: 'LONDONDERRY',
    county: 'County Londonderry',
  },
  {
    area: 'BT',
    outward: 'BT49',
    town: 'Limavady',
    county: 'County Londonderry',
  },
  {
    area: 'BT',
    outward: 'BT51',
    town: 'Coleraine',
    county: 'County Londonderry',
  },
  {
    area: 'BT',
    outward: 'BT52',
    town: 'Coleraine',
    county: 'County Londonderry',
  },
  {
    area: 'BT',
    outward: 'BT53',
    town: 'Ballymoney',
    county: 'County Antrim',
  },
  {
    area: 'BT',
    outward: 'BT54',
    town: 'BALLYCASTLE',
    county: 'County Antrim',
  },
  {
    area: 'BT',
    outward: 'BT55',
    town: 'Portstewart',
    county: 'County Londonderry',
  },
  {
    area: 'BT',
    outward: 'BT56',
    town: 'Portrush',
    county: 'County Antrim',
  },
  {
    area: 'BT',
    outward: 'BT57',
    town: 'Bushmills',
    county: 'County Antrim',
  },
  {
    area: 'BT',
    outward: 'BT58',
    town: 'Non-geographic',
    county: 'County Antrim',
  },
  {
    area: 'BT',
    outward: 'BT60',
    town: 'Armagh',
    county: 'County Armagh',
  },
  {
    area: 'BT',
    outward: 'BT61',
    town: 'Armagh',
    county: 'County Armagh',
  },
  {
    area: 'BT',
    outward: 'BT62',
    town: 'Craigavon',
    county: 'County Armagh',
  },
  {
    area: 'BT',
    outward: 'BT63',
    town: 'Craigavon',
    county: 'County Armagh',
  },
  {
    area: 'BT',
    outward: 'BT64',
    town: 'Craigavon',
    county: 'County Armagh',
  },
  {
    area: 'BT',
    outward: 'BT65',
    town: 'Craigavon',
    county: 'County Armagh',
  },
  {
    area: 'BT',
    outward: 'BT66',
    town: 'Craigavon',
    county: 'County Armagh',
  },
  {
    area: 'BT',
    outward: 'BT67',
    town: 'Craigavon',
    county: 'County Armagh',
  },
  {
    area: 'BT',
    outward: 'BT68',
    town: 'CALEDON',
    county: 'County Tyrone',
  },
  {
    area: 'BT',
    outward: 'BT69',
    town: 'AUGHNACLOY',
    county: 'County Tyrone',
  },
  {
    area: 'BT',
    outward: 'BT70',
    town: 'Dungannon',
    county: 'County Tyrone',
  },
  {
    area: 'BT',
    outward: 'BT71',
    town: 'Dungannon',
    county: 'County Tyrone',
  },
  {
    area: 'BT',
    outward: 'BT74',
    town: 'Enniskillen',
    county: 'County Fermanagh',
  },
  {
    area: 'BT',
    outward: 'BT75',
    town: 'Fivemiletown',
    county: 'County Tyrone',
  },
  {
    area: 'BT',
    outward: 'BT76',
    town: 'Clogher',
    county: 'County Tyrone',
  },
  {
    area: 'BT',
    outward: 'BT77',
    town: 'Augher',
    county: 'County Tyrone',
  },
  {
    area: 'BT',
    outward: 'BT78',
    town: 'Omagh',
    county: 'County Tyrone',
  },
  {
    area: 'BT',
    outward: 'BT79',
    town: 'Omagh',
    county: 'County Tyrone',
  },
  {
    area: 'BT',
    outward: 'BT80',
    town: 'Cookstown',
    county: 'County Tyrone',
  },
  {
    area: 'BT',
    outward: 'BT81',
    town: 'Castlederg',
    county: 'County Tyrone',
  },
  {
    area: 'BT',
    outward: 'BT82',
    town: 'Strabane',
    county: 'County Tyrone',
  },
  {
    area: 'BT',
    outward: 'BT92',
    town: 'Enniskillen',
    county: 'County Fermanagh',
  },
  {
    area: 'BT',
    outward: 'BT93',
    town: 'Enniskillen',
    county: 'County Fermanagh',
  },
  {
    area: 'BT',
    outward: 'BT94',
    town: 'Enniskillen',
    county: 'County Fermanagh',
  },
  {
    area: 'CA',
    outward: 'CA1',
    town: 'CARLISLE',
    county: 'Cumbria',
  },
  {
    area: 'CA',
    outward: 'CA2',
    town: 'CARLISLE',
    county: 'Cumbria',
  },
  {
    area: 'CA',
    outward: 'CA3',
    town: 'CARLISLE',
    county: 'Cumbria',
  },
  {
    area: 'CA',
    outward: 'CA4',
    town: 'CARLISLE',
    county: 'Cumbria',
  },
  {
    area: 'CA',
    outward: 'CA5',
    town: 'CARLISLE',
    county: 'Cumbria',
  },
  {
    area: 'CA',
    outward: 'CA6',
    town: 'CARLISLE',
    county: 'Cumbria',
  },
  {
    area: 'CA',
    outward: 'CA7',
    town: 'Wigton',
    county: 'Cumbria',
  },
  {
    area: 'CA',
    outward: 'CA8',
    town: 'BRAMPTON',
    county: 'Cumbria',
  },
  {
    area: 'CA',
    outward: 'CA10',
    town: 'PENRITH',
    county: 'Cumbria',
  },
  {
    area: 'CA',
    outward: 'CA11',
    town: 'PENRITH',
    county: 'Cumbria',
  },
  {
    area: 'CA',
    outward: 'CA12',
    town: 'KESWICK',
    county: 'Cumbria',
  },
  {
    area: 'CA',
    outward: 'CA13',
    town: 'Cockermouth',
    county: 'Cumbria',
  },
  {
    area: 'CA',
    outward: 'CA14',
    town: 'Workington',
    county: 'Cumbria',
  },
  {
    area: 'CA',
    outward: 'CA15',
    town: 'Maryport',
    county: 'Cumbria',
  },
  {
    area: 'CA',
    outward: 'CA16',
    town: 'Appleby-in-Westmorland',
    county: 'Cumbria',
  },
  {
    area: 'CA',
    outward: 'CA17',
    town: 'Kirkby Stephen',
    county: 'Cumbria',
  },
  {
    area: 'CA',
    outward: 'CA18',
    town: 'Ravenglass',
    county: 'Cumbria',
  },
  {
    area: 'CA',
    outward: 'CA19',
    town: 'Holmrook',
    county: 'Cumbria',
  },
  {
    area: 'CA',
    outward: 'CA20',
    town: 'Seascale',
    county: 'Cumbria',
  },
  {
    area: 'CA',
    outward: 'CA21',
    town: 'Beckermet',
    county: 'Cumbria',
  },
  {
    area: 'CA',
    outward: 'CA22',
    town: 'EGREMONT',
    county: 'Cumbria',
  },
  {
    area: 'CA',
    outward: 'CA23',
    town: 'Cleator',
    county: 'Cumbria',
  },
  {
    area: 'CA',
    outward: 'CA24',
    town: 'Moor Row',
    county: 'Cumbria',
  },
  {
    area: 'CA',
    outward: 'CA25',
    town: 'Cleator Moor',
    county: 'Cumbria',
  },
  {
    area: 'CA',
    outward: 'CA26',
    town: 'Frizington',
    county: 'Cumbria',
  },
  {
    area: 'CA',
    outward: 'CA27',
    town: 'ST. BEES',
    county: 'Cumbria',
  },
  {
    area: 'CA',
    outward: 'CA28',
    town: 'Whitehaven',
    county: 'Cumbria',
  },
  {
    area: 'CA',
    outward: 'CA95',
    town: 'Non-geographic',
    county: 'Cumbria',
  },
  {
    area: 'CA',
    outward: 'CA99',
    town: 'Non-geographic',
    county: 'Cumbria',
  },
  {
    area: 'CB',
    outward: 'CB1',
    town: 'Cambridge',
    county: 'Cambridgeshire',
  },
  {
    area: 'CB',
    outward: 'CB2',
    town: 'Cambridge',
    county: 'Cambridgeshire',
  },
  {
    area: 'CB',
    outward: 'CB3',
    town: 'Cambridge',
    county: 'Cambridgeshire',
  },
  {
    area: 'CB',
    outward: 'CB4',
    town: 'Cambridge',
    county: 'Cambridgeshire',
  },
  {
    area: 'CB',
    outward: 'CB5',
    town: 'Cambridge',
    county: 'Cambridgeshire',
  },
  {
    area: 'CB',
    outward: 'CB6',
    town: 'ELY',
    county: 'Cambridgeshire',
  },
  {
    area: 'CB',
    outward: 'CB7',
    town: 'ELY',
    county: 'Cambridgeshire',
  },
  {
    area: 'CB',
    outward: 'CB8',
    town: 'NEWMARKET',
    county: 'Suffolk',
  },
  {
    area: 'CB',
    outward: 'CB9',
    town: 'HAVERHILL',
    county: 'Suffolk',
  },
  {
    area: 'CB',
    outward: 'CB10',
    town: 'Saffron Walden',
    county: 'Essex',
  },
  {
    area: 'CB',
    outward: 'CB11',
    town: 'Saffron Walden',
    county: 'Essex',
  },
  {
    area: 'CB',
    outward: 'CB21',
    town: 'Cambridge',
    county: 'Cambridgeshire',
  },
  {
    area: 'CB',
    outward: 'CB22',
    town: 'Cambridge',
    county: 'Cambridgeshire',
  },
  {
    area: 'CB',
    outward: 'CB23',
    town: 'Cambridge',
    county: 'Cambridgeshire',
  },
  {
    area: 'CB',
    outward: 'CB24',
    town: 'Cambridge',
    county: 'Cambridgeshire',
  },
  {
    area: 'CB',
    outward: 'CB25',
    town: 'Cambridge',
    county: 'Cambridgeshire',
  },
  {
    area: 'CF',
    outward: 'CF3',
    town: 'Cardiff',
    county: 'South Glamorgan',
  },
  {
    area: 'CF',
    outward: 'CF5',
    town: 'Cardiff',
    county: 'South Glamorgan',
  },
  {
    area: 'CF',
    outward: 'CF10',
    town: 'Cardiff',
    county: 'South Glamorgan',
  },
  {
    area: 'CF',
    outward: 'CF11',
    town: 'Cardiff',
    county: 'South Glamorgan',
  },
  {
    area: 'CF',
    outward: 'CF14',
    town: 'Cardiff',
    county: 'South Glamorgan',
  },
  {
    area: 'CF',
    outward: 'CF15',
    town: 'Cardiff',
    county: 'South Glamorgan',
  },
  {
    area: 'CF',
    outward: 'CF23',
    town: 'Cardiff',
    county: 'South Glamorgan',
  },
  {
    area: 'CF',
    outward: 'CF24',
    town: 'Cardiff',
    county: 'South Glamorgan',
  },
  {
    area: 'CF',
    outward: 'CF30',
    town: 'Non-geographic',
    county: 'South Glamorgan',
  },
  {
    area: 'CF',
    outward: 'CF31',
    town: 'Bridgend',
    county: 'Mid Glamorgan',
  },
  {
    area: 'CF',
    outward: 'CF32',
    town: 'Bridgend',
    county: 'Mid Glamorgan',
  },
  {
    area: 'CF',
    outward: 'CF33',
    town: 'Bridgend',
    county: 'Mid Glamorgan',
  },
  {
    area: 'CF',
    outward: 'CF34',
    town: 'Maesteg',
    county: 'Mid Glamorgan',
  },
  {
    area: 'CF',
    outward: 'CF35',
    town: 'Bridgend',
    county: 'Mid Glamorgan',
  },
  {
    area: 'CF',
    outward: 'CF36',
    town: 'Porthcawl',
    county: 'Mid Glamorgan',
  },
  {
    area: 'CF',
    outward: 'CF37',
    town: 'Pontypridd',
    county: 'Mid Glamorgan',
  },
  {
    area: 'CF',
    outward: 'CF38',
    town: 'Pontypridd',
    county: 'Mid Glamorgan',
  },
  {
    area: 'CF',
    outward: 'CF39',
    town: 'Porth',
    county: 'Mid Glamorgan',
  },
  {
    area: 'CF',
    outward: 'CF40',
    town: 'Tonypandy',
    county: 'Mid Glamorgan',
  },
  {
    area: 'CF',
    outward: 'CF41',
    town: 'Pentre',
    county: 'Mid Glamorgan',
  },
  {
    area: 'CF',
    outward: 'CF42',
    town: 'Treorchy',
    county: 'Mid Glamorgan',
  },
  {
    area: 'CF',
    outward: 'CF43',
    town: 'FERNDALE',
    county: 'Mid Glamorgan',
  },
  {
    area: 'CF',
    outward: 'CF44',
    town: 'Aberdare',
    county: 'Mid Glamorgan',
  },
  {
    area: 'CF',
    outward: 'CF45',
    town: 'MOUNTAIN ASH',
    county: 'Mid Glamorgan',
  },
  {
    area: 'CF',
    outward: 'CF46',
    town: 'Treharris',
    county: 'Mid Glamorgan',
  },
  {
    area: 'CF',
    outward: 'CF47',
    town: 'Merthyr Tydfil',
    county: 'Mid Glamorgan',
  },
  {
    area: 'CF',
    outward: 'CF48',
    town: 'Merthyr Tydfil',
    county: 'Mid Glamorgan',
  },
  {
    area: 'CF',
    outward: 'CF61',
    town: 'Llantwit Major',
    county: 'South Glamorgan',
  },
  {
    area: 'CF',
    outward: 'CF62',
    town: 'BARRY',
    county: 'South Glamorgan',
  },
  {
    area: 'CF',
    outward: 'CF63',
    town: 'BARRY',
    county: 'South Glamorgan',
  },
  {
    area: 'CF',
    outward: 'CF64',
    town: 'Dinas Powys (shared)',
    county: 'South Glamorgan',
  },
  {
    area: 'CF',
    outward: 'CF71',
    town: 'Llantwit Major (shared)',
    county: 'South Glamorgan',
  },
  {
    area: 'CF',
    outward: 'CF72',
    town: 'Pontyclun',
    county: 'Mid Glamorgan',
  },
  {
    area: 'CF',
    outward: 'CF81',
    town: 'Bargoed',
    county: 'Mid Glamorgan',
  },
  {
    area: 'CF',
    outward: 'CF82',
    town: 'Hengoed',
    county: 'Mid Glamorgan',
  },
  {
    area: 'CF',
    outward: 'CF83',
    town: 'Caerphilly',
    county: 'Mid Glamorgan',
  },
  {
    area: 'CF',
    outward: 'CF91',
    town: 'Non-geographic',
    county: 'South Glamorgan',
  },
  {
    area: 'CF',
    outward: 'CF95',
    town: 'Non-geographic',
    county: 'South Glamorgan',
  },
  {
    area: 'CF',
    outward: 'CF99',
    town: 'Non-geographic',
    county: 'South Glamorgan',
  },
  {
    area: 'CH',
    outward: 'CH1',
    town: 'Chester',
    county: 'Cheshire',
  },
  {
    area: 'CH',
    outward: 'CH2',
    town: 'Chester',
    county: 'Cheshire',
  },
  {
    area: 'CH',
    outward: 'CH3',
    town: 'Chester',
    county: 'Cheshire',
  },
  {
    area: 'CH',
    outward: 'CH4',
    town: 'Chester',
    county: 'Cheshire',
  },
  {
    area: 'CH',
    outward: 'CH5',
    town: 'Deeside',
    county: 'Clwyd',
  },
  {
    area: 'CH',
    outward: 'CH6',
    town: 'Bagillt (shared)',
    county: 'Clwyd',
  },
  {
    area: 'CH',
    outward: 'CH7',
    town: 'Buckley (shared)',
    county: 'Clwyd',
  },
  {
    area: 'CH',
    outward: 'CH25',
    town: 'Non-geographic',
    county: 'Merseyside',
  },
  {
    area: 'CH',
    outward: 'CH26',
    town: 'Non-geographic',
    county: 'Merseyside',
  },
  {
    area: 'CH',
    outward: 'CH27',
    town: 'Non-geographic',
    county: 'Merseyside',
  },
  {
    area: 'CH',
    outward: 'CH28',
    town: 'Non-geographic',
    county: 'Merseyside',
  },
  {
    area: 'CH',
    outward: 'CH29',
    town: 'Non-geographic',
    county: 'Merseyside',
  },
  {
    area: 'CH',
    outward: 'CH30',
    town: 'Non-geographic',
    county: 'Merseyside',
  },
  {
    area: 'CH',
    outward: 'CH31',
    town: 'Non-geographic',
    county: 'Merseyside',
  },
  {
    area: 'CH',
    outward: 'CH32',
    town: 'Non-geographic',
    county: 'Merseyside',
  },
  {
    area: 'CH',
    outward: 'CH33',
    town: 'Non-geographic',
    county: 'Merseyside',
  },
  {
    area: 'CH',
    outward: 'CH34',
    town: 'Non-geographic',
    county: 'Merseyside',
  },
  {
    area: 'CH',
    outward: 'CH41',
    town: 'Birkenhead',
    county: 'Merseyside',
  },
  {
    area: 'CH',
    outward: 'CH42',
    town: 'Birkenhead',
    county: 'Merseyside',
  },
  {
    area: 'CH',
    outward: 'CH43',
    town: 'Prenton',
    county: 'Merseyside',
  },
  {
    area: 'CH',
    outward: 'CH44',
    town: 'Wallasey',
    county: 'Merseyside',
  },
  {
    area: 'CH',
    outward: 'CH45',
    town: 'Wallasey',
    county: 'Merseyside',
  },
  {
    area: 'CH',
    outward: 'CH46',
    town: 'WIRRAL',
    county: 'Merseyside',
  },
  {
    area: 'CH',
    outward: 'CH47',
    town: 'WIRRAL',
    county: 'Merseyside',
  },
  {
    area: 'CH',
    outward: 'CH48',
    town: 'WIRRAL',
    county: 'Merseyside',
  },
  {
    area: 'CH',
    outward: 'CH49',
    town: 'WIRRAL',
    county: 'Merseyside',
  },
  {
    area: 'CH',
    outward: 'CH60',
    town: 'WIRRAL',
    county: 'Merseyside',
  },
  {
    area: 'CH',
    outward: 'CH61',
    town: 'WIRRAL',
    county: 'Merseyside',
  },
  {
    area: 'CH',
    outward: 'CH62',
    town: 'WIRRAL',
    county: 'Merseyside',
  },
  {
    area: 'CH',
    outward: 'CH63',
    town: 'WIRRAL',
    county: 'Merseyside',
  },
  {
    area: '',
    outward: 'CH64',
    town: 'Neston',
    county: 'Merseyside',
  },
  {
    area: '',
    outward: 'CH65',
    town: 'Ellesmere Port',
    county: 'Merseyside',
  },
  {
    area: '',
    outward: 'CH66',
    town: 'Ellesmere Port',
    county: 'Merseyside',
  },
  {
    area: 'CH',
    outward: 'CH70',
    town: 'Non-geographic',
    county: 'Cheshire',
  },
  {
    area: 'CH',
    outward: 'CH88',
    town: 'Non-geographic',
    county: 'Cheshire',
  },
  {
    area: 'CH',
    outward: 'CH99',
    town: 'Non-geographic',
    county: 'Cheshire',
  },
  {
    outward: 'CM0',
    town: 'Southminster, Bradwell, Tillingham, Asheldham, Dengie, Burnham-on-Crouch, Tillingham',
    county: 'Maldon',
    coordinates: [51.662523, 0.82912161],
  },
  {
    outward: 'CM1',
    town: 'Chelmsford, Writtle',
    county: 'Chelmsford',
    coordinates: [51.737213, 0.47599066],
  },
  {
    outward: 'CM2',
    town: 'Chelmsford',
    county: 'Chelmsford',
    coordinates: [51.728372, 0.46705003],
  },
  {
    outward: 'CM3',
    town: 'Hatfield Peverel, South Woodham Ferrers, Boreham, Maylandsea',
    county: 'Chelmsford',
    coordinates: [51.785623, 0.46869429],
  },
  {
    outward: 'CM4',
    town: 'Blackmore, Fryerning, Ingatestone, Stock',
    county: 'Brentwood',
    coordinates: [51.670158, 0.38267486],
  },
  {
    outward: 'CM5',
    town: 'Chipping Ongar, High Ongar, Bobbingworth, Moreton, The Lavers, The Rodings',
    county: 'Epping Forest',
    coordinates: [51.710728, 0.24418956],
  },
  {
    outward: 'CM6',
    town: 'Great Dunmow, Felsted',
    county: 'Uttlesford',
    coordinates: [51.872315, 0.36273828],
  },
  {
    outward: 'CM7',
    town: 'Braintree',
    county: 'Braintree',
    coordinates: [51.876265, 0.56645693],
  },
  {
    outward: 'CM8',
    town: 'Witham',
    county: 'Braintree',
    coordinates: [51.798703, 0.63901033],
  },
  {
    outward: 'CM9',
    town: "Maldon, Tollesbury, Tolleshunt D'Arcy, Tolleshunt Knights",
    county: 'Maldon',
    coordinates: [51.741545, 0.69003036],
  },
  {
    outward: 'CM11',
    town: 'Billericay (East), Great Burstead',
    county: 'Basildon',
    coordinates: [51.634322, 0.42693975],
  },
  {
    outward: 'CM12',
    town: 'Billericay (West)',
    county: 'Basildon',
    coordinates: [51.62913, 0.41923528],
  },
  {
    outward: 'CM13',
    town: 'Brentwood, East Horndon, Great Warley, Herongate, Hutton, Ingrave, Little Warley, West Horndon',
    county: 'Brentwood',
    coordinates: [51.631749, 0.33343636],
  },
  {
    outward: 'CM14',
    town: 'Brentwood',
    county: 'Brentwood',
    coordinates: [51.620306, 0.30359417],
  },
  {
    outward: 'CM15',
    town: 'Brentwood, Doddinghurst, Kelvedon Hatch, Mountnessing, Shenfield, Stondon Massey',
    county: 'Brentwood',
    coordinates: [51.665971, 0.26946763],
  },
  {
    outward: 'CM16',
    town: 'Epping, Theydon Bois',
    county: 'Epping Forest',
    coordinates: [51.700041, 0.11300739],
  },
  {
    outward: 'CM17',
    town: 'Harlow, Old Harlow, Matching, Matching Tye, Matching Green',
    county: 'Harlow',
    coordinates: [51.783171, 0.13225829],
  },
  {
    outward: 'CM18',
    town: 'Harlow',
    county: 'Harlow',
    coordinates: [51.764472, 0.10352242],
  },
  {
    outward: 'CM19',
    town: 'Harlow, Roydon',
    county: 'Harlow',
    coordinates: [51.76786, 0.090012568],
  },
  {
    outward: 'CM20',
    town: 'Harlow',
    county: 'Harlow',
    coordinates: [51.771747, 0.093946281],
  },
  {
    outward: 'CM21',
    town: 'Sawbridgeworth',
    county: 'East Hertfordshire',
    coordinates: [51.803615, 0.13748744],
  },
  {
    outward: 'CM22',
    town: "Bishop's Stortford, Sheering",
    county: 'East Hertfordshire',
    coordinates: [51.924558, 0.24583864],
  },
  {
    outward: 'CM23',
    town: "Bishop's Stortford",
    county: 'East Hertfordshire',
    coordinates: [51.937084, 0.15152218],
  },
  {
    outward: 'CM24',
    town: 'Stansted Mountfitchet',
    county: 'Uttlesford',
    coordinates: [51.880949, 0.21909359],
  },
  {
    outward: 'CM77',
    town: 'Braintree, Great Notley, Rayne',
    county: 'Braintree',
    coordinates: [51.878201, 0.50801094],
  },
  {
    outward: 'CM92',
    town: 'Non-geographic',
    county: 'Harlow',
    coordinates: [51.769357, 0.061253245],
  },
  {
    outward: 'CM98',
    town: 'Non-geographic',
    county: 'Chelmsford',
    coordinates: [51.752184, 0.51884349],
  },
  {
    outward: 'CM99',
    town: 'Chelmsford',
    county: 'Chelmsford',
    coordinates: [51.737213, 0.47599066],
  },
  {
    area: 'CO',
    outward: 'CO1',
    town: 'Colchester',
    county: 'Essex',
  },
  {
    area: 'CO',
    outward: 'CO2',
    town: 'Colchester',
    county: 'Essex',
  },
  {
    area: 'CO',
    outward: 'CO3',
    town: 'Colchester',
    county: 'Essex',
  },
  {
    area: 'CO',
    outward: 'CO4',
    town: 'Colchester',
    county: 'Essex',
  },
  {
    area: 'CO',
    outward: 'CO5',
    town: 'Colchester',
    county: 'Essex',
  },
  {
    area: 'CO',
    outward: 'CO6',
    town: 'Colchester',
    county: 'Essex',
  },
  {
    area: 'CO',
    outward: 'CO7',
    town: 'Colchester',
    county: 'Essex',
  },
  {
    area: 'CO',
    outward: 'CO8',
    town: 'BURES',
    county: 'Suffolk',
  },
  {
    area: 'CO',
    outward: 'CO9',
    town: 'Halstead',
    county: 'Essex',
  },
  {
    area: 'CO',
    outward: 'CO10',
    town: 'SUDBURY',
    county: 'Suffolk',
  },
  {
    area: 'CO',
    outward: 'CO11',
    town: 'Manningtree',
    county: 'Essex',
  },
  {
    area: 'CO',
    outward: 'CO12',
    town: 'Harwich',
    county: 'Essex',
  },
  {
    area: 'CO',
    outward: 'CO13',
    town: 'Frinton-on-Sea',
    county: 'Essex',
  },
  {
    area: 'CO',
    outward: 'CO14',
    town: 'Walton-on-the-Naze',
    county: 'Essex',
  },
  {
    area: 'CO',
    outward: 'CO15',
    town: 'Clacton-on-Sea',
    county: 'Essex',
  },
  {
    area: 'CO',
    outward: 'CO16',
    town: 'Clacton-on-Sea',
    county: 'Essex',
  },
  {
    outward: 'CR0',
    town: 'Croydon, Addiscombe, Shirley, Addington, New Addington, Forestdale, Waddon',
    county: 'Croydon',
    coordinates: [51.34034, -0.0050608257],
  },
  {
    outward: 'CR2',
    town: 'South Croydon, Sanderstead, Selsdon, Addington',
    county: 'Croydon',
    coordinates: [51.347927, -0.089571656],
  },
  {
    outward: 'CR3',
    town: 'Caterham, Whyteleafe, Chaldon, Woldingham',
    county: 'Croydon',
    coordinates: [51.307715, -0.083207501],
  },
  {
    outward: 'CR4',
    town: 'Mitcham, Beddington Corner',
    county: 'Merton',
    coordinates: [51.410383, -0.14732096],
  },
  {
    outward: 'CR5',
    town: 'Coulsdon, Chipstead, Woodmansterne',
    county: 'Croydon',
    coordinates: [51.313816, -0.13634763],
  },
  {
    outward: 'CR6',
    town: 'Warlingham, Chelsham, Farleigh',
    county: 'Croydon',
    coordinates: [51.279515, -0.074000301],
  },
  {
    outward: 'CR7',
    town: 'Thornton Heath',
    county: 'Croydon',
    coordinates: [51.394377, -0.11579722],
  },
  {
    outward: 'CR8',
    town: 'Purley, Kenley',
    county: 'Croydon',
    coordinates: [51.33853, -0.11065113],
  },
  {
    area: 'CR',
    outward: 'CR8',
    town: 'Kenley (shared)',
    county: 'Surrey',
  },
  {
    outward: 'CR9',
    town: 'Non-geographic',
    county: 'Croydon',
    coordinates: [51.333332, -0.0038720438],
  },
  {
    outward: 'CR44',
    town: 'Non-geographic',
    county: 'Croydon',
    coordinates: [51.382348, -0.13287528],
  },
  {
    outward: 'CR90',
    town: 'Non-geographic',
    county: 'Croydon',
    coordinates: [51.375716, -0.091863492],
  },
  {
    area: 'CT',
    outward: 'CT1',
    town: 'Canterbury',
    county: 'Kent',
  },
  {
    area: 'CT',
    outward: 'CT2',
    town: 'Canterbury',
    county: 'Kent',
  },
  {
    area: 'CT',
    outward: 'CT3',
    town: 'Canterbury',
    county: 'Kent',
  },
  {
    area: 'CT',
    outward: 'CT4',
    town: 'Canterbury',
    county: 'Kent',
  },
  {
    area: 'CT',
    outward: 'CT5',
    town: 'Whitstable',
    county: 'Kent',
  },
  {
    area: 'CT',
    outward: 'CT6',
    town: 'HERNE BAY',
    county: 'Kent',
  },
  {
    area: 'CT',
    outward: 'CT7',
    town: 'BIRCHINGTON',
    county: 'Kent',
  },
  {
    area: 'CT',
    outward: 'CT8',
    town: 'Westgate-on-Sea',
    county: 'Kent',
  },
  {
    area: 'CT',
    outward: 'CT9',
    town: 'Non-geographic',
    county: 'Kent',
  },
  {
    area: 'CT',
    outward: 'CT10',
    town: 'Broadstairs',
    county: 'Kent',
  },
  {
    area: 'CT',
    outward: 'CT11',
    town: 'Ramsgate',
    county: 'Kent',
  },
  {
    area: 'CT',
    outward: 'CT12',
    town: 'Ramsgate',
    county: 'Kent',
  },
  {
    area: 'CT',
    outward: 'CT13',
    town: 'SANDWICH',
    county: 'Kent',
  },
  {
    area: 'CT',
    outward: 'CT14',
    town: 'DEAL',
    county: 'Kent',
  },
  {
    area: 'CT',
    outward: 'CT15',
    town: 'Dover',
    county: 'Kent',
  },
  {
    area: 'CT',
    outward: 'CT16',
    town: 'Dover',
    county: 'Kent',
  },
  {
    area: 'CT',
    outward: 'CT17',
    town: 'Dover',
    county: 'Kent',
  },
  {
    area: 'CT',
    outward: 'CT18',
    town: 'Folkestone',
    county: 'Kent',
  },
  {
    area: 'CT',
    outward: 'CT19',
    town: 'Folkestone',
    county: 'Kent',
  },
  {
    area: 'CT',
    outward: 'CT20',
    town: 'Folkestone',
    county: 'Kent',
  },
  {
    area: 'CT',
    outward: 'CT21',
    town: 'HYTHE',
    county: 'Kent',
  },
  {
    area: 'CT',
    outward: 'CT50',
    town: 'Non-geographic',
    county: 'Kent',
  },
  {
    area: 'CV',
    outward: 'CV1',
    town: 'Coventry',
    county: 'West Midlands',
  },
  {
    area: 'CV',
    outward: 'CV2',
    town: 'Coventry',
    county: 'West Midlands',
  },
  {
    area: 'CV',
    outward: 'CV3',
    town: 'Coventry',
    county: 'West Midlands',
  },
  {
    area: 'CV',
    outward: 'CV4',
    town: 'Coventry',
    county: 'West Midlands',
  },
  {
    area: 'CV',
    outward: 'CV5',
    town: 'Coventry',
    county: 'West Midlands',
  },
  {
    area: 'CV',
    outward: 'CV6',
    town: 'Coventry',
    county: 'West Midlands',
  },
  {
    area: 'CV',
    outward: 'CV7',
    town: 'Coventry',
    county: 'West Midlands',
  },
  {
    area: 'CV',
    outward: 'CV8',
    town: 'Coventry (shared)',
    county: 'West Midlands',
  },
  {
    area: 'CV',
    outward: 'CV9',
    town: 'Atherstone',
    county: 'Warwickshire',
  },
  {
    area: 'CV',
    outward: 'CV10',
    town: 'Nuneaton',
    county: 'Warwickshire',
  },
  {
    area: 'CV',
    outward: 'CV11',
    town: 'Nuneaton',
    county: 'Warwickshire',
  },
  {
    area: 'CV',
    outward: 'CV12',
    town: 'Bedworth',
    county: 'Warwickshire',
  },
  {
    area: 'CV',
    outward: 'CV13',
    town: 'Nuneaton',
    county: 'Warwickshire',
  },
  {
    area: 'CV',
    outward: 'CV21',
    town: 'RUGBY',
    county: 'Warwickshire',
  },
  {
    area: 'CV',
    outward: 'CV22',
    town: 'RUGBY',
    county: 'Warwickshire',
  },
  {
    area: 'CV',
    outward: 'CV23',
    town: 'RUGBY',
    county: 'Warwickshire',
  },
  {
    area: 'CV',
    outward: 'CV31',
    town: 'LEAMINGTON SPA',
    county: 'Warwickshire',
  },
  {
    area: 'CV',
    outward: 'CV32',
    town: 'LEAMINGTON SPA',
    county: 'Warwickshire',
  },
  {
    area: 'CV',
    outward: 'CV33',
    town: 'LEAMINGTON SPA',
    county: 'Warwickshire',
  },
  {
    area: 'CV',
    outward: 'CV34',
    town: 'Warwick',
    county: 'Warwickshire',
  },
  {
    area: 'CV',
    outward: 'CV35',
    town: 'Warwick',
    county: 'Warwickshire',
  },
  {
    area: 'CV',
    outward: 'CV36',
    town: 'Shipston-on-Stour',
    county: 'Warwickshire',
  },
  {
    area: 'CV',
    outward: 'CV37',
    town: 'Shipston-on-Stour (shared)',
    county: 'Warwickshire',
  },
  {
    area: 'CV',
    outward: 'CV47',
    town: 'Southam',
    county: 'Warwickshire',
  },
  {
    area: 'CW',
    outward: 'CW1',
    town: 'Crewe',
    county: 'Cheshire',
  },
  {
    area: 'CW',
    outward: 'CW2',
    town: 'Crewe',
    county: 'Cheshire',
  },
  {
    area: 'CW',
    outward: 'CW3',
    town: 'Crewe',
    county: 'Cheshire',
  },
  {
    area: 'CW',
    outward: 'CW4',
    town: 'Crewe',
    county: 'Cheshire',
  },
  {
    area: 'CW',
    outward: 'CW5',
    town: 'Nantwich',
    county: 'Cheshire',
  },
  {
    area: 'CW',
    outward: 'CW6',
    town: 'Tarporley',
    county: 'Cheshire',
  },
  {
    area: 'CW',
    outward: 'CW7',
    town: 'Winsford',
    county: 'Cheshire',
  },
  {
    area: 'CW',
    outward: 'CW8',
    town: 'Northwich',
    county: 'Cheshire',
  },
  {
    area: 'CW',
    outward: 'CW10',
    town: 'Middlewich',
    county: 'Cheshire',
  },
  {
    area: 'CW',
    outward: 'CW11',
    town: 'Sandbach',
    county: 'Cheshire',
  },
  {
    area: 'CW',
    outward: 'CW12',
    town: 'Congleton',
    county: 'Cheshire',
  },
  {
    area: 'CW',
    outward: 'CW98',
    town: 'Non-geographic',
    county: 'Cheshire',
  },
  {
    outward: 'DA1',
    town: 'Dartford, Crayford, Barnes Cray',
    county: 'Dartford',
    coordinates: [51.4445, 0.20975447],
  },
  {
    outward: 'DA2',
    town: 'Dartford, Stone, Joydens Wood, Wilmington, Bean',
    county: 'Dartford',
    coordinates: [51.440489, 0.23960681],
  },
  {
    outward: 'DA3',
    town: 'Longfield, Hartley, Fawkham',
    county: 'Dartford',
    coordinates: [51.387833, 0.30230399],
  },
  {
    outward: 'DA4',
    town: 'Dartford, South Darenth, Farningham, Eynsford',
    county: 'Dartford',
    coordinates: [51.369373, 0.21346256],
  },
  {
    outward: 'DA5',
    town: 'Bexley, Albany Park, Joydens Wood',
    county: 'Bexley',
    coordinates: [51.44178, 0.14999043],
  },
  {
    outward: 'DA6',
    town: 'Bexleyheath, Upton',
    county: 'Bexley',
    coordinates: [51.450268, 0.13875986],
  },
  {
    outward: 'DA7',
    town: 'Bexleyheath, Barnehurst',
    county: 'Bexley',
    coordinates: [51.463461, 0.13380512],
  },
  {
    outward: 'DA8',
    town: 'Erith, Northumberland Heath, Slade Green',
    county: 'Bexley',
    coordinates: [51.482681, 0.17625485],
  },
  {
    outward: 'DA9',
    town: 'Greenhithe, Stone',
    county: 'Dartford',
    coordinates: [51.44868, 0.27701077],
  },
  {
    outward: 'DA10',
    town: 'Swanscombe, Ebbsfleet',
    county: 'Dartford',
    coordinates: [51.449983, 0.30832276],
  },
  {
    outward: 'DA11',
    town: 'Gravesend (west), Northfleet',
    county: 'Gravesham',
    coordinates: [51.442728, 0.36772177],
  },
  {
    outward: 'DA12',
    town: 'Gravesend (east)',
    county: 'Gravesham',
    coordinates: [51.441275, 0.37019371],
  },
  {
    outward: 'DA13',
    town: 'Meopham',
    county: 'Gravesham',
    coordinates: [51.372255, 0.36245969],
  },
  {
    outward: 'DA14',
    town: 'Sidcup, Foots Cray, Albany Park, Longlands',
    county: 'Bexley',
    coordinates: [51.426481, 0.10503758],
  },
  {
    outward: 'DA15',
    town: 'Sidcup, Blackfen, Longlands, Lamorbey, Avery Hill',
    county: 'Bexley',
    coordinates: [51.43585, 0.10112309],
  },
  {
    outward: 'DA16',
    town: 'Welling, Falconwood, East Wickham',
    county: 'Bexley',
    coordinates: [51.461392, 0.11002683],
  },
  {
    outward: 'DA17',
    town: 'Belvedere, Lessness Heath',
    county: 'Bexley',
    coordinates: [51.49077, 0.14725277],
  },
  {
    outward: 'DA18',
    town: 'Erith Marshes, Thamesmead',
    county: 'Bexley',
    coordinates: [51.496327, 0.14927162],
  },
  {
    area: 'DD',
    outward: 'DD1',
    town: 'Dundee',
    county: 'Angus',
  },
  {
    area: 'DD',
    outward: 'DD2',
    town: 'Dundee',
    county: 'Angus',
  },
  {
    area: 'DD',
    outward: 'DD3',
    town: 'Dundee',
    county: 'Angus',
  },
  {
    area: 'DD',
    outward: 'DD4',
    town: 'Dundee',
    county: 'Angus',
  },
  {
    area: 'DD',
    outward: 'DD5',
    town: 'Dundee',
    county: 'Angus',
  },
  {
    area: 'DD',
    outward: 'DD6',
    town: 'Newport-on-Tay (shared)',
    county: 'Fife',
  },
  {
    area: 'DD',
    outward: 'DD6',
    town: 'Tayport (shared)',
    county: 'Fife',
  },
  {
    area: 'DD',
    outward: 'DD7',
    town: 'Carnoustie',
    county: 'Angus',
  },
  {
    area: 'DD',
    outward: 'DD8',
    town: 'Forfar (shared)',
    county: 'Angus',
  },
  {
    area: 'DD',
    outward: 'DD9',
    town: 'Brechin',
    county: 'Angus',
  },
  {
    area: 'DD',
    outward: 'DD10',
    town: 'MONTROSE',
    county: 'Angus',
  },
  {
    area: 'DD',
    outward: 'DD11',
    town: 'Arbroath',
    county: 'Angus',
  },
  {
    area: 'DE',
    outward: 'DE1',
    town: 'Derby',
    county: 'Derbyshire',
  },
  {
    area: 'DE',
    outward: 'DE3',
    town: 'Derby',
    county: 'Derbyshire',
  },
  {
    area: 'DE',
    outward: 'DE4',
    town: 'MATLOCK',
    county: 'Derbyshire',
  },
  {
    area: 'DE',
    outward: 'DE5',
    town: 'RIPLEY',
    county: 'Derbyshire',
  },
  {
    area: 'DE',
    outward: 'DE11',
    town: 'Swadlincote',
    county: 'Derbyshire',
  },
  {
    area: 'DE',
    outward: 'DE12',
    town: 'Swadlincote',
    county: 'Derbyshire',
  },
  {
    area: 'DE',
    outward: 'DE13',
    town: 'BURTON-ON-TRENT',
    county: 'Staffordshire',
  },
  {
    area: 'DE',
    outward: 'DE14',
    town: 'BURTON-ON-TRENT',
    county: 'Staffordshire',
  },
  {
    area: 'DE',
    outward: 'DE15',
    town: 'BURTON-ON-TRENT',
    county: 'Staffordshire',
  },
  {
    area: 'DE',
    outward: 'DE21',
    town: 'Derby',
    county: 'Derbyshire',
  },
  {
    area: 'DE',
    outward: 'DE22',
    town: 'Derby',
    county: 'Derbyshire',
  },
  {
    area: 'DE',
    outward: 'DE23',
    town: 'Derby',
    county: 'Derbyshire',
  },
  {
    area: 'DE',
    outward: 'DE24',
    town: 'Derby',
    county: 'Derbyshire',
  },
  {
    area: 'DE',
    outward: 'DE45',
    town: 'Bakewell',
    county: 'Derbyshire',
  },
  {
    area: 'DE',
    outward: 'DE55',
    town: 'Alfreton',
    county: 'Derbyshire',
  },
  {
    area: 'DE',
    outward: 'DE56',
    town: 'Belper',
    county: 'Derbyshire',
  },
  {
    area: 'DE',
    outward: 'DE65',
    town: 'Derby',
    county: 'Derbyshire',
  },
  {
    area: 'DE',
    outward: 'DE72',
    town: 'Derby',
    county: 'Derbyshire',
  },
  {
    area: 'DE',
    outward: 'DE73',
    town: 'Derby',
    county: 'Derbyshire',
  },
  {
    area: 'DE',
    outward: 'DE74',
    town: 'Derby',
    county: 'Derbyshire',
  },
  {
    area: 'DE',
    outward: 'DE75',
    town: 'Heanor',
    county: 'Derbyshire',
  },
  {
    area: 'DE',
    outward: 'DE99',
    town: 'Non-geographic',
    county: 'Derbyshire',
  },
  {
    area: 'DG',
    outward: 'DG1',
    town: 'Dumfries',
    county: 'Dumfriesshire',
  },
  {
    area: 'DG',
    outward: 'DG2',
    town: 'Dumfries',
    county: 'Dumfriesshire',
  },
  {
    area: 'DG',
    outward: 'DG3',
    town: 'THORNHILL',
    county: 'Dumfriesshire',
  },
  {
    area: 'DG',
    outward: 'DG4',
    town: 'Sanquhar',
    county: 'Dumfriesshire',
  },
  {
    area: 'DG',
    outward: 'DG5',
    town: 'Dalbeattie',
    county: 'Kirkcudbrightshire',
  },
  {
    area: 'DG',
    outward: 'DG6',
    town: 'Kirkcudbright',
    county: 'Kirkcudbrightshire',
  },
  {
    area: 'DG',
    outward: 'DG7',
    town: 'Castle Douglas',
    county: 'Kirkcudbrightshire',
  },
  {
    area: 'DG',
    outward: 'DG8',
    town: 'Newton Stewart',
    county: 'Wigtownshire',
  },
  {
    area: 'DG',
    outward: 'DG9',
    town: 'Stranraer',
    county: 'Wigtownshire',
  },
  {
    area: 'DG',
    outward: 'DG10',
    town: 'Moffat',
    county: 'Dumfriesshire',
  },
  {
    area: 'DG',
    outward: 'DG11',
    town: 'Lockerbie',
    county: 'Dumfriesshire',
  },
  {
    area: 'DG',
    outward: 'DG12',
    town: 'ANNAN',
    county: 'Dumfriesshire',
  },
  {
    area: 'DG',
    outward: 'DG13',
    town: 'Langholm',
    county: 'Dumfriesshire',
  },
  {
    area: 'DG',
    outward: 'DG14',
    town: 'Canonbie',
    county: 'Dumfriesshire',
  },
  {
    area: 'DG',
    outward: 'DG16',
    town: 'GRETNA',
    county: 'Dumfriesshire',
  },
  {
    area: 'DH',
    outward: 'DH1',
    town: 'DURHAM',
    county: 'County Durham',
  },
  {
    area: 'DH',
    outward: 'DH2',
    town: 'CHESTER LE STREET',
    county: 'County Durham',
  },
  {
    area: 'DH',
    outward: 'DH3',
    town: 'CHESTER LE STREET',
    county: 'County Durham',
  },
  {
    area: 'DH',
    outward: 'DH4',
    town: 'HOUGHTON LE SPRING',
    county: 'Tyne and Wear',
  },
  {
    area: 'DH',
    outward: 'DH5',
    town: 'HOUGHTON LE SPRING',
    county: 'Tyne and Wear',
  },
  {
    area: 'DH',
    outward: 'DH6',
    town: 'DURHAM',
    county: 'County Durham',
  },
  {
    area: 'DH',
    outward: 'DH7',
    town: 'DURHAM',
    county: 'County Durham',
  },
  {
    area: 'DH',
    outward: 'DH8',
    town: 'DURHAM (shared)',
    county: 'County Durham',
  },
  {
    area: 'DH',
    outward: 'DH97',
    town: 'Non-geographic',
    county: 'County Durham',
  },
  {
    area: 'DH',
    outward: 'DH98',
    town: 'Non-geographic',
    county: 'County Durham',
  },
  {
    area: 'DH',
    outward: 'DH99',
    town: 'Non-geographic',
    county: 'County Durham',
  },
  {
    area: 'DL',
    outward: 'DL1',
    town: 'Darlington',
    county: 'County Durham',
  },
  {
    area: 'DL',
    outward: 'DL2',
    town: 'Darlington',
    county: 'County Durham',
  },
  {
    area: 'DL',
    outward: 'DL3',
    town: 'Darlington',
    county: 'County Durham',
  },
  {
    area: 'DL',
    outward: 'DL4',
    town: 'Shildon',
    county: 'County Durham',
  },
  {
    area: 'DL',
    outward: 'DL5',
    town: 'Newton Aycliffe',
    county: 'County Durham',
  },
  {
    area: 'DL',
    outward: 'DL6',
    town: 'Northallerton',
    county: 'North Yorkshire',
  },
  {
    area: 'DL',
    outward: 'DL7',
    town: 'Northallerton',
    county: 'North Yorkshire',
  },
  {
    area: 'DL',
    outward: 'DL8',
    town: 'Bedale (shared)',
    county: 'North Yorkshire',
  },
  {
    area: 'DL',
    outward: 'DL10',
    town: 'RICHMOND',
    county: 'North Yorkshire',
  },
  {
    area: 'DL',
    outward: 'DL11',
    town: 'RICHMOND',
    county: 'North Yorkshire',
  },
  {
    area: 'DL',
    outward: 'DL12',
    town: 'Barnard Castle',
    county: 'County Durham',
  },
  {
    area: 'DL',
    outward: 'DL13',
    town: 'Bishop Auckland',
    county: 'County Durham',
  },
  {
    area: 'DL',
    outward: 'DL14',
    town: 'Bishop Auckland',
    county: 'County Durham',
  },
  {
    area: 'DL',
    outward: 'DL15',
    town: 'CROOK',
    county: 'County Durham',
  },
  {
    area: 'DL',
    outward: 'DL16',
    town: 'Spennymoor (shared)',
    county: 'County Durham',
  },
  {
    area: 'DL',
    outward: 'DL16',
    town: 'Non-geographic',
    county: 'County Durham',
  },
  {
    area: 'DL',
    outward: 'DL17',
    town: 'Ferryhill',
    county: 'County Durham',
  },
  {
    area: 'DL',
    outward: 'DL98',
    town: 'Non-geographic',
    county: 'County Durham',
  },
  {
    area: 'DN',
    outward: 'DN1',
    town: 'Doncaster',
    county: 'South Yorkshire',
  },
  {
    area: 'DN',
    outward: 'DN2',
    town: 'Doncaster',
    county: 'South Yorkshire',
  },
  {
    area: 'DN',
    outward: 'DN3',
    town: 'Doncaster',
    county: 'South Yorkshire',
  },
  {
    area: 'DN',
    outward: 'DN4',
    town: 'Doncaster',
    county: 'South Yorkshire',
  },
  {
    area: 'DN',
    outward: 'DN5',
    town: 'Doncaster',
    county: 'South Yorkshire',
  },
  {
    area: 'DN',
    outward: 'DN6',
    town: 'Doncaster',
    county: 'South Yorkshire',
  },
  {
    area: 'DN',
    outward: 'DN7',
    town: 'Doncaster',
    county: 'South Yorkshire',
  },
  {
    area: 'DN',
    outward: 'DN8',
    town: 'Doncaster',
    county: 'South Yorkshire',
  },
  {
    area: 'DN',
    outward: 'DN9',
    town: 'Doncaster',
    county: 'South Yorkshire',
  },
  {
    area: 'DN',
    outward: 'DN10',
    town: 'Doncaster',
    county: 'South Yorkshire',
  },
  {
    area: 'DN',
    outward: 'DN11',
    town: 'Doncaster',
    county: 'South Yorkshire',
  },
  {
    area: 'DN',
    outward: 'DN12',
    town: 'Doncaster',
    county: 'South Yorkshire',
  },
  {
    area: 'DN',
    outward: 'DN14',
    town: 'Goole',
    county: 'North Humberside',
  },
  {
    area: 'DN',
    outward: 'DN15',
    town: 'Scunthorpe',
    county: 'South Humberside',
  },
  {
    area: 'DN',
    outward: 'DN16',
    town: 'Scunthorpe',
    county: 'South Humberside',
  },
  {
    area: 'DN',
    outward: 'DN17',
    town: 'Scunthorpe',
    county: 'South Humberside',
  },
  {
    area: 'DN',
    outward: 'DN18',
    town: 'Barton-upon-Humber',
    county: 'South Humberside',
  },
  {
    area: 'DN',
    outward: 'DN19',
    town: 'BARROW-UPON-HUMBER',
    county: 'South Humberside',
  },
  {
    area: 'DN',
    outward: 'DN20',
    town: 'Brigg',
    county: 'South Humberside',
  },
  {
    area: 'DN',
    outward: 'DN21',
    town: 'GAINSBOROUGH',
    county: 'Lincolnshire',
  },
  {
    area: 'DN',
    outward: 'DN22',
    town: 'Retford',
    county: 'Nottinghamshire',
  },
  {
    area: 'DN',
    outward: 'DN31',
    town: 'Grimsby',
    county: 'South Humberside',
  },
  {
    area: 'DN',
    outward: 'DN32',
    town: 'Grimsby',
    county: 'South Humberside',
  },
  {
    area: 'DN',
    outward: 'DN33',
    town: 'Grimsby',
    county: 'South Humberside',
  },
  {
    area: 'DN',
    outward: 'DN34',
    town: 'Grimsby',
    county: 'South Humberside',
  },
  {
    area: 'DN',
    outward: 'DN35',
    town: 'Cleethorpes',
    county: 'South Humberside',
  },
  {
    area: 'DN',
    outward: 'DN36',
    town: 'Grimsby',
    county: 'South Humberside',
  },
  {
    area: 'DN',
    outward: 'DN37',
    town: 'Grimsby',
    county: 'South Humberside',
  },
  {
    area: 'DN',
    outward: 'DN38',
    town: 'Barnetby',
    county: 'South Humberside',
  },
  {
    area: 'DN',
    outward: 'DN39',
    town: 'ULCEBY',
    county: 'South Humberside',
  },
  {
    area: 'DN',
    outward: 'DN40',
    town: 'Immingham',
    county: 'South Humberside',
  },
  {
    area: 'DN',
    outward: 'DN41',
    town: 'Grimsby',
    county: 'South Humberside',
  },
  {
    area: 'DN',
    outward: 'DN55',
    town: 'Non-geographic',
    county: 'South Yorkshire',
  },
  {
    area: 'DT',
    outward: 'DT1',
    town: 'DORCHESTER',
    county: 'Dorset',
  },
  {
    area: 'DT',
    outward: 'DT2',
    town: 'DORCHESTER',
    county: 'Dorset',
  },
  {
    area: 'DT',
    outward: 'DT3',
    town: 'WEYMOUTH',
    county: 'Dorset',
  },
  {
    area: 'DT',
    outward: 'DT4',
    town: 'WEYMOUTH',
    county: 'Dorset',
  },
  {
    area: 'DT',
    outward: 'DT5',
    town: 'PORTLAND',
    county: 'Dorset',
  },
  {
    area: 'DT',
    outward: 'DT6',
    town: 'Bridport',
    county: 'Dorset',
  },
  {
    area: 'DT',
    outward: 'DT7',
    town: 'Lyme Regis',
    county: 'Dorset',
  },
  {
    area: 'DT',
    outward: 'DT8',
    town: 'Beaminster',
    county: 'Dorset',
  },
  {
    area: 'DT',
    outward: 'DT9',
    town: 'Sherborne',
    county: 'Dorset',
  },
  {
    area: 'DT',
    outward: 'DT10',
    town: 'Sturminster Newton',
    county: 'Dorset',
  },
  {
    area: 'DT',
    outward: 'DT11',
    town: 'Blandford Forum',
    county: 'Dorset',
  },
  {
    area: 'DY',
    outward: 'DY1',
    town: 'Dudley',
    county: 'West Midlands',
  },
  {
    area: 'DY',
    outward: 'DY2',
    town: 'Dudley',
    county: 'West Midlands',
  },
  {
    area: 'DY',
    outward: 'DY3',
    town: 'Dudley',
    county: 'West Midlands',
  },
  {
    area: 'DY',
    outward: 'DY4',
    town: 'Tipton',
    county: 'West Midlands',
  },
  {
    area: 'DY',
    outward: 'DY5',
    town: 'Brierley Hill',
    county: 'West Midlands',
  },
  {
    area: 'DY',
    outward: 'DY6',
    town: 'Kingswinford',
    county: 'West Midlands',
  },
  {
    area: 'DY',
    outward: 'DY7',
    town: 'Stourbridge',
    county: 'West Midlands',
  },
  {
    area: 'DY',
    outward: 'DY8',
    town: 'Stourbridge',
    county: 'West Midlands',
  },
  {
    area: 'DY',
    outward: 'DY9',
    town: 'Stourbridge',
    county: 'West Midlands',
  },
  {
    area: 'DY',
    outward: 'DY10',
    town: 'Kidderminster',
    county: 'Worcestershire',
  },
  {
    area: 'DY',
    outward: 'DY11',
    town: 'Kidderminster',
    county: 'Worcestershire',
  },
  {
    area: 'DY',
    outward: 'DY12',
    town: 'Bewdley',
    county: 'Worcestershire',
  },
  {
    area: 'DY',
    outward: 'DY13',
    town: 'Stourport-on-Severn',
    county: 'Worcestershire',
  },
  {
    area: 'DY',
    outward: 'DY14',
    town: 'Kidderminster',
    county: 'Worcestershire',
  },
  {
    outward: 'E1',
    town: 'Aldgate, Bishopsgate, Whitechapel, Shoreditch, Spitalfields, Shadwell, Stepney, Mile End, Portsoken',
    county: 'Tower Hamlets',
    coordinates: [51.512497, -0.05209791],
  },
  {
    outward: 'E1W',
    town: 'Wapping',
    county: 'Tower Hamlets',
  },
  {
    outward: 'E2',
    town: 'Bethnal Green, Haggerston, Shoreditch, Cambridge Heath',
    county: 'Tower Hamlets',
    coordinates: [51.526024, -0.06691716],
  },
  {
    outward: 'E3',
    town: 'Bow, Bromley-by-Bow, Old Ford, Mile End, Three Mills, London Gas Museum',
    county: 'Tower Hamlets',
    coordinates: [51.53023, -0.028907296],
  },
  {
    outward: 'E4',
    town: 'Chingford, Sewardstone, Highams Park',
    county: 'Waltham Forest',
    coordinates: [51.63325, 0.0081558303],
  },
  {
    outward: 'E5',
    town: 'Upper Clapton, Lower Clapton, Leyton, Stoke Newington',
    county: 'Hackney',
    coordinates: [51.557186, -0.049164802],
  },
  {
    outward: 'E6',
    town: 'East Ham, Beckton, Upton Park, Barking',
    county: 'Newham',
    coordinates: [51.537563, 0.040293854],
  },
  {
    outward: 'E7',
    town: 'Forest Gate, Stratford',
    county: 'Newham',
    coordinates: [51.554412, 0.019654969],
  },
  {
    outward: 'E8',
    town: 'Hackney Central, Dalston, London Fields',
    county: 'Hackney',
    coordinates: [51.544454, -0.062790254],
  },
  {
    outward: 'E9',
    town: 'Homerton, Hackney Wick, South Hackney, Hackney Marshes, Victoria Park',
    county: 'Hackney',
    coordinates: [51.545421, -0.04195212],
  },
  {
    outward: 'E10',
    town: 'Leyton, Temple Mills, Hackney Marshes, Upper Clapton, Walthamstow Marshes',
    county: 'Hackney',
    coordinates: [51.573046, -0.0088001352],
  },
  {
    outward: 'E11',
    town: 'Leytonstone, Wanstead, Aldersbrook, Snaresbrook, Cann Hall',
    county: 'Waltham Forest',
    coordinates: [51.564032, 0.0087982145],
  },
  {
    outward: 'E12',
    town: 'Manor Park, Little Ilford, Aldersbrook',
    county: 'Newham',
    coordinates: [51.551892, 0.046228846],
  },
  {
    outward: 'E13',
    town: 'Plaistow, West Ham, Upton Park',
    county: 'Newham',
    coordinates: [51.529253, 0.023154858],
  },
  {
    outward: 'E14',
    town: 'Poplar, Isle of Dogs, Limehouse, Canary Wharf, Blackwall, Cubitt Town',
    county: 'Tower Hamlets',
    coordinates: [51.509978, -0.01501005],
  },
  {
    outward: 'E15',
    town: 'Stratford, West Ham, Maryland, Leyton, Leytonstone, Temple Mills, Hackney Wick, Bow',
    county: 'Newham',
    coordinates: [51.54997, -0.0034902478],
  },
  {
    outward: 'E16',
    town: 'Silvertown, North Woolwich, Canning Town, Custom House, London City Airport',
    county: 'Newham',
    coordinates: [51.507713, 0.022805703],
  },
  {
    outward: 'E17',
    town: 'Walthamstow, Upper Walthamstow',
    county: 'Waltham Forest',
    coordinates: [51.585503, -0.018013658],
  },
  {
    outward: 'E18',
    town: 'Woodford, South Woodford',
    county: 'Redbridge',
    coordinates: [51.591999, 0.026576978],
  },
  {
    outward: 'E20',
    town: 'Olympic Park, Stratford',
    county: 'Newham',
    coordinates: [51.547915, -0.0074312368],
  },
  {
    outward: 'E77',
    town: 'Non-geographic',
    county: 'Tower Hamlets',
    coordinates: [51.513948, -0.06912914],
  },
  {
    outward: 'E98',
    town: 'Non-geographic',
    county: 'Tower Hamlets',
    coordinates: [51.508024, -0.064393535],
  },
  {
    area: 'E',
    outward: 'E98',
    town: 'Non-geographic',
    county: 'Tower Hamlets',
    coordinates: [51.508024, -0.064393535],
  },
  {
    outward: 'EC1',
    town: 'Clerkenwell, Finsbury',
    county: 'City of London',
    coordinates: [51.526276, -0.107978],
  },
  {
    outward: 'EC1A',
    town: "St Bartholomew's Hospital",
    county: 'City of London',
    coordinates: [51.525136, -0.11118274],
  },
  {
    outward: 'EC1M',
    town: 'Clerkenwell, Farringdon',
    county: 'Islington',
    coordinates: [51.520368, -0.10551336],
  },
  {
    outward: 'EC1N',
    town: 'Hatton Garden',
    county: 'Camden',
    coordinates: [51.518252, -0.11032888],
  },
  {
    outward: 'EC1P',
    town: 'Non-geographic',
    county: 'London',
    coordinates: [51.524503, -0.11208828],
  },
  {
    outward: 'EC1R',
    town: 'Finsbury, Finsbury Estate',
    county: 'Islington',
    coordinates: [51.52326, -0.10695019],
  },
  {
    outward: 'EC1V',
    town: 'Finsbury, Moorfields Eye Hospital',
    county: 'Islington',
    coordinates: [51.523198, -0.099802784],
  },
  {
    outward: 'EC1Y',
    town: "St Luke's, Bunhill Fields",
    county: 'Islington',
    coordinates: [51.523387, -0.097027183],
  },
  {
    outward: 'EC2',
    town: 'Shoreditch',
    county: 'Hackney',
    coordinates: [51.525319, -0.079503],
  },
  {
    outward: 'EC2A',
    town: 'Shoreditch',
    county: 'Hackney',
    coordinates: [51.520707, -0.087596529],
  },
  {
    outward: 'EC2M',
    town: 'Broadgate, Liverpool Street',
    county: 'City of London',
    coordinates: [51.516838, -0.08473179],
  },
  {
    outward: 'EC2N',
    town: 'Old Broad Street, Tower 42',
    county: 'City of London',
    coordinates: [51.514782, -0.084918932],
  },
  {
    outward: 'EC2P',
    town: 'Non-geographic',
    county: 'London',
    coordinates: [51.524503, -0.11208828],
  },
  {
    outward: 'EC2R',
    town: 'Bank of England',
    county: 'City of London',
    coordinates: [51.51727, -0.090248429],
  },
  {
    outward: 'EC2V',
    town: 'Guildhall',
    county: 'City of London',
    coordinates: [51.515373, -0.092460886],
  },
  {
    outward: 'EC2Y',
    town: 'Barbican',
    county: 'City of London',
    coordinates: [51.517346, -0.096039438],
  },
  {
    outward: 'EC3',
    town: 'St Mary Axe, Aldgate',
    county: 'City of London',
    coordinates: [51.513439, -0.077167],
  },
  {
    outward: 'EC3A',
    town: 'St Mary Axe, Aldgate',
    county: 'City of London',
    coordinates: [51.513299, -0.081104267],
  },
  {
    outward: 'EC3B',
    town: 'City of London',
    county: 'London',
  },
  {
    outward: 'EC3M',
    town: "Leadenhall, Lloyd's of London, Fenchurch Street",
    county: 'City of London',
    coordinates: [51.510603, -0.085598604],
  },
  {
    outward: 'EC3N',
    town: 'Tower Hill, Tower of London',
    county: 'Tower Hamlets',
    coordinates: [51.51406, -0.076489152],
  },
  {
    outward: 'EC3P',
    town: 'Non-geographic',
    county: 'London',
    coordinates: [51.524503, -0.11208828],
  },
  {
    outward: 'EC3R',
    town: 'Monument, Billingsgate',
    county: 'City of London',
    coordinates: [51.509998, -0.082698506],
  },
  {
    outward: 'EC3V',
    town: 'Royal Exchange, Lombard Street',
    county: 'City of London',
    coordinates: [51.51273, -0.084269979],
  },
  {
    outward: 'EC4',
    town: 'Blackfriars, Billingsgate',
    county: 'City of London',
    coordinates: [51.511992, -0.103862],
  },
  {
    outward: 'EC4A',
    town: 'Fetter Lane',
    county: 'City of London',
    coordinates: [51.517526, -0.10828337],
  },
  {
    outward: 'EC4M',
    town: "St Paul's",
    county: 'City of London',
    coordinates: [51.512829, -0.094757765],
  },
  {
    outward: 'EC4N',
    town: 'Mansion House',
    county: 'City of London',
    coordinates: [51.513463, -0.09170476],
  },
  {
    outward: 'EC4P',
    town: 'Non-geographic',
    county: 'London',
    coordinates: [51.524503, -0.11208828],
  },
  {
    outward: 'EC4R',
    town: 'Cannon Street',
    county: 'City of London',
    coordinates: [51.51097, -0.090497485],
  },
  {
    outward: 'EC4V',
    town: 'Blackfriars',
    county: 'City of London',
    coordinates: [51.512092, -0.094197654],
  },
  {
    outward: 'EC4Y',
    town: 'Temple',
    county: 'City of London',
    coordinates: [51.512597, -0.10484138],
  },
  {
    outward: 'EC50',
    town: 'Non-geographic',
    county: 'London',
    coordinates: [51.524503, -0.11208828],
  },
  {
    outward: 'EC88',
    town: 'Non-geographic',
    county: 'London',
  },
  {
    area: 'EH',
    outward: 'EH1',
    town: 'Edinburgh',
    county: 'Midlothian',
  },
  {
    area: 'EH',
    outward: 'EH2',
    town: 'Edinburgh',
    county: 'Midlothian',
  },
  {
    area: 'EH',
    outward: 'EH3',
    town: 'Edinburgh',
    county: 'Midlothian',
  },
  {
    area: 'EH',
    outward: 'EH4',
    town: 'Edinburgh',
    county: 'Midlothian',
  },
  {
    area: 'EH',
    outward: 'EH5',
    town: 'Edinburgh',
    county: 'Midlothian',
  },
  {
    area: 'EH',
    outward: 'EH6',
    town: 'Edinburgh',
    county: 'Midlothian',
  },
  {
    area: 'EH',
    outward: 'EH7',
    town: 'Edinburgh',
    county: 'Midlothian',
  },
  {
    area: 'EH',
    outward: 'EH8',
    town: 'Edinburgh',
    county: 'Midlothian',
  },
  {
    area: 'EH',
    outward: 'EH9',
    town: 'Edinburgh',
    county: 'Midlothian',
  },
  {
    area: '',
    outward: 'EH10',
    town: 'Edinburgh',
    county: 'Midlothian',
  },
  {
    area: '',
    outward: 'EH11',
    town: 'Edinburgh',
    county: 'Midlothian',
  },
  {
    area: '',
    outward: 'EH12',
    town: 'Edinburgh',
    county: 'Midlothian',
  },
  {
    area: '',
    outward: 'EH13',
    town: 'Edinburgh',
    county: 'Midlothian',
  },
  {
    area: '',
    outward: 'EH14',
    town: 'Edinburgh (shared)',
    county: 'Midlothian',
  },
  {
    area: 'EH',
    outward: 'EH14',
    town: 'Balerno (shared)',
    county: 'Midlothian',
  },
  {
    area: '',
    outward: 'EH15',
    town: 'Edinburgh',
    county: 'Midlothian',
  },
  {
    area: '',
    outward: 'EH16',
    town: 'Edinburgh',
    county: 'Midlothian',
  },
  {
    area: '',
    outward: 'EH17',
    town: 'Edinburgh',
    county: 'Midlothian',
  },
  {
    area: 'EH',
    outward: 'EH18',
    town: 'Lasswade',
    county: 'Midlothian',
  },
  {
    area: 'EH',
    outward: 'EH19',
    town: 'Bonnyrigg',
    county: 'Midlothian',
  },
  {
    area: 'EH',
    outward: 'EH20',
    town: 'Loanhead',
    county: 'Midlothian',
  },
  {
    area: 'EH',
    outward: 'EH21',
    town: 'Musselburgh',
    county: 'Midlothian',
  },
  {
    area: 'EH',
    outward: 'EH22',
    town: 'Dalkeith',
    county: 'Midlothian',
  },
  {
    area: 'EH',
    outward: 'EH23',
    town: 'Gorebridge',
    county: 'Midlothian',
  },
  {
    area: 'EH',
    outward: 'EH24',
    town: 'ROSEWELL',
    county: 'Midlothian',
  },
  {
    area: 'EH',
    outward: 'EH25',
    town: 'ROSLIN',
    county: 'Midlothian',
  },
  {
    area: 'EH',
    outward: 'EH26',
    town: 'Penicuik',
    county: 'Midlothian',
  },
  {
    area: 'EH',
    outward: 'EH27',
    town: 'KIRKNEWTON',
    county: 'Midlothian',
  },
  {
    area: 'EH',
    outward: 'EH28',
    town: 'NEWBRIDGE',
    county: 'Midlothian',
  },
  {
    area: 'EH',
    outward: 'EH29',
    town: 'Kirkliston',
    county: 'West Lothian',
  },
  {
    area: 'EH',
    outward: 'EH30',
    town: 'South Queensferry',
    county: 'West Lothian',
  },
  {
    area: 'EH',
    outward: 'EH31',
    town: 'Gullane',
    county: 'East Lothian',
  },
  {
    area: 'EH',
    outward: 'EH32',
    town: 'Longniddry (shared)',
    county: 'East Lothian',
  },
  {
    area: 'EH',
    outward: 'EH33',
    town: 'Tranent',
    county: 'East Lothian',
  },
  {
    area: 'EH',
    outward: 'EH34',
    town: 'Tranent',
    county: 'East Lothian',
  },
  {
    area: 'EH',
    outward: 'EH35',
    town: 'Tranent',
    county: 'East Lothian',
  },
  {
    area: 'EH',
    outward: 'EH36',
    town: 'Humbie',
    county: 'East Lothian',
  },
  {
    area: 'EH',
    outward: 'EH37',
    town: 'PATHHEAD',
    county: 'Midlothian',
  },
  {
    area: 'EH',
    outward: 'EH38',
    town: 'HERIOT',
    county: 'Midlothian',
  },
  {
    area: 'EH',
    outward: 'EH39',
    town: 'North Berwick',
    county: 'East Lothian',
  },
  {
    area: 'EH',
    outward: 'EH40',
    town: 'East Linton',
    county: 'East Lothian',
  },
  {
    area: 'EH',
    outward: 'EH41',
    town: 'HADDINGTON',
    county: 'East Lothian',
  },
  {
    area: 'EH',
    outward: 'EH42',
    town: 'Dunbar',
    county: 'East Lothian',
  },
  {
    area: 'EH',
    outward: 'EH43',
    town: 'Walkerburn',
    county: 'Peeblesshire',
  },
  {
    area: 'EH',
    outward: 'EH44',
    town: 'Innerleithen',
    county: 'Peeblesshire',
  },
  {
    area: 'EH',
    outward: 'EH45',
    town: 'Peebles',
    county: 'Peeblesshire',
  },
  {
    area: 'EH',
    outward: 'EH46',
    town: 'West Linton',
    county: 'Peeblesshire',
  },
  {
    area: 'EH',
    outward: 'EH47',
    town: 'Bathgate',
    county: 'West Lothian',
  },
  {
    area: 'EH',
    outward: 'EH48',
    town: 'Bathgate',
    county: 'West Lothian',
  },
  {
    area: 'EH',
    outward: 'EH49',
    town: 'Linlithgow',
    county: 'West Lothian',
  },
  {
    area: 'EH',
    outward: 'EH51',
    town: "Bo'ness",
    county: 'West Lothian',
  },
  {
    area: 'EH',
    outward: 'EH52',
    town: 'BROXBURN',
    county: 'West Lothian',
  },
  {
    area: 'EH',
    outward: 'EH53',
    town: 'LIVINGSTON',
    county: 'West Lothian',
  },
  {
    area: 'EH',
    outward: 'EH54',
    town: 'LIVINGSTON',
    county: 'West Lothian',
  },
  {
    area: 'EH',
    outward: 'EH55',
    town: 'West Calder',
    county: 'West Lothian',
  },
  {
    area: '',
    outward: 'EH91',
    town: 'Non-geographic',
    county: 'Midlothian',
  },
  {
    area: '',
    outward: 'EH95',
    town: 'Non-geographic',
    county: 'Midlothian',
  },
  {
    area: '',
    outward: 'EH99',
    town: 'Non-geographic',
    county: 'Midlothian',
  },
  {
    outward: 'EN1',
    town: 'Bush Hill Park, Bulls Cross, Enfield Town, Forty Hill',
    county: 'Enfield',
    coordinates: [51.651933, -0.077090768],
  },
  {
    outward: 'EN2',
    town: 'Botany Bay, Clay Hill, Crews Hill, Bulls Cross, Enfield Chase, Enfield Town, Forty Hill',
    county: 'Enfield',
    coordinates: [51.657483, -0.082104083],
  },
  {
    outward: 'EN3',
    town: 'Enfield Highway, Enfield Island Village, Enfield Lock, Enfield Wash, Ponders End',
    county: 'Enfield',
    coordinates: [51.644435, -0.053183515],
  },
  {
    outward: 'EN4',
    town: 'Hadley Wood, Cockfosters, East Barnet, New Barnet',
    county: 'Enfield',
    coordinates: [51.649928, -0.14930818],
  },
  {
    outward: 'EN5',
    town: 'High Barnet, Arkley',
    county: 'Barnet',
    coordinates: [51.643239, -0.17114478],
  },
  {
    outward: 'EN6',
    town: 'Potters Bar, South Mimms, Cuffley, Northaw',
    county: 'Hertsmere',
    coordinates: [51.699279, -0.18975339],
  },
  {
    outward: 'EN7',
    town: 'Cheshunt',
    county: 'Broxbourne',
    coordinates: [51.701123, -0.050476168],
  },
  {
    outward: 'EN8',
    town: 'Waltham Cross, Cheshunt',
    county: 'Enfield',
    coordinates: [51.707866, -0.036884637],
  },
  {
    outward: 'EN9',
    town: 'Waltham Abbey',
    county: 'Epping Forest',
    coordinates: [51.686246, -0.013746726],
  },
  {
    outward: 'EN10',
    town: 'Broxbourne',
    county: 'Broxbourne',
    coordinates: [51.728633, -0.025283755],
  },
  {
    outward: 'EN11',
    town: 'Hoddesdon',
    county: 'Broxbourne',
    coordinates: [51.764847, -0.0072694577],
  },
  {
    area: 'EN',
    outward: 'EN11',
    town: 'non-geo shared',
    county: 'Hertfordshire',
  },
  {
    area: 'EN',
    outward: 'EN11',
    town: 'Hoddesdon (shared)',
    county: 'Hertfordshire',
  },
  {
    outward: 'EN77',
    town: 'Non-geographic',
    county: 'Enfield',
    coordinates: [49.766809, -7.5571598],
  },
  {
    area: '',
    outward: 'EN77',
    town: 'Non-geographic',
    county: 'Hertfordshire',
  },
  {
    area: 'EX',
    outward: 'EX1',
    town: 'Exeter',
    county: 'Devon',
  },
  {
    area: 'EX',
    outward: 'EX2',
    town: 'Exeter',
    county: 'Devon',
  },
  {
    area: 'EX',
    outward: 'EX3',
    town: 'Exeter',
    county: 'Devon',
  },
  {
    area: 'EX',
    outward: 'EX4',
    town: 'Exeter',
    county: 'Devon',
  },
  {
    area: 'EX',
    outward: 'EX5',
    town: 'Exeter',
    county: 'Devon',
  },
  {
    area: 'EX',
    outward: 'EX6',
    town: 'Exeter',
    county: 'Devon',
  },
  {
    area: 'EX',
    outward: 'EX7',
    town: 'Dawlish',
    county: 'Devon',
  },
  {
    area: 'EX',
    outward: 'EX8',
    town: 'Exmouth',
    county: 'Devon',
  },
  {
    area: 'EX',
    outward: 'EX9',
    town: 'Budleigh Salterton',
    county: 'Devon',
  },
  {
    area: 'EX',
    outward: 'EX10',
    town: 'Sidmouth',
    county: 'Devon',
  },
  {
    area: 'EX',
    outward: 'EX11',
    town: 'OTTERY ST. MARY',
    county: 'Devon',
  },
  {
    area: 'EX',
    outward: 'EX12',
    town: 'SEATON',
    county: 'Devon',
  },
  {
    area: 'EX',
    outward: 'EX13',
    town: 'Axminster',
    county: 'Devon',
  },
  {
    area: 'EX',
    outward: 'EX14',
    town: 'Honiton',
    county: 'Devon',
  },
  {
    area: 'EX',
    outward: 'EX15',
    town: 'Cullompton',
    county: 'Devon',
  },
  {
    area: 'EX',
    outward: 'EX16',
    town: 'TIVERTON',
    county: 'Devon',
  },
  {
    area: 'EX',
    outward: 'EX17',
    town: 'Crediton',
    county: 'Devon',
  },
  {
    area: 'EX',
    outward: 'EX18',
    town: 'Chulmleigh',
    county: 'Devon',
  },
  {
    area: 'EX',
    outward: 'EX19',
    town: 'Winkleigh',
    county: 'Devon',
  },
  {
    area: 'EX',
    outward: 'EX20',
    town: 'North Tawton (shared)',
    county: 'Devon',
  },
  {
    area: 'EX',
    outward: 'EX21',
    town: 'Beaworthy',
    county: 'Devon',
  },
  {
    area: 'EX',
    outward: 'EX22',
    town: 'HOLSWORTHY',
    county: 'Devon',
  },
  {
    area: 'EX',
    outward: 'EX23',
    town: 'Bude',
    county: 'Cornwall',
  },
  {
    area: 'EX',
    outward: 'EX24',
    town: 'COLYTON',
    county: 'Devon',
  },
  {
    area: 'EX',
    outward: 'EX31',
    town: 'Barnstaple',
    county: 'Devon',
  },
  {
    area: 'EX',
    outward: 'EX32',
    town: 'Barnstaple',
    county: 'Devon',
  },
  {
    area: 'EX',
    outward: 'EX33',
    town: 'Braunton',
    county: 'Devon',
  },
  {
    area: 'EX',
    outward: 'EX34',
    town: 'Ilfracombe (shared)',
    county: 'Devon',
  },
  {
    area: 'EX',
    outward: 'EX35',
    town: 'Lynmouth (shared)',
    county: 'Devon',
  },
  {
    area: 'EX',
    outward: 'EX36',
    town: 'South Molton',
    county: 'Devon',
  },
  {
    area: 'EX',
    outward: 'EX37',
    town: 'Umberleigh',
    county: 'Devon',
  },
  {
    area: 'EX',
    outward: 'EX38',
    town: 'TORRINGTON',
    county: 'Devon',
  },
  {
    area: 'EX',
    outward: 'EX39',
    town: 'Bideford',
    county: 'Devon',
  },
  {
    area: 'FK',
    outward: 'FK1',
    town: 'Falkirk',
    county: 'Stirlingshire',
  },
  {
    area: 'FK',
    outward: 'FK2',
    town: 'Falkirk',
    county: 'Stirlingshire',
  },
  {
    area: 'FK',
    outward: 'FK3',
    town: 'Grangemouth',
    county: 'Stirlingshire',
  },
  {
    area: 'FK',
    outward: 'FK4',
    town: 'Bonnybridge',
    county: 'Stirlingshire',
  },
  {
    area: 'FK',
    outward: 'FK5',
    town: 'Larbert',
    county: 'Stirlingshire',
  },
  {
    area: 'FK',
    outward: 'FK6',
    town: 'DENNY',
    county: 'Stirlingshire',
  },
  {
    area: 'FK',
    outward: 'FK7',
    town: 'Stirling',
    county: 'Stirlingshire',
  },
  {
    area: 'FK',
    outward: 'FK8',
    town: 'Stirling',
    county: 'Stirlingshire',
  },
  {
    area: 'FK',
    outward: 'FK9',
    town: 'Stirling',
    county: 'Stirlingshire',
  },
  {
    area: 'FK',
    outward: 'FK10',
    town: 'Alloa (shared)',
    county: 'Clackmannanshire',
  },
  {
    area: 'FK',
    outward: 'FK11',
    town: 'Menstrie',
    county: 'Clackmannanshire',
  },
  {
    area: 'FK',
    outward: 'FK12',
    town: 'ALVA',
    county: 'Clackmannanshire',
  },
  {
    area: 'FK',
    outward: 'FK13',
    town: 'Tillicoultry',
    county: 'Clackmannanshire',
  },
  {
    area: 'FK',
    outward: 'FK14',
    town: 'DOLLAR',
    county: 'Clackmannanshire',
  },
  {
    area: 'FK',
    outward: 'FK15',
    town: 'Dunblane',
    county: 'Perthshire',
  },
  {
    area: 'FK',
    outward: 'FK16',
    town: 'Doune',
    county: 'Perthshire',
  },
  {
    area: 'FK',
    outward: 'FK17',
    town: 'Callander',
    county: 'Perthshire',
  },
  {
    area: 'FK',
    outward: 'FK18',
    town: 'Callander',
    county: 'Perthshire',
  },
  {
    area: 'FK',
    outward: 'FK19',
    town: 'Lochearnhead',
    county: 'Perthshire',
  },
  {
    area: 'FK',
    outward: 'FK20',
    town: 'Crianlarich',
    county: 'Perthshire',
  },
  {
    area: 'FK',
    outward: 'FK21',
    town: 'Killin',
    county: 'Perthshire',
  },
  {
    area: 'FY',
    outward: 'FY0',
    town: 'Non-geographic',
    county: 'Lancashire',
  },
  {
    area: 'FY',
    outward: 'FY1',
    town: 'Blackpool',
    county: 'Lancashire',
  },
  {
    area: 'FY',
    outward: 'FY2',
    town: 'Blackpool',
    county: 'Lancashire',
  },
  {
    area: 'FY',
    outward: 'FY3',
    town: 'Blackpool',
    county: 'Lancashire',
  },
  {
    area: 'FY',
    outward: 'FY4',
    town: 'Blackpool',
    county: 'Lancashire',
  },
  {
    area: 'FY',
    outward: 'FY5',
    town: 'Thornton-Cleveleys',
    county: 'Lancashire',
  },
  {
    area: 'FY',
    outward: 'FY6',
    town: 'Poulton-le-Fylde',
    county: 'Lancashire',
  },
  {
    area: 'FY',
    outward: 'FY7',
    town: 'Fleetwood',
    county: 'Lancashire',
  },
  {
    area: 'FY',
    outward: 'FY8',
    town: 'LYTHAM ST. ANNES',
    county: 'Lancashire',
  },
  {
    area: 'G',
    outward: 'G1',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G2',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G3',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G4',
    outward: 'G4',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G5',
    outward: 'G5',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G9',
    outward: 'G9',
    town: 'Non-geographic',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G11',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G12',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G13',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G14',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G15',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G20',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G21',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G22',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G23',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G31',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G32',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G33',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G34',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G40',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G41',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G42',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G43',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G44',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G45',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G46',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G51',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G52',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G53',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G58',
    town: 'Non-geographic',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G60',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G61',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G62',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G63',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G64',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G65',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G66',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G67',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G68',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G69',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G70',
    town: 'Non-geographic',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G71',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G72',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G73',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G74',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G75',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G76',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G77',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G78',
    town: 'Glasgow',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G79',
    town: 'Non-geographic',
    county: 'Lanarkshire',
  },
  {
    area: 'G',
    outward: 'G81',
    town: 'Clydebank',
    county: 'Dunbartonshire',
  },
  {
    area: 'G',
    outward: 'G82',
    town: 'Dumbarton',
    county: 'Dunbartonshire',
  },
  {
    area: 'G',
    outward: 'G83',
    town: 'ALEXANDRIA (shared)',
    county: 'Dunbartonshire',
  },
  {
    area: 'G',
    outward: 'G84',
    town: 'Helensburgh',
    county: 'Dunbartonshire',
  },
  {
    area: 'G',
    outward: 'G90',
    town: 'Non-geographic',
    county: 'Lanarkshire',
  },
  {
    area: '',
    outward: 'GIR',
    town: 'Non-geographic',
    county: 'Merseyside',
  },
  {
    area: 'GL',
    outward: 'GL1',
    town: 'Gloucester',
    county: 'Gloucestershire',
  },
  {
    area: 'GL',
    outward: 'GL2',
    town: 'Gloucester',
    county: 'Gloucestershire',
  },
  {
    area: 'GL',
    outward: 'GL3',
    town: 'Gloucester',
    county: 'Gloucestershire',
  },
  {
    area: 'GL',
    outward: 'GL4',
    town: 'Gloucester',
    county: 'Gloucestershire',
  },
  {
    area: 'GL',
    outward: 'GL5',
    town: 'Stroud',
    county: 'Gloucestershire',
  },
  {
    area: 'GL',
    outward: 'GL6',
    town: 'Stroud',
    county: 'Gloucestershire',
  },
  {
    area: 'GL',
    outward: 'GL7',
    town: 'Cirencester (shared)',
    county: 'Gloucestershire',
  },
  {
    area: 'GL',
    outward: 'GL8',
    town: 'Tetbury',
    county: 'Gloucestershire',
  },
  {
    area: 'GL',
    outward: 'GL9',
    town: 'BADMINTON',
    county: 'Avon',
  },
  {
    area: 'GL',
    outward: 'GL10',
    town: 'STONEHOUSE',
    county: 'Gloucestershire',
  },
  {
    area: 'GL',
    outward: 'GL11',
    town: 'non-geo shared',
    county: 'Gloucestershire',
  },
  {
    area: 'GL',
    outward: 'GL12',
    town: 'Wotton-under-Edge',
    county: 'Gloucestershire',
  },
  {
    area: 'GL',
    outward: 'GL11',
    town: 'Dursley (shared)',
    county: 'Gloucestershire',
  },
  {
    area: 'GL',
    outward: 'GL13',
    town: 'BERKELEY',
    county: 'Gloucestershire',
  },
  {
    area: 'GL',
    outward: 'GL14',
    town: 'Cinderford (shared)',
    county: 'Gloucestershire',
  },
  {
    area: 'GL',
    outward: 'GL15',
    town: 'BLAKENEY (shared)',
    county: 'Gloucestershire',
  },
  {
    area: 'GL',
    outward: 'GL16',
    town: 'COLEFORD',
    county: 'Gloucestershire',
  },
  {
    area: 'GL',
    outward: 'GL17',
    town: 'Drybrook (shared)',
    county: 'Gloucestershire',
  },
  {
    area: 'GL',
    outward: 'GL18',
    town: 'Dymock (shared)',
    county: 'Gloucestershire',
  },
  {
    area: 'G',
    outward: 'GL19',
    town: 'Gloucester',
    county: 'Gloucestershire',
  },
  {
    area: 'GL',
    outward: 'GL20',
    town: 'Tewkesbury',
    county: 'Gloucestershire',
  },
  {
    area: 'GL',
    outward: 'GL50',
    town: 'Cheltenham',
    county: 'Gloucestershire',
  },
  {
    area: 'GL',
    outward: 'GL51',
    town: 'Cheltenham',
    county: 'Gloucestershire',
  },
  {
    area: 'GL',
    outward: 'GL52',
    town: 'Cheltenham',
    county: 'Gloucestershire',
  },
  {
    area: 'GL',
    outward: 'GL53',
    town: 'Cheltenham',
    county: 'Gloucestershire',
  },
  {
    area: 'GL',
    outward: 'GL54',
    town: 'Cheltenham',
    county: 'Gloucestershire',
  },
  {
    area: 'GL',
    outward: 'GL55',
    town: 'Chipping Campden',
    county: 'Gloucestershire',
  },
  {
    area: 'GL',
    outward: 'GL56',
    town: 'Moreton-in-Marsh',
    county: 'Gloucestershire',
  },
  {
    outward: 'GU1',
    town: 'Guildford Town Centre, Slyfield, Merrow',
    county: 'Guildford',
    coordinates: [51.245291, -0.58231792],
  },
  {
    outward: 'GU2',
    town: 'Guildford Park, Onslow Village, Park Barn, University of Surrey',
    county: 'Guildford',
    coordinates: [51.234877, -0.57779643],
  },
  {
    outward: 'GU3',
    town: 'Normandy, Puttenham, Worplesdon, Christmas Pie, Flexford',
    county: 'Guildford',
    coordinates: [51.23157, -0.63457485],
  },
  {
    outward: 'GU4',
    town: 'Burpham, Chilworth, Jacobs Well, Shalford',
    county: 'Guildford',
    coordinates: [51.246767, -0.52487507],
  },
  {
    outward: 'GU5',
    town: 'Albury, Bramley, Shere',
    county: 'Guildford',
    coordinates: [51.202117, -0.55936707],
  },
  {
    outward: 'GU6',
    town: 'Cranleigh, Ewhurst, Alfold',
    county: 'Waverley',
    coordinates: [51.140962, -0.48424915],
  },
  {
    outward: 'GU7',
    town: 'Farncombe, Godalming',
    county: 'Waverley',
    coordinates: [51.185998, -0.60575855],
  },
  {
    outward: 'GU8',
    town: 'Chiddingfold, Dunsfold, Elstead, Hascombe, Milford, Thursley, Witley',
    county: 'Waverley',
    coordinates: [51.172317, -0.5927104],
  },
  {
    outward: 'GU9',
    town: 'Farnham, Badshot Lea, Hale, Heath End',
    county: 'Waverley',
    coordinates: [51.235201, -0.81165614],
  },
  {
    outward: 'GU10',
    town: 'Bentley, Frensham, Churt, Crondall, Tongham, Ewshot, Seale, Tilford',
    county: 'Waverley',
    coordinates: [51.233412, -0.73199218],
  },
  {
    outward: 'GU11',
    town: 'Aldershot',
    county: 'Rushmoor',
    coordinates: [51.248742, -0.76117283],
  },
  {
    outward: 'GU12',
    town: 'Aldershot, Ash, Ash Green, Ash Vale',
    county: 'Rushmoor',
    coordinates: [51.238823, -0.74432066],
  },
  {
    outward: 'GU13',
    town: 'Fleet',
    county: 'Hampshire',
  },
  {
    outward: 'GU14',
    town: 'Farnborough, Cove',
    county: 'Rushmoor',
    coordinates: [51.289171, -0.76546319],
  },
  {
    outward: 'GU15',
    town: 'Camberley, Old Dean, RMAS',
    county: 'Surrey Heath',
    coordinates: [51.332793, -0.71697988],
  },
  {
    outward: 'GU16',
    town: 'Frimley, Frimley Green, Deepcut, Mytchett',
    county: 'Surrey Heath',
    coordinates: [51.286937, -0.72641755],
  },
  {
    outward: 'GU17',
    town: 'Blackwater, Hawley, Minley, Darby Green, Frogmore, Blackbushe',
    county: 'Hart',
    coordinates: [51.330814, -0.78095266],
  },
  {
    outward: 'GU18',
    town: 'Lightwater',
    county: 'Surrey Heath',
    coordinates: [51.35209, -0.67014265],
  },
  {
    outward: 'GU19',
    town: 'Bagshot',
    county: 'Surrey Heath',
    coordinates: [51.360778, -0.69353301],
  },
  {
    outward: 'GU20',
    town: 'Windlesham',
    county: 'Surrey Heath',
    coordinates: [51.368465, -0.66383593],
  },
  {
    outward: 'GU21',
    town: "Woking, Knaphill, St. John's, Horsell, Goldsworth Park",
    county: 'Woking',
    coordinates: [51.318119, -0.61295023],
  },
  {
    outward: 'GU22',
    town: 'Woking, Pyrford, Hook Heath, Mayford, Old Woking',
    county: 'Woking',
    coordinates: [51.309189, -0.56957505],
  },
  {
    outward: 'GU23',
    town: 'Send, Ripley, Ockham, Wisley',
    county: 'Guildford',
    coordinates: [51.300154, -0.49233802],
  },
  {
    outward: 'GU24',
    town: 'Bisley, Pirbright, Chobham, Knaphill, Brookwood, West End',
    county: 'Woking',
    coordinates: [51.30442, -0.63868342],
  },
  {
    outward: 'GU25',
    town: 'Virginia Water, Wentworth',
    county: 'Runnymede',
    coordinates: [51.401726, -0.56345564],
  },
  {
    outward: 'GU26',
    town: 'Hindhead, Bramshott Chase, Grayshott',
    county: 'Waverley',
    coordinates: [51.113517, -0.73297592],
  },
  {
    outward: 'GU27',
    town: 'Haslemere, Fernhurst, Shottermill, Grayswood',
    county: 'Waverley',
    coordinates: [51.100596, -0.70672099],
  },
  {
    area: 'GU',
    outward: 'GU27',
    town: 'non-geo shared',
    county: 'Surrey',
  },
  {
    area: 'GU',
    outward: 'GU27',
    town: 'Haslemere (shared)',
    county: 'Surrey',
  },
  {
    outward: 'GU28',
    town: 'Petworth, Graffham, Northchapel, Byworth, Lodsworth',
    county: 'Chichester',
    coordinates: [50.986694, -0.60883085],
  },
  {
    outward: 'GU29',
    town: 'Midhurst, Cocking, Easebourne',
    county: 'Chichester',
    coordinates: [50.999621, -0.73170508],
  },
  {
    outward: 'GU30',
    town: 'Liphook, Bramshott, Conford, Linch, Milland, Passfield',
    county: 'East Hampshire',
    coordinates: [51.076329, -0.80342483],
  },
  {
    outward: 'GU31',
    town: 'Petersfield, Buriton, East Harting, South Harting, Elsted, Rogate',
    county: 'East Hampshire',
    coordinates: [51.002848, -0.9350538],
  },
  {
    outward: 'GU32',
    town: 'East Meon, West Meon, Sheet, Steep',
    county: 'East Hampshire',
    coordinates: [51.034752, -0.94082627],
  },
  {
    outward: 'GU33',
    town: 'Liss, Greatham, Selborne, Rake',
    county: 'East Hampshire',
    coordinates: [51.075687, -0.88777579],
  },
  {
    outward: 'GU34',
    town: 'Alton, Beech, Bentworth, Medstead, Four Marks, Golden Pot, Lasham',
    county: 'East Hampshire',
    coordinates: [51.147191, -0.97697025],
  },
  {
    outward: 'GU35',
    town: 'Bordon, Headley, Headley Down Lindford, Oakhanger, Kingsley, Arford, Whitehill',
    county: 'East Hampshire',
    coordinates: [51.106157, -0.85000645],
  },
  {
    outward: 'GU46',
    town: 'Yateley',
    county: 'Hart',
    coordinates: [51.340877, -0.83215526],
  },
  {
    outward: 'GU47',
    town: 'Sandhurst, College Town, Owlsmoor, Little Sandhurst',
    county: 'Bracknell Forest',
    coordinates: [51.345395, -0.77535381],
  },
  {
    outward: 'GU51',
    town: 'Fleet, Elvetham Heath',
    county: 'Hart',
    coordinates: [51.296113, -0.85570917],
  },
  {
    outward: 'GU52',
    town: 'Church Crookham, Crookham Village',
    county: 'Hart',
    coordinates: [51.255232, -0.84546064],
  },
  {
    outward: 'GU95',
    town: 'British Gas',
    county: 'Guildford',
    coordinates: [51.326293, -0.76307465],
  },
  {
    area: '',
    outward: 'GU95',
    town: 'Non-geographic',
    county: 'Surrey',
  },
  {
    area: 'GY',
    outward: 'GY1',
    town: 'Guernsey',
    county: 'Channel Islesnot',
  },
  {
    area: 'GY',
    outward: 'GY2',
    town: 'Guernsey',
    county: 'Channel Islesnot',
  },
  {
    area: 'GY',
    outward: 'GY3',
    town: 'Guernsey',
    county: 'Channel Islesnot',
  },
  {
    area: 'GY',
    outward: 'GY4',
    town: 'Guernsey',
    county: 'Channel Islesnot',
  },
  {
    area: 'GY',
    outward: 'GY5',
    town: 'Guernsey',
    county: 'Channel Islesnot',
  },
  {
    area: 'GY',
    outward: 'GY6',
    town: 'Guernsey',
    county: 'Channel Islesnot',
  },
  {
    area: 'GY',
    outward: 'GY7',
    town: 'Guernsey',
    county: 'Channel Islesnot',
  },
  {
    area: 'GY',
    outward: 'GY8',
    town: 'Guernsey',
    county: 'Channel Islesnot',
  },
  {
    area: 'GY',
    outward: 'GY9',
    town: 'Guernsey',
    county: 'Channel Islesnot',
  },
  {
    area: 'GY',
    outward: 'GY10',
    town: 'Guernsey',
    county: 'Channel Isles',
  },
  {
    outward: 'HA0',
    town: 'Alperton, Sudbury, Sudbury Hill, Wembley Central, North Wembley',
    county: 'Harrow',
    coordinates: [51.539677, -0.30304808],
  },
  {
    outward: 'HA1',
    town: 'Harrow, North Harrow, Northwick Park',
    county: 'Harrow',
    coordinates: [51.581021, -0.33679754],
  },
  {
    outward: 'HA2',
    town: 'North Harrow, South Harrow',
    county: 'Harrow',
    coordinates: [51.572341, -0.35059235],
  },
  {
    outward: 'HA3',
    town: 'Harrow Weald, Kenton, Wealdstone',
    county: 'Harrow',
    coordinates: [51.579337, -0.31958317],
  },
  {
    outward: 'HA4',
    town: 'Ruislip',
    county: 'Hillingdon',
    coordinates: [51.573425, -0.41357178],
  },
  {
    outward: 'HA5',
    town: 'Pinner, Eastcote, Hatch End, Carpenders Park',
    county: 'Harrow',
    coordinates: [51.592949, -0.38407581],
  },
  {
    outward: 'HA6',
    town: 'Northwood, Moor Park, Sandy Lodge',
    county: 'Hillingdon',
    coordinates: [51.611575, -0.42334862],
  },
  {
    outward: 'HA7',
    town: 'Stanmore',
    county: 'Harrow',
    coordinates: [51.605562, -0.3023113],
  },
  {
    outward: 'HA8',
    town: 'Edgware',
    county: 'Harrow',
    coordinates: [51.606783, -0.27198215],
  },
  {
    outward: 'HA9',
    town: 'Wembley, Wembley Park, Wembley Central, Preston, Tokyngton',
    county: 'Brent',
    coordinates: [51.557908, -0.28351498],
  },
  {
    area: 'HD',
    outward: 'HD1',
    town: 'Huddersfield',
    county: 'West Yorkshire',
  },
  {
    area: 'HD',
    outward: 'HD2',
    town: 'Huddersfield',
    county: 'West Yorkshire',
  },
  {
    area: 'HD',
    outward: 'HD3',
    town: 'Huddersfield',
    county: 'West Yorkshire',
  },
  {
    area: 'HD',
    outward: 'HD4',
    town: 'Huddersfield',
    county: 'West Yorkshire',
  },
  {
    area: 'HD',
    outward: 'HD5',
    town: 'Huddersfield',
    county: 'West Yorkshire',
  },
  {
    area: 'HD',
    outward: 'HD6',
    town: 'Brighouse',
    county: 'West Yorkshire',
  },
  {
    area: 'HD',
    outward: 'HD7',
    town: 'Huddersfield',
    county: 'West Yorkshire',
  },
  {
    area: 'HD',
    outward: 'HD8',
    town: 'Huddersfield',
    county: 'West Yorkshire',
  },
  {
    area: 'HD',
    outward: 'HD9',
    town: 'Holmfirth',
    county: 'West Yorkshire',
  },
  {
    area: 'HG',
    outward: 'HG1',
    town: 'Harrogate',
    county: 'North Yorkshire',
  },
  {
    area: 'HG',
    outward: 'HG2',
    town: 'Harrogate',
    county: 'North Yorkshire',
  },
  {
    area: 'HG',
    outward: 'HG3',
    town: 'Harrogate',
    county: 'North Yorkshire',
  },
  {
    area: 'HG',
    outward: 'HG4',
    town: 'Ripon',
    county: 'North Yorkshire',
  },
  {
    area: 'HG',
    outward: 'HG5',
    town: 'Knaresborough',
    county: 'North Yorkshire',
  },
  {
    outward: 'HP1',
    town:
      "Bourne End, Boxmoor, Fields End, Gadebridge, Great Gaddesden, Nettleden, Piccotts End, Water End, Warner's End",
    county: 'Dacorum',
    coordinates: [51.7484, -0.46988523],
  },
  {
    outward: 'HP2',
    town: 'Gaddesden Row, Piccotts End, Grove Hill, Adeyfield, Hemel Hempstead Industrial Estate',
    county: 'Dacorum',
    coordinates: [51.752926, -0.47009449],
  },
  {
    outward: 'HP3',
    town: 'Apsley, Bovingdon, Felden, Flaunden, Hemel Hempstead, Leverstock Green',
    county: 'Dacorum',
    coordinates: [51.728821, -0.4709229],
  },
  {
    outward: 'HP4',
    town: 'Berkhamsted, Dagnall, Dudswell, Little Gaddesden, Northchurch, Potten End, Ringshall',
    county: 'Dacorum',
    coordinates: [51.761823, -0.56964752],
  },
  {
    outward: 'HP5',
    town:
      'Chesham, Ashley Green, Bellingdon, Botley, Chartridge, Hawridge, Latimer, Lye Green, Newtown, Waterside, Whelpley Hill',
    county: 'Chiltern',
    coordinates: [51.707518, -0.61061154],
  },
  {
    outward: 'HP6',
    town: 'Amersham, Chesham Bois, Hyde Heath, Little Chalfont',
    county: 'Chiltern',
    coordinates: [51.67368, -0.59980262],
  },
  {
    outward: 'HP7',
    town: 'Amersham, Coleshill, Little Chalfont, Little Missenden, Penn Street, Winchmore Hill',
    county: 'Chiltern',
    coordinates: [51.673717, -0.60898496],
  },
  {
    outward: 'HP8',
    town: 'Chalfont Saint Giles',
    county: 'South Buckinghamshire',
    coordinates: [51.648152, -0.55091048],
  },
  {
    outward: 'HP9',
    town: 'Beaconsfield, Forty Green, Holtspur, Jordans, Knotty Green, Seer Green',
    county: 'Chiltern',
    coordinates: [51.607721, -0.64157468],
  },
  {
    outward: 'HP10',
    town:
      'High Wycombe, Flackwell Heath, Handy Cross, Hedsor, Loudwater, Penn, Tylers Green, Wooburn, Wooburn Green, Wooburn Moor',
    county: 'Wycombe',
    coordinates: [51.589355, -0.68144421],
  },
  {
    outward: 'HP11',
    town: 'Wycombe Marsh',
    county: 'Wycombe',
    coordinates: [51.62776, -0.74800293],
  },
  {
    outward: 'HP12',
    town: 'High Wycombe, Booker, Sands',
    county: 'Wycombe',
    coordinates: [51.635855, -0.76698401],
  },
  {
    outward: 'HP13',
    town: 'High Wycombe, Downley',
    county: 'Wycombe',
    coordinates: [51.642309, -0.73632974],
  },
  {
    outward: 'HP14',
    town:
      "Beacon's Bottom, Bledlow Ridge, Bolter End, Bradenham, Cadmore End, Hughenden Valley, Ibstone, Lane End, Naphill, Northend, Stokenchurch, The City, Upper North Dean, West Wycombe",
    county: 'Wycombe',
    coordinates: [51.644412, -0.79700128],
  },
  {
    outward: 'HP15',
    town: 'Cryers Hill, Great Kingshill, Hazlemere, Holmer Green, Hughenden Valley',
    county: 'Wycombe',
    coordinates: [51.662146, -0.73227913],
  },
  {
    outward: 'HP16',
    town: 'Great Missenden, Ballinger, The Lee, Little Hampden, Little Kingshill, Prestwood, South Heath',
    county: 'Wycombe',
    coordinates: [51.703112, -0.70667797],
  },
  {
    outward: 'HP17',
    town:
      "Aston Sandford, Bishopstone, Butler's Cross, Dinton, Dorton, Ellesborough, Ford, The Kimbles, Kingsey, Haddenham, Meadle, Nash Lee, Stone, Upton, Westlington",
    county: 'Aylesbury Vale',
    coordinates: [51.75603, -0.80812058],
  },
  {
    outward: 'HP18',
    town:
      'Ashendon, Boarstall, Brill, Chearsley, Chilton, Cuddington, Dorton, Easington, Edgcott, Grendon Underwood, Ickford, Kingswood, Long Crendon, Lower Winchendon, Ludgershall, Oakley, Shabbington, Upper Winchendon, Waddesdon, Westcott, Worminghall, Wotton Underwood',
    county: 'Aylesbury Vale',
    coordinates: [51.791193, -0.91263375],
  },
  {
    outward: 'HP19',
    town: 'Aylesbury, Fairford Leys, Prebendal Farm, Quarrendon, Watermead',
    county: 'Aylesbury Vale',
    coordinates: [51.83341, -0.80536799],
  },
  {
    outward: 'HP20',
    town: 'Aylesbury, Aylesbury Town Centre, Broughton, Elmhurst',
    county: 'Aylesbury Vale',
    coordinates: [51.818553, -0.80947501],
  },
  {
    outward: 'HP21',
    town: 'Aylesbury, Bedgrove, Elm Farm, Queens Park, Southcourt, Walton, Walton Court',
    county: 'Aylesbury Vale',
    coordinates: [51.812594, -0.78337551],
  },
  {
    outward: 'HP22',
    town:
      'Aston Abbotts, Aston Clinton, Bierton, Buckland, Drayton Beauchamp, Dunsmore, Halton, Hardwick, Hulcott, North Lee, Nup End, Oving, Pitchcott, Quainton, Rowsham, Stoke Mandeville, Weedon, Wendover, Weston Turville, Whitchurch, Wingrave',
    county: 'Aylesbury Vale',
    coordinates: [51.851989, -0.87122786],
  },
  {
    area: 'HP',
    outward: 'HP22',
    town: 'non-geo shared',
    county: 'Buckinghamshire',
  },
  {
    outward: 'HP23',
    town:
      'Aldbury, Buckland Common, Cholesbury, Hastoe, Long Marston, Marsworth, New Mill, Puttenham, St Leonards, Tring, Wigginton, Wilstone',
    county: 'Dacorum',
    coordinates: [51.793238, -0.66352991],
  },
  {
    outward: 'HP27',
    town:
      'Askett, Bledlow, Ilmer, Lacey Green, Longwick, Loosley Row, Monks Risborough, Owlswick, Pitch Green, Princes Risborough, Saunderton',
    county: 'Wycombe',
    coordinates: [51.722499, -0.83126869],
  },
  {
    area: 'HP',
    outward: 'HP27',
    town: 'Princes Risborough',
    county: 'Buckinghamshire',
  },
  {
    area: 'HR',
    outward: 'HR1',
    town: 'Hereford',
    county: 'Herefordshire',
  },
  {
    area: 'HR',
    outward: 'HR2',
    town: 'Hereford',
    county: 'Herefordshire',
  },
  {
    area: 'HR',
    outward: 'HR3',
    town: 'Hereford',
    county: 'Herefordshire',
  },
  {
    area: 'HR',
    outward: 'HR4',
    town: 'Hereford',
    county: 'Herefordshire',
  },
  {
    area: 'HR',
    outward: 'HR5',
    town: 'KINGTON',
    county: 'Herefordshire',
  },
  {
    area: 'HR',
    outward: 'HR6',
    town: 'Leominster',
    county: 'Herefordshire',
  },
  {
    area: 'HR',
    outward: 'HR7',
    town: 'Bromyard',
    county: 'Herefordshire',
  },
  {
    area: 'HR',
    outward: 'HR8',
    town: 'Ledbury',
    county: 'Herefordshire',
  },
  {
    area: 'HR',
    outward: 'HR9',
    town: 'Ross-on-Wye',
    county: 'Herefordshire',
  },
  {
    area: 'HS',
    outward: 'HS1',
    town: 'Stornoway',
    county: 'Isle of Lewis',
  },
  {
    area: 'HS',
    outward: 'HS2',
    town: 'ISLE OF LEWIS',
    county: 'Isle of Lewis',
  },
  {
    area: 'HS',
    outward: 'HS3',
    town: 'ISLE OF HARRIS',
    county: 'Isle of Harris',
  },
  {
    area: 'HS',
    outward: 'HS4',
    town: 'ISLE OF SCALPAY',
    county: 'Isle of Scalpay',
  },
  {
    area: 'HS',
    outward: 'HS5',
    town: 'ISLE OF HARRIS',
    county: 'Isle of Harris',
  },
  {
    area: 'HS',
    outward: 'HS6',
    town: 'ISLE OF NORTH UIST',
    county: 'Isle of North Uist',
  },
  {
    area: 'HS',
    outward: 'HS7',
    town: 'ISLE OF BENBECULA',
    county: 'Isle of Benbecula',
  },
  {
    area: 'HS',
    outward: 'HS8',
    town: 'ISLE OF SOUTH UIST',
    county: 'Inverness-shire',
  },
  {
    area: 'HS',
    outward: 'HS9',
    town: 'ISLE OF BARRA',
    county: 'Isle of Barra',
  },
  {
    area: 'HU',
    outward: 'HU1',
    town: 'HULL',
    county: 'North Humberside',
  },
  {
    area: 'HU',
    outward: 'HU2',
    town: 'HULL',
    county: 'North Humberside',
  },
  {
    area: 'HU',
    outward: 'HU3',
    town: 'HULL',
    county: 'North Humberside',
  },
  {
    area: 'HU',
    outward: 'HU4',
    town: 'HULL',
    county: 'North Humberside',
  },
  {
    area: 'HU',
    outward: 'HU5',
    town: 'HULL',
    county: 'North Humberside',
  },
  {
    area: 'HU',
    outward: 'HU6',
    town: 'HULL',
    county: 'North Humberside',
  },
  {
    area: 'HU',
    outward: 'HU7',
    town: 'HULL',
    county: 'North Humberside',
  },
  {
    area: 'HU',
    outward: 'HU8',
    town: 'HULL',
    county: 'North Humberside',
  },
  {
    area: 'HU',
    outward: 'HU9',
    town: 'HULL',
    county: 'North Humberside',
  },
  {
    area: 'HU',
    outward: 'HU10',
    town: 'HULL',
    county: 'North Humberside',
  },
  {
    area: 'HU',
    outward: 'HU11',
    town: 'HULL',
    county: 'North Humberside',
  },
  {
    area: 'HU',
    outward: 'HU12',
    town: 'HULL',
    county: 'North Humberside',
  },
  {
    area: 'HU',
    outward: 'HU13',
    town: 'Hessle',
    county: 'North Humberside',
  },
  {
    area: 'HU',
    outward: 'HU14',
    town: 'North Ferriby',
    county: 'North Humberside',
  },
  {
    area: 'HU',
    outward: 'HU15',
    town: 'BROUGH',
    county: 'North Humberside',
  },
  {
    area: 'HU',
    outward: 'HU16',
    town: 'COTTINGHAM',
    county: 'North Humberside',
  },
  {
    area: 'HU',
    outward: 'HU17',
    town: 'Beverley',
    county: 'North Humberside',
  },
  {
    area: 'HU',
    outward: 'HU18',
    town: 'Hornsea',
    county: 'North Humberside',
  },
  {
    area: 'HU',
    outward: 'HU19',
    town: 'Withernsea',
    county: 'North Humberside',
  },
  {
    area: 'HU',
    outward: 'HU20',
    town: 'COTTINGHAM',
    county: 'North Humberside',
  },
  {
    area: 'HX',
    outward: 'HX1',
    town: 'HALIFAX (shared)',
    county: 'West Yorkshire',
  },
  {
    area: 'HX',
    outward: 'HX1',
    town: 'non-geo shared',
    county: 'West Yorkshire',
  },
  {
    area: 'HX',
    outward: 'HX2',
    town: 'HALIFAX',
    county: 'West Yorkshire',
  },
  {
    area: 'HX',
    outward: 'HX3',
    town: 'HALIFAX',
    county: 'West Yorkshire',
  },
  {
    area: 'HX',
    outward: 'HX4',
    town: 'HALIFAX',
    county: 'West Yorkshire',
  },
  {
    area: 'HX',
    outward: 'HX5',
    town: 'Elland',
    county: 'West Yorkshire',
  },
  {
    area: 'HX',
    outward: 'HX6',
    town: 'Sowerby Bridge',
    county: 'West Yorkshire',
  },
  {
    area: 'HX',
    outward: 'HX7',
    town: 'Hebden Bridge',
    county: 'West Yorkshire',
  },
  {
    outward: 'IG1',
    town: 'Ilford',
    county: 'Redbridge',
    coordinates: [51.558255, 0.072064067],
  },
  {
    outward: 'IG2',
    town: 'Gants Hill, Newbury Park',
    county: 'Redbridge',
    coordinates: [51.572847, 0.082411036],
  },
  {
    outward: 'IG3',
    town: 'Seven Kings',
    county: 'Redbridge',
    coordinates: [51.561221, 0.092945837],
  },
  {
    outward: 'IG4',
    town: 'Redbridge',
    county: 'Redbridge',
    coordinates: [51.576197, 0.057668342],
  },
  {
    outward: 'IG5',
    town: 'Clayhall',
    county: 'Redbridge',
    coordinates: [51.582095, 0.061341249],
  },
  {
    outward: 'IG6',
    town: 'Barkingside, Hainault',
    county: 'Redbridge',
    coordinates: [51.578987, 0.082518528],
  },
  {
    outward: 'IG7',
    town: 'Chigwell',
    county: 'Epping Forest',
    coordinates: [51.60901, 0.11092931],
  },
  {
    outward: 'IG8',
    town: 'Woodford Green, Woodford Bridge',
    county: 'Redbridge',
    coordinates: [51.607377, 0.023391276],
  },
  {
    area: 'IG',
    outward: 'IG8shared[6]',
    town: 'Chigwell',
    county: 'Essex',
  },
  {
    outward: 'IG9',
    town: 'Buckhurst Hill',
    county: 'Epping Forest',
    coordinates: [51.623454, 0.044766103],
  },
  {
    outward: 'IG10',
    town: 'Loughton, High Beach',
    county: 'Epping Forest',
    coordinates: [51.647746, 0.055948672],
  },
  {
    outward: 'IG11',
    town: 'Barking',
    county: 'Barking and Dagenham',
    coordinates: [51.525635, 0.079910083],
  },
  {
    area: 'IM',
    outward: 'IM1',
    town: 'Isle of Man',
    county: 'Isle of Mannot',
  },
  {
    area: 'IM',
    outward: 'IM2',
    town: 'Isle of Man',
    county: 'Isle of Mannot',
  },
  {
    area: 'IM',
    outward: 'IM3',
    town: 'Isle of Man',
    county: 'Isle of Mannot',
  },
  {
    area: 'IM',
    outward: 'IM4',
    town: 'Isle of Man',
    county: 'Isle of Mannot',
  },
  {
    area: 'IM',
    outward: 'IM5',
    town: 'Isle of Man',
    county: 'Isle of Mannot',
  },
  {
    area: 'IM',
    outward: 'IM6',
    town: 'Isle of Man',
    county: 'Isle of Mannot',
  },
  {
    area: 'IM',
    outward: 'IM7',
    town: 'Isle of Man',
    county: 'Isle of Mannot',
  },
  {
    area: 'IM',
    outward: 'IM8',
    town: 'Isle of Man',
    county: 'Isle of Mannot',
  },
  {
    area: 'IM',
    outward: 'IM9',
    town: 'Isle of Man',
    county: 'Isle of Mannot',
  },
  {
    area: '',
    outward: 'IM99',
    town: 'Non-geographic',
    county: 'Isle of Man',
  },
  {
    area: 'IP',
    outward: 'IP1',
    town: 'Ipswich',
    county: 'Suffolk',
  },
  {
    area: 'IP',
    outward: 'IP2',
    town: 'Ipswich',
    county: 'Suffolk',
  },
  {
    area: 'IP',
    outward: 'IP3',
    town: 'Ipswich',
    county: 'Suffolk',
  },
  {
    area: 'IP',
    outward: 'IP4',
    town: 'Ipswich',
    county: 'Suffolk',
  },
  {
    area: 'IP',
    outward: 'IP5',
    town: 'Ipswich',
    county: 'Suffolk',
  },
  {
    area: 'IP',
    outward: 'IP6',
    town: 'Ipswich',
    county: 'Suffolk',
  },
  {
    area: 'IP',
    outward: 'IP7',
    town: 'Ipswich',
    county: 'Suffolk',
  },
  {
    area: 'IP',
    outward: 'IP8',
    town: 'Ipswich',
    county: 'Suffolk',
  },
  {
    area: 'IP',
    outward: 'IP9',
    town: 'Ipswich',
    county: 'Suffolk',
  },
  {
    area: '',
    outward: 'IP10',
    town: 'Ipswich',
    county: 'Suffolk',
  },
  {
    area: 'IP',
    outward: 'IP11',
    town: 'Felixstowe',
    county: 'Suffolk',
  },
  {
    area: 'IP',
    outward: 'IP12',
    town: 'WOODBRIDGE',
    county: 'Suffolk',
  },
  {
    area: 'IP',
    outward: 'IP13',
    town: 'WOODBRIDGE',
    county: 'Suffolk',
  },
  {
    area: 'IP',
    outward: 'IP14',
    town: 'Stowmarket',
    county: 'Suffolk',
  },
  {
    area: 'IP',
    outward: 'IP15',
    town: 'Aldeburgh',
    county: 'Suffolk',
  },
  {
    area: 'IP',
    outward: 'IP16',
    town: 'Leiston',
    county: 'Suffolk',
  },
  {
    area: 'IP',
    outward: 'IP17',
    town: 'Saxmundham',
    county: 'Suffolk',
  },
  {
    area: 'IP',
    outward: 'IP18',
    town: 'Southwold',
    county: 'Suffolk',
  },
  {
    area: 'IP',
    outward: 'IP19',
    town: 'Halesworth',
    county: 'Suffolk',
  },
  {
    area: 'IP',
    outward: 'IP20',
    town: 'HARLESTON',
    county: 'Norfolk',
  },
  {
    area: 'IP',
    outward: 'IP21',
    town: 'Diss (shared)',
    county: 'Norfolk',
  },
  {
    area: 'IP',
    outward: 'IP21',
    town: 'EYE (shared)',
    county: 'Suffolk',
  },
  {
    area: 'IP',
    outward: 'IP22',
    town: 'Diss',
    county: 'Norfolk',
  },
  {
    area: 'IP',
    outward: 'IP23',
    town: 'EYE',
    county: 'Suffolk',
  },
  {
    area: 'IP',
    outward: 'IP24',
    town: 'Thetford',
    county: 'Norfolk',
  },
  {
    area: 'IP',
    outward: 'IP25',
    town: 'Thetford',
    county: 'Norfolk',
  },
  {
    area: 'IP',
    outward: 'IP26',
    town: 'Thetford',
    county: 'Norfolk',
  },
  {
    area: 'IP',
    outward: 'IP27',
    town: 'BRANDON',
    county: 'Suffolk',
  },
  {
    area: 'IP',
    outward: 'IP28',
    town: 'BURY ST. EDMUNDS',
    county: 'Suffolk',
  },
  {
    area: 'IP',
    outward: 'IP29',
    town: 'BURY ST. EDMUNDS',
    county: 'Suffolk',
  },
  {
    area: '',
    outward: 'IP30',
    town: 'BURY ST. EDMUNDS',
    county: 'Suffolk',
  },
  {
    area: '',
    outward: 'IP31',
    town: 'BURY ST. EDMUNDS',
    county: 'Suffolk',
  },
  {
    area: '',
    outward: 'IP32',
    town: 'BURY ST. EDMUNDS',
    county: 'Suffolk',
  },
  {
    area: '',
    outward: 'IP33',
    town: 'BURY ST. EDMUNDS',
    county: 'Suffolk',
  },
  {
    area: 'IP',
    outward: 'IP98',
    town: 'Non-geographic',
    county: 'Norfolk',
  },
  {
    area: 'IV',
    outward: 'IV1',
    town: 'Inverness',
    county: 'Inverness-shire',
  },
  {
    area: 'IV',
    outward: 'IV2',
    town: 'Inverness',
    county: 'Inverness-shire',
  },
  {
    area: 'IV',
    outward: 'IV3',
    town: 'Inverness',
    county: 'Inverness-shire',
  },
  {
    area: 'IV',
    outward: 'IV4',
    town: 'Beauly',
    county: 'Inverness-shire',
  },
  {
    area: 'IV',
    outward: 'IV5',
    town: 'Inverness',
    county: 'Inverness-shire',
  },
  {
    area: 'IV',
    outward: 'IV7',
    town: 'Dingwall',
    county: 'Ross-shire',
  },
  {
    area: 'IV',
    outward: 'IV8',
    town: 'Munlochy',
    county: 'Ross-shire',
  },
  {
    area: 'IV',
    outward: 'IV10',
    town: 'Fortrose',
    county: 'Ross-shire',
  },
  {
    area: 'IV',
    outward: 'IV11',
    town: 'Cromarty',
    county: 'Ross-shire',
  },
  {
    area: 'IV',
    outward: 'IV12',
    town: 'Nairn',
    county: 'Nairnshire',
  },
  {
    area: '',
    outward: 'IV13',
    town: 'Inverness',
    county: 'Inverness-shire',
  },
  {
    area: 'IV',
    outward: 'IV14',
    town: 'Strathpeffer',
    county: 'Ross-shire',
  },
  {
    area: '',
    outward: 'IV15',
    town: 'Dingwall',
    county: 'Ross-shire',
  },
  {
    area: '',
    outward: 'IV16',
    town: 'Dingwall',
    county: 'Ross-shire',
  },
  {
    area: 'IV',
    outward: 'IV17',
    town: 'Alness',
    county: 'Ross-shire',
  },
  {
    area: 'IV',
    outward: 'IV18',
    town: 'Invergordon',
    county: 'Ross-shire',
  },
  {
    area: 'IV',
    outward: 'IV19',
    town: 'Tain',
    county: 'Ross-shire',
  },
  {
    area: 'IV',
    outward: 'IV20',
    town: 'Tain',
    county: 'Ross-shire',
  },
  {
    area: 'IV',
    outward: 'IV21',
    town: 'Gairloch',
    county: 'Ross-shire',
  },
  {
    area: 'IV',
    outward: 'IV22',
    town: 'Achnasheen',
    county: 'Ross-shire',
  },
  {
    area: 'IV',
    outward: 'IV23',
    town: 'Garve',
    county: 'Ross-shire',
  },
  {
    area: 'IV',
    outward: 'IV24',
    town: 'Ardgay',
    county: 'Sutherland',
  },
  {
    area: 'IV',
    outward: 'IV25',
    town: 'Dornoch',
    county: 'Sutherland',
  },
  {
    area: 'IV',
    outward: 'IV26',
    town: 'Ullapool',
    county: 'Ross-shire',
  },
  {
    area: 'IV',
    outward: 'IV27',
    town: 'Lairg',
    county: 'Sutherland',
  },
  {
    area: 'IV',
    outward: 'IV28',
    town: 'Rogart',
    county: 'Sutherland',
  },
  {
    area: 'IV',
    outward: 'IV30',
    town: 'ELGIN',
    county: 'Morayshire',
  },
  {
    area: 'IV',
    outward: 'IV31',
    town: 'Lossiemouth',
    county: 'Morayshire',
  },
  {
    area: 'IV',
    outward: 'IV32',
    town: 'Fochabers',
    county: 'Morayshire',
  },
  {
    area: 'IV',
    outward: 'IV36',
    town: 'Forres',
    county: 'Morayshire',
  },
  {
    area: 'IV',
    outward: 'IV40',
    town: 'KYLE',
    county: 'Ross-shire',
  },
  {
    area: 'IV',
    outward: 'IV41',
    town: 'ISLE OF SKYE',
    county: 'Isle of Skye',
  },
  {
    area: 'IV',
    outward: 'IV42',
    town: 'ISLE OF SKYE',
    county: 'Isle of Skye',
  },
  {
    area: 'IV',
    outward: 'IV43',
    town: 'ISLE OF SKYE',
    county: 'Isle of Skye',
  },
  {
    area: 'IV',
    outward: 'IV44',
    town: 'ISLE OF SKYE',
    county: 'Isle of Skye',
  },
  {
    area: 'IV',
    outward: 'IV45',
    town: 'ISLE OF SKYE',
    county: 'Isle of Skye',
  },
  {
    area: 'IV',
    outward: 'IV46',
    town: 'ISLE OF SKYE',
    county: 'Isle of Skye',
  },
  {
    area: 'IV',
    outward: 'IV47',
    town: 'ISLE OF SKYE',
    county: 'Isle of Skye',
  },
  {
    area: 'IV',
    outward: 'IV48',
    town: 'ISLE OF SKYE',
    county: 'Isle of Skye',
  },
  {
    area: 'IV',
    outward: 'IV49',
    town: 'ISLE OF SKYE',
    county: 'Isle of Skye',
  },
  {
    area: 'IV',
    outward: 'IV51',
    town: 'Portree',
    county: 'Isle of Skye',
  },
  {
    area: 'IV',
    outward: 'IV52',
    town: 'Plockton',
    county: 'Ross-shire',
  },
  {
    area: 'IV',
    outward: 'IV53',
    town: 'STROME FERRY',
    county: 'Ross-shire',
  },
  {
    area: 'IV',
    outward: 'IV54',
    town: 'STRATHCARRON',
    county: 'Ross-shire',
  },
  {
    area: '',
    outward: 'IV55',
    town: 'ISLE OF SKYE',
    county: 'Isle of Skye',
  },
  {
    area: '',
    outward: 'IV56',
    town: 'ISLE OF SKYE',
    county: 'Isle of Skye',
  },
  {
    area: '',
    outward: 'IV63',
    town: 'Inverness',
    county: 'Inverness-shire',
  },
  {
    area: '',
    outward: 'IV99',
    town: 'Non-geographic',
    county: 'Inverness-shire',
  },
  {
    area: 'JE',
    outward: 'JE1',
    town: 'Non-geographic',
    county: 'Channel Islesnot',
  },
  {
    area: 'JE',
    outward: 'JE2',
    town: 'Jersey',
    county: 'Channel Islesnot',
  },
  {
    area: 'JE',
    outward: 'JE3',
    town: 'Jersey',
    county: 'Channel Islesnot',
  },
  {
    area: 'JE',
    outward: 'JE4',
    town: 'Non-geographic',
    county: 'Channel Islesnot',
  },
  {
    area: 'JE',
    outward: 'JE5',
    town: 'Jersey',
    county: 'Channel Islesnot',
  },
  {
    area: 'KA',
    outward: 'KA1',
    town: 'Kilmarnock',
    county: 'Ayrshire',
  },
  {
    area: 'KA',
    outward: 'KA2',
    town: 'Kilmarnock',
    county: 'Ayrshire',
  },
  {
    area: 'KA',
    outward: 'KA3',
    town: 'Kilmarnock',
    county: 'Ayrshire',
  },
  {
    area: 'KA',
    outward: 'KA4',
    town: 'GALSTON',
    county: 'Ayrshire',
  },
  {
    area: 'KA',
    outward: 'KA5',
    town: 'Mauchline',
    county: 'Ayrshire',
  },
  {
    area: 'KA',
    outward: 'KA6',
    town: 'Ayr',
    county: 'Ayrshire',
  },
  {
    area: 'KA',
    outward: 'KA7',
    town: 'Ayr',
    county: 'Ayrshire',
  },
  {
    area: 'KA',
    outward: 'KA8',
    town: 'Ayr',
    county: 'Ayrshire',
  },
  {
    area: 'KA',
    outward: 'KA9',
    town: 'Prestwick',
    county: 'Ayrshire',
  },
  {
    area: 'KA',
    outward: 'KA10',
    town: 'Troon',
    county: 'Ayrshire',
  },
  {
    area: 'KA',
    outward: 'KA11',
    town: 'IRVINE',
    county: 'Ayrshire',
  },
  {
    area: 'KA',
    outward: 'KA12',
    town: 'IRVINE',
    county: 'Ayrshire',
  },
  {
    area: 'KA',
    outward: 'KA13',
    town: 'Kilwinning',
    county: 'Ayrshire',
  },
  {
    area: 'KA',
    outward: 'KA14',
    town: 'Beith',
    county: 'Ayrshire',
  },
  {
    area: 'KA',
    outward: 'KA15',
    town: 'Beith',
    county: 'Ayrshire',
  },
  {
    area: 'KA',
    outward: 'KA16',
    town: 'Newmilns',
    county: 'Ayrshire',
  },
  {
    area: 'KA',
    outward: 'KA17',
    town: 'Darvel',
    county: 'Ayrshire',
  },
  {
    area: 'KA',
    outward: 'KA18',
    town: 'Cumnock',
    county: 'Ayrshire',
  },
  {
    area: 'KA',
    outward: 'KA19',
    town: 'Maybole',
    county: 'Ayrshire',
  },
  {
    area: 'KA',
    outward: 'KA20',
    town: 'Stevenston',
    county: 'Ayrshire',
  },
  {
    area: 'KA',
    outward: 'KA21',
    town: 'Saltcoats',
    county: 'Ayrshire',
  },
  {
    area: 'KA',
    outward: 'KA22',
    town: 'Ardrossan',
    county: 'Ayrshire',
  },
  {
    area: 'KA',
    outward: 'KA23',
    town: 'West Kilbride',
    county: 'Ayrshire',
  },
  {
    area: 'KA',
    outward: 'KA24',
    town: 'DALRY',
    county: 'Ayrshire',
  },
  {
    area: 'KA',
    outward: 'KA25',
    town: 'Kilbirnie',
    county: 'Ayrshire',
  },
  {
    area: 'KA',
    outward: 'KA26',
    town: 'Girvan',
    county: 'Ayrshire',
  },
  {
    area: 'KA',
    outward: 'KA27',
    town: 'Isle of Arran',
    county: 'Isle of Arran',
  },
  {
    area: 'KA',
    outward: 'KA28',
    town: 'ISLE OF CUMBRAE',
    county: 'Isle of Cumbrae',
  },
  {
    area: 'KA',
    outward: 'KA29',
    town: 'Largs',
    county: 'Ayrshire',
  },
  {
    area: '',
    outward: 'KA30',
    town: 'Largs',
    county: 'Ayrshire',
  },
  {
    outward: 'KT1',
    town: 'Kingston upon Thames, Hampton Wick',
    county: 'Kingston upon Thames',
    coordinates: [51.408596, -0.30433927],
  },
  {
    outward: 'KT2',
    town: 'Kingston upon Thames, Norbiton',
    county: 'Kingston upon Thames',
    coordinates: [51.413955, -0.30242972],
  },
  {
    outward: 'KT3',
    town: 'New Malden',
    county: 'Kingston upon Thames',
    coordinates: [51.402625, -0.258942],
  },
  {
    outward: 'KT4',
    town: 'Worcester Park',
    county: 'Sutton',
    coordinates: [51.378171, -0.24427923],
  },
  {
    outward: 'KT5',
    town: 'Berrylands, Tolworth',
    county: 'Kingston upon Thames',
    coordinates: [51.395788, -0.29735236],
  },
  {
    outward: 'KT6',
    town: 'Surbiton, Tolworth, Long Ditton',
    county: 'Kingston upon Thames',
    coordinates: [51.392393, -0.31065911],
  },
  {
    outward: 'KT7',
    town: 'Thames Ditton, Weston Green',
    county: 'Elmbridge',
    coordinates: [51.383516, -0.32183603],
  },
  {
    outward: 'KT8',
    town: 'West Molesey, East Molesey, Hampton Court Palace, Bushy Park',
    county: 'Elmbridge',
    coordinates: [51.397497, -0.34889621],
  },
  {
    area: 'KT',
    outward: 'KT8',
    town: 'East Molesey (shared)',
    county: 'Surrey',
  },
  {
    outward: 'KT9',
    town: 'Chessington, Hook, Malden Rushett',
    county: 'Kingston upon Thames',
    coordinates: [51.367414, -0.30584649],
  },
  {
    outward: 'KT10',
    town: 'Esher, Claygate, Hinchley Wood',
    county: 'Elmbridge',
    coordinates: [51.376481, -0.3373816],
  },
  {
    outward: 'KT11',
    town: 'Cobham',
    county: 'Elmbridge',
    coordinates: [51.32952, -0.41379281],
  },
  {
    outward: 'KT12',
    town: 'Walton-on-Thames, Hersham',
    county: 'Elmbridge',
    coordinates: [51.386684, -0.42035398],
  },
  {
    outward: 'KT13',
    town: 'Weybridge',
    county: 'Elmbridge',
    coordinates: [51.366804, -0.43994312],
  },
  {
    outward: 'KT14',
    town: 'Byfleet, West Byfleet',
    county: 'Elmbridge',
    coordinates: [51.334371, -0.51137075],
  },
  {
    outward: 'KT15',
    town: 'Addlestone, New Haw',
    county: 'Runnymede',
    coordinates: [51.367194, -0.50648551],
  },
  {
    outward: 'KT16',
    town: 'Chertsey, Lyne',
    county: 'Runnymede',
    coordinates: [51.387935, -0.52748335],
  },
  {
    outward: 'KT17',
    town: 'Stoneleigh, East Ewell, Epsom',
    county: 'Epsom and Ewell',
    coordinates: [51.335322, -0.26244034],
  },
  {
    outward: 'KT18',
    town: 'Epsom, Headley, Langley Vale, Tattenham Corner',
    county: 'Epsom and Ewell',
    coordinates: [51.332345, -0.26726132],
  },
  {
    outward: 'KT19',
    town: 'Horton, West Ewell, Stoneleigh',
    county: 'Epsom and Ewell',
    coordinates: [51.360472, -0.25317219],
  },
  {
    outward: 'KT20',
    town: 'Tadworth, Burgh Heath',
    county: 'Reigate and Banstead',
    coordinates: [51.29135, -0.23730943],
  },
  {
    outward: 'KT21',
    town: 'Ashtead',
    county: 'Mole Valley',
    coordinates: [51.310089, -0.29860366],
  },
  {
    outward: 'KT22',
    town: 'Leatherhead, Oxshott, Fetcham',
    county: 'Mole Valley',
    coordinates: [51.30148, -0.34096798],
  },
  {
    outward: 'KT23',
    town: 'The Bookhams',
    county: 'Mole Valley',
    coordinates: [51.279714, -0.38770682],
  },
  {
    outward: 'KT24',
    town: 'West Horsley, East Horsley, Effingham',
    county: 'Guildford',
    coordinates: [51.268213, -0.43076957],
  },
  {
    area: 'KW',
    outward: 'KW1',
    town: 'WICK',
    county: 'Caithness',
  },
  {
    area: 'KW',
    outward: 'KW2',
    town: 'Lybster',
    county: 'Caithness',
  },
  {
    area: 'KW',
    outward: 'KW3',
    town: 'Lybster',
    county: 'Caithness',
  },
  {
    area: 'KW',
    outward: 'KW5',
    town: 'Latheron',
    county: 'Caithness',
  },
  {
    area: 'KW',
    outward: 'KW6',
    town: 'Dunbeath',
    county: 'Caithness',
  },
  {
    area: 'KW',
    outward: 'KW7',
    town: 'BERRIEDALE',
    county: 'Caithness',
  },
  {
    area: 'KW',
    outward: 'KW8',
    town: 'Helmsdale',
    county: 'Sutherland',
  },
  {
    area: 'KW',
    outward: 'KW9',
    town: 'Brora',
    county: 'Sutherland',
  },
  {
    area: 'KW',
    outward: 'KW10',
    town: 'Golspie',
    county: 'Sutherland',
  },
  {
    area: 'KW',
    outward: 'KW11',
    town: 'Kinbrace',
    county: 'Sutherland',
  },
  {
    area: 'KW',
    outward: 'KW12',
    town: 'Halkirk',
    county: 'Caithness',
  },
  {
    area: 'KW',
    outward: 'KW13',
    town: 'Forsinard',
    county: 'Sutherland',
  },
  {
    area: 'KW',
    outward: 'KW14',
    town: 'Thurso',
    county: 'Caithness',
  },
  {
    area: 'KW',
    outward: 'KW15',
    town: 'Kirkwall',
    county: 'Orkney',
  },
  {
    area: 'KW',
    outward: 'KW16',
    town: 'Stromness',
    county: 'Orkney',
  },
  {
    area: 'KW',
    outward: 'KW17',
    town: 'Orkney',
    county: 'Orkney',
  },
  {
    area: 'KY',
    outward: 'KY1',
    town: 'Kirkcaldy',
    county: 'Fife',
  },
  {
    area: 'KY',
    outward: 'KY2',
    town: 'Kirkcaldy',
    county: 'Fife',
  },
  {
    area: 'KY',
    outward: 'KY3',
    town: 'Burntisland',
    county: 'Fife',
  },
  {
    area: 'KY',
    outward: 'KY4',
    town: 'Cowdenbeath (shared)',
    county: 'Fife',
  },
  {
    area: 'KY',
    outward: 'KY5',
    town: 'Lochgelly',
    county: 'Fife',
  },
  {
    area: 'KY',
    outward: 'KY6',
    town: 'Glenrothes',
    county: 'Fife',
  },
  {
    area: 'KY',
    outward: 'KY7',
    town: 'Glenrothes',
    county: 'Fife',
  },
  {
    area: 'KY',
    outward: 'KY8',
    town: 'LEVEN',
    county: 'Fife',
  },
  {
    area: 'KY',
    outward: 'KY9',
    town: 'LEVEN',
    county: 'Fife',
  },
  {
    area: 'KY',
    outward: 'KY10',
    town: 'Anstruther',
    county: 'Fife',
  },
  {
    area: 'KY',
    outward: 'KY11',
    town: 'Dunfermline (shared)',
    county: 'Fife',
  },
  {
    area: 'KY',
    outward: 'KY11',
    town: 'Inverkeithing (shared)',
    county: 'Fife',
  },
  {
    area: 'KY',
    outward: 'KY12',
    town: 'Dunfermline',
    county: 'Fife',
  },
  {
    area: 'KY',
    outward: 'KY13',
    town: 'Kinross',
    county: 'Kinross-shire',
  },
  {
    area: 'KY',
    outward: 'KY14',
    town: 'Cupar',
    county: 'Fife',
  },
  {
    area: 'KY',
    outward: 'KY15',
    town: 'Cupar',
    county: 'Fife',
  },
  {
    area: 'KY',
    outward: 'KY16',
    town: 'ST. ANDREWS',
    county: 'Fife',
  },
  {
    area: '',
    outward: 'KY99',
    town: 'Non-geographic',
    county: 'Fife',
  },
  {
    area: '',
    outward: 'L1',
    town: 'Liverpool',
    county: 'Merseyside',
  },
  {
    area: '',
    outward: 'L2',
    town: 'Liverpool',
    county: 'Merseyside',
  },
  {
    area: '',
    outward: 'L3',
    town: 'Liverpool',
    county: 'Merseyside',
  },
  {
    area: '',
    outward: 'L4',
    town: 'Liverpool',
    county: 'Merseyside',
  },
  {
    area: '',
    outward: 'L5',
    town: 'Liverpool',
    county: 'Merseyside',
  },
  {
    area: '',
    outward: 'L6',
    town: 'Liverpool',
    county: 'Merseyside',
  },
  {
    area: '',
    outward: 'L7',
    town: 'Liverpool',
    county: 'Merseyside',
  },
  {
    area: '',
    outward: 'L8',
    town: 'Liverpool',
    county: 'Merseyside',
  },
  {
    area: '',
    outward: 'L9',
    town: 'Liverpool',
    county: 'Merseyside',
  },
  {
    area: '',
    outward: 'L10',
    town: 'Liverpool',
    county: 'Merseyside',
  },
  {
    area: '',
    outward: 'L11',
    town: 'Liverpool',
    county: 'Merseyside',
  },
  {
    area: '',
    outward: 'L12',
    town: 'Liverpool',
    county: 'Merseyside',
  },
  {
    area: '',
    outward: 'L13',
    town: 'Liverpool',
    county: 'Merseyside',
  },
  {
    area: '',
    outward: 'L14',
    town: 'Liverpool',
    county: 'Merseyside',
  },
  {
    area: '',
    outward: 'L15',
    town: 'Liverpool',
    county: 'Merseyside',
  },
  {
    area: '',
    outward: 'L16',
    town: 'Liverpool',
    county: 'Merseyside',
  },
  {
    area: '',
    outward: 'L17',
    town: 'Liverpool',
    county: 'Merseyside',
  },
  {
    area: '',
    outward: 'L18',
    town: 'Liverpool',
    county: 'Merseyside',
  },
  {
    area: '',
    outward: 'L19',
    town: 'Liverpool',
    county: 'Merseyside',
  },
  {
    area: '',
    outward: 'L20',
    town: 'Liverpool (shared)',
    county: 'Merseyside',
  },
  {
    area: '',
    outward: 'L21',
    town: 'Liverpool',
    county: 'Merseyside',
  },
  {
    area: '',
    outward: 'L22',
    town: 'Liverpool',
    county: 'Merseyside',
  },
  {
    area: '',
    outward: 'L23',
    town: 'Liverpool',
    county: 'Merseyside',
  },
  {
    area: '',
    outward: 'L24',
    town: 'Liverpool',
    county: 'Merseyside',
  },
  {
    area: '',
    outward: 'L25',
    town: 'Liverpool',
    county: 'Merseyside',
  },
  {
    area: '',
    outward: 'L26',
    town: 'Liverpool',
    county: 'Merseyside',
  },
  {
    area: '',
    outward: 'L27',
    town: 'Liverpool',
    county: 'Merseyside',
  },
  {
    area: '',
    outward: 'L28',
    town: 'Liverpool',
    county: 'Merseyside',
  },
  {
    area: '',
    outward: 'L29',
    town: 'Liverpool',
    county: 'Merseyside',
  },
  {
    area: '',
    outward: 'L30',
    town: 'Bootle',
    county: 'Merseyside',
  },
  {
    area: 'L',
    outward: 'L31',
    town: 'Liverpool',
    county: 'Merseyside',
  },
  {
    area: 'L',
    outward: 'L32',
    town: 'Liverpool',
    county: 'Merseyside',
  },
  {
    area: 'L',
    outward: 'L33',
    town: 'Liverpool',
    county: 'Merseyside',
  },
  {
    area: 'L',
    outward: 'L34',
    town: 'Prescot',
    county: 'Merseyside',
  },
  {
    area: 'L',
    outward: 'L35',
    town: 'Prescot',
    county: 'Merseyside',
  },
  {
    area: 'L',
    outward: 'L36',
    town: 'Liverpool',
    county: 'Merseyside',
  },
  {
    area: 'L',
    outward: 'L37',
    town: 'Liverpool',
    county: 'Merseyside',
  },
  {
    area: 'L',
    outward: 'L38',
    town: 'Liverpool',
    county: 'Merseyside',
  },
  {
    area: 'L',
    outward: 'L39',
    town: 'Ormskirk',
    county: 'Lancashire',
  },
  {
    area: '',
    outward: 'L40',
    town: 'Ormskirk',
    county: 'Lancashire',
  },
  {
    area: '',
    outward: 'L67',
    town: 'Non-geographic',
    county: 'Merseyside',
  },
  {
    area: '',
    outward: 'L68',
    town: 'Non-geographic',
    county: 'Merseyside',
  },
  {
    area: '',
    outward: 'L69',
    town: 'Non-geographic',
    county: 'Merseyside',
  },
  {
    area: '',
    outward: 'L70',
    town: 'Non-geographic',
    county: 'Merseyside',
  },
  {
    area: '',
    outward: 'L71',
    town: 'Non-geographic',
    county: 'Merseyside',
  },
  {
    area: '',
    outward: 'L72',
    town: 'Non-geographic',
    county: 'Merseyside',
  },
  {
    area: '',
    outward: 'L73',
    town: 'Non-geographic',
    county: 'Merseyside',
  },
  {
    area: '',
    outward: 'L74',
    town: 'Non-geographic',
    county: 'Merseyside',
  },
  {
    area: '',
    outward: 'L75',
    town: 'Non-geographic',
    county: 'Merseyside',
  },
  {
    area: '',
    outward: 'L80',
    town: 'Non-geographic',
    county: 'Merseyside',
  },
  {
    area: 'LA',
    outward: 'LA1',
    town: 'LANCASTER',
    county: 'Lancashire',
  },
  {
    area: 'LA',
    outward: 'LA2',
    town: 'LANCASTER',
    county: 'Lancashire',
  },
  {
    area: 'LA',
    outward: 'LA3',
    town: 'Morecambe',
    county: 'Lancashire',
  },
  {
    area: 'LA',
    outward: 'LA4',
    town: 'Morecambe',
    county: 'Lancashire',
  },
  {
    area: 'LA',
    outward: 'LA5',
    town: 'Carnforth',
    county: 'Lancashire',
  },
  {
    area: 'LA',
    outward: 'LA6',
    town: 'Carnforth',
    county: 'Lancashire',
  },
  {
    area: 'LA',
    outward: 'LA7',
    town: 'Milnthorpe',
    county: 'Cumbria',
  },
  {
    area: 'LA',
    outward: 'LA8',
    town: 'Kendal',
    county: 'Cumbria',
  },
  {
    area: 'LA',
    outward: 'LA9',
    town: 'Kendal',
    county: 'Cumbria',
  },
  {
    area: 'LA',
    outward: 'LA10',
    town: 'Sedbergh',
    county: 'Cumbria',
  },
  {
    area: 'LA',
    outward: 'LA11',
    town: 'Grange-over-Sands',
    county: 'Cumbria',
  },
  {
    area: 'LA',
    outward: 'LA12',
    town: 'Ulverston',
    county: 'Cumbria',
  },
  {
    area: 'LA',
    outward: 'LA13',
    town: 'Barrow-in-Furness',
    county: 'Cumbria',
  },
  {
    area: 'LA',
    outward: 'LA14',
    town: 'non-geo shared',
    county: 'Cumbria',
  },
  {
    area: 'LA',
    outward: 'LA14',
    town: 'Barrow-in-Furness (shared)',
    county: 'Cumbria',
  },
  {
    area: 'LA',
    outward: 'LA15',
    town: 'Dalton-in-Furness',
    county: 'Cumbria',
  },
  {
    area: 'LA',
    outward: 'LA16',
    town: 'Askam-in-Furness',
    county: 'Cumbria',
  },
  {
    area: 'LA',
    outward: 'LA17',
    town: 'Kirkby-in-Furness',
    county: 'Cumbria',
  },
  {
    area: 'LA',
    outward: 'LA18',
    town: 'Millom',
    county: 'Cumbria',
  },
  {
    area: 'LA',
    outward: 'LA19',
    town: 'Millom',
    county: 'Cumbria',
  },
  {
    area: 'LA',
    outward: 'LA20',
    town: 'Broughton-in-Furness',
    county: 'Cumbria',
  },
  {
    area: 'LA',
    outward: 'LA21',
    town: 'CONISTON',
    county: 'Cumbria',
  },
  {
    area: 'LA',
    outward: 'LA22',
    town: 'Ambleside',
    county: 'Cumbria',
  },
  {
    area: 'LA',
    outward: 'LA23',
    town: 'WINDERMERE',
    county: 'Cumbria',
  },
  {
    area: 'LD',
    outward: 'LD1',
    town: 'Llandrindod Wells',
    county: 'Powys',
  },
  {
    area: 'LD',
    outward: 'LD2',
    town: 'Builth Wells',
    county: 'Powys',
  },
  {
    area: 'LD',
    outward: 'LD3',
    town: 'Brecon',
    county: 'Powys',
  },
  {
    area: 'LD',
    outward: 'LD4',
    town: 'Llangammarch Wells',
    county: 'Powys',
  },
  {
    area: 'LD',
    outward: 'LD5',
    town: 'Llanwrtyd Wells',
    county: 'Powys',
  },
  {
    area: 'LD',
    outward: 'LD6',
    town: 'Rhayader',
    county: 'Powys',
  },
  {
    area: 'LD',
    outward: 'LD7',
    town: 'KNIGHTON',
    county: 'Powys',
  },
  {
    area: 'LD',
    outward: 'LD8',
    town: 'Presteigne',
    county: 'Powys',
  },
  {
    area: 'LE',
    outward: 'LE1',
    town: 'Leicester',
    county: 'Leicestershire',
  },
  {
    area: 'LE',
    outward: 'LE2',
    town: 'Leicester',
    county: 'Leicestershire',
  },
  {
    area: 'LE',
    outward: 'LE3',
    town: 'Leicester',
    county: 'Leicestershire',
  },
  {
    area: 'LE',
    outward: 'LE4',
    town: 'Leicester',
    county: 'Leicestershire',
  },
  {
    area: 'LE',
    outward: 'LE5',
    town: 'Leicester',
    county: 'Leicestershire',
  },
  {
    area: 'LE',
    outward: 'LE6',
    town: 'Leicester',
    county: 'Leicestershire',
  },
  {
    area: 'LE',
    outward: 'LE7',
    town: 'Leicester',
    county: 'Leicestershire',
  },
  {
    area: 'LE',
    outward: 'LE8',
    town: 'Leicester',
    county: 'Leicestershire',
  },
  {
    area: 'LE',
    outward: 'LE9',
    town: 'Leicester',
    county: 'Leicestershire',
  },
  {
    area: 'LE',
    outward: 'LE10',
    town: 'Hinckley',
    county: 'Leicestershire',
  },
  {
    area: 'LE',
    outward: 'LE11',
    town: 'Loughborough',
    county: 'Leicestershire',
  },
  {
    area: 'LE',
    outward: 'LE12',
    town: 'Loughborough',
    county: 'Leicestershire',
  },
  {
    area: 'LE',
    outward: 'LE13',
    town: 'Melton Mowbray',
    county: 'Leicestershire',
  },
  {
    area: 'LE',
    outward: 'LE14',
    town: 'Melton Mowbray',
    county: 'Leicestershire',
  },
  {
    area: 'LE',
    outward: 'LE15',
    town: 'Oakham',
    county: 'Leicestershire / Rutland',
  },
  {
    area: 'LE',
    outward: 'LE16',
    town: 'Market Harborough',
    county: 'Leicestershire / Rutland',
  },
  {
    area: 'LE',
    outward: 'LE17',
    town: 'Lutterworth',
    county: 'Leicestershire',
  },
  {
    area: 'LE',
    outward: 'LE18',
    town: 'WIGSTON',
    county: 'Leicestershire',
  },
  {
    area: '',
    outward: 'LE19',
    town: 'Leicester',
    county: 'Leicestershire',
  },
  {
    area: '',
    outward: 'LE21',
    town: 'Non-geographic',
    county: 'Leicestershire',
  },
  {
    area: '',
    outward: 'LE41',
    town: 'Non-geographic',
    county: 'Leicestershire',
  },
  {
    area: '',
    outward: 'LE55',
    town: 'Non-geographic',
    county: 'Leicestershire',
  },
  {
    area: 'LE',
    outward: 'LE65',
    town: 'Ashby-de-la-Zouch',
    county: 'Leicestershire',
  },
  {
    area: 'LE',
    outward: 'LE67',
    town: 'Coalville (shared)',
    county: 'Leicestershire',
  },
  {
    area: '',
    outward: 'LE87',
    town: 'Non-geographic',
    county: 'Leicestershire',
  },
  {
    area: '',
    outward: 'LE94',
    town: 'Non-geographic',
    county: 'Leicestershire',
  },
  {
    area: '',
    outward: 'LE95',
    town: 'Non-geographic',
    county: 'Leicestershire',
  },
  {
    area: 'LL',
    outward: 'LL11',
    town: 'Wrexham',
    county: 'Clwyd',
  },
  {
    area: 'LL',
    outward: 'LL12',
    town: 'Wrexham',
    county: 'Clwyd',
  },
  {
    area: 'LL',
    outward: 'LL13',
    town: 'Wrexham',
    county: 'Clwyd',
  },
  {
    area: 'LL',
    outward: 'LL14',
    town: 'Wrexham',
    county: 'Clwyd',
  },
  {
    area: 'LL',
    outward: 'LL15',
    town: 'Ruthin',
    county: 'Clwyd',
  },
  {
    area: 'LL',
    outward: 'LL16',
    town: 'Denbigh',
    county: 'Clwyd',
  },
  {
    area: 'LL',
    outward: 'LL17',
    town: 'ST. ASAPH',
    county: 'Clwyd',
  },
  {
    area: 'LL',
    outward: 'LL18',
    town: 'non-geo shared',
    county: 'Clwyd',
  },
  {
    area: 'LL',
    outward: 'LL18',
    town: 'Rhyl (shared)',
    county: 'Clwyd',
  },
  {
    area: 'LL',
    outward: 'LL19',
    town: 'Prestatyn',
    county: 'Clwyd',
  },
  {
    area: 'LL',
    outward: 'LL20',
    town: 'Llangollen',
    county: 'Clwyd',
  },
  {
    area: 'LL',
    outward: 'LL21',
    town: 'Corwen',
    county: 'Clwyd',
  },
  {
    area: 'LL',
    outward: 'LL22',
    town: 'Abergele',
    county: 'Clwyd',
  },
  {
    area: 'LL',
    outward: 'LL23',
    town: 'BALA',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL24',
    town: 'Betws-y-Coed',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL25',
    town: 'Dolwyddelan',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL26',
    town: 'Llanrwst',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL27',
    town: 'Trefriw',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL28',
    town: 'Colwyn Bay',
    county: 'Clwyd',
  },
  {
    area: 'LL',
    outward: 'LL29',
    town: 'Colwyn Bay',
    county: 'Clwyd',
  },
  {
    area: 'LL',
    outward: 'LL30',
    town: 'Llandudno',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL31',
    town: 'Conwy (shared)',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL31',
    town: 'Llandudno Junction (shared)',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL32',
    town: 'Conwy',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL33',
    town: 'Llanfairfechan',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL34',
    town: 'Penmaenmawr',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL35',
    town: 'ABERDYFI',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL36',
    town: 'Tywyn',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL37',
    town: 'Llwyngwril',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL38',
    town: 'Fairbourne',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL39',
    town: 'Arthog',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL40',
    town: 'Dolgellau',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL41',
    town: 'Blaenau Ffestiniog',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL42',
    town: 'Barmouth',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL43',
    town: 'TALYBONT',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL44',
    town: 'Dyffryn Ardudwy',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL45',
    town: 'Llanbedr',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL46',
    town: 'Harlech',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL47',
    town: 'Talsarnau',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL48',
    town: 'Penrhyndeudraeth',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL49',
    town: 'Porthmadog',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL51',
    town: 'Garndolbenmaen',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL52',
    town: 'Criccieth',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL53',
    town: 'Pwllheli',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL54',
    town: 'Caernarfon',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL55',
    town: 'Caernarfon',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL56',
    town: 'Y Felinheli',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL57',
    town: 'BANGOR',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL58',
    town: 'Beaumaris',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL59',
    town: 'Menai Bridge',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL60',
    town: 'Gaerwen',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL61',
    town: 'Llanfairpwllgwyngyll',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL62',
    town: 'Bodorgan',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL63',
    town: 'TY CROES',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL64',
    town: 'Rhosneigr',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL65',
    town: 'Holyhead',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL66',
    town: 'Rhosgoch',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL67',
    town: 'Cemaes Bay',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL68',
    town: 'Amlwch',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL69',
    town: 'Penysarn',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL70',
    town: 'DULAS',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL71',
    town: 'LLANERCHYMEDD',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL72',
    town: 'MOELFRE',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL73',
    town: 'MARIANGLAS',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL74',
    town: 'Tyn-y-Gongl',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL75',
    town: 'Pentraeth',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL76',
    town: 'Llanbedrgoch',
    county: 'Gwynedd',
  },
  {
    area: '',
    outward: 'LL77',
    town: 'non-geo shared',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL77',
    town: 'Llangefni (shared)',
    county: 'Gwynedd',
  },
  {
    area: 'LL',
    outward: 'LL78',
    town: 'BRYNTEG',
    county: 'Gwynedd',
  },
  {
    area: 'LN',
    outward: 'LN1',
    town: 'LINCOLN',
    county: 'Lincolnshire',
  },
  {
    area: 'LN',
    outward: 'LN2',
    town: 'LINCOLN',
    county: 'Lincolnshire',
  },
  {
    area: 'LN',
    outward: 'LN3',
    town: 'LINCOLN',
    county: 'Lincolnshire',
  },
  {
    area: 'LN',
    outward: 'LN4',
    town: 'LINCOLN',
    county: 'Lincolnshire',
  },
  {
    area: 'LN',
    outward: 'LN5',
    town: 'LINCOLN',
    county: 'Lincolnshire',
  },
  {
    area: 'LN',
    outward: 'LN6',
    town: 'LINCOLN',
    county: 'Lincolnshire',
  },
  {
    area: 'LN',
    outward: 'LN7',
    town: 'Market Rasen',
    county: 'Lincolnshire',
  },
  {
    area: 'LN',
    outward: 'LN8',
    town: 'Market Rasen',
    county: 'Lincolnshire',
  },
  {
    area: 'LN',
    outward: 'LN9',
    town: 'HORNCASTLE',
    county: 'Lincolnshire',
  },
  {
    area: 'LN',
    outward: 'LN10',
    town: 'Woodhall Spa',
    county: 'Lincolnshire',
  },
  {
    area: 'LN',
    outward: 'LN11',
    town: 'LOUTH',
    county: 'Lincolnshire',
  },
  {
    area: 'LN',
    outward: 'LN12',
    town: 'Mablethorpe',
    county: 'Lincolnshire',
  },
  {
    area: 'LN',
    outward: 'LN13',
    town: 'ALFORD',
    county: 'Lincolnshire',
  },
  {
    area: 'LS',
    outward: 'LS1',
    town: 'Leeds',
    county: 'West Yorkshire',
  },
  {
    area: 'LS',
    outward: 'LS2',
    town: 'Leeds',
    county: 'West Yorkshire',
  },
  {
    area: 'LS',
    outward: 'LS3',
    town: 'Leeds',
    county: 'West Yorkshire',
  },
  {
    area: 'LS',
    outward: 'LS4',
    town: 'Leeds',
    county: 'West Yorkshire',
  },
  {
    area: 'LS',
    outward: 'LS5',
    town: 'Leeds',
    county: 'West Yorkshire',
  },
  {
    area: 'LS',
    outward: 'LS6',
    town: 'Leeds',
    county: 'West Yorkshire',
  },
  {
    area: 'LS',
    outward: 'LS7',
    town: 'Leeds',
    county: 'West Yorkshire',
  },
  {
    area: 'LS',
    outward: 'LS8',
    town: 'Leeds',
    county: 'West Yorkshire',
  },
  {
    area: 'LS',
    outward: 'LS9',
    town: 'Leeds',
    county: 'West Yorkshire',
  },
  {
    area: '',
    outward: 'LS10',
    town: 'Leeds',
    county: 'West Yorkshire',
  },
  {
    area: '',
    outward: 'LS11',
    town: 'Leeds',
    county: 'West Yorkshire',
  },
  {
    area: '',
    outward: 'LS12',
    town: 'Leeds',
    county: 'West Yorkshire',
  },
  {
    area: '',
    outward: 'LS13',
    town: 'Leeds',
    county: 'West Yorkshire',
  },
  {
    area: '',
    outward: 'LS14',
    town: 'Leeds',
    county: 'West Yorkshire',
  },
  {
    area: '',
    outward: 'LS15',
    town: 'Leeds',
    county: 'West Yorkshire',
  },
  {
    area: '',
    outward: 'LS16',
    town: 'Leeds',
    county: 'West Yorkshire',
  },
  {
    area: '',
    outward: 'LS17',
    town: 'Leeds',
    county: 'West Yorkshire',
  },
  {
    area: '',
    outward: 'LS18',
    town: 'Leeds',
    county: 'West Yorkshire',
  },
  {
    area: '',
    outward: 'LS19',
    town: 'Leeds',
    county: 'West Yorkshire',
  },
  {
    area: '',
    outward: 'LS20',
    town: 'Leeds',
    county: 'West Yorkshire',
  },
  {
    area: 'LS',
    outward: 'LS21',
    town: 'Otley',
    county: 'West Yorkshire',
  },
  {
    area: 'LS',
    outward: 'LS22',
    town: 'Wetherby',
    county: 'West Yorkshire',
  },
  {
    area: 'LS',
    outward: 'LS23',
    town: 'Wetherby',
    county: 'West Yorkshire',
  },
  {
    area: 'LS',
    outward: 'LS24',
    town: 'Tadcaster',
    county: 'North Yorkshire',
  },
  {
    area: '',
    outward: 'LS25',
    town: 'Leeds',
    county: 'West Yorkshire',
  },
  {
    area: '',
    outward: 'LS26',
    town: 'Leeds',
    county: 'West Yorkshire',
  },
  {
    area: '',
    outward: 'LS27',
    town: 'Leeds',
    county: 'West Yorkshire',
  },
  {
    area: 'LS',
    outward: 'LS28',
    town: 'Pudsey',
    county: 'West Yorkshire',
  },
  {
    area: 'LS',
    outward: 'LS29',
    town: 'Ilkley',
    county: 'West Yorkshire',
  },
  {
    area: '',
    outward: 'LS88',
    town: 'Non-geographic',
    county: 'West Yorkshire',
  },
  {
    area: '',
    outward: 'LS98',
    town: 'Non-geographic',
    county: 'West Yorkshire',
  },
  {
    area: '',
    outward: 'LS99',
    town: 'Non-geographic',
    county: 'West Yorkshire',
  },
  {
    outward: 'LU1',
    town: 'Luton (South), Aley Green, Caddington, Lower Woodside, Pepperstock, Skimpot, Slip End, Woodside',
    county: 'Luton',
    coordinates: [51.879977, -0.42292639],
  },
  {
    outward: 'LU2',
    town:
      'Luton (East), Chiltern Green, Cockernhoe, East Hyde, Lawrence End, Lilley, New Mill End, Peters Green, Tea Green, The Hyde, Wandon End, Wandon Green, Winch Hill',
    county: 'Luton',
    coordinates: [51.879843, -0.40301242],
  },
  {
    outward: 'LU3',
    town: 'Luton (North), Lower Sundon, Streatley, Sundon',
    county: 'Luton',
    coordinates: [51.885732, -0.42496267],
  },
  {
    outward: 'LU4',
    town: 'Luton (West), Chalton, Leagrave',
    county: 'Luton',
    coordinates: [51.892716, -0.47251639],
  },
  {
    outward: 'LU5',
    town:
      'Dunstable (East including Woodside Estate), Bidwell, Chalgrave, Fancott, Harlington, Houghton Regis (including Townsend Industrial Estate), Thorn, Toddington',
    county: 'Central Bedfordshire',
    coordinates: [51.880786, -0.51057921],
  },
  {
    outward: 'LU6',
    town:
      'Dunstable (West), Eaton Bray, Edlesborough, Holywell, Kensworth, Northall, Sewell, Studham, Totternhoe, Whipsnade',
    county: 'Central Bedfordshire',
    coordinates: [51.892097, -0.53489574],
  },
  {
    outward: 'LU7',
    town:
      'Leighton Buzzard, Bragenham, Briggington, Burcott, Cheddington, Chelmscote, Clipstone, Crafton, Cublington, Eggington, Great Billington, Grove, Heath and Reach, Hockliffe, Horton, Hollingdon, Ivinghoe, Ivinghoe Aston, Ledburn, Leedon, Little Billington, Mentmore, Pitstone, Slapton, Soulbury, Stanbridge, Stewkley, Stockgrove, Tebworth, Tilsworth, Wing, Wingfield',
    county: 'Central Bedfordshire',
    coordinates: [51.941678, -0.65555907],
  },
  {
    outward: 'LU95',
    town: 'Non-geographic',
    county: 'Leighton Buzzard',
  },
  {
    area: 'M',
    outward: 'M3',
    town: 'Manchester (shared)',
    county: 'Lancashire',
  },
  {
    area: 'M',
    outward: 'M3',
    town: 'SALFORD (shared)',
    county: 'Lancashire',
  },
  {
    area: 'M',
    outward: 'M4',
    town: 'Manchester',
    county: 'Lancashire',
  },
  {
    area: 'M',
    outward: 'M5',
    town: 'SALFORD',
    county: 'Lancashire',
  },
  {
    area: 'M',
    outward: 'M6',
    town: 'SALFORD',
    county: 'Lancashire',
  },
  {
    area: 'M',
    outward: 'M7',
    town: 'SALFORD',
    county: 'Lancashire',
  },
  {
    area: '',
    outward: 'M11',
    town: 'Manchester',
    county: 'Lancashire',
  },
  {
    area: '',
    outward: 'M12',
    town: 'Manchester',
    county: 'Lancashire',
  },
  {
    area: '',
    outward: 'M13',
    town: 'Manchester',
    county: 'Lancashire',
  },
  {
    area: '',
    outward: 'M14',
    town: 'Manchester',
    county: 'Lancashire',
  },
  {
    area: '',
    outward: 'M15',
    town: 'Manchester',
    county: 'Lancashire',
  },
  {
    area: '',
    outward: 'M16',
    town: 'Manchester',
    county: 'Lancashire',
  },
  {
    area: '',
    outward: 'M17',
    town: 'Manchester',
    county: 'Lancashire',
  },
  {
    area: '',
    outward: 'M18',
    town: 'Manchester',
    county: 'Lancashire',
  },
  {
    area: '',
    outward: 'M19',
    town: 'Manchester',
    county: 'Lancashire',
  },
  {
    area: '',
    outward: 'M20',
    town: 'Manchester',
    county: 'Lancashire',
  },
  {
    area: '',
    outward: 'M21',
    town: 'Manchester',
    county: 'Lancashire',
  },
  {
    area: '',
    outward: 'M22',
    town: 'Manchester',
    county: 'Lancashire',
  },
  {
    area: '',
    outward: 'M23',
    town: 'Manchester',
    county: 'Lancashire',
  },
  {
    area: '',
    outward: 'M24',
    town: 'Manchester',
    county: 'Lancashire',
  },
  {
    area: '',
    outward: 'M25',
    town: 'Manchester',
    county: 'Lancashire',
  },
  {
    area: '',
    outward: 'M26',
    town: 'Manchester',
    county: 'Lancashire',
  },
  {
    area: '',
    outward: 'M27',
    town: 'Manchester',
    county: 'Lancashire',
  },
  {
    area: '',
    outward: 'M28',
    town: 'Manchester',
    county: 'Lancashire',
  },
  {
    area: '',
    outward: 'M29',
    town: 'Manchester',
    county: 'Lancashire',
  },
  {
    area: '',
    outward: 'M30',
    town: 'Manchester',
    county: 'Lancashire',
  },
  {
    area: '',
    outward: 'M31',
    town: 'Manchester',
    county: 'Lancashire',
  },
  {
    area: '',
    outward: 'M32',
    town: 'Manchester',
    county: 'Lancashire',
  },
  {
    area: 'M',
    outward: 'M33',
    town: 'SALE',
    county: 'Cheshire',
  },
  {
    area: '',
    outward: 'M34',
    town: 'Manchester',
    county: 'Lancashire',
  },
  {
    area: '',
    outward: 'M35',
    town: 'Manchester',
    county: 'Lancashire',
  },
  {
    area: '',
    outward: 'M38',
    town: 'Manchester',
    county: 'Lancashire',
  },
  {
    area: '',
    outward: 'M40',
    town: 'Manchester',
    county: 'Lancashire',
  },
  {
    area: '',
    outward: 'M41',
    town: 'Manchester',
    county: 'Lancashire',
  },
  {
    area: '',
    outward: 'M43',
    town: 'Manchester',
    county: 'Lancashire',
  },
  {
    area: '',
    outward: 'M44',
    town: 'Manchester',
    county: 'Lancashire',
  },
  {
    area: '',
    outward: 'M45',
    town: 'Manchester',
    county: 'Lancashire',
  },
  {
    area: '',
    outward: 'M46',
    town: 'Manchester',
    county: 'Lancashire',
  },
  {
    area: '',
    outward: 'M50',
    town: 'SALFORD',
    county: 'Lancashire',
  },
  {
    area: '',
    outward: 'M60',
    town: 'non-geo shared',
    county: 'Lancashire',
  },
  {
    area: '',
    outward: 'M60',
    town: 'non-geo shared',
    county: 'Lancashire',
  },
  {
    area: '',
    outward: 'M61',
    town: 'Non-geographic',
    county: 'Lancashire',
  },
  {
    area: '',
    outward: 'M90',
    town: 'Manchester',
    county: 'Lancashire',
  },
  {
    area: '',
    outward: 'M99',
    town: 'Non-geographic',
    county: 'Lancashire',
  },
  {
    outward: 'ME1',
    town: 'Rochester, Burham, Wouldham',
    county: 'Medway',
    coordinates: [51.386148, 0.50743955],
  },
  {
    outward: 'ME2',
    town: 'Strood, Halling, Cuxton, Frindsbury',
    county: 'Medway',
    coordinates: [51.372776, 0.45904613],
  },
  {
    outward: 'ME3',
    town: 'Rochester',
    county: 'Medway',
    coordinates: [51.448019, 0.67875267],
  },
  {
    outward: 'ME4',
    town: 'Chatham',
    county: 'Medway',
    coordinates: [51.407516, 0.54790965],
  },
  {
    outward: 'ME5',
    town: 'Walderslade, Blue Bell Hill, Luton',
    county: 'Medway',
    coordinates: [51.368442, 0.54527959],
  },
  {
    outward: 'ME6',
    town: 'Snodland, West Malling',
    county: 'Tonbridge and Malling',
    coordinates: [51.329355, 0.44230891],
  },
  {
    area: 'ME',
    outward: 'ME6,',
    town: 'Aylesford (shared)',
    county: 'Kent',
  },
  {
    area: 'ME',
    outward: 'ME6',
    town: 'Snodland (shared)',
    county: 'Kent',
  },
  {
    outward: 'ME7',
    town: 'Gillingham',
    county: 'Medway',
    coordinates: [51.386468, 0.54638065],
  },
  {
    outward: 'ME8',
    town: 'Rainham',
    county: 'Medway',
    coordinates: [51.366581, 0.58510339],
  },
  {
    outward: 'ME9',
    town: 'Sittingbourne',
    county: 'Swale',
    coordinates: [51.322773, 0.74007266],
  },
  {
    outward: 'ME10',
    town: 'Sittingbourne',
    county: 'Swale',
    coordinates: [51.34041, 0.73278181],
  },
  {
    outward: 'ME11',
    town: 'Queenborough, Rushenden',
    county: 'Swale',
    coordinates: [51.41697, 0.74183519],
  },
  {
    outward: 'ME12',
    town: 'Isle of Sheppey',
    county: 'Swale',
    coordinates: [51.440699, 0.76429659],
  },
  {
    outward: 'ME13',
    town: 'Faversham',
    county: 'Swale',
    coordinates: [51.288423, 0.8534349],
  },
  {
    outward: 'ME14',
    town: 'Maidstone, Bearsted, Grove Green',
    county: 'Maidstone',
    coordinates: [51.274428, 0.5246648],
  },
  {
    outward: 'ME15',
    town: 'Bearsted, Downswood, Shepway, Senacre, Maidstone Town Centre, Loose, Mangravet, Parkwood',
    county: 'Maidstone',
    coordinates: [51.242602, 0.51909551],
  },
  {
    outward: 'ME16',
    town: 'Barming, West Maidstone',
    county: 'Maidstone',
    coordinates: [51.281509, 0.49684505],
  },
  {
    outward: 'ME17',
    town:
      'Hollingbourne, Hucking, Harrietsham, Lenham, Boughton Monchelsea, Linton, Coxheath, Chart Sutton, Sutton Valence',
    county: 'Maidstone',
    coordinates: [51.241398, 0.67227194],
  },
  {
    outward: 'ME18',
    town: 'Maidstone',
    county: 'Maidstone',
    coordinates: [51.266213, 0.37803245],
  },
  {
    outward: 'ME19',
    town: 'West Malling',
    county: 'Tonbridge and Malling',
    coordinates: [51.279567, 0.39747867],
  },
  {
    outward: 'ME20',
    town: 'Aylesford',
    county: 'Tonbridge and Malling',
    coordinates: [51.299119, 0.44229572],
  },
  {
    outward: 'ME99',
    town: 'Non-geographic',
    county: 'Maidstone',
    coordinates: [51.278325, 0.51909963],
  },
  {
    area: '',
    outward: 'ME99',
    town: 'Non-geographic',
    county: 'Kent',
  },
  {
    area: 'MK',
    outward: 'MK1',
    town: 'Milton Keynes',
    county: 'Buckinghamshire',
  },
  {
    area: 'MK',
    outward: 'MK2',
    town: 'Milton Keynes',
    county: 'Buckinghamshire',
  },
  {
    area: 'MK',
    outward: 'MK3',
    town: 'Milton Keynes',
    county: 'Buckinghamshire',
  },
  {
    area: 'MK',
    outward: 'MK4',
    town: 'Milton Keynes',
    county: 'Buckinghamshire',
  },
  {
    area: 'MK',
    outward: 'MK5',
    town: 'Milton Keynes',
    county: 'Buckinghamshire',
  },
  {
    area: 'MK',
    outward: 'MK6',
    town: 'Milton Keynes',
    county: 'Buckinghamshire',
  },
  {
    area: 'MK',
    outward: 'MK7',
    town: 'Milton Keynes',
    county: 'Buckinghamshire',
  },
  {
    area: 'MK',
    outward: 'MK8',
    town: 'Milton Keynes',
    county: 'Buckinghamshire',
  },
  {
    area: 'MK',
    outward: 'MK9',
    town: 'Milton Keynes',
    county: 'Buckinghamshire',
  },
  {
    area: '',
    outward: 'MK10',
    town: 'Milton Keynes',
    county: 'Buckinghamshire',
  },
  {
    area: '',
    outward: 'MK11',
    town: 'Milton Keynes',
    county: 'Buckinghamshire',
  },
  {
    area: '',
    outward: 'MK12',
    town: 'Milton Keynes',
    county: 'Buckinghamshire',
  },
  {
    area: '',
    outward: 'MK13',
    town: 'Milton Keynes',
    county: 'Buckinghamshire',
  },
  {
    area: '',
    outward: 'MK14',
    town: 'Milton Keynes',
    county: 'Buckinghamshire',
  },
  {
    area: '',
    outward: 'MK15',
    town: 'Milton Keynes',
    county: 'Buckinghamshire',
  },
  {
    area: 'MK',
    outward: 'MK16',
    town: 'Newport Pagnell',
    county: 'Buckinghamshire',
  },
  {
    area: '',
    outward: 'MK17',
    town: 'Milton Keynes',
    county: 'Buckinghamshire',
  },
  {
    area: 'MK',
    outward: 'MK18',
    town: 'Buckingham',
    county: 'Buckinghamshire',
  },
  {
    area: '',
    outward: 'MK19',
    town: 'Milton Keynes',
    county: 'Buckinghamshire',
  },
  {
    area: 'MK',
    outward: 'MK40',
    town: 'Bedford',
    county: 'Bedfordshire',
  },
  {
    area: 'MK',
    outward: 'MK41',
    town: 'Bedford',
    county: 'Bedfordshire',
  },
  {
    area: 'MK',
    outward: 'MK42',
    town: 'Bedford',
    county: 'Bedfordshire',
  },
  {
    area: 'MK',
    outward: 'MK43',
    town: 'Bedford',
    county: 'Bedfordshire',
  },
  {
    area: 'MK',
    outward: 'MK44',
    town: 'Bedford',
    county: 'Bedfordshire',
  },
  {
    area: 'MK',
    outward: 'MK45',
    town: 'Bedford',
    county: 'Bedfordshire',
  },
  {
    area: 'MK',
    outward: 'MK46',
    town: 'OLNEY',
    county: 'Buckinghamshire',
  },
  {
    area: '',
    outward: 'MK77',
    town: 'Non-geographic',
    county: 'Buckinghamshire',
  },
  {
    area: 'ML',
    outward: 'ML1',
    town: 'Motherwell',
    county: 'Lanarkshire',
  },
  {
    area: 'ML',
    outward: 'ML2',
    town: 'Wishaw',
    county: 'Lanarkshire',
  },
  {
    area: 'ML',
    outward: 'ML3',
    town: 'HAMILTON',
    county: 'Lanarkshire',
  },
  {
    area: 'ML',
    outward: 'ML4',
    town: 'Bellshill',
    county: 'Lanarkshire',
  },
  {
    area: 'ML',
    outward: 'ML5',
    town: 'Coatbridge',
    county: 'Lanarkshire',
  },
  {
    area: 'ML',
    outward: 'ML6',
    town: 'AIRDRIE',
    county: 'Lanarkshire',
  },
  {
    area: 'ML',
    outward: 'ML7',
    town: 'Shotts',
    county: 'Lanarkshire',
  },
  {
    area: 'ML',
    outward: 'ML8',
    town: 'Carluke',
    county: 'Lanarkshire',
  },
  {
    area: 'ML',
    outward: 'ML9',
    town: 'Larkhall',
    county: 'Lanarkshire',
  },
  {
    area: 'ML',
    outward: 'ML10',
    town: 'Strathaven',
    county: 'Lanarkshire',
  },
  {
    area: 'ML',
    outward: 'ML11',
    town: 'Lanark',
    county: 'Lanarkshire',
  },
  {
    area: 'ML',
    outward: 'ML12',
    town: 'BIGGAR',
    county: 'Lanarkshire',
  },
  {
    outward: 'N1',
    town: 'Barnsbury, Canonbury, Kings Cross, Islington, Pentonville, De Beauvoir Town, Hoxton',
    county: 'Hackney',
    coordinates: [51.539425, -0.11793111],
  },
  {
    area: 'N',
    outward: 'N1',
    town: 'LONDON',
    county: 'London',
  },
  {
    outward: 'N1C',
    town: 'Kings Cross',
    county: 'Camden',
  },
  {
    area: 'N',
    outward: 'N1C',
    town: 'LONDON',
    county: 'London',
  },
  {
    outward: 'N1P',
    town: 'Non-geographic',
    county: 'Islington',
  },
  {
    area: 'N',
    outward: 'N1P',
    town: 'Non-geographic',
    county: 'London',
  },
  {
    outward: 'N2',
    town: 'East Finchley, Fortis Green, Hampstead Garden Suburb',
    county: 'Barnet',
    coordinates: [51.586175, -0.17013147],
  },
  {
    area: '',
    outward: 'N2',
    town: 'LONDON',
    county: 'London',
  },
  {
    outward: 'N3',
    town: 'Finchley, Church End, Finchley Central',
    county: 'Barnet',
    coordinates: [51.606177, -0.19292462],
  },
  {
    area: '',
    outward: 'N3',
    town: 'LONDON',
    county: 'London',
  },
  {
    outward: 'N4',
    town: 'Finsbury Park, Manor House, Harringay, Stroud Green',
    county: 'Haringey',
    coordinates: [51.577749, -0.10216114],
  },
  {
    area: '',
    outward: 'N4',
    town: 'LONDON',
    county: 'London',
  },
  {
    outward: 'N5',
    town: 'Highbury, Highbury Fields',
    county: 'Islington',
    coordinates: [51.55455, -0.1022326],
  },
  {
    area: '',
    outward: 'N5',
    town: 'LONDON',
    county: 'London',
  },
  {
    outward: 'N6',
    town: 'Highgate, Hampstead Heath',
    county: 'Camden',
    coordinates: [51.574339, -0.14934951],
  },
  {
    area: '',
    outward: 'N6',
    town: 'LONDON',
    county: 'London',
  },
  {
    outward: 'N7',
    town: 'Holloway, Barnsbury, Islington, Tufnell Park',
    county: 'Islington',
    coordinates: [51.555344, -0.12871342],
  },
  {
    area: '',
    outward: 'N7',
    town: 'LONDON',
    county: 'London',
  },
  {
    outward: 'N8',
    town: 'Hornsey, Crouch End, Harringay',
    county: 'Haringey',
    coordinates: [51.584529, -0.10492435],
  },
  {
    area: '',
    outward: 'N8',
    town: 'LONDON',
    county: 'London',
  },
  {
    outward: 'N9',
    town: 'Lower Edmonton, Edmonton',
    county: 'Enfield',
    coordinates: [51.627045, -0.044059925],
  },
  {
    area: '',
    outward: 'N9',
    town: 'LONDON',
    county: 'London',
  },
  {
    outward: 'N10',
    town: 'Muswell Hill',
    county: 'Haringey',
    coordinates: [51.600529, -0.15071083],
  },
  {
    area: '',
    outward: 'N10',
    town: 'LONDON',
    county: 'London',
  },
  {
    outward: 'N11',
    town: 'New Southgate, Friern Barnet, Bounds Green, Arnos Grove',
    county: 'Enfield',
    coordinates: [51.615924, -0.13480278],
  },
  {
    area: '',
    outward: 'N11',
    town: 'LONDON',
    county: 'London',
  },
  {
    outward: 'N12',
    town: 'North Finchley, Woodside Park',
    county: 'Barnet',
    coordinates: [51.609953, -0.174837],
  },
  {
    area: '',
    outward: 'N12',
    town: 'LONDON',
    county: 'London',
  },
  {
    outward: 'N13',
    town: 'Palmers Green',
    county: 'Enfield',
    coordinates: [51.620946, -0.10755358],
  },
  {
    area: '',
    outward: 'N13',
    town: 'LONDON',
    county: 'London',
  },
  {
    outward: 'N14',
    town: 'Southgate, Oakwood, Arnos Grove',
    county: 'Enfield',
    coordinates: [51.64193, -0.14223401],
  },
  {
    area: '',
    outward: 'N14',
    town: 'LONDON',
    county: 'London',
  },
  {
    outward: 'N15',
    town: "West Green, Seven Sisters, Duckett's Green",
    county: 'Haringey',
    coordinates: [51.584643, -0.092520204],
  },
  {
    area: '',
    outward: 'N15',
    town: 'LONDON',
    county: 'London',
  },
  {
    outward: 'N16',
    town: 'Stoke Newington, Stamford Hill, Shacklewell, Dalston, Newington Green',
    county: 'Hackney',
    coordinates: [51.565965, -0.081427142],
  },
  {
    area: '',
    outward: 'N16',
    town: 'LONDON',
    county: 'London',
  },
  {
    outward: 'N17',
    town: 'Tottenham, South Tottenham',
    county: 'Haringey',
    coordinates: [51.599578, -0.067708293],
  },
  {
    area: '',
    outward: 'N17',
    town: 'LONDON',
    county: 'London',
  },
  {
    outward: 'N18',
    town: 'Upper Edmonton, Edmonton',
    county: 'Enfield',
    coordinates: [51.609087, -0.086036484],
  },
  {
    area: '',
    outward: 'N18',
    town: 'LONDON',
    county: 'London',
  },
  {
    outward: 'N19',
    town: 'Upper Holloway, Archway, Tufnell Park, Hornsey',
    county: 'Islington',
    coordinates: [51.571541, -0.12599921],
  },
  {
    area: '',
    outward: 'N19',
    town: 'LONDON',
    county: 'London',
  },
  {
    outward: 'N20',
    town: 'Whetstone, Totteridge, Oakleigh Park',
    county: 'Barnet',
    coordinates: [51.628393, -0.16081884],
  },
  {
    area: '',
    outward: 'N20',
    town: 'LONDON',
    county: 'London',
  },
  {
    outward: 'N21',
    town: 'Winchmore Hill, Bush Hill, Grange Park',
    county: 'Enfield',
    coordinates: [51.636934, -0.10308738],
  },
  {
    area: '',
    outward: 'N21',
    town: 'LONDON',
    county: 'London',
  },
  {
    outward: 'N22',
    town: 'Wood Green, Bounds Green, Bowes Park',
    county: 'Haringey',
    coordinates: [51.606472, -0.11001848],
  },
  {
    area: '',
    outward: 'N22',
    town: 'LONDON',
    county: 'London',
  },
  {
    outward: 'N81',
    town: 'Non-geographic',
    county: 'Haringey',
    coordinates: [51.589973, -0.1123924],
  },
  {
    area: '',
    outward: 'N81',
    town: 'Non-geographic',
    county: 'London',
  },
  {
    area: 'NE',
    outward: 'NE1',
    town: 'Newcastle upon Tyne',
    county: 'Tyne and Wear',
  },
  {
    area: 'NE',
    outward: 'NE2',
    town: 'Newcastle upon Tyne',
    county: 'Tyne and Wear',
  },
  {
    area: 'NE',
    outward: 'NE3',
    town: 'Newcastle upon Tyne',
    county: 'Tyne and Wear',
  },
  {
    area: 'NE',
    outward: 'NE4',
    town: 'Newcastle upon Tyne',
    county: 'Tyne and Wear',
  },
  {
    area: 'NE',
    outward: 'NE5',
    town: 'Newcastle upon Tyne',
    county: 'Tyne and Wear',
  },
  {
    area: 'NE',
    outward: 'NE6',
    town: 'Newcastle upon Tyne',
    county: 'Tyne and Wear',
  },
  {
    area: 'NE',
    outward: 'NE7',
    town: 'Newcastle upon Tyne',
    county: 'Tyne and Wear',
  },
  {
    area: '',
    outward: 'NE8',
    town: 'Gateshead',
    county: 'Tyne and Wear',
  },
  {
    area: '',
    outward: 'NE9',
    town: 'Gateshead',
    county: 'Tyne and Wear',
  },
  {
    area: '',
    outward: 'NE10',
    town: 'Gateshead',
    county: 'Tyne and Wear',
  },
  {
    area: '',
    outward: 'NE11',
    town: 'Gateshead',
    county: 'Tyne and Wear',
  },
  {
    area: '',
    outward: 'NE12',
    town: 'Newcastle upon Tyne',
    county: 'Tyne and Wear',
  },
  {
    area: '',
    outward: 'NE13',
    town: 'Newcastle upon Tyne',
    county: 'Tyne and Wear',
  },
  {
    area: '',
    outward: 'NE15',
    town: 'Newcastle upon Tyne',
    county: 'Tyne and Wear',
  },
  {
    area: '',
    outward: 'NE16',
    town: 'Newcastle upon Tyne',
    county: 'Tyne and Wear',
  },
  {
    area: '',
    outward: 'NE17',
    town: 'Newcastle upon Tyne',
    county: 'Tyne and Wear',
  },
  {
    area: '',
    outward: 'NE18',
    town: 'Newcastle upon Tyne',
    county: 'Tyne and Wear',
  },
  {
    area: '',
    outward: 'NE19',
    town: 'Newcastle upon Tyne',
    county: 'Tyne and Wear',
  },
  {
    area: '',
    outward: 'NE20',
    town: 'Newcastle upon Tyne',
    county: 'Tyne and Wear',
  },
  {
    area: 'NE',
    outward: 'NE21',
    town: 'Blaydon-on-Tyne',
    county: 'Tyne and Wear',
  },
  {
    area: 'NE',
    outward: 'NE22',
    town: 'Bedlington',
    county: 'Northumberland',
  },
  {
    area: 'NE',
    outward: 'NE23',
    town: 'Cramlington',
    county: 'Northumberland',
  },
  {
    area: 'NE',
    outward: 'NE24',
    town: 'BLYTH',
    county: 'Northumberland',
  },
  {
    area: 'NE',
    outward: 'NE25',
    town: 'Whitley Bay',
    county: 'Tyne and Wear',
  },
  {
    area: 'NE',
    outward: 'NE26',
    town: 'Whitley Bay',
    county: 'Tyne and Wear',
  },
  {
    area: '',
    outward: 'NE27',
    town: 'Newcastle upon Tyne',
    county: 'Tyne and Wear',
  },
  {
    area: 'NE',
    outward: 'NE28',
    town: 'Wallsend',
    county: 'Tyne and Wear',
  },
  {
    area: 'NE',
    outward: 'NE29',
    town: 'North Shields',
    county: 'Tyne and Wear',
  },
  {
    area: '',
    outward: 'NE30',
    town: 'North Shields',
    county: 'Tyne and Wear',
  },
  {
    area: 'NE',
    outward: 'NE31',
    town: 'Hebburn',
    county: 'Tyne and Wear',
  },
  {
    area: 'NE',
    outward: 'NE32',
    town: 'Jarrow',
    county: 'Tyne and Wear',
  },
  {
    area: 'NE',
    outward: 'NE33',
    town: 'South Shields',
    county: 'Tyne and Wear',
  },
  {
    area: 'NE',
    outward: 'NE34',
    town: 'South Shields',
    county: 'Tyne and Wear',
  },
  {
    area: 'NE',
    outward: 'NE35',
    town: 'Boldon Colliery',
    county: 'Tyne and Wear',
  },
  {
    area: 'NE',
    outward: 'NE36',
    town: 'East Boldon',
    county: 'Tyne and Wear',
  },
  {
    area: 'NE',
    outward: 'NE37',
    town: 'WASHINGTON',
    county: 'Tyne and Wear',
  },
  {
    area: 'NE',
    outward: 'NE38',
    town: 'WASHINGTON',
    county: 'Tyne and Wear',
  },
  {
    area: 'NE',
    outward: 'NE39',
    town: 'Rowlands Gill',
    county: 'Tyne and Wear',
  },
  {
    area: 'NE',
    outward: 'NE40',
    town: 'RYTON',
    county: 'Tyne and Wear',
  },
  {
    area: 'NE',
    outward: 'NE41',
    town: 'Wylam',
    county: 'Northumberland',
  },
  {
    area: 'NE',
    outward: 'NE42',
    town: 'Prudhoe',
    county: 'Northumberland',
  },
  {
    area: 'NE',
    outward: 'NE43',
    town: 'Stocksfield',
    county: 'Northumberland',
  },
  {
    area: 'NE',
    outward: 'NE44',
    town: 'Riding Mill',
    county: 'Northumberland',
  },
  {
    area: 'NE',
    outward: 'NE45',
    town: 'Corbridge',
    county: 'Northumberland',
  },
  {
    area: 'NE',
    outward: 'NE46',
    town: 'Hexham',
    county: 'Northumberland',
  },
  {
    area: 'NE',
    outward: 'NE47',
    town: 'Hexham',
    county: 'Northumberland',
  },
  {
    area: 'NE',
    outward: 'NE48',
    town: 'Hexham',
    county: 'Northumberland',
  },
  {
    area: 'NE',
    outward: 'NE49',
    town: 'Haltwhistle',
    county: 'Northumberland',
  },
  {
    area: 'NE',
    outward: 'NE61',
    town: 'MORPETH',
    county: 'Northumberland',
  },
  {
    area: 'NE',
    outward: 'NE62',
    town: 'Choppington',
    county: 'Northumberland',
  },
  {
    area: 'NE',
    outward: 'NE63',
    town: 'Ashington',
    county: 'Northumberland',
  },
  {
    area: 'NE',
    outward: 'NE64',
    town: 'Newbiggin-by-the-Sea',
    county: 'Northumberland',
  },
  {
    area: 'NE',
    outward: 'NE65',
    town: 'MORPETH',
    county: 'Northumberland',
  },
  {
    area: 'NE',
    outward: 'NE66',
    town: 'non-geo shared',
    county: 'Northumberland',
  },
  {
    area: 'NE',
    outward: 'NE66',
    town: 'Alnwick (shared)',
    county: 'Northumberland',
  },
  {
    area: 'NE',
    outward: 'NE67',
    town: 'Chathill',
    county: 'Northumberland',
  },
  {
    area: 'NE',
    outward: 'NE68',
    town: 'Seahouses',
    county: 'Northumberland',
  },
  {
    area: 'NE',
    outward: 'NE69',
    town: 'Bamburgh',
    county: 'Northumberland',
  },
  {
    area: 'NE',
    outward: 'NE70',
    town: 'BELFORD',
    county: 'Northumberland',
  },
  {
    area: 'NE',
    outward: 'NE71',
    town: 'Wooler',
    county: 'Northumberland',
  },
  {
    area: '',
    outward: 'NE82',
    town: 'Non-geographic',
    county: 'Tyne and Wear',
  },
  {
    area: '',
    outward: 'NE83',
    town: 'Non-geographic',
    county: 'Tyne and Wear',
  },
  {
    area: '',
    outward: 'NE85',
    town: 'Non-geographic',
    county: 'Tyne and Wear',
  },
  {
    area: '',
    outward: 'NE88',
    town: 'Non-geographic',
    county: 'Tyne and Wear',
  },
  {
    area: '',
    outward: 'NE92',
    town: 'Non-geographic',
    county: 'Tyne and Wear',
  },
  {
    area: '',
    outward: 'NE98',
    town: 'Non-geographic',
    county: 'Tyne and Wear',
  },
  {
    area: '',
    outward: 'NE99',
    town: 'Non-geographic',
    county: 'Tyne and Wear',
  },
  {
    area: 'NG',
    outward: 'NG1',
    town: 'Nottingham',
    county: 'Nottinghamshire',
  },
  {
    area: 'NG',
    outward: 'NG2',
    town: 'Nottingham',
    county: 'Nottinghamshire',
  },
  {
    area: 'NG',
    outward: 'NG3',
    town: 'Nottingham',
    county: 'Nottinghamshire',
  },
  {
    area: 'NG',
    outward: 'NG4',
    town: 'Nottingham',
    county: 'Nottinghamshire',
  },
  {
    area: 'NG',
    outward: 'NG5',
    town: 'Nottingham',
    county: 'Nottinghamshire',
  },
  {
    area: 'NG',
    outward: 'NG6',
    town: 'Nottingham',
    county: 'Nottinghamshire',
  },
  {
    area: 'NG',
    outward: 'NG7',
    town: 'Nottingham',
    county: 'Nottinghamshire',
  },
  {
    area: 'NG',
    outward: 'NG8',
    town: 'Nottingham',
    county: 'Nottinghamshire',
  },
  {
    area: 'NG',
    outward: 'NG9',
    town: 'Nottingham',
    county: 'Nottinghamshire',
  },
  {
    area: '',
    outward: 'NG10',
    town: 'Nottingham',
    county: 'Nottinghamshire',
  },
  {
    area: '',
    outward: 'NG11',
    town: 'Nottingham',
    county: 'Nottinghamshire',
  },
  {
    area: '',
    outward: 'NG12',
    town: 'Nottingham',
    county: 'Nottinghamshire',
  },
  {
    area: '',
    outward: 'NG13',
    town: 'Nottingham',
    county: 'Nottinghamshire',
  },
  {
    area: '',
    outward: 'NG14',
    town: 'Nottingham',
    county: 'Nottinghamshire',
  },
  {
    area: '',
    outward: 'NG15',
    town: 'Nottingham',
    county: 'Nottinghamshire',
  },
  {
    area: '',
    outward: 'NG16',
    town: 'Nottingham',
    county: 'Nottinghamshire',
  },
  {
    area: '',
    outward: 'NG17',
    town: 'Nottingham (shared)',
    county: 'Nottinghamshire',
  },
  {
    area: 'NG',
    outward: 'NG17',
    town: 'Sutton-in-Ashfield (shared)',
    county: 'Nottinghamshire',
  },
  {
    area: 'NG',
    outward: 'NG18',
    town: 'Mansfield',
    county: 'Nottinghamshire',
  },
  {
    area: 'NG',
    outward: 'NG19',
    town: 'Mansfield',
    county: 'Nottinghamshire',
  },
  {
    area: '',
    outward: 'NG20',
    town: 'Mansfield',
    county: 'Nottinghamshire',
  },
  {
    area: '',
    outward: 'NG21',
    town: 'Mansfield',
    county: 'Nottinghamshire',
  },
  {
    area: 'NG',
    outward: 'NG22',
    town: 'NEWARK',
    county: 'Nottinghamshire',
  },
  {
    area: 'NG',
    outward: 'NG23',
    town: 'NEWARK',
    county: 'Nottinghamshire',
  },
  {
    area: 'NG',
    outward: 'NG24',
    town: 'NEWARK',
    county: 'Nottinghamshire',
  },
  {
    area: 'NG',
    outward: 'NG25',
    town: 'SOUTHWELL',
    county: 'Nottinghamshire',
  },
  {
    area: 'NG',
    outward: 'NG31',
    town: 'Grantham',
    county: 'Lincolnshire',
  },
  {
    area: 'NG',
    outward: 'NG32',
    town: 'Grantham',
    county: 'Lincolnshire',
  },
  {
    area: 'NG',
    outward: 'NG33',
    town: 'Grantham',
    county: 'Lincolnshire',
  },
  {
    area: 'NG',
    outward: 'NG34',
    town: 'Sleaford',
    county: 'Lincolnshire',
  },
  {
    area: '',
    outward: 'NG70',
    town: 'Non-geographic',
    county: 'Nottinghamshire',
  },
  {
    area: '',
    outward: 'NG80',
    town: 'Non-geographic',
    county: 'Nottinghamshire',
  },
  {
    area: '',
    outward: 'NG90',
    town: 'Non-geographic',
    county: 'Nottinghamshire',
  },
  {
    area: 'NN',
    outward: 'NN1',
    town: 'Northampton',
    county: 'Northamptonshire',
  },
  {
    area: 'NN',
    outward: 'NN2',
    town: 'Northampton',
    county: 'Northamptonshire',
  },
  {
    area: 'NN',
    outward: 'NN3',
    town: 'Northampton',
    county: 'Northamptonshire',
  },
  {
    area: 'NN',
    outward: 'NN4',
    town: 'Northampton',
    county: 'Northamptonshire',
  },
  {
    area: 'NN',
    outward: 'NN5',
    town: 'Northampton',
    county: 'Northamptonshire',
  },
  {
    area: 'NN',
    outward: 'NN6',
    town: 'Northampton',
    county: 'Northamptonshire',
  },
  {
    area: 'NN',
    outward: 'NN7',
    town: 'Northampton',
    county: 'Northamptonshire',
  },
  {
    area: 'NN',
    outward: 'NN8',
    town: 'Wellingborough',
    county: 'Northamptonshire',
  },
  {
    area: 'NN',
    outward: 'NN9',
    town: 'Wellingborough',
    county: 'Northamptonshire',
  },
  {
    area: 'NN',
    outward: 'NN10',
    town: 'Rushden',
    county: 'Northamptonshire',
  },
  {
    area: 'NN',
    outward: 'NN11',
    town: 'Daventry',
    county: 'Northamptonshire',
  },
  {
    area: 'NN',
    outward: 'NN12',
    town: 'Towcester',
    county: 'Northamptonshire',
  },
  {
    area: 'NN',
    outward: 'NN13',
    town: 'Brackley',
    county: 'Northamptonshire',
  },
  {
    area: 'NN',
    outward: 'NN14',
    town: 'Kettering',
    county: 'Northamptonshire',
  },
  {
    area: 'NN',
    outward: 'NN15',
    town: 'Kettering',
    county: 'Northamptonshire',
  },
  {
    area: 'NN',
    outward: 'NN16',
    town: 'Kettering',
    county: 'Northamptonshire',
  },
  {
    area: 'NN',
    outward: 'NN17',
    town: 'Corby',
    county: 'Northamptonshire',
  },
  {
    area: 'NN',
    outward: 'NN18',
    town: 'Corby',
    county: 'Northamptonshire',
  },
  {
    area: '',
    outward: 'NN29',
    town: 'Wellingborough',
    county: 'Northamptonshire',
  },
  {
    area: 'NP',
    outward: 'NP4',
    town: 'Pontypool',
    county: 'Gwent',
  },
  {
    area: 'NP',
    outward: 'NP7',
    town: 'Crickhowell (shared)',
    county: 'Powys',
  },
  {
    area: 'NP',
    outward: 'NP7',
    town: 'Abergavenny (shared)',
    county: 'Gwent',
  },
  {
    area: 'NP',
    outward: 'NP8',
    town: 'Crickhowell',
    county: 'Powys',
  },
  {
    area: 'NP',
    outward: 'NP10',
    town: 'NEWPORT',
    county: 'Gwent',
  },
  {
    area: 'NP',
    outward: 'NP11',
    town: 'NEWPORT',
    county: 'Gwent',
  },
  {
    area: 'NP',
    outward: 'NP12',
    town: 'BLACKWOOD',
    county: 'Gwent',
  },
  {
    area: 'NP',
    outward: 'NP13',
    town: 'Abertillery',
    county: 'Gwent',
  },
  {
    area: 'NP',
    outward: 'NP15',
    town: 'Usk',
    county: 'Gwent',
  },
  {
    area: 'NP',
    outward: 'NP16',
    town: 'Chepstow',
    county: 'Gwent',
  },
  {
    area: 'NP',
    outward: 'NP18',
    town: 'NEWPORT',
    county: 'Gwent',
  },
  {
    area: 'NP',
    outward: 'NP19',
    town: 'NEWPORT',
    county: 'Gwent',
  },
  {
    area: '',
    outward: 'NP20',
    town: 'NEWPORT',
    county: 'Gwent',
  },
  {
    area: 'NP',
    outward: 'NP22',
    town: 'Tredegar',
    county: 'Gwent',
  },
  {
    area: 'NP',
    outward: 'NP23',
    town: 'Ebbw Vale',
    county: 'Gwent',
  },
  {
    area: 'NP',
    outward: 'NP24',
    town: 'New Tredegar',
    county: 'Gwent',
  },
  {
    area: 'NP',
    outward: 'NP25',
    town: 'Monmouth',
    county: 'Gwent',
  },
  {
    area: 'NP',
    outward: 'NP26',
    town: 'CALDICOT',
    county: 'Gwent',
  },
  {
    area: 'NP',
    outward: 'NP44',
    town: 'Cwmbran',
    county: 'Gwent',
  },
  {
    area: 'NR',
    outward: 'NR1',
    town: 'Norwich',
    county: 'Norfolk',
  },
  {
    area: 'NR',
    outward: 'NR2',
    town: 'Norwich',
    county: 'Norfolk',
  },
  {
    area: 'NR',
    outward: 'NR3',
    town: 'Norwich',
    county: 'Norfolk',
  },
  {
    area: 'NR',
    outward: 'NR4',
    town: 'Norwich',
    county: 'Norfolk',
  },
  {
    area: 'NR',
    outward: 'NR5',
    town: 'Norwich',
    county: 'Norfolk',
  },
  {
    area: 'NR',
    outward: 'NR6',
    town: 'Norwich',
    county: 'Norfolk',
  },
  {
    area: 'NR',
    outward: 'NR7',
    town: 'Norwich',
    county: 'Norfolk',
  },
  {
    area: 'NR',
    outward: 'NR8',
    town: 'Norwich',
    county: 'Norfolk',
  },
  {
    area: 'NR',
    outward: 'NR9',
    town: 'Norwich',
    county: 'Norfolk',
  },
  {
    area: '',
    outward: 'NR10',
    town: 'Norwich',
    county: 'Norfolk',
  },
  {
    area: '',
    outward: 'NR11',
    town: 'Norwich',
    county: 'Norfolk',
  },
  {
    area: '',
    outward: 'NR12',
    town: 'Norwich',
    county: 'Norfolk',
  },
  {
    area: '',
    outward: 'NR13',
    town: 'Norwich',
    county: 'Norfolk',
  },
  {
    area: '',
    outward: 'NR14',
    town: 'Norwich',
    county: 'Norfolk',
  },
  {
    area: '',
    outward: 'NR15',
    town: 'Norwich',
    county: 'Norfolk',
  },
  {
    area: '',
    outward: 'NR16',
    town: 'Norwich',
    county: 'Norfolk',
  },
  {
    area: 'NR',
    outward: 'NR17',
    town: 'Attleborough',
    county: 'Norfolk',
  },
  {
    area: '',
    outward: 'NR18',
    town: 'non-geo shared',
    county: 'Norfolk',
  },
  {
    area: 'NR',
    outward: 'NR18',
    town: 'Wymondham (shared)',
    county: 'Norfolk',
  },
  {
    area: '',
    outward: 'NR19',
    town: 'non-geo shared',
    county: 'Norfolk',
  },
  {
    area: 'NR',
    outward: 'NR19,',
    town: 'Dereham (shared)',
    county: 'Norfolk',
  },
  {
    area: '',
    outward: 'NR20',
    town: 'Dereham',
    county: 'Norfolk',
  },
  {
    area: 'NR',
    outward: 'NR21',
    town: 'Fakenham',
    county: 'Norfolk',
  },
  {
    area: 'NR',
    outward: 'NR22',
    town: 'Walsingham',
    county: 'Norfolk',
  },
  {
    area: 'NR',
    outward: 'NR23',
    town: 'Wells-next-the-Sea',
    county: 'Norfolk',
  },
  {
    area: 'NR',
    outward: 'NR24',
    town: 'Melton Constable',
    county: 'Norfolk',
  },
  {
    area: 'NR',
    outward: 'NR25',
    town: 'HOLT',
    county: 'Norfolk',
  },
  {
    area: '',
    outward: 'NR26',
    town: 'non-geo shared',
    county: 'Norfolk',
  },
  {
    area: 'NR',
    outward: 'NR26',
    town: 'Sheringham (shared)',
    county: 'Norfolk',
  },
  {
    area: 'NR',
    outward: 'NR27',
    town: 'Cromer',
    county: 'Norfolk',
  },
  {
    area: '',
    outward: 'NR28',
    town: 'non-geo shared',
    county: 'Norfolk',
  },
  {
    area: 'NR',
    outward: 'NR28',
    town: 'North Walsham (shared)',
    county: 'Norfolk',
  },
  {
    area: 'NR',
    outward: 'NR29',
    town: 'Great Yarmouth',
    county: 'Norfolk',
  },
  {
    area: '',
    outward: 'NR30',
    town: 'Great Yarmouth',
    county: 'Norfolk',
  },
  {
    area: '',
    outward: 'NR31',
    town: 'Great Yarmouth',
    county: 'Norfolk',
  },
  {
    area: 'NR',
    outward: 'NR32',
    town: 'Lowestoft',
    county: 'Suffolk',
  },
  {
    area: 'NR',
    outward: 'NR33',
    town: 'Lowestoft',
    county: 'Suffolk',
  },
  {
    area: 'NR',
    outward: 'NR34',
    town: 'Beccles',
    county: 'Suffolk',
  },
  {
    area: 'NR',
    outward: 'NR35',
    town: 'Bungay',
    county: 'Suffolk',
  },
  {
    area: '',
    outward: 'NR99',
    town: 'Non-geographic',
    county: 'Norfolk',
  },
  {
    outward: 'NW1',
    town: 'Euston, Camden Town, Somers Town, Primrose Hill, Gospel Oak, Lisson Grove',
    county: 'Camden',
    coordinates: [51.536729, -0.13944085],
  },
  {
    outward: 'NW1W',
    town: 'Non-geographic, Euston',
    county: 'Camden',
    coordinates: [51.530784, -0.13543005],
  },
  {
    area: 'NW',
    outward: 'NW1W',
    town: 'Non-geographic',
    county: 'London',
  },
  {
    outward: 'NW2',
    town: 'Cricklewood, Willesden, Neasden, Dollis Hill, Childs Hill',
    county: 'Barnet',
    coordinates: [51.562193, -0.21335227],
  },
  {
    outward: 'NW3',
    town: 'Hampstead, Chalk Farm, Primrose Hill, Swiss Cottage, Belsize Park, Gospel Oak, Frognal, Childs Hill',
    county: 'Camden',
    coordinates: [51.559003, -0.17778639],
  },
  {
    outward: 'NW4',
    town: 'Brent Cross, Hendon',
    county: 'Barnet',
    coordinates: [51.592278, -0.22197477],
  },
  {
    outward: 'NW5',
    town: 'Kentish Town, Tufnell Park, Dartmouth Park, Gospel Oak',
    county: 'Camden',
    coordinates: [51.55218, -0.14129145],
  },
  {
    outward: 'NW6',
    town: "Kilburn, Brondesbury, West Hampstead, Queen's Park",
    county: 'Brent',
    coordinates: [51.552426, -0.19328131],
  },
  {
    outward: 'NW7',
    town: 'Mill Hill, Arkley',
    county: 'Barnet',
    coordinates: [51.61547, -0.24117495],
  },
  {
    outward: 'NW8',
    town: "St John's Wood, Primrose Hill, Lisson Grove",
    county: 'Camden',
    coordinates: [51.535624, -0.18195008],
  },
  {
    outward: 'NW9',
    town: 'The Hyde, Colindale, Kingsbury, Queensbury, West Hendon',
    county: 'Barnet',
    coordinates: [51.584491, -0.26042886],
  },
  {
    outward: 'NW10',
    town: 'Willesden, Acton, Harlesden, Kensal Green, Neasden, Old Oak Common, Park Royal, Stonebridge, Brent Park',
    county: 'Brent',
    coordinates: [51.559111, -0.24930817],
  },
  {
    outward: 'NW11',
    town: 'Golders Green, Hampstead Garden Suburb',
    county: 'Barnet',
    coordinates: [51.584599, -0.20018977],
  },
  {
    outward: 'NW26',
    town: 'Non-geographic',
    county: 'Camden',
    coordinates: [51.530784, -0.13543005],
  },
  {
    area: '',
    outward: 'NW26',
    town: 'Non-geographic',
    county: 'London',
  },
  {
    area: 'OL',
    outward: 'OL1',
    town: 'Oldham',
    county: 'Lancashire',
  },
  {
    area: 'OL',
    outward: 'OL2',
    town: 'Oldham',
    county: 'Lancashire',
  },
  {
    area: 'OL',
    outward: 'OL3',
    town: 'Oldham',
    county: 'Lancashire',
  },
  {
    area: 'OL',
    outward: 'OL4',
    town: 'Oldham',
    county: 'Lancashire',
  },
  {
    area: 'OL',
    outward: 'OL5',
    town: 'Ashton-under-Lyne',
    county: 'Lancashire',
  },
  {
    area: 'OL',
    outward: 'OL6',
    town: 'Ashton-under-Lyne',
    county: 'Lancashire',
  },
  {
    area: 'OL',
    outward: 'OL7',
    town: 'Ashton-under-Lyne',
    county: 'Lancashire',
  },
  {
    area: 'OL',
    outward: 'OL8',
    town: 'Oldham',
    county: 'Lancashire',
  },
  {
    area: 'OL',
    outward: 'OL9',
    town: 'Oldham',
    county: 'Lancashire',
  },
  {
    area: 'OL',
    outward: 'OL10',
    town: 'HEYWOOD',
    county: 'Lancashire',
  },
  {
    area: 'OL',
    outward: 'OL11',
    town: 'Rochdale',
    county: 'Lancashire',
  },
  {
    area: 'OL',
    outward: 'OL12',
    town: 'Rochdale',
    county: 'Lancashire',
  },
  {
    area: 'OL',
    outward: 'OL13',
    town: 'Bacup',
    county: 'Lancashire',
  },
  {
    area: 'OL',
    outward: 'OL14',
    town: 'Todmorden',
    county: 'Lancashire',
  },
  {
    area: 'OL',
    outward: 'OL15',
    town: 'LITTLEBOROUGH',
    county: 'Lancashire',
  },
  {
    area: 'OL',
    outward: 'OL16',
    town: 'non-geo shared',
    county: 'Lancashire',
  },
  {
    area: 'OL',
    outward: 'OL16',
    town: 'Rochdale (shared)',
    county: 'Lancashire',
  },
  {
    area: '',
    outward: 'OL95',
    town: 'Non-geographic',
    county: 'Lancashire',
  },
  {
    outward: 'OX1',
    town: 'Central and South Oxford, Kennington',
    county: 'Oxford',
    coordinates: [51.750231, -1.2673668],
  },
  {
    outward: 'OX2',
    town: 'North and West Oxford, Botley, North Hinksey, Summertown, Wytham',
    county: 'Oxford',
    coordinates: [51.752397, -1.2715329],
  },
  {
    outward: 'OX3',
    town: 'North East Oxford, Headington, Marston, Elsfield',
    county: 'Oxford',
    coordinates: [51.76441, -1.2252889],
  },
  {
    outward: 'OX4',
    town: 'East Oxford, Cowley, Blackbird Leys, Sandford-on-Thames, Iffley',
    county: 'Oxford',
    coordinates: [51.750537, -1.2432861],
  },
  {
    outward: 'OX5',
    town: 'Kidlington',
    county: 'Cherwell',
    coordinates: [51.822307, -1.2897612],
  },
  {
    outward: 'OX6',
    town: 'Bicester',
    county: 'Oxford',
  },
  {
    outward: 'OX7',
    town: 'Charlbury, Chadlington',
    county: 'West Oxfordshire',
    coordinates: [51.866948, -1.4788924],
  },
  {
    outward: 'OX8',
    town: 'Witney, Burford, Eynsham',
    county: 'Oxford',
  },
  {
    outward: 'OX9',
    town: 'Postcombe, Thame, Towersey, North Weston',
    county: 'South Oxfordshire',
    coordinates: [51.748168, -0.97867801],
  },
  {
    outward: 'OX10',
    town: 'Wallingford, Berinsfield, Cholsey, Dorchester',
    county: 'South Oxfordshire',
    coordinates: [51.600443, -1.1228842],
  },
  {
    outward: 'OX11',
    town: 'Didcot, Blewbury, Harwell',
    county: 'South Oxfordshire',
    coordinates: [51.602002, -1.2562826],
  },
  {
    outward: 'OX12',
    town: 'Wantage, Grove',
    county: 'Vale of White Horse',
    coordinates: [51.60358, -1.4233897],
  },
  {
    outward: 'OX13',
    town: 'Longworth, Sunningwell, Frilford, Cothill, Appleton',
    county: 'Vale of White Horse',
    coordinates: [51.68036, -1.4203472],
  },
  {
    outward: 'OX14',
    town:
      'Abingdon, Appleford-on-Thames, Burcot, Clifton Hampden, Culham, Drayton, Little Wittenham, Long Wittenham, Radley, Milton, Sutton Courtenay',
    county: 'South Oxfordshire',
    coordinates: [51.669901, -1.2965712],
  },
  {
    outward: 'OX15',
    town:
      'Bloxham, Drayton, Wroxton, North Newington, South Newington, Swalcliffe, Sibford Gower, Sibford Ferris, Barford St Michael, Barford St John, Deddington, Hempton, Broughton, Hornton, Horley, Balscote, Shenington, Alkerton, Wigginton, Milton, Hook Norton, Milcombe, Shutford, Epwell, Tadmarton, Swerford, Bodicote, Brailes, Edge Hill, Ratley, Upton',
    county: 'Cherwell',
    coordinates: [51.990837, -1.3674742],
  },
  {
    outward: 'OX16',
    town: 'Banbury',
    county: 'Cherwell',
    coordinates: [52.06004, -1.3399076],
  },
  {
    outward: 'OX17',
    town:
      "Adderbury, Aynho, Arlescote, Chacombe, Charlton, Chipping Warden, Claydon, Cropredy, Culworth, Edgcote, Farnborough, Great Bourton, Hanwell, King's Sutton, Little Bourton, Lower Thorpe, Marston St Lawrence, Middleton Cheney, Mollington, Nethercote, Newbottle, Overthorpe, Shotteswell, Stuchbury, Sulgrave, Thenford, Thorpe Mandeville, Twyford, Wardington, Warkworth, Warmington, Williamscott",
    county: 'Cherwell',
    coordinates: [52.102926, -1.2979135],
  },
  {
    outward: 'OX18',
    town:
      'Alvescot, Asthall, Aston, Bampton, Barrington, Black Bourton, Bradwell, Brize Norton, Burford, Carterton, Chimney, Clanfield, Cote, Fulbrook, Grafton, Holwell, Lew, Little Clanfield, Radcot, Shilton, Signet, Swinbrook, Taynton, Upper Windrush, Upton, Weald, Westwell, Widford, Windrush',
    county: 'West Oxfordshire',
    coordinates: [51.766534, -1.5870935],
  },
  {
    area: 'OX',
    outward: 'OX18',
    town: 'BAMPTON (shared)',
    county: 'Oxfordshire',
  },
  {
    outward: 'OX20',
    town: 'Woodstock, Bladon, Glympton, Kiddington, Woodleys, Wootton',
    county: 'West Oxfordshire',
    coordinates: [51.856191, -1.3613482],
  },
  {
    outward: 'OX25',
    town:
      'Ambrosden, Arncott, Blackthorn, Caulcott, Duns Tew, Little Chesterton, Lower Heyford, Merton, Middle Aston, Middleton Stoney, North Aston, Piddington, Rousham, Somerton, Steeple Aston, Steeple Barton, Upper Heyford, Wendlebury, Weston-on-the-Green',
    county: 'Cherwell',
    coordinates: [51.849792, -1.1133575],
  },
  {
    outward: 'OX26',
    town: 'Bicester, Chesterton, Launton',
    county: 'Cherwell',
    coordinates: [51.896641, -1.1669783],
  },
  {
    outward: 'OX27',
    town:
      'Ardley, Bainton, Baynards Green, Bucknell, Caversfield, Charndon, Fewcott, Fringford, Fritwell, Godington, Hardwick, Hethe, Marsh Gibbon, Newton Morrell, Poundon, Souldern, Stoke Lyne, Stratton Audley, Tusmore',
    county: 'Cherwell',
    coordinates: [51.906937, -1.0813431],
  },
  {
    outward: 'OX28',
    town: 'Witney',
    county: 'West Oxfordshire',
    coordinates: [51.790653, -1.4635186],
  },
  {
    outward: 'OX29',
    town:
      'Barnard Gate, Brighthampton, Church Hanborough, Combe, Crawley, Curbridge, Ducklington, Eynsham, Freeland, Hailey, Hardwick, High Cogges, Long Hanborough, New Yatt, Newbridge, North Leigh, East End (North Leigh), Old Shifford, Shifford, Standlake, Stanton Harcourt, Stonesfield, Sutton, Yelford',
    county: 'West Oxfordshire',
    coordinates: [51.789058, -1.5209938],
  },
  {
    outward: 'OX33',
    town:
      'Wheatley, Forest Hill, Holton, Horspath, Horton-cum-Studley, Littleworth, Milton Common, Stanton St. John, Waterperry, Waterstock, Woodperry.',
    county: 'South Oxfordshire',
    coordinates: [51.802932, -1.1464877],
  },
  {
    outward: 'OX39',
    town: 'Chinnor',
    county: 'South Oxfordshire',
    coordinates: [51.716754, -0.92841753],
  },
  {
    outward: 'OX44',
    town: 'Chalgrove, Garsington, Toot Baldon',
    county: 'South Oxfordshire',
    coordinates: [51.670802, -1.089843],
  },
  {
    outward: 'OX49',
    town: 'Watlington',
    county: 'South Oxfordshire',
    coordinates: [51.612767, -0.99988752],
  },
  {
    area: 'PA',
    outward: 'PA1',
    town: 'PAISLEY',
    county: 'Renfrewshire',
  },
  {
    area: 'PA',
    outward: 'PA2',
    town: 'PAISLEY',
    county: 'Renfrewshire',
  },
  {
    area: 'PA',
    outward: 'PA3',
    town: 'PAISLEY',
    county: 'Renfrewshire',
  },
  {
    area: 'PA',
    outward: 'PA4',
    town: 'Renfrew',
    county: 'Renfrewshire',
  },
  {
    area: 'PA',
    outward: 'PA5',
    town: 'Johnstone',
    county: 'Renfrewshire',
  },
  {
    area: 'PA',
    outward: 'PA6',
    town: 'Johnstone',
    county: 'Renfrewshire',
  },
  {
    area: 'PA',
    outward: 'PA7',
    town: 'BISHOPTON',
    county: 'Renfrewshire',
  },
  {
    area: 'PA',
    outward: 'PA8',
    town: 'Erskine',
    county: 'Renfrewshire',
  },
  {
    area: 'PA',
    outward: 'PA9',
    town: 'Johnstone',
    county: 'Renfrewshire',
  },
  {
    area: '',
    outward: 'PA10',
    town: 'Johnstone',
    county: 'Renfrewshire',
  },
  {
    area: 'PA',
    outward: 'PA11',
    town: 'Bridge of Weir',
    county: 'Renfrewshire',
  },
  {
    area: 'PA',
    outward: 'PA12',
    town: 'Lochwinnoch',
    county: 'Renfrewshire',
  },
  {
    area: 'PA',
    outward: 'PA13',
    town: 'Kilmacolm',
    county: 'Renfrewshire',
  },
  {
    area: 'PA',
    outward: 'PA14',
    town: 'Port Glasgow',
    county: 'Renfrewshire',
  },
  {
    area: 'PA',
    outward: 'PA15',
    town: 'Greenock',
    county: 'Renfrewshire',
  },
  {
    area: 'PA',
    outward: 'PA16',
    town: 'Greenock',
    county: 'Renfrewshire',
  },
  {
    area: 'PA',
    outward: 'PA17',
    town: 'Skelmorlie',
    county: 'Ayrshire',
  },
  {
    area: 'PA',
    outward: 'PA18',
    town: 'Wemyss Bay',
    county: 'Renfrewshire',
  },
  {
    area: 'PA',
    outward: 'PA19',
    town: 'Gourock',
    county: 'Renfrewshire',
  },
  {
    area: 'PA',
    outward: 'PA20',
    town: 'Isle of Bute',
    county: 'Isle of Bute',
  },
  {
    area: 'PA',
    outward: 'PA21',
    town: 'Tighnabruaich',
    county: 'Argyll',
  },
  {
    area: 'PA',
    outward: 'PA22',
    town: 'Colintraive',
    county: 'Argyll',
  },
  {
    area: 'PA',
    outward: 'PA23',
    town: 'Dunoon',
    county: 'Argyll',
  },
  {
    area: 'PA',
    outward: 'PA24',
    town: 'Cairndow',
    county: 'Argyll',
  },
  {
    area: 'PA',
    outward: 'PA25',
    town: 'Cairndow',
    county: 'Argyll',
  },
  {
    area: 'PA',
    outward: 'PA26',
    town: 'Cairndow',
    county: 'Argyll',
  },
  {
    area: 'PA',
    outward: 'PA27',
    town: 'Cairndow',
    county: 'Argyll',
  },
  {
    area: 'PA',
    outward: 'PA28',
    town: 'Campbeltown',
    county: 'Argyll',
  },
  {
    area: 'PA',
    outward: 'PA29',
    town: 'TARBERT',
    county: 'Argyll',
  },
  {
    area: 'PA',
    outward: 'PA30',
    town: 'Lochgilphead',
    county: 'Argyll',
  },
  {
    area: 'PA',
    outward: 'PA31',
    town: 'Lochgilphead',
    county: 'Argyll',
  },
  {
    area: 'PA',
    outward: 'PA32',
    town: 'Inveraray',
    county: 'Argyll',
  },
  {
    area: 'PA',
    outward: 'PA33',
    town: 'Dalmally',
    county: 'Argyll',
  },
  {
    area: 'PA',
    outward: 'PA34',
    town: 'Oban',
    county: 'Argyll',
  },
  {
    area: 'PA',
    outward: 'PA35',
    town: 'Taynuilt',
    county: 'Argyll',
  },
  {
    area: 'PA',
    outward: 'PA36',
    town: 'Bridge of Orchy',
    county: 'Argyll',
  },
  {
    area: 'PA',
    outward: 'PA37',
    town: 'Oban',
    county: 'Argyll',
  },
  {
    area: 'PA',
    outward: 'PA38',
    town: 'Appin',
    county: 'Argyll',
  },
  {
    area: 'PA',
    outward: 'PA41',
    town: 'ISLE OF GIGHA',
    county: 'Isle of Gigha',
  },
  {
    area: 'PA',
    outward: 'PA42',
    town: 'ISLE OF ISLAY',
    county: 'Isle of Islay',
  },
  {
    area: 'PA',
    outward: 'PA43',
    town: 'ISLE OF ISLAY',
    county: 'Isle of Islay',
  },
  {
    area: 'PA',
    outward: 'PA44',
    town: 'ISLE OF ISLAY',
    county: 'Isle of Islay',
  },
  {
    area: 'PA',
    outward: 'PA45',
    town: 'ISLE OF ISLAY',
    county: 'Isle of Islay',
  },
  {
    area: 'PA',
    outward: 'PA46',
    town: 'ISLE OF ISLAY',
    county: 'Isle of Islay',
  },
  {
    area: 'PA',
    outward: 'PA47',
    town: 'ISLE OF ISLAY',
    county: 'Isle of Islay',
  },
  {
    area: 'PA',
    outward: 'PA48',
    town: 'ISLE OF ISLAY',
    county: 'Isle of Islay',
  },
  {
    area: 'PA',
    outward: 'PA49',
    town: 'ISLE OF ISLAY',
    county: 'Isle of Islay',
  },
  {
    area: 'PA',
    outward: 'PA60',
    town: 'ISLE OF JURA',
    county: 'Isle of Jura',
  },
  {
    area: 'PA',
    outward: 'PA61',
    town: 'ISLE OF COLONSAY',
    county: 'Isle of Colonsay',
  },
  {
    area: 'PA',
    outward: 'PA62',
    town: 'Isle of Mull',
    county: 'Isle of Mull',
  },
  {
    area: 'PA',
    outward: 'PA63',
    town: 'Isle of Mull',
    county: 'Isle of Mull',
  },
  {
    area: 'PA',
    outward: 'PA64',
    town: 'Isle of Mull',
    county: 'Isle of Mull',
  },
  {
    area: 'PA',
    outward: 'PA65',
    town: 'Isle of Mull',
    county: 'Isle of Mull',
  },
  {
    area: 'PA',
    outward: 'PA66',
    town: 'Isle of Mull',
    county: 'Isle of Mull',
  },
  {
    area: 'PA',
    outward: 'PA67',
    town: 'Isle of Mull',
    county: 'Isle of Mull',
  },
  {
    area: 'PA',
    outward: 'PA68',
    town: 'Isle of Mull',
    county: 'Isle of Mull',
  },
  {
    area: 'PA',
    outward: 'PA69',
    town: 'Isle of Mull',
    county: 'Isle of Mull',
  },
  {
    area: '',
    outward: 'PA70',
    town: 'Isle of Mull',
    county: 'Isle of Mull',
  },
  {
    area: '',
    outward: 'PA71',
    town: 'Isle of Mull',
    county: 'Isle of Mull',
  },
  {
    area: '',
    outward: 'PA72',
    town: 'Isle of Mull',
    county: 'Isle of Mull',
  },
  {
    area: '',
    outward: 'PA73',
    town: 'Isle of Mull',
    county: 'Isle of Mull',
  },
  {
    area: '',
    outward: 'PA74',
    town: 'Isle of Mull',
    county: 'Isle of Mull',
  },
  {
    area: '',
    outward: 'PA75',
    town: 'Isle of Mull',
    county: 'Isle of Mull',
  },
  {
    area: 'PA',
    outward: 'PA76',
    town: 'ISLE OF IONA',
    county: 'Isle of Iona',
  },
  {
    area: 'PA',
    outward: 'PA77',
    town: 'ISLE OF TIREE',
    county: 'Isle of Tiree',
  },
  {
    area: 'PA',
    outward: 'PA78',
    town: 'ISLE OF COLL',
    county: 'Isle of Coll',
  },
  {
    area: '',
    outward: 'PA80',
    town: 'Oban',
    county: 'Argyll',
  },
  {
    area: 'PE',
    outward: 'PE1',
    town: 'Peterborough',
    county: 'Cambridgeshire',
  },
  {
    area: 'PE',
    outward: 'PE2',
    town: 'Peterborough',
    county: 'Cambridgeshire',
  },
  {
    area: 'PE',
    outward: 'PE3',
    town: 'Peterborough',
    county: 'Cambridgeshire',
  },
  {
    area: 'PE',
    outward: 'PE4',
    town: 'Peterborough',
    county: 'Cambridgeshire',
  },
  {
    area: 'PE',
    outward: 'PE5',
    town: 'Peterborough',
    county: 'Cambridgeshire',
  },
  {
    area: 'PE',
    outward: 'PE6',
    town: 'Peterborough',
    county: 'Cambridgeshire',
  },
  {
    area: 'PE',
    outward: 'PE7',
    town: 'Peterborough',
    county: 'Cambridgeshire',
  },
  {
    area: 'PE',
    outward: 'PE8',
    town: 'Peterborough',
    county: 'Cambridgeshire',
  },
  {
    area: 'PE',
    outward: 'PE10',
    town: 'BOURNE',
    county: 'Lincolnshire',
  },
  {
    area: 'PE',
    outward: 'PE11',
    town: 'SPALDING',
    county: 'Lincolnshire',
  },
  {
    area: 'PE',
    outward: 'PE12',
    town: 'SPALDING',
    county: 'Lincolnshire',
  },
  {
    area: 'PE',
    outward: 'PE13',
    town: 'Wisbech',
    county: 'Cambridgeshire',
  },
  {
    area: 'PE',
    outward: 'PE14',
    town: 'Wisbech',
    county: 'Cambridgeshire',
  },
  {
    area: 'PE',
    outward: 'PE15',
    town: 'MARCH',
    county: 'Cambridgeshire',
  },
  {
    area: 'PE',
    outward: 'PE16',
    town: 'Chatteris',
    county: 'Cambridgeshire',
  },
  {
    area: 'PE',
    outward: 'PE19',
    town: 'ST. NEOTS',
    county: 'Cambridgeshire',
  },
  {
    area: 'PE',
    outward: 'PE20',
    town: 'BOSTON',
    county: 'Lincolnshire',
  },
  {
    area: 'PE',
    outward: 'PE21',
    town: 'BOSTON',
    county: 'Lincolnshire',
  },
  {
    area: 'PE',
    outward: 'PE22',
    town: 'BOSTON',
    county: 'Lincolnshire',
  },
  {
    area: 'PE',
    outward: 'PE23',
    town: 'Spilsby',
    county: 'Lincolnshire',
  },
  {
    area: 'PE',
    outward: 'PE24',
    town: 'Skegness',
    county: 'Lincolnshire',
  },
  {
    area: 'PE',
    outward: 'PE25',
    town: 'Skegness',
    county: 'Lincolnshire',
  },
  {
    area: 'PE',
    outward: 'PE26',
    town: 'Huntingdon',
    county: 'Cambridgeshire',
  },
  {
    area: 'PE',
    outward: 'PE27',
    town: 'ST. IVES',
    county: 'Cambridgeshire',
  },
  {
    area: 'PE',
    outward: 'PE28',
    town: 'Huntingdon',
    county: 'Cambridgeshire',
  },
  {
    area: 'PE',
    outward: 'PE29',
    town: 'Huntingdon',
    county: 'Cambridgeshire',
  },
  {
    area: 'PE',
    outward: 'PE30',
    town: "King's Lynn",
    county: 'Norfolk',
  },
  {
    area: 'PE',
    outward: 'PE31',
    town: "King's Lynn",
    county: 'Norfolk',
  },
  {
    area: 'PE',
    outward: 'PE32',
    town: "King's Lynn",
    county: 'Norfolk',
  },
  {
    area: 'PE',
    outward: 'PE33',
    town: "King's Lynn",
    county: 'Norfolk',
  },
  {
    area: 'PE',
    outward: 'PE34',
    town: "King's Lynn",
    county: 'Norfolk',
  },
  {
    area: 'PE',
    outward: 'PE35',
    town: 'SANDRINGHAM',
    county: 'Norfolk',
  },
  {
    area: 'PE',
    outward: 'PE36',
    town: 'Hunstanton',
    county: 'Norfolk',
  },
  {
    area: 'PE',
    outward: 'PE37',
    town: 'Swaffham',
    county: 'Norfolk',
  },
  {
    area: 'PE',
    outward: 'PE38',
    town: 'Downham Market',
    county: 'Norfolk',
  },
  {
    area: '',
    outward: 'PE99',
    town: 'Non-geographic',
    county: 'Cambridgeshire',
  },
  {
    area: 'PH',
    outward: 'PH1',
    town: 'PERTH',
    county: 'Perthshire',
  },
  {
    area: 'PH',
    outward: 'PH2',
    town: 'PERTH',
    county: 'Perthshire',
  },
  {
    area: 'PH',
    outward: 'PH3',
    town: 'Auchterarder',
    county: 'Perthshire',
  },
  {
    area: 'PH',
    outward: 'PH4',
    town: 'Auchterarder',
    county: 'Perthshire',
  },
  {
    area: 'PH',
    outward: 'PH5',
    town: 'Crieff',
    county: 'Perthshire',
  },
  {
    area: 'PH',
    outward: 'PH6',
    town: 'Crieff',
    county: 'Perthshire',
  },
  {
    area: 'PH',
    outward: 'PH7',
    town: 'Crieff',
    county: 'Perthshire',
  },
  {
    area: 'PH',
    outward: 'PH8',
    town: 'Dunkeld',
    county: 'Perthshire',
  },
  {
    area: 'PH',
    outward: 'PH9',
    town: 'Pitlochry',
    county: 'Perthshire',
  },
  {
    area: 'PH',
    outward: 'PH10',
    town: 'BLAIRGOWRIE',
    county: 'Perthshire',
  },
  {
    area: 'PH',
    outward: 'PH11',
    town: 'BLAIRGOWRIE',
    county: 'Perthshire',
  },
  {
    area: 'PH',
    outward: 'PH12',
    town: 'BLAIRGOWRIE',
    county: 'Perthshire',
  },
  {
    area: 'PH',
    outward: 'PH13',
    town: 'BLAIRGOWRIE',
    county: 'Perthshire',
  },
  {
    area: '',
    outward: 'PH14',
    town: 'PERTH',
    county: 'Perthshire',
  },
  {
    area: 'PH',
    outward: 'PH15',
    town: 'ABERFELDY',
    county: 'Perthshire',
  },
  {
    area: '',
    outward: 'PH16',
    town: 'Pitlochry',
    county: 'Perthshire',
  },
  {
    area: '',
    outward: 'PH17',
    town: 'Pitlochry',
    county: 'Perthshire',
  },
  {
    area: '',
    outward: 'PH18',
    town: 'Pitlochry',
    county: 'Perthshire',
  },
  {
    area: 'PH',
    outward: 'PH19',
    town: 'Dalwhinnie',
    county: 'Inverness-shire',
  },
  {
    area: 'PH',
    outward: 'PH20',
    town: 'Newtonmore',
    county: 'Inverness-shire',
  },
  {
    area: 'PH',
    outward: 'PH21',
    town: 'Kingussie',
    county: 'Inverness-shire',
  },
  {
    area: 'PH',
    outward: 'PH22',
    town: 'Aviemore',
    county: 'Inverness-shire',
  },
  {
    area: 'PH',
    outward: 'PH23',
    town: 'Carrbridge',
    county: 'Inverness-shire',
  },
  {
    area: 'PH',
    outward: 'PH24',
    town: 'Boat of Garten',
    county: 'Inverness-shire',
  },
  {
    area: 'PH',
    outward: 'PH25',
    town: 'Nethy Bridge',
    county: 'Inverness-shire',
  },
  {
    area: 'PH',
    outward: 'PH26',
    town: 'Grantown-on-Spey',
    county: 'Morayshire',
  },
  {
    area: 'PH',
    outward: 'PH30',
    town: 'Corrour',
    county: 'Inverness-shire',
  },
  {
    area: 'PH',
    outward: 'PH31',
    town: 'ROY BRIDGE',
    county: 'Inverness-shire',
  },
  {
    area: 'PH',
    outward: 'PH32',
    town: 'Fort Augustus',
    county: 'Inverness-shire',
  },
  {
    area: 'PH',
    outward: 'PH33',
    town: 'FORT WILLIAM',
    county: 'Inverness-shire',
  },
  {
    area: 'PH',
    outward: 'PH34',
    town: 'Spean Bridge',
    county: 'Inverness-shire',
  },
  {
    area: 'PH',
    outward: 'PH35',
    town: 'Invergarry',
    county: 'Inverness-shire',
  },
  {
    area: 'PH',
    outward: 'PH36',
    town: 'Acharacle',
    county: 'Argyll',
  },
  {
    area: 'PH',
    outward: 'PH37',
    town: 'Glenfinnan',
    county: 'Inverness-shire',
  },
  {
    area: 'PH',
    outward: 'PH38',
    town: 'Lochailort',
    county: 'Inverness-shire',
  },
  {
    area: 'PH',
    outward: 'PH39',
    town: 'Arisaig',
    county: 'Inverness-shire',
  },
  {
    area: 'PH',
    outward: 'PH40',
    town: 'Mallaig',
    county: 'Inverness-shire',
  },
  {
    area: 'PH',
    outward: 'PH41',
    town: 'Mallaig',
    county: 'Inverness-shire',
  },
  {
    area: 'PH',
    outward: 'PH42',
    town: 'ISLE OF EIGG',
    county: 'Isle of Eigg',
  },
  {
    area: 'PH',
    outward: 'PH43',
    town: 'ISLE OF RUM',
    county: 'Isle of Rum',
  },
  {
    area: 'PH',
    outward: 'PH44',
    town: 'ISLE OF CANNA',
    county: 'Isle of Canna',
  },
  {
    area: 'PH',
    outward: 'PH49',
    town: 'Ballachulish',
    county: 'Argyll',
  },
  {
    area: 'PH',
    outward: 'PH50',
    town: 'Kinlochleven',
    county: 'Argyll',
  },
  {
    area: 'PL',
    outward: 'PL1',
    town: 'Plymouth',
    county: 'Devon',
  },
  {
    area: 'PL',
    outward: 'PL2',
    town: 'Plymouth',
    county: 'Devon',
  },
  {
    area: 'PL',
    outward: 'PL3',
    town: 'Plymouth',
    county: 'Devon',
  },
  {
    area: 'PL',
    outward: 'PL4',
    town: 'Plymouth',
    county: 'Devon',
  },
  {
    area: 'PL',
    outward: 'PL5',
    town: 'Plymouth',
    county: 'Devon',
  },
  {
    area: 'PL',
    outward: 'PL6',
    town: 'Plymouth',
    county: 'Devon',
  },
  {
    area: 'PL',
    outward: 'PL7',
    town: 'Plymouth',
    county: 'Devon',
  },
  {
    area: 'PL',
    outward: 'PL8',
    town: 'Plymouth',
    county: 'Devon',
  },
  {
    area: 'PL',
    outward: 'PL9',
    town: 'Plymouth',
    county: 'Devon',
  },
  {
    area: 'PL',
    outward: 'PL10',
    town: 'Torpoint',
    county: 'Cornwall',
  },
  {
    area: 'PL',
    outward: 'PL11',
    town: 'Torpoint',
    county: 'Cornwall',
  },
  {
    area: 'PL',
    outward: 'PL12',
    town: 'Saltash',
    county: 'Cornwall',
  },
  {
    area: 'PL',
    outward: 'PL13',
    town: 'Looe',
    county: 'Cornwall',
  },
  {
    area: 'PL',
    outward: 'PL14',
    town: 'Liskeard',
    county: 'Cornwall',
  },
  {
    area: 'PL',
    outward: 'PL15',
    town: 'LAUNCESTON',
    county: 'Cornwall',
  },
  {
    area: 'PL',
    outward: 'PL16',
    town: 'LIFTON',
    county: 'Devon',
  },
  {
    area: 'PL',
    outward: 'PL17',
    town: 'CALLINGTON',
    county: 'Cornwall',
  },
  {
    area: 'PL',
    outward: 'PL18',
    town: 'Calstock (shared)',
    county: 'Cornwall',
  },
  {
    area: 'PL',
    outward: 'PL19',
    town: 'TAVISTOCK',
    county: 'Devon',
  },
  {
    area: 'PL',
    outward: 'PL20',
    town: 'YELVERTON',
    county: 'Devon',
  },
  {
    area: 'PL',
    outward: 'PL21',
    town: 'Ivybridge',
    county: 'Devon',
  },
  {
    area: 'PL',
    outward: 'PL22',
    town: 'Lostwithiel',
    county: 'Cornwall',
  },
  {
    area: 'PL',
    outward: 'PL23',
    town: 'Fowey',
    county: 'Cornwall',
  },
  {
    area: 'PL',
    outward: 'PL24',
    town: 'PAR',
    county: 'Cornwall',
  },
  {
    area: 'PL',
    outward: 'PL25',
    town: 'ST. AUSTELL',
    county: 'Cornwall',
  },
  {
    area: 'PL',
    outward: 'PL26',
    town: 'ST. AUSTELL',
    county: 'Cornwall',
  },
  {
    area: 'PL',
    outward: 'PL27',
    town: 'Wadebridge',
    county: 'Cornwall',
  },
  {
    area: 'PL',
    outward: 'PL28',
    town: 'Padstow',
    county: 'Cornwall',
  },
  {
    area: 'PL',
    outward: 'PL29',
    town: 'Port Isaac',
    county: 'Cornwall',
  },
  {
    area: 'PL',
    outward: 'PL30',
    town: 'Bodmin',
    county: 'Cornwall',
  },
  {
    area: 'PL',
    outward: 'PL31',
    town: 'Bodmin',
    county: 'Cornwall',
  },
  {
    area: 'PL',
    outward: 'PL32',
    town: 'Camelford',
    county: 'Cornwall',
  },
  {
    area: 'PL',
    outward: 'PL33',
    town: 'Delabole',
    county: 'Cornwall',
  },
  {
    area: 'PL',
    outward: 'PL34',
    town: 'Tintagel',
    county: 'Cornwall',
  },
  {
    area: 'PL',
    outward: 'PL35',
    town: 'Boscastle',
    county: 'Cornwall',
  },
  {
    area: '',
    outward: 'PL95',
    town: 'Non-geographic',
    county: 'Devon',
  },
  {
    area: 'PO',
    outward: 'PO1',
    town: 'Portsmouth',
    county: 'Hampshire',
  },
  {
    area: 'PO',
    outward: 'PO2',
    town: 'Portsmouth',
    county: 'Hampshire',
  },
  {
    area: 'PO',
    outward: 'PO3',
    town: 'Portsmouth',
    county: 'Hampshire',
  },
  {
    area: 'PO',
    outward: 'PO4',
    town: 'Southsea',
    county: 'Hampshire',
  },
  {
    area: 'PO',
    outward: 'PO5',
    town: 'Southsea',
    county: 'Hampshire',
  },
  {
    area: 'PO',
    outward: 'PO6',
    town: 'Portsmouth',
    county: 'Hampshire',
  },
  {
    area: 'PO',
    outward: 'PO7',
    town: 'Waterlooville',
    county: 'Hampshire',
  },
  {
    area: 'PO',
    outward: 'PO8',
    town: 'Waterlooville',
    county: 'Hampshire',
  },
  {
    area: 'PO',
    outward: 'PO9',
    town: 'Havant (shared)',
    county: 'Hampshire',
  },
  {
    area: 'PO',
    outward: 'PO10',
    town: 'Emsworth',
    county: 'Hampshire',
  },
  {
    area: 'PO',
    outward: 'PO11',
    town: 'Hayling Island',
    county: 'Hampshire',
  },
  {
    area: 'PO',
    outward: 'PO12',
    town: 'Gosport (shared)',
    county: 'Hampshire',
  },
  {
    area: 'PO',
    outward: 'PO12',
    town: 'Non-geographic',
    county: 'Hampshire',
  },
  {
    area: 'PO',
    outward: 'PO13',
    town: 'Gosport (shared)',
    county: 'Hampshire',
  },
  {
    area: 'PO',
    outward: 'PO13',
    town: 'Lee-on-the-Solent (shared)',
    county: 'Hampshire',
  },
  {
    area: 'PO',
    outward: 'PO14',
    town: 'Fareham',
    county: 'Hampshire',
  },
  {
    area: 'PO',
    outward: 'PO15',
    town: 'Fareham',
    county: 'Hampshire',
  },
  {
    area: 'PO',
    outward: 'PO16',
    town: 'Fareham',
    county: 'Hampshire',
  },
  {
    area: 'PO',
    outward: 'PO17',
    town: 'Fareham',
    county: 'Hampshire',
  },
  {
    area: 'PO',
    outward: 'PO18',
    town: 'Chichester',
    county: 'West Sussex',
  },
  {
    area: 'PO',
    outward: 'PO19',
    town: 'Chichester',
    county: 'West Sussex',
  },
  {
    area: '',
    outward: 'PO20',
    town: 'Chichester',
    county: 'West Sussex',
  },
  {
    area: 'PO',
    outward: 'PO21',
    town: 'Bognor Regis',
    county: 'West Sussex',
  },
  {
    area: 'PO',
    outward: 'PO22',
    town: 'Bognor Regis',
    county: 'West Sussex',
  },
  {
    area: 'PO',
    outward: 'PO30',
    town: 'NEWPORT (shared)',
    county: 'Isle of Wight',
  },
  {
    area: 'PO',
    outward: 'PO30',
    town: 'non-geo shared',
    county: 'Isle of Wight',
  },
  {
    area: 'PO',
    outward: 'PO31',
    town: 'Cowes',
    county: 'Isle of Wight',
  },
  {
    area: 'PO',
    outward: 'PO32',
    town: 'East Cowes',
    county: 'Isle of Wight',
  },
  {
    area: 'PO',
    outward: 'PO33',
    town: 'Ryde',
    county: 'Isle of Wight',
  },
  {
    area: 'PO',
    outward: 'PO34',
    town: 'SEAVIEW',
    county: 'Isle of Wight',
  },
  {
    area: 'PO',
    outward: 'PO35',
    town: 'Bembridge',
    county: 'Isle of Wight',
  },
  {
    area: 'PO',
    outward: 'PO36',
    town: 'non-geo shared',
    county: 'Isle of Wight',
  },
  {
    area: 'PO',
    outward: 'PO36',
    town: 'Sandown (shared)',
    county: 'Isle of Wight',
  },
  {
    area: 'PO',
    outward: 'PO37',
    town: 'Shanklin',
    county: 'Isle of Wight',
  },
  {
    area: 'PO',
    outward: 'PO38',
    town: 'Ventnor',
    county: 'Isle of Wight',
  },
  {
    area: 'PO',
    outward: 'PO39',
    town: 'Totland Bay',
    county: 'Isle of Wight',
  },
  {
    area: 'PO',
    outward: 'PO40',
    town: 'FRESHWATER',
    county: 'Isle of Wight',
  },
  {
    area: '',
    outward: 'PO41',
    town: 'YARMOUTH',
    county: 'Isle of Wight',
  },
  {
    area: 'PR',
    outward: 'PR0',
    town: 'Non-geographic',
    county: 'Lancashire',
  },
  {
    area: 'PR',
    outward: 'PR1',
    town: 'PRESTON',
    county: 'Lancashire',
  },
  {
    area: 'PR',
    outward: 'PR2',
    town: 'PRESTON',
    county: 'Lancashire',
  },
  {
    area: 'PR',
    outward: 'PR3',
    town: 'PRESTON',
    county: 'Lancashire',
  },
  {
    area: 'PR',
    outward: 'PR4',
    town: 'PRESTON',
    county: 'Lancashire',
  },
  {
    area: 'PR',
    outward: 'PR5',
    town: 'PRESTON',
    county: 'Lancashire',
  },
  {
    area: 'PR',
    outward: 'PR6',
    town: 'Chorley',
    county: 'Lancashire',
  },
  {
    area: 'PR',
    outward: 'PR7',
    town: 'Chorley',
    county: 'Lancashire',
  },
  {
    area: 'PR',
    outward: 'PR8',
    town: 'Southport',
    county: 'Merseyside',
  },
  {
    area: 'PR',
    outward: 'PR9',
    town: 'Southport',
    county: 'Merseyside',
  },
  {
    area: '',
    outward: 'PR11',
    town: 'Non-geographic',
    county: 'Lancashire',
  },
  {
    area: 'PR',
    outward: 'PR25',
    town: 'LEYLAND',
    county: 'Lancashire',
  },
  {
    area: 'PR',
    outward: 'PR26',
    town: 'LEYLAND',
    county: 'Lancashire',
  },
  {
    area: 'RG',
    outward: 'RG1',
    town: 'READING',
    county: 'Berkshire',
  },
  {
    area: 'RG',
    outward: 'RG2',
    town: 'READING',
    county: 'Berkshire',
  },
  {
    area: 'RG',
    outward: 'RG4',
    town: 'READING',
    county: 'Berkshire',
  },
  {
    area: 'RG',
    outward: 'RG5',
    town: 'READING',
    county: 'Berkshire',
  },
  {
    area: 'RG',
    outward: 'RG6',
    town: 'READING',
    county: 'Berkshire',
  },
  {
    area: 'RG',
    outward: 'RG7',
    town: 'READING',
    county: 'Berkshire',
  },
  {
    area: 'RG',
    outward: 'RG8',
    town: 'READING',
    county: 'Berkshire',
  },
  {
    area: 'RG',
    outward: 'RG9',
    town: 'Henley-on-Thames',
    county: 'Oxfordshire',
  },
  {
    area: '',
    outward: 'RG10',
    town: 'READING',
    county: 'Berkshire',
  },
  {
    area: 'RG',
    outward: 'RG12',
    town: 'Bracknell',
    county: 'Berkshire',
  },
  {
    area: 'RG',
    outward: 'RG14',
    town: 'NEWBURY',
    county: 'Berkshire',
  },
  {
    area: 'RG',
    outward: 'RG17',
    town: 'Hungerford',
    county: 'Berkshire',
  },
  {
    area: 'RG',
    outward: 'RG18',
    town: 'Thatcham',
    county: 'Berkshire',
  },
  {
    area: '',
    outward: 'RG19',
    town: 'READING (shared)',
    county: 'Berkshire',
  },
  {
    area: 'RG',
    outward: 'RG19',
    town: 'Thatcham (shared)',
    county: 'Berkshire',
  },
  {
    area: '',
    outward: 'RG20',
    town: 'NEWBURY',
    county: 'Berkshire',
  },
  {
    area: 'RG',
    outward: 'RG21',
    town: 'Basingstoke',
    county: 'Hampshire',
  },
  {
    area: 'RG',
    outward: 'RG22',
    town: 'Basingstoke',
    county: 'Hampshire',
  },
  {
    area: 'RG',
    outward: 'RG23',
    town: 'Basingstoke',
    county: 'Hampshire',
  },
  {
    area: 'RG',
    outward: 'RG24',
    town: 'Basingstoke',
    county: 'Hampshire',
  },
  {
    area: 'RG',
    outward: 'RG25',
    town: 'Basingstoke',
    county: 'Hampshire',
  },
  {
    area: 'RG',
    outward: 'RG26',
    town: 'Tadley',
    county: 'Hampshire',
  },
  {
    area: 'RG',
    outward: 'RG27',
    town: 'HOOK',
    county: 'Hampshire',
  },
  {
    area: 'RG',
    outward: 'RG28',
    town: 'Basingstoke (shared)',
    county: 'Hampshire',
  },
  {
    area: 'RG',
    outward: 'RG29',
    town: 'HOOK',
    county: 'Hampshire',
  },
  {
    area: '',
    outward: 'RG30',
    town: 'READING',
    county: 'Berkshire',
  },
  {
    area: '',
    outward: 'RG31',
    town: 'READING',
    county: 'Berkshire',
  },
  {
    area: 'RG',
    outward: 'RG40',
    town: 'Wokingham',
    county: 'Berkshire',
  },
  {
    area: 'RG',
    outward: 'RG41',
    town: 'Wokingham',
    county: 'Berkshire',
  },
  {
    area: '',
    outward: 'RG42',
    town: 'Bracknell',
    county: 'Berkshire',
  },
  {
    area: 'RG',
    outward: 'RG45',
    town: 'Crowthorne',
    county: 'Berkshire',
  },
  {
    outward: 'RH1',
    town: 'Redhill, Bletchingley, Nutfield, Merstham, South Nutfield',
    county: 'Reigate and Banstead',
    coordinates: [51.240567, -0.1646819],
  },
  {
    outward: 'RH2',
    town: 'Reigate',
    county: 'Reigate and Banstead',
    coordinates: [51.238009, -0.2049817],
  },
  {
    outward: 'RH3',
    town: 'Betchworth, Brockham, Buckland, Strood Green',
    county: 'Mole Valley',
    coordinates: [51.238367, -0.26086685],
  },
  {
    outward: 'RH4',
    town: 'Dorking',
    county: 'Mole Valley',
    coordinates: [51.231714, -0.33075896],
  },
  {
    area: 'RH',
    outward: 'RH4',
    town: 'Betchworth (shared)',
    county: 'Surrey',
  },
  {
    area: 'RH',
    outward: 'RH4',
    town: 'Dorking (shared)',
    county: 'Surrey',
  },
  {
    outward: 'RH5',
    town: 'Capel, Westhumble, Mickleham, Ockley',
    county: 'Mole Valley',
    coordinates: [51.23478, -0.32192439],
  },
  {
    area: 'RH',
    outward: 'RH5',
    town: 'Dorking',
    county: 'Surrey',
  },
  {
    outward: 'RH6',
    town: 'Horley, Gatwick Airport',
    county: 'Crawley',
    coordinates: [51.168334, -0.17893693],
  },
  {
    area: 'RH',
    outward: 'RH6',
    town: 'GATWICK (shared)',
    county: 'West Sussex',
  },
  {
    outward: 'RH7',
    town: 'Lingfield, Dormansland',
    county: 'Tandridge',
    coordinates: [51.174263, -0.017058753],
  },
  {
    outward: 'RH8',
    town: 'Oxted',
    county: 'Tandridge',
    coordinates: [51.259899, -0.0040879818],
  },
  {
    outward: 'RH9',
    town: 'Godstone, South Godstone',
    county: 'Tandridge',
    coordinates: [51.251097, -0.065271602],
  },
  {
    outward: 'RH10',
    town:
      'Copthorne, Crawley Down, Domewood, Furnace Green, Northgate, Maidenbower, Pound Hill, Rowfant, Southgate, Three Bridges, Tilgate',
    county: 'Crawley',
    coordinates: [51.117177, -0.18847891],
  },
  {
    outward: 'RH11',
    town:
      'Bewbush, Broadfield, Gossops Green, Ifield, Langley Green, Lowfield Heath, Pease Pottage, Southgate West, West Green',
    county: 'Crawley',
    coordinates: [51.123739, -0.20166941],
  },
  {
    outward: 'RH12',
    town: 'Horsham, Broadbridge Heath, Rusper, Rudgwick, Warnham',
    county: 'Horsham',
    coordinates: [49.766809, -7.5571598],
  },
  {
    outward: 'RH13',
    town: 'Barns Green, Cowfold, Mannings Heath, Partridge Green, Slinfold, Southwater, West Grinstead',
    county: 'Horsham',
    coordinates: [51.053156, -0.32220524],
  },
  {
    outward: 'RH14',
    town: 'Billingshurst, Ifold, Kirdford, Loxwood, Plaistow, Wisborough Green',
    county: 'Chichester',
    coordinates: [51.039354, -0.52078751],
  },
  {
    outward: 'RH15',
    town: 'Burgess Hill, Ditchling Common',
    county: 'Mid Sussex',
    coordinates: [50.952832, -0.1271896],
  },
  {
    outward: 'RH16',
    town: 'Haywards Heath, Lindfield',
    county: 'Mid Sussex',
    coordinates: [51.006415, -0.10382195],
  },
  {
    outward: 'RH17',
    town:
      'Ansty, Ardingly, Balcombe, Bolney, Chelwood Gate, Cuckfield, Danehill, Handcross, Hickstead, Horsted Keynes, Wivelsfield, Wivelsfield Green',
    county: 'Mid Sussex',
    coordinates: [51.00356, -0.14441815],
  },
  {
    outward: 'RH18',
    town: 'Forest Row',
    county: 'Mid Sussex',
    coordinates: [51.097001, 0.033821307],
  },
  {
    outward: 'RH19',
    town: 'East Grinstead, Ashurst Wood, Dormans Park, Felbridge, Felcourt, Sharpthorne, West Hoathly',
    county: 'Mid Sussex',
    coordinates: [51.125873, -0.0097425769],
  },
  {
    outward: 'RH20',
    town: 'Pulborough, Ashington, Storrington, West Chiltington, West Chiltington Common',
    county: 'Horsham',
    coordinates: [50.95727, -0.51179062],
  },
  {
    outward: 'RH77',
    town: 'Non-geographic',
    county: 'Crawley',
    coordinates: [51.138775, -0.16977926],
  },
  {
    area: '',
    outward: 'RH77',
    town: 'Non-geographic',
    county: 'West Sussex',
  },
  {
    outward: 'RM1',
    town: 'Romford',
    county: 'Havering',
    coordinates: [51.5776, 0.17826093],
  },
  {
    outward: 'RM2',
    town: 'Gidea Park, Heath Park',
    county: 'Havering',
    coordinates: [51.577913, 0.19091933],
  },
  {
    outward: 'RM3',
    town: 'Harold Wood, Harold Hill, Noak Hill',
    county: 'Havering',
    coordinates: [51.597199, 0.21695748],
  },
  {
    outward: 'RM4',
    town:
      'Havering-atte-Bower, Abridge, Stapleford Abbotts, Noak Hill, Navestock, Stapleford Tawney, Stapleford Aerodrome',
    county: 'Havering',
    coordinates: [51.651567, 0.14446451],
  },
  {
    outward: 'RM5',
    town: 'Collier Row',
    county: 'Havering',
    coordinates: [51.597077, 0.16227013],
  },
  {
    outward: 'RM6',
    town: 'Chadwell Heath, Little Heath',
    county: 'Barking and Dagenham',
    coordinates: [51.570386, 0.12935673],
  },
  {
    outward: 'RM7',
    town: 'Rush Green, Mawneys, Romford',
    county: 'Havering',
    coordinates: [51.57244, 0.18007792],
  },
  {
    outward: 'RM8',
    town: 'Dagenham, Becontree Heath',
    county: 'Barking and Dagenham',
    coordinates: [51.561301, 0.14724113],
  },
  {
    outward: 'RM9',
    town: 'Dagenham, Becontree',
    county: 'Barking and Dagenham',
    coordinates: [51.544049, 0.12227137],
  },
  {
    outward: 'RM10',
    town: 'Dagenham',
    county: 'Barking and Dagenham',
    coordinates: [51.554182, 0.16809698],
  },
  {
    outward: 'RM11',
    town: 'Hornchurch, Emerson Park, Ardleigh Green',
    county: 'Havering',
    coordinates: [51.570191, 0.18834011],
  },
  {
    outward: 'RM12',
    town: 'Hornchurch, Elm Park',
    county: 'Havering',
    coordinates: [51.561858, 0.19394205],
  },
  {
    outward: 'RM13',
    town: 'Rainham, South Hornchurch, Wennington',
    county: 'Havering',
    coordinates: [51.516766, 0.19212017],
  },
  {
    outward: 'RM14',
    town: 'Upminster, Cranham, North Ockendon, Bulphan',
    county: 'Havering',
    coordinates: [51.552369, 0.24675743],
  },
  {
    outward: 'RM15',
    town: 'South Ockendon, Aveley',
    county: 'Thurrock',
    coordinates: [51.499427, 0.26266727],
  },
  {
    outward: 'RM16',
    town: 'Chafford Hundred, Chadwell St Mary, North Stifford, Orsett',
    county: 'Thurrock',
    coordinates: [51.495911, 0.3319371],
  },
  {
    outward: 'RM17',
    town: 'Grays, Badgers Dene',
    county: 'Thurrock',
    coordinates: [51.492202, 0.32036674],
  },
  {
    outward: 'RM18',
    town: 'Tilbury, East Tilbury, West Tilbury',
    county: 'Thurrock',
    coordinates: [51.465287, 0.35141052],
  },
  {
    outward: 'RM19',
    town: 'Purfleet',
    county: 'Thurrock',
    coordinates: [51.493821, 0.28420141],
  },
  {
    outward: 'RM20',
    town: 'West Thurrock, South Stifford',
    county: 'Thurrock',
    coordinates: [51.489033, 0.27556382],
  },
  {
    outward: 'RM50',
    town: 'Non-geographic',
    county: 'Romford',
  },
  {
    area: 'S',
    outward: 'S33',
    town: 'HOPE VALLEY',
    county: 'Derbyshire',
  },
  {
    area: 'S',
    outward: 'S42',
    town: 'Chesterfield',
    county: 'Derbyshire',
  },
  {
    area: 'S',
    outward: 'S43',
    town: 'Chesterfield',
    county: 'Derbyshire',
  },
  {
    area: 'S',
    outward: 'S44',
    town: 'Chesterfield',
    county: 'Derbyshire',
  },
  {
    area: 'S',
    outward: 'S45',
    town: 'Chesterfield',
    county: 'Derbyshire',
  },
  {
    area: 'S',
    outward: 'S49',
    town: 'Non-geographic',
    county: 'Derbyshire',
  },
  {
    area: 'S',
    outward: 'S60',
    town: 'Rotherham',
    county: 'South Yorkshire',
  },
  {
    area: 'S',
    outward: 'S61',
    town: 'Rotherham',
    county: 'South Yorkshire',
  },
  {
    area: 'S',
    outward: 'S62',
    town: 'Rotherham',
    county: 'South Yorkshire',
  },
  {
    area: 'S',
    outward: 'S63',
    town: 'Rotherham',
    county: 'South Yorkshire',
  },
  {
    area: 'S',
    outward: 'S64',
    town: 'Mexborough',
    county: 'South Yorkshire',
  },
  {
    area: 'S',
    outward: 'S65',
    town: 'Rotherham',
    county: 'South Yorkshire',
  },
  {
    area: 'S',
    outward: 'S66',
    town: 'Rotherham',
    county: 'South Yorkshire',
  },
  {
    area: 'S',
    outward: 'S70',
    town: 'Barnsley',
    county: 'South Yorkshire',
  },
  {
    area: 'S',
    outward: 'S71',
    town: 'Barnsley',
    county: 'South Yorkshire',
  },
  {
    area: 'S',
    outward: 'S72',
    town: 'Barnsley',
    county: 'South Yorkshire',
  },
  {
    area: 'S',
    outward: 'S73',
    town: 'Barnsley',
    county: 'South Yorkshire',
  },
  {
    area: 'S',
    outward: 'S74',
    town: 'Barnsley',
    county: 'South Yorkshire',
  },
  {
    area: 'S',
    outward: 'S75',
    town: 'Barnsley',
    county: 'South Yorkshire',
  },
  {
    area: 'S',
    outward: 'S81',
    town: 'Worksop',
    county: 'Nottinghamshire',
  },
  {
    area: '',
    outward: 'S95',
    town: 'Non-geographic',
    county: 'South Yorkshire',
  },
  {
    area: '',
    outward: 'S96',
    town: 'Non-geographic',
    county: 'South Yorkshire',
  },
  {
    area: '',
    outward: 'S97',
    town: 'Non-geographic',
    county: 'South Yorkshire',
  },
  {
    area: '',
    outward: 'S97',
    town: 'non-geo shared',
    county: 'South Yorkshire',
  },
  {
    area: '',
    outward: 'S98',
    town: 'Non-geographic',
    county: 'South Yorkshire',
  },
  {
    area: '',
    outward: 'S99',
    town: 'Non-geographic',
    county: 'South Yorkshire',
  },
  {
    area: 'SA',
    outward: 'SA1',
    town: 'Swansea',
    county: 'West Glamorgan',
  },
  {
    area: 'SA',
    outward: 'SA2',
    town: 'Swansea',
    county: 'West Glamorgan',
  },
  {
    area: 'SA',
    outward: 'SA3',
    town: 'Swansea',
    county: 'West Glamorgan',
  },
  {
    area: 'SA',
    outward: 'SA4',
    town: 'Swansea',
    county: 'West Glamorgan',
  },
  {
    area: 'SA',
    outward: 'SA5',
    town: 'Swansea',
    county: 'West Glamorgan',
  },
  {
    area: 'SA',
    outward: 'SA6',
    town: 'Swansea',
    county: 'West Glamorgan',
  },
  {
    area: 'SA',
    outward: 'SA7',
    town: 'Swansea',
    county: 'West Glamorgan',
  },
  {
    area: 'SA',
    outward: 'SA8',
    town: 'Swansea',
    county: 'West Glamorgan',
  },
  {
    area: 'SA',
    outward: 'SA9',
    town: 'Swansea',
    county: 'West Glamorgan',
  },
  {
    area: 'SA',
    outward: 'SA10',
    town: 'Neath',
    county: 'West Glamorgan',
  },
  {
    area: 'SA',
    outward: 'SA11',
    town: 'Neath',
    county: 'West Glamorgan',
  },
  {
    area: 'SA',
    outward: 'SA12',
    town: 'Port Talbot',
    county: 'West Glamorgan',
  },
  {
    area: 'SA',
    outward: 'SA13',
    town: 'Port Talbot',
    county: 'West Glamorgan',
  },
  {
    area: 'SA',
    outward: 'SA14',
    town: 'Llanelli',
    county: 'Dyfed',
  },
  {
    area: 'SA',
    outward: 'SA15',
    town: 'Llanelli',
    county: 'Dyfed',
  },
  {
    area: 'SA',
    outward: 'SA16',
    town: 'Burry Port',
    county: 'Dyfed',
  },
  {
    area: 'SA',
    outward: 'SA17',
    town: 'Ferryside (shared)',
    county: 'Dyfed',
  },
  {
    area: 'SA',
    outward: 'SA18',
    town: 'Ammanford',
    county: 'Dyfed',
  },
  {
    area: 'SA',
    outward: 'SA19',
    town: 'Llandeilo (shared)',
    county: 'Dyfed',
  },
  {
    area: 'SA',
    outward: 'SA20',
    town: 'Llandovery',
    county: 'Dyfed',
  },
  {
    area: 'SA',
    outward: 'SA31',
    town: 'Carmarthen',
    county: 'Dyfed',
  },
  {
    area: 'SA',
    outward: 'SA32',
    town: 'Carmarthen',
    county: 'Dyfed',
  },
  {
    area: 'SA',
    outward: 'SA33',
    town: 'Carmarthen',
    county: 'Dyfed',
  },
  {
    area: 'SA',
    outward: 'SA34',
    town: 'Whitland',
    county: 'Dyfed',
  },
  {
    area: 'SA',
    outward: 'SA35',
    town: 'Llanfyrnach',
    county: 'Dyfed',
  },
  {
    area: 'SA',
    outward: 'SA36',
    town: 'Glogue',
    county: 'Dyfed',
  },
  {
    area: 'SA',
    outward: 'SA37',
    town: 'Boncath',
    county: 'Dyfed',
  },
  {
    area: 'SA',
    outward: 'SA38',
    town: 'Newcastle Emlyn',
    county: 'Dyfed',
  },
  {
    area: 'SA',
    outward: 'SA39',
    town: 'PENCADER',
    county: 'Dyfed',
  },
  {
    area: 'SA',
    outward: 'SA40',
    town: 'Llanybydder',
    county: 'Dyfed',
  },
  {
    area: 'SA',
    outward: 'SA41',
    town: 'Crymych',
    county: 'Dyfed',
  },
  {
    area: 'SA',
    outward: 'SA42',
    town: 'NEWPORT',
    county: 'Dyfed',
  },
  {
    area: 'SA',
    outward: 'SA43',
    town: 'CARDIGAN',
    county: 'Dyfed',
  },
  {
    area: 'SA',
    outward: 'SA44',
    town: 'Llandysul',
    county: 'Dyfed',
  },
  {
    area: 'SA',
    outward: 'SA45',
    town: 'New Quay',
    county: 'Dyfed',
  },
  {
    area: 'SA',
    outward: 'SA46',
    town: 'Aberaeron',
    county: 'Dyfed',
  },
  {
    area: 'SA',
    outward: 'SA47',
    town: 'LLANARTH',
    county: 'Dyfed',
  },
  {
    area: 'SA',
    outward: 'SA48',
    town: 'non-geo shared',
    county: 'Dyfed',
  },
  {
    area: 'SA',
    outward: 'SA48',
    town: 'Lampeter (shared)',
    county: 'Dyfed',
  },
  {
    area: 'SA',
    outward: 'SA61',
    town: 'Haverfordwest',
    county: 'Dyfed',
  },
  {
    area: 'SA',
    outward: 'SA62',
    town: 'Haverfordwest',
    county: 'Dyfed',
  },
  {
    area: 'SA',
    outward: 'SA63',
    town: 'Clarbeston Road',
    county: 'Dyfed',
  },
  {
    area: 'SA',
    outward: 'SA64',
    town: 'Goodwick',
    county: 'Dyfed',
  },
  {
    area: 'SA',
    outward: 'SA65',
    town: 'Fishguard',
    county: 'Dyfed',
  },
  {
    area: 'SA',
    outward: 'SA66',
    town: 'Clynderwen',
    county: 'Dyfed',
  },
  {
    area: 'SA',
    outward: 'SA67',
    town: 'NARBERTH',
    county: 'Dyfed',
  },
  {
    area: 'SA',
    outward: 'SA68',
    town: 'Kilgetty',
    county: 'Dyfed',
  },
  {
    area: 'SA',
    outward: 'SA69',
    town: 'Saundersfoot',
    county: 'Dyfed',
  },
  {
    area: 'SA',
    outward: 'SA70',
    town: 'Tenby',
    county: 'Dyfed',
  },
  {
    area: 'SA',
    outward: 'SA71',
    town: 'PEMBROKE',
    county: 'Dyfed',
  },
  {
    area: 'SA',
    outward: 'SA72',
    town: 'non-geo shared',
    county: 'Dyfed',
  },
  {
    area: 'SA',
    outward: 'SA72',
    town: 'Pembroke Dock (shared)',
    county: 'Dyfed',
  },
  {
    area: 'SA',
    outward: 'SA73',
    town: 'Milford Haven',
    county: 'Dyfed',
  },
  {
    area: '',
    outward: 'SA80',
    town: 'Non-geographic',
    county: 'West Glamorgan',
  },
  {
    area: '',
    outward: 'SA99',
    town: 'Non-geographic',
    county: 'West Glamorgan',
  },
  {
    outward: 'SE1',
    town: 'Bankside, South Bank, Southwark, Bermondsey, Vauxhall',
    county: 'Southwark',
    coordinates: [51.498409, -0.10165523],
  },
  {
    outward: 'SE1P',
    town: 'Non-geographic',
    county: 'Southwark',
    coordinates: [51.492762, -0.080151673],
  },
  {
    area: 'SE',
    outward: 'SE1P',
    town: 'Non-geographic',
    county: 'London',
  },
  {
    outward: 'SE2',
    town: 'Abbey Wood, West Heath, Crossness, Thamesmead',
    county: 'Greenwich',
    coordinates: [51.481603, 0.12271219],
  },
  {
    outward: 'SE3',
    town: 'Blackheath, Kidbrooke, Westcombe Park',
    county: 'Greenwich',
    coordinates: [51.467796, 0.0180305],
  },
  {
    outward: 'SE4',
    town: 'Brockley, Crofton Park, Western Ladywell, Honor Oak',
    county: 'Lewisham',
    coordinates: [51.453277, -0.032497323],
  },
  {
    outward: 'SE5',
    town: 'Camberwell, Denmark Hill, Southampton Way Estate, Peckham',
    county: 'Lambeth',
    coordinates: [51.48248, -0.095374827],
  },
  {
    outward: 'SE6',
    town: 'Bellingham, Catford, Hither Green',
    county: 'Lewisham',
    coordinates: [51.445315, -0.0062021278],
  },
  {
    outward: 'SE7',
    town: 'Charlton',
    county: 'Greenwich',
    coordinates: [51.483026, 0.034848417],
  },
  {
    outward: 'SE8',
    town: 'Deptford, Evelyn',
    county: 'Lewisham',
    coordinates: [51.486013, -0.03159257],
  },
  {
    outward: 'SE9',
    town: 'Eltham, Mottingham, New Eltham, Falconwood, Chinbrook, Longlands, Kidbrooke',
    county: 'Greenwich',
    coordinates: [51.450353, 0.062295515],
  },
  {
    outward: 'SE10',
    town: 'Greenwich, Maze Hill, Greenwich Peninsula',
    county: 'Greenwich',
    coordinates: [51.487122, 0.0032841377],
  },
  {
    outward: 'SE11',
    town: 'Kennington, Vauxhall',
    county: 'Lambeth',
    coordinates: [51.485536, -0.10969408],
  },
  {
    outward: 'SE12',
    town: 'Lee, Grove Park, Chinbrook, Hither Green, Eltham, Horn Park, Blackheath',
    county: 'Lewisham',
    coordinates: [51.449266, 0.013068373],
  },
  {
    outward: 'SE13',
    town: 'Lewisham, Hither Green, Ladywell',
    county: 'Lewisham',
    coordinates: [51.4596, -0.0059538934],
  },
  {
    outward: 'SE14',
    town: 'New Cross, New Cross Gate',
    county: 'Lewisham',
    coordinates: [51.474839, -0.046876962],
  },
  {
    outward: 'SE15',
    town: 'Nunhead, Peckham',
    county: 'Lewisham',
    coordinates: [51.47681, -0.068163385],
  },
  {
    outward: 'SE16',
    town: 'Rotherhithe, Bermondsey, Surrey Quays',
    county: 'Southwark',
    coordinates: [51.490761, -0.048285259],
  },
  {
    outward: 'SE17',
    town: 'Walworth, Newington',
    county: 'Southwark',
    coordinates: [51.489348, -0.091357828],
  },
  {
    outward: 'SE18',
    town: 'Plumstead, Woolwich',
    county: 'Greenwich',
    coordinates: [51.484461, 0.084733441],
  },
  {
    outward: 'SE19',
    town: 'Upper Norwood, Crystal Palace, Crown Point, Norwood',
    county: 'Croydon',
    coordinates: [51.420963, -0.086067379],
  },
  {
    outward: 'SE20',
    town: 'Anerley, Crystal Palace, Penge, Beckenham',
    county: 'Bromley',
    coordinates: [51.409107, -0.059069145],
  },
  {
    outward: 'SE21',
    town: 'Dulwich, Dulwich Village, West Dulwich, Tulse Hill, Sydenham Hill',
    county: 'Lambeth',
    coordinates: [51.434204, -0.085226241],
  },
  {
    outward: 'SE22',
    town: 'East Dulwich, Peckham Rye, Loughborough Junction, Herne Hill',
    county: 'Southwark',
    coordinates: [51.447688, -0.072271143],
  },
  {
    outward: 'SE23',
    town: 'Forest Hill, Honor Oak, Crofton Park',
    county: 'Lewisham',
    coordinates: [51.443782, -0.049252879],
  },
  {
    outward: 'SE24',
    town: 'Herne Hill, Tulse Hill',
    county: 'Lambeth',
    coordinates: [51.464009, -0.093149521],
  },
  {
    outward: 'SE25',
    town: 'South Norwood, Selhurst',
    county: 'Croydon',
    coordinates: [51.40169, -0.076118048],
  },
  {
    outward: 'SE26',
    town: 'Sydenham, Crystal Palace',
    county: 'Bromley',
    coordinates: [51.429773, -0.04609493],
  },
  {
    outward: 'SE27',
    town: 'West Norwood, Gipsy Hill, Norwood',
    county: 'Lambeth',
    coordinates: [51.436348, -0.10538034],
  },
  {
    outward: 'SE28',
    town: 'Thamesmead',
    county: 'Greenwich',
    coordinates: [51.495614, 0.098266154],
  },
  {
    outward: 'SE99',
    town: 'Non-geographic',
    county: 'London',
  },
  {
    area: 'SG',
    outward: 'SG1',
    town: 'Stevenage',
    county: 'Hertfordshire',
  },
  {
    area: 'SG',
    outward: 'SG2',
    town: 'Stevenage',
    county: 'Hertfordshire',
  },
  {
    area: 'SG',
    outward: 'SG3',
    town: 'Knebworth',
    county: 'Hertfordshire',
  },
  {
    area: 'SG',
    outward: 'SG4',
    town: 'Hitchin',
    county: 'Hertfordshire',
  },
  {
    area: 'SG',
    outward: 'SG5',
    town: 'Hitchin',
    county: 'Hertfordshire',
  },
  {
    area: 'SG',
    outward: 'SG6',
    town: 'non-geo shared',
    county: 'Hertfordshire',
  },
  {
    area: 'SG',
    outward: 'SG6',
    town: 'LETCHWORTH GARDEN CITY (shared)',
    county: 'Hertfordshire',
  },
  {
    area: 'SG',
    outward: 'SG7',
    town: 'Baldock',
    county: 'Hertfordshire',
  },
  {
    area: 'SG',
    outward: 'SG8',
    town: 'ROYSTON',
    county: 'Hertfordshire',
  },
  {
    area: 'SG',
    outward: 'SG9',
    town: 'Buntingford',
    county: 'Hertfordshire',
  },
  {
    area: 'SG',
    outward: 'SG10',
    town: 'Much Hadham',
    county: 'Hertfordshire',
  },
  {
    area: 'SG',
    outward: 'SG11',
    town: 'WARE',
    county: 'Hertfordshire',
  },
  {
    area: 'SG',
    outward: 'SG12',
    town: 'WARE',
    county: 'Hertfordshire',
  },
  {
    area: 'SG',
    outward: 'SG13',
    town: 'Hertford',
    county: 'Hertfordshire',
  },
  {
    area: 'SG',
    outward: 'SG14',
    town: 'Hertford',
    county: 'Hertfordshire',
  },
  {
    area: 'SG',
    outward: 'SG15',
    town: 'Arlesey',
    county: 'Bedfordshire',
  },
  {
    area: 'SG',
    outward: 'SG16',
    town: 'Henlow',
    county: 'Bedfordshire',
  },
  {
    area: 'SG',
    outward: 'SG17',
    town: 'SHEFFORD',
    county: 'Bedfordshire',
  },
  {
    area: 'SG',
    outward: 'SG18',
    town: 'Biggleswade',
    county: 'Bedfordshire',
  },
  {
    area: 'SG',
    outward: 'SG19',
    town: 'SANDY',
    county: 'Bedfordshire',
  },
  {
    area: 'SK',
    outward: 'SK1',
    town: 'Stockport',
    county: 'Cheshire',
  },
  {
    area: 'SK',
    outward: 'SK2',
    town: 'Stockport',
    county: 'Cheshire',
  },
  {
    area: 'SK',
    outward: 'SK3',
    town: 'Stockport',
    county: 'Cheshire',
  },
  {
    area: 'SK',
    outward: 'SK4',
    town: 'Stockport',
    county: 'Cheshire',
  },
  {
    area: 'SK',
    outward: 'SK5',
    town: 'Stockport',
    county: 'Cheshire',
  },
  {
    area: 'SK',
    outward: 'SK6',
    town: 'Stockport',
    county: 'Cheshire',
  },
  {
    area: 'SK',
    outward: 'SK7',
    town: 'Stockport',
    county: 'Cheshire',
  },
  {
    area: 'SK',
    outward: 'SK8',
    town: 'CHEADLE',
    county: 'Cheshire',
  },
  {
    area: 'SK',
    outward: 'SK9',
    town: 'Alderley Edge (shared)',
    county: 'Cheshire',
  },
  {
    area: 'SK',
    outward: 'SK10',
    town: 'Macclesfield',
    county: 'Cheshire',
  },
  {
    area: 'SK',
    outward: 'SK11',
    town: 'Macclesfield',
    county: 'Cheshire',
  },
  {
    area: '',
    outward: 'SK12',
    town: 'Stockport',
    county: 'Cheshire',
  },
  {
    area: 'SK',
    outward: 'SK13',
    town: 'Glossop',
    county: 'Derbyshire',
  },
  {
    area: 'SK',
    outward: 'SK14',
    town: 'HYDE',
    county: 'Cheshire',
  },
  {
    area: 'SK',
    outward: 'SK15',
    town: 'Stalybridge',
    county: 'Cheshire',
  },
  {
    area: 'SK',
    outward: 'SK16',
    town: 'Dukinfield',
    county: 'Cheshire',
  },
  {
    area: 'SK',
    outward: 'SK17',
    town: 'Buxton',
    county: 'Derbyshire',
  },
  {
    area: 'SK',
    outward: 'SK22',
    town: 'HIGH PEAK',
    county: 'Derbyshire',
  },
  {
    area: 'SK',
    outward: 'SK23',
    town: 'HIGH PEAK',
    county: 'Derbyshire',
  },
  {
    outward: 'SL0',
    town: 'Iver, Iver Heath, Richings Park, Thorney',
    county: 'South Buckinghamshire',
    coordinates: [51.522086, -0.50795379],
  },
  {
    outward: 'SL1',
    town: 'Burnham, Littleworth, Salt Hill, Slough, Upton, Cippenham',
    county: 'South Buckinghamshire',
    coordinates: [51.508271, -0.58461853],
  },
  {
    outward: 'SL2',
    town: 'Farnham Common, Farnham Royal, Hedgerley, Stoke Poges',
    county: 'South Buckinghamshire',
    coordinates: [51.52676, -0.61489465],
  },
  {
    outward: 'SL3',
    town: 'Colnbrook, Datchet, Fulmer, George Green, Horton, Langley, Poyle',
    county: 'Slough',
    coordinates: [51.475676, -0.51638022],
  },
  {
    outward: 'SL4',
    town:
      'Boveney, Clewer, Cranbourne, Dorney, Eton, Eton Wick, Fifield, Oakley Green, Old Windsor, Spital, Windsor, Berkshire, Winkfield, Woodside',
    county: 'Bracknell Forest',
    coordinates: [51.481162, -0.61040066],
  },
  {
    outward: 'SL5',
    town: 'Ascot, Burleigh, Cheapside, North Ascot, South Ascot, Sunningdale, Sunninghill',
    county: 'Windsor and Maidenhead',
    coordinates: [51.402678, -0.65268947],
  },
  {
    outward: 'SL6',
    town:
      "Bray, Bray Wick, Burchett's Green, Cookham, Cookham Dean, Cookham Rise, Furze Platt, Hitcham, Holyport, Hurley, Hurley Bottom, Littlewick Green, Maidenhead, Paley Street, Pinkneys Green, Stud Green, Taplow, Touchen End, White Waltham, Woodlands Park",
    county: 'South Buckinghamshire',
    coordinates: [51.525605, -0.70097357],
  },
  {
    outward: 'SL7',
    town: 'Bisham, Little Marlow, Lower Woodend, Buckinghamshire, Marlow, Marlow Bottom, Medmenham',
    county: 'South Buckinghamshire',
    coordinates: [51.570717, -0.77544203],
  },
  {
    outward: 'SL8',
    town: 'Bourne End, Well End, Buckinghamshire',
    county: 'South Buckinghamshire',
    coordinates: [51.575941, -0.70825554],
  },
  {
    outward: 'SL9',
    town: 'Chalfont Common, Chalfont St Peter, Gerrards Cross, Horn Hill',
    county: 'South Buckinghamshire',
    coordinates: [51.608595, -0.55367216],
  },
  {
    outward: 'SL60',
    town: 'Non-geographic',
    county: 'Maidenhead',
    coordinates: [51.518554, -0.71479426],
  },
  {
    area: '',
    outward: 'SL60',
    town: 'Non-geographic',
    county: 'Berkshire',
  },
  {
    outward: 'SL95',
    town: 'Non-geographic',
    county: 'Slough',
    coordinates: [51.508271, -0.58461853],
  },
  {
    area: '',
    outward: 'SL95',
    town: 'Non-geographic',
    county: 'Berkshire',
  },
  {
    outward: 'SM1',
    town: 'Sutton, Rose Hill, The Wrythe, Carshalton, Benhilton, Erskine Village',
    county: 'Sutton',
    coordinates: [51.359919, -0.19302374],
  },
  {
    outward: 'SM2',
    town: 'Belmont, South Sutton, East Ewell',
    county: 'Sutton',
    coordinates: [51.358872, -0.19102534],
  },
  {
    outward: 'SM3',
    town: 'North Cheam',
    county: 'Sutton',
    coordinates: [51.355393, -0.22222803],
  },
  {
    outward: 'SM4',
    town: 'Morden, Morden Park, Lower Morden, St. Helier',
    county: 'Merton',
    coordinates: [51.384566, -0.22463315],
  },
  {
    outward: 'SM5',
    town:
      'Carshalton, Carshalton Beeches, Carshalton on the Hill, The Wrythe, Carshalton Village, Eastern St. Helier, Middleton Circle',
    county: 'Sutton',
    coordinates: [51.380072, -0.18319141],
  },
  {
    outward: 'SM6',
    town: 'Wallington, Beddington, Hackbridge, Roundshaw, South Beddington',
    county: 'Sutton',
    coordinates: [51.365888, -0.15308218],
  },
  {
    outward: 'SM7',
    town: 'Banstead',
    county: 'Reigate and Banstead',
    coordinates: [51.319039, -0.21355952],
  },
  {
    area: 'SN',
    outward: 'SN1',
    town: 'Swindon',
    county: 'Wiltshire',
  },
  {
    area: 'SN',
    outward: 'SN2',
    town: 'Swindon',
    county: 'Wiltshire',
  },
  {
    area: 'SN',
    outward: 'SN3',
    town: 'Swindon',
    county: 'Wiltshire',
  },
  {
    area: 'SN',
    outward: 'SN4',
    town: 'Swindon',
    county: 'Wiltshire',
  },
  {
    area: 'SN',
    outward: 'SN5',
    town: 'Swindon',
    county: 'Wiltshire',
  },
  {
    area: 'SN',
    outward: 'SN6',
    town: 'Swindon',
    county: 'Wiltshire',
  },
  {
    area: 'SN',
    outward: 'SN7',
    town: 'Faringdon',
    county: 'Oxfordshire',
  },
  {
    area: 'SN',
    outward: 'SN8',
    town: 'MARLBOROUGH',
    county: 'Wiltshire',
  },
  {
    area: 'SN',
    outward: 'SN10',
    town: 'Devizes',
    county: 'Wiltshire',
  },
  {
    area: 'SN',
    outward: 'SN11',
    town: 'Calne',
    county: 'Wiltshire',
  },
  {
    area: 'SN',
    outward: 'SN12',
    town: 'Melksham',
    county: 'Wiltshire',
  },
  {
    area: 'SN',
    outward: 'SN13',
    town: 'Corsham',
    county: 'Wiltshire',
  },
  {
    area: 'SN',
    outward: 'SN14',
    town: 'Chippenham',
    county: 'Wiltshire',
  },
  {
    area: 'SN',
    outward: 'SN15',
    town: 'non-geo shared',
    county: 'Wiltshire',
  },
  {
    area: 'SN',
    outward: 'SN15',
    town: 'Chippenham (shared)',
    county: 'Wiltshire',
  },
  {
    area: 'SN',
    outward: 'SN16',
    town: 'Malmesbury',
    county: 'Wiltshire',
  },
  {
    area: '',
    outward: 'SN25',
    town: 'Swindon',
    county: 'Wiltshire',
  },
  {
    area: '',
    outward: 'SN26',
    town: 'Swindon',
    county: 'Wiltshire',
  },
  {
    area: '',
    outward: 'SN38',
    town: 'Non-geographic',
    county: 'Wiltshire',
  },
  {
    area: '',
    outward: 'SN99',
    town: 'Non-geographic',
    county: 'Wiltshire',
  },
  {
    area: 'SO',
    outward: 'SO14',
    town: 'Southampton',
    county: 'Hampshire',
  },
  {
    area: 'SO',
    outward: 'SO15',
    town: 'Southampton',
    county: 'Hampshire',
  },
  {
    area: 'SO',
    outward: 'SO16',
    town: 'Southampton',
    county: 'Hampshire',
  },
  {
    area: 'SO',
    outward: 'SO17',
    town: 'Southampton',
    county: 'Hampshire',
  },
  {
    area: 'SO',
    outward: 'SO18',
    town: 'Southampton',
    county: 'Hampshire',
  },
  {
    area: 'SO',
    outward: 'SO19',
    town: 'Southampton',
    county: 'Hampshire',
  },
  {
    area: 'SO',
    outward: 'SO20',
    town: 'STOCKBRIDGE',
    county: 'Hampshire',
  },
  {
    area: 'SO',
    outward: 'SO21',
    town: 'Winchester',
    county: 'Hampshire',
  },
  {
    area: 'SO',
    outward: 'SO22',
    town: 'Winchester',
    county: 'Hampshire',
  },
  {
    area: 'SO',
    outward: 'SO23',
    town: 'Winchester',
    county: 'Hampshire',
  },
  {
    area: 'SO',
    outward: 'SO24',
    town: 'ALRESFORD',
    county: 'Hampshire',
  },
  {
    area: 'SO',
    outward: 'SO25',
    town: 'Non-geographic',
    county: 'Hampshire',
  },
  {
    area: '',
    outward: 'SO30',
    town: 'Southampton',
    county: 'Hampshire',
  },
  {
    area: '',
    outward: 'SO31',
    town: 'Southampton',
    county: 'Hampshire',
  },
  {
    area: '',
    outward: 'SO32',
    town: 'Southampton',
    county: 'Hampshire',
  },
  {
    area: '',
    outward: 'SO40',
    town: 'Southampton (shared)',
    county: 'Hampshire',
  },
  {
    area: 'SO',
    outward: 'SO40',
    town: 'LYNDHURST (shared)',
    county: 'Hampshire',
  },
  {
    area: 'SO',
    outward: 'SO41',
    town: 'Lymington',
    county: 'Hampshire',
  },
  {
    area: 'SO',
    outward: 'SO42',
    town: 'Brockenhurst',
    county: 'Hampshire',
  },
  {
    area: 'SO',
    outward: 'SO43',
    town: 'LYNDHURST',
    county: 'Hampshire',
  },
  {
    area: '',
    outward: 'SO45',
    town: 'Southampton',
    county: 'Hampshire',
  },
  {
    area: 'SO',
    outward: 'SO50',
    town: 'Eastleigh',
    county: 'Hampshire',
  },
  {
    area: 'SO',
    outward: 'SO51',
    town: 'Romsey',
    county: 'Hampshire',
  },
  {
    area: '',
    outward: 'SO52',
    town: 'Southampton',
    county: 'Hampshire',
  },
  {
    area: 'SO',
    outward: 'SO53',
    town: 'Eastleigh',
    county: 'Hampshire',
  },
  {
    area: '',
    outward: 'SO97',
    town: 'Non-geographic',
    county: 'Hampshire',
  },
  {
    area: 'SP',
    outward: 'SP1',
    town: 'Salisbury',
    county: 'Wiltshire',
  },
  {
    area: 'SP',
    outward: 'SP2',
    town: 'Salisbury',
    county: 'Wiltshire',
  },
  {
    area: 'SP',
    outward: 'SP3',
    town: 'Salisbury',
    county: 'Wiltshire',
  },
  {
    area: 'SP',
    outward: 'SP4',
    town: 'Salisbury',
    county: 'Wiltshire',
  },
  {
    area: 'SP',
    outward: 'SP5',
    town: 'Salisbury',
    county: 'Wiltshire',
  },
  {
    area: 'SP',
    outward: 'SP6',
    town: 'Fordingbridge',
    county: 'Hampshire',
  },
  {
    area: 'SP',
    outward: 'SP7',
    town: 'Shaftesbury',
    county: 'Dorset',
  },
  {
    area: 'SP',
    outward: 'SP8',
    town: 'GILLINGHAM',
    county: 'Dorset',
  },
  {
    area: 'SP',
    outward: 'SP9',
    town: 'Tidworth',
    county: 'Wiltshire',
  },
  {
    area: 'SP',
    outward: 'SP10',
    town: 'ANDOVER',
    county: 'Hampshire',
  },
  {
    area: 'SP',
    outward: 'SP11',
    town: 'ANDOVER',
    county: 'Hampshire',
  },
  {
    area: 'SR',
    outward: 'SR1',
    town: 'SUNDERLAND',
    county: 'Tyne and Wear',
  },
  {
    area: 'SR',
    outward: 'SR2',
    town: 'SUNDERLAND',
    county: 'Tyne and Wear',
  },
  {
    area: 'SR',
    outward: 'SR3',
    town: 'SUNDERLAND',
    county: 'Tyne and Wear',
  },
  {
    area: 'SR',
    outward: 'SR4',
    town: 'SUNDERLAND',
    county: 'Tyne and Wear',
  },
  {
    area: 'SR',
    outward: 'SR5',
    town: 'SUNDERLAND',
    county: 'Tyne and Wear',
  },
  {
    area: 'SR',
    outward: 'SR6',
    town: 'SUNDERLAND',
    county: 'Tyne and Wear',
  },
  {
    area: 'SR',
    outward: 'SR7',
    town: 'Seaham',
    county: 'County Durham',
  },
  {
    area: 'SR',
    outward: 'SR8',
    town: 'Peterlee',
    county: 'County Durham',
  },
  {
    area: 'SR',
    outward: 'SR9',
    town: 'Non-geographic',
    county: 'Tyne and Wear',
  },
  {
    outward: 'SS0',
    town: 'Southend-on-Sea, Westcliff-on-Sea',
    county: 'Southend-on-Sea',
    coordinates: [51.55216, 0.6777998],
  },
  {
    outward: 'SS1',
    town: 'Southend-on-Sea, Thorpe Bay',
    county: 'Southend-on-Sea',
    coordinates: [51.543523, 0.71237046],
  },
  {
    area: 'SS',
    outward: 'SS1',
    town: 'Westcliff-on-Sea (shared)',
    county: 'Essex',
  },
  {
    area: 'SS',
    outward: 'SS1',
    town: 'Southend-on-Sea',
    county: 'Essex',
  },
  {
    outward: 'SS2',
    town: 'Prittlewell, Southend-on-Sea',
    county: 'Southend-on-Sea',
    coordinates: [51.547412, 0.72349252],
  },
  {
    area: 'SS',
    outward: 'SS2',
    town: 'Southend-on-Sea',
    county: 'Essex',
  },
  {
    outward: 'SS3',
    town: 'Shoeburyness',
    county: 'Rochford',
    coordinates: [51.553599, 0.80853362],
  },
  {
    outward: 'SS4',
    town: 'Rochford',
    county: 'Rochford',
    coordinates: [51.58308, 0.70681542],
  },
  {
    outward: 'SS5',
    town: 'Hockley',
    county: 'Rochford',
    coordinates: [51.602281, 0.6564715],
  },
  {
    outward: 'SS6',
    town: 'Rayleigh',
    county: 'Rochford',
    coordinates: [51.584471, 0.60201788],
  },
  {
    outward: 'SS7',
    town: 'Hadleigh, South Benfleet, Thundersley',
    county: 'Castle Point',
    coordinates: [51.562395, 0.57579454],
  },
  {
    outward: 'SS8',
    town: 'Canvey Island',
    county: 'Castle Point',
    coordinates: [51.51841, 0.59205108],
  },
  {
    outward: 'SS9',
    town: 'Eastwood, Leigh-on-Sea',
    county: 'Southend-on-Sea',
    coordinates: [51.548734, 0.64847433],
  },
  {
    outward: 'SS11',
    town: 'Wickford',
    county: 'Basildon',
    coordinates: [51.614534, 0.5230439],
  },
  {
    outward: 'SS12',
    town: 'Wickford',
    county: 'Basildon',
    coordinates: [51.615038, 0.51756805],
  },
  {
    outward: 'SS13',
    town: 'Basildon',
    county: 'Basildon',
    coordinates: [51.576056, 0.51993028],
  },
  {
    outward: 'SS14',
    town: 'Basildon',
    county: 'Basildon',
    coordinates: [51.571103, 0.46003895],
  },
  {
    outward: 'SS15',
    town: 'Laindon',
    county: 'Basildon',
    coordinates: [51.586298, 0.4317416],
  },
  {
    outward: 'SS16',
    town: 'Langdon Hills',
    county: 'Basildon',
    coordinates: [51.564445, 0.47260831],
  },
  {
    outward: 'SS17',
    town: 'Corringham, Stanford-le-Hope',
    county: 'Thurrock',
    coordinates: [51.516682, 0.44083087],
  },
  {
    outward: 'SS22',
    town: 'Non-geographic',
    county: 'Southend-on-Sea',
    coordinates: [51.55698, 0.70682309],
  },
  {
    area: '',
    outward: 'SS22',
    town: 'Non-geographic',
    county: 'Essex',
  },
  {
    outward: 'SS99',
    town: 'Non-geographic',
    county: 'Southend-on-Sea',
    coordinates: [51.531166, 0.72942942],
  },
  {
    area: '',
    outward: 'SS99',
    town: 'Non-geographic',
    county: 'Essex',
  },
  {
    area: 'ST',
    outward: 'ST1',
    town: 'Stoke-on-Trent',
    county: 'Staffordshire',
  },
  {
    area: 'ST',
    outward: 'ST2',
    town: 'Stoke-on-Trent',
    county: 'Staffordshire',
  },
  {
    area: 'ST',
    outward: 'ST3',
    town: 'Stoke-on-Trent',
    county: 'Staffordshire',
  },
  {
    area: 'ST',
    outward: 'ST4',
    town: 'Stoke-on-Trent',
    county: 'Staffordshire',
  },
  {
    area: 'ST',
    outward: 'ST5',
    town: 'NEWCASTLE',
    county: 'Staffordshire',
  },
  {
    area: 'ST',
    outward: 'ST6',
    town: 'Stoke-on-Trent',
    county: 'Staffordshire',
  },
  {
    area: 'ST',
    outward: 'ST7',
    town: 'Stoke-on-Trent',
    county: 'Staffordshire',
  },
  {
    area: 'ST',
    outward: 'ST8',
    town: 'Stoke-on-Trent',
    county: 'Staffordshire',
  },
  {
    area: 'ST',
    outward: 'ST9',
    town: 'Stoke-on-Trent',
    county: 'Staffordshire',
  },
  {
    area: '',
    outward: 'ST10',
    town: 'Stoke-on-Trent',
    county: 'Staffordshire',
  },
  {
    area: '',
    outward: 'ST11',
    town: 'Stoke-on-Trent',
    county: 'Staffordshire',
  },
  {
    area: '',
    outward: 'ST12',
    town: 'Stoke-on-Trent',
    county: 'Staffordshire',
  },
  {
    area: 'ST',
    outward: 'ST13',
    town: 'LEEK',
    county: 'Staffordshire',
  },
  {
    area: 'ST',
    outward: 'ST14',
    town: 'Uttoxeter',
    county: 'Staffordshire',
  },
  {
    area: 'ST',
    outward: 'ST15',
    town: 'STONE',
    county: 'Staffordshire',
  },
  {
    area: 'ST',
    outward: 'ST16',
    town: 'Stafford',
    county: 'Staffordshire',
  },
  {
    area: 'ST',
    outward: 'ST17',
    town: 'Stafford',
    county: 'Staffordshire',
  },
  {
    area: 'ST',
    outward: 'ST18',
    town: 'Stafford',
    county: 'Staffordshire',
  },
  {
    area: 'ST',
    outward: 'ST19',
    town: 'Stafford',
    county: 'Staffordshire',
  },
  {
    area: '',
    outward: 'ST20',
    town: 'Stafford',
    county: 'Staffordshire',
  },
  {
    area: '',
    outward: 'ST21',
    town: 'Stafford',
    county: 'Staffordshire',
  },
  {
    area: '',
    outward: 'ST55',
    town: 'Non-geographic',
    county: 'Staffordshire',
  },
  {
    outward: 'SW1A',
    town: 'Whitehall, Buckingham Palace',
    county: 'Westminster',
    coordinates: [51.49984, -0.12466273],
  },
  {
    outward: 'SW1E',
    town: 'Buckingham Gate, Victoria Station',
    county: 'Westminster',
    coordinates: [51.498125, -0.14060996],
  },
  {
    outward: 'SW1H',
    town: 'Buckingham Gate, Victoria St, Birdcage Walk',
    county: 'Westminster',
    coordinates: [51.499445, -0.13541268],
  },
  {
    outward: 'SW1P',
    town: 'Victoria Station, the Houses of Parliament, Vauxhall Bridge, Pimlico',
    county: 'Westminster',
    coordinates: [51.496429, -0.13743747],
  },
  {
    outward: 'SW1V',
    town: 'Vauxhall Bridge, Chelsea Bridge, Victoria Station, Pimlico',
    county: 'Westminster',
    coordinates: [51.495671, -0.14183351],
  },
  {
    outward: 'SW1W',
    town: 'Belgravia',
    county: 'Westminster',
    coordinates: [51.498404, -0.14686581],
  },
  {
    outward: 'SW1X',
    town: 'Belgravia, Knightsbridge',
    county: 'Westminster',
    coordinates: [51.497343, -0.15944292],
  },
  {
    outward: 'SW1Y',
    town: "St. James's",
    county: 'Westminster',
    coordinates: [51.50799, -0.13381017],
  },
  {
    outward: 'SW2',
    town: 'Brixton, Brixton Hill, Streatham Hill, Tulse Hill, Clapham Park, Balham',
    county: 'Lambeth',
    coordinates: [51.454693, -0.11933117],
  },
  {
    outward: 'SW3',
    town: 'Chelsea, Brompton, Knightsbridge',
    county: 'Kensington and Chelsea',
    coordinates: [51.501272, -0.16056694],
  },
  {
    outward: 'SW4',
    town: 'Clapham, Stockwell',
    county: 'Lambeth',
    coordinates: [51.461644, -0.1481249],
  },
  {
    outward: 'SW5',
    town: "Earl's Court",
    county: 'Kensington and Chelsea',
    coordinates: [51.490069, -0.19012971],
  },
  {
    outward: 'SW6',
    town: "Fulham, Parson's Green",
    county: 'Hammersmith and Fulham',
    coordinates: [51.48008, -0.1966602],
  },
  {
    outward: 'SW7',
    town: 'South Kensington, Knightsbridge',
    county: 'Kensington and Chelsea',
    coordinates: [51.499581, -0.1714696],
  },
  {
    outward: 'SW8',
    town: 'South Lambeth, Vauxhall, Battersea, Clapham, Stockwell',
    county: 'Lambeth',
    coordinates: [51.478732, -0.11886095],
  },
  {
    outward: 'SW9',
    town: 'Stockwell, Brixton, Clapham',
    county: 'Lambeth',
    coordinates: [51.471957, -0.11303446],
  },
  {
    outward: 'SW10',
    town: 'West Brompton, Chelsea',
    county: 'Kensington and Chelsea',
    coordinates: [51.477972, -0.18300497],
  },
  {
    outward: 'SW11',
    town: 'Battersea, Clapham Junction',
    county: 'Wandsworth',
    coordinates: [51.464566, -0.16469231],
  },
  {
    outward: 'SW12',
    town: 'Balham, Clapham South, Hyde Farm',
    county: 'Wandsworth',
    coordinates: [51.448263, -0.14243481],
  },
  {
    outward: 'SW13',
    town: 'Barnes, Castelnau',
    county: 'Richmond upon Thames',
    coordinates: [51.471845, -0.24899353],
  },
  {
    outward: 'SW14',
    town: 'Mortlake, East Sheen',
    county: 'Richmond upon Thames',
    coordinates: [51.46141, -0.26996407],
  },
  {
    outward: 'SW15',
    town: 'Putney, Roehampton University',
    county: 'Wandsworth',
    coordinates: [51.464133, -0.22292897],
  },
  {
    outward: 'SW16',
    town: 'Streatham, Norbury, Thornton Heath, Streatham Park, Furzedown, Streatham Vale, Pollards Hill',
    county: 'Lambeth',
    coordinates: [51.438392, -0.13076338],
  },
  {
    outward: 'SW17',
    town: 'Tooting, Mitcham',
    county: 'Wandsworth',
    coordinates: [51.433773, -0.18296084],
  },
  {
    outward: 'SW18',
    town: 'Wandsworth, Southfields, Earlsfield',
    county: 'Wandsworth',
    coordinates: [51.458462, -0.18559358],
  },
  {
    outward: 'SW19',
    town: 'Wimbledon, Colliers Wood, Southfields',
    county: 'Merton',
    coordinates: [51.421637, -0.18614621],
  },
  {
    outward: 'SW20',
    town: 'Merton Park, Raynes Park',
    county: 'Merton',
    coordinates: [51.421261, -0.25377521],
  },
  {
    outward: 'SW95',
    town: 'Non-geographic',
    county: 'Lambeth',
    coordinates: [51.481035, -0.13580363],
  },
  {
    area: '',
    outward: 'SW95',
    town: 'Non-geographic',
    county: 'London',
  },
  {
    outward: 'SW99',
    town: 'Non-geographic',
    county: 'Lambeth',
  },
  {
    area: 'SY',
    outward: 'SY1',
    town: 'Shrewsbury',
    county: 'Shropshire',
  },
  {
    area: 'SY',
    outward: 'SY2',
    town: 'Shrewsbury',
    county: 'Shropshire',
  },
  {
    area: 'SY',
    outward: 'SY3',
    town: 'Shrewsbury',
    county: 'Shropshire',
  },
  {
    area: 'SY',
    outward: 'SY4',
    town: 'Shrewsbury',
    county: 'Shropshire',
  },
  {
    area: 'SY',
    outward: 'SY5',
    town: 'Shrewsbury',
    county: 'Shropshire',
  },
  {
    area: 'SY',
    outward: 'SY6',
    town: 'Church Stretton',
    county: 'Shropshire',
  },
  {
    area: 'SY',
    outward: 'SY7',
    town: 'BUCKNELL (shared)',
    county: 'Shropshire',
  },
  {
    area: 'SY',
    outward: 'SY8',
    town: 'Ludlow',
    county: 'Shropshire',
  },
  {
    area: 'SY',
    outward: 'SY10',
    town: 'Oswestry',
    county: 'Shropshire',
  },
  {
    area: 'SY',
    outward: 'SY11',
    town: 'Oswestry',
    county: 'Shropshire',
  },
  {
    area: 'SY',
    outward: 'SY12',
    town: 'ELLESMERE',
    county: 'Shropshire',
  },
  {
    area: 'SY',
    outward: 'SY13',
    town: 'WHITCHURCH',
    county: 'Shropshire',
  },
  {
    area: 'SY',
    outward: 'SY14',
    town: 'MALPAS',
    county: 'Cheshire',
  },
  {
    area: 'SY',
    outward: 'SY15',
    town: 'MONTGOMERY',
    county: 'Powys',
  },
  {
    area: 'SY',
    outward: 'SY16',
    town: 'NEWTOWN',
    county: 'Powys',
  },
  {
    area: 'SY',
    outward: 'SY17',
    town: 'Caersws (shared)',
    county: 'Powys',
  },
  {
    area: 'SY',
    outward: 'SY18',
    town: 'Llanidloes',
    county: 'Powys',
  },
  {
    area: 'SY',
    outward: 'SY19',
    town: 'Llanbrynmair',
    county: 'Powys',
  },
  {
    area: 'SY',
    outward: 'SY20',
    town: 'Machynlleth',
    county: 'Powys',
  },
  {
    area: 'SY',
    outward: 'SY21',
    town: 'Welshpool',
    county: 'Powys',
  },
  {
    area: 'SY',
    outward: 'SY22',
    town: 'Llanfechain (shared)',
    county: 'Powys',
  },
  {
    area: 'SY',
    outward: 'SY23',
    town: 'Aberystwyth (shared)',
    county: 'Dyfed',
  },
  {
    area: 'SY',
    outward: 'SY24',
    town: 'Borth (shared)',
    county: 'Dyfed',
  },
  {
    area: 'SY',
    outward: 'SY25',
    town: 'Tregaron (shared)',
    county: 'Dyfed',
  },
  {
    area: '',
    outward: 'SY99',
    town: 'Non-geographic',
    county: 'Shropshire',
  },
  {
    area: 'TA',
    outward: 'TA1',
    town: 'Taunton',
    county: 'Somerset',
  },
  {
    area: 'TA',
    outward: 'TA2',
    town: 'Taunton',
    county: 'Somerset',
  },
  {
    area: 'TA',
    outward: 'TA3',
    town: 'Taunton',
    county: 'Somerset',
  },
  {
    area: 'TA',
    outward: 'TA4',
    town: 'Taunton',
    county: 'Somerset',
  },
  {
    area: 'TA',
    outward: 'TA5',
    town: 'Bridgwater',
    county: 'Somerset',
  },
  {
    area: 'TA',
    outward: 'TA6',
    town: 'Bridgwater',
    county: 'Somerset',
  },
  {
    area: 'TA',
    outward: 'TA7',
    town: 'Bridgwater',
    county: 'Somerset',
  },
  {
    area: 'TA',
    outward: 'TA8',
    town: 'Burnham-on-Sea',
    county: 'Somerset',
  },
  {
    area: 'TA',
    outward: 'TA9',
    town: 'HIGHBRIDGE',
    county: 'Somerset',
  },
  {
    area: 'TA',
    outward: 'TA10',
    town: 'Langport',
    county: 'Somerset',
  },
  {
    area: 'TA',
    outward: 'TA11',
    town: 'Somerton',
    county: 'Somerset',
  },
  {
    area: 'TA',
    outward: 'TA12',
    town: 'Martock',
    county: 'Somerset',
  },
  {
    area: 'TA',
    outward: 'TA13',
    town: 'South Petherton',
    county: 'Somerset',
  },
  {
    area: 'TA',
    outward: 'TA14',
    town: 'Stoke-sub-Hamdon',
    county: 'Somerset',
  },
  {
    area: 'TA',
    outward: 'TA15',
    town: 'Montacute',
    county: 'Somerset',
  },
  {
    area: 'TA',
    outward: 'TA16',
    town: 'Merriott',
    county: 'Somerset',
  },
  {
    area: 'TA',
    outward: 'TA17',
    town: 'HINTON ST. GEORGE',
    county: 'Somerset',
  },
  {
    area: 'TA',
    outward: 'TA18',
    town: 'Crewkerne',
    county: 'Somerset',
  },
  {
    area: 'TA',
    outward: 'TA19',
    town: 'Ilminster',
    county: 'Somerset',
  },
  {
    area: 'TA',
    outward: 'TA20',
    town: 'CHARD',
    county: 'Somerset',
  },
  {
    area: 'TA',
    outward: 'TA21',
    town: 'WELLINGTON',
    county: 'Somerset',
  },
  {
    area: 'TA',
    outward: 'TA22',
    town: 'Dulverton',
    county: 'Somerset',
  },
  {
    area: 'TA',
    outward: 'TA23',
    town: 'Watchet',
    county: 'Somerset',
  },
  {
    area: 'TA',
    outward: 'TA24',
    town: 'Minehead',
    county: 'Somerset',
  },
  {
    area: 'TD',
    outward: 'TD1',
    town: 'Galashiels',
    county: 'Selkirkshire',
  },
  {
    area: 'TD',
    outward: 'TD2',
    town: 'Lauder',
    county: 'Berwickshire',
  },
  {
    area: 'TD',
    outward: 'TD3',
    town: 'GORDON',
    county: 'Berwickshire',
  },
  {
    area: 'TD',
    outward: 'TD4',
    town: 'Earlston',
    county: 'Berwickshire',
  },
  {
    area: 'TD',
    outward: 'TD5',
    town: 'KELSO',
    county: 'Roxburghshire',
  },
  {
    area: 'TD',
    outward: 'TD6',
    town: 'MELROSE',
    county: 'Roxburghshire',
  },
  {
    area: 'TD',
    outward: 'TD7',
    town: 'SELKIRK',
    county: 'Selkirkshire',
  },
  {
    area: 'TD',
    outward: 'TD8',
    town: 'Jedburgh',
    county: 'Roxburghshire',
  },
  {
    area: 'TD',
    outward: 'TD9',
    town: 'Hawick (shared)',
    county: 'Roxburghshire',
  },
  {
    area: 'TD',
    outward: 'TD10',
    town: 'Duns',
    county: 'Berwickshire',
  },
  {
    area: 'TD',
    outward: 'TD11',
    town: 'Duns',
    county: 'Berwickshire',
  },
  {
    area: 'TD',
    outward: 'TD12',
    town: 'Coldstream (shared)',
    county: 'Berwickshire',
  },
  {
    area: 'TD',
    outward: 'TD13',
    town: 'Cockburnspath',
    county: 'Berwickshire',
  },
  {
    area: 'TD',
    outward: 'TD14',
    town: 'Eyemouth',
    county: 'Berwickshire',
  },
  {
    area: 'TD',
    outward: 'TD15',
    town: 'Berwick-upon-Tweed',
    county: 'Northumberland',
  },
  {
    area: 'TF',
    outward: 'TF1',
    town: 'Telford',
    county: 'Shropshire',
  },
  {
    area: 'TF',
    outward: 'TF2',
    town: 'Telford',
    county: 'Shropshire',
  },
  {
    area: 'TF',
    outward: 'TF3',
    town: 'Telford',
    county: 'Shropshire',
  },
  {
    area: 'TF',
    outward: 'TF4',
    town: 'Telford',
    county: 'Shropshire',
  },
  {
    area: 'TF',
    outward: 'TF5',
    town: 'Telford',
    county: 'Shropshire',
  },
  {
    area: 'TF',
    outward: 'TF6',
    town: 'Telford',
    county: 'Shropshire',
  },
  {
    area: 'TF',
    outward: 'TF7',
    town: 'Telford',
    county: 'Shropshire',
  },
  {
    area: 'TF',
    outward: 'TF8',
    town: 'Telford',
    county: 'Shropshire',
  },
  {
    area: 'TF',
    outward: 'TF9',
    town: 'Market Drayton',
    county: 'Shropshire',
  },
  {
    area: 'TF',
    outward: 'TF10',
    town: 'NEWPORT',
    county: 'Shropshire',
  },
  {
    area: 'TF',
    outward: 'TF11',
    town: 'Shifnal',
    county: 'Shropshire',
  },
  {
    area: 'TF',
    outward: 'TF12',
    town: 'Broseley',
    county: 'Shropshire',
  },
  {
    area: 'TF',
    outward: 'TF13',
    town: 'Much Wenlock',
    county: 'Shropshire',
  },
  {
    area: 'TN',
    outward: 'TN1',
    town: 'TUNBRIDGE WELLS',
    county: 'Kent',
  },
  {
    area: 'TN',
    outward: 'TN2',
    town: 'non-geo shared',
    county: 'East Sussex',
  },
  {
    area: 'TN',
    outward: 'TN2',
    town: 'TUNBRIDGE WELLS (shared)',
    county: 'Kent',
  },
  {
    area: 'TN',
    outward: 'TN3',
    town: 'TUNBRIDGE WELLS',
    county: 'Kent',
  },
  {
    area: 'TN',
    outward: 'TN5',
    town: 'Wadhurst',
    county: 'East Sussex',
  },
  {
    area: 'TN',
    outward: 'TN4',
    town: 'TUNBRIDGE WELLS',
    county: 'Kent',
  },
  {
    area: 'TN',
    outward: 'TN6',
    town: 'Crowborough',
    county: 'East Sussex',
  },
  {
    area: 'TN',
    outward: 'TN7',
    town: 'Hartfield',
    county: 'East Sussex',
  },
  {
    area: 'TN',
    outward: 'TN8',
    town: 'EDENBRIDGE',
    county: 'Kent',
  },
  {
    area: 'TN',
    outward: 'TN9',
    town: 'Tonbridge',
    county: 'Kent',
  },
  {
    area: '',
    outward: 'TN10',
    town: 'Tonbridge',
    county: 'Kent',
  },
  {
    area: '',
    outward: 'TN11',
    town: 'Tonbridge',
    county: 'Kent',
  },
  {
    area: '',
    outward: 'TN12',
    town: 'Tonbridge',
    county: 'Kent',
  },
  {
    area: 'TN',
    outward: 'TN13',
    town: 'Sevenoaks',
    county: 'Kent',
  },
  {
    area: 'TN',
    outward: 'TN14',
    town: 'Sevenoaks',
    county: 'Kent',
  },
  {
    area: 'TN',
    outward: 'TN15',
    town: 'Sevenoaks',
    county: 'Kent',
  },
  {
    area: 'TN',
    outward: 'TN16',
    town: 'Westerham',
    county: 'Kent',
  },
  {
    area: 'TN',
    outward: 'TN17',
    town: 'CRANBROOK',
    county: 'Kent',
  },
  {
    area: 'TN',
    outward: 'TN18',
    town: 'CRANBROOK',
    county: 'Kent',
  },
  {
    area: 'TN',
    outward: 'TN19',
    town: 'Etchingham',
    county: 'East Sussex',
  },
  {
    area: 'TN',
    outward: 'TN20',
    town: 'MAYFIELD',
    county: 'East Sussex',
  },
  {
    area: 'TN',
    outward: 'TN21',
    town: 'HEATHFIELD',
    county: 'East Sussex',
  },
  {
    area: 'TN',
    outward: 'TN22',
    town: 'Uckfield',
    county: 'East Sussex',
  },
  {
    area: 'TN',
    outward: 'TN23',
    town: 'ASHFORD',
    county: 'Kent',
  },
  {
    area: 'TN',
    outward: 'TN24',
    town: 'ASHFORD',
    county: 'Kent',
  },
  {
    area: 'TN',
    outward: 'TN25',
    town: 'ASHFORD',
    county: 'Kent',
  },
  {
    area: 'TN',
    outward: 'TN26',
    town: 'ASHFORD',
    county: 'Kent',
  },
  {
    area: 'TN',
    outward: 'TN27',
    town: 'ASHFORD',
    county: 'Kent',
  },
  {
    area: 'TN',
    outward: 'TN28',
    town: 'New Romney',
    county: 'Kent',
  },
  {
    area: 'TN',
    outward: 'TN29',
    town: 'Romney Marsh',
    county: 'Kent',
  },
  {
    area: 'TN',
    outward: 'TN30',
    town: 'Tenterden',
    county: 'Kent',
  },
  {
    area: 'TN',
    outward: 'TN31',
    town: 'RYE',
    county: 'East Sussex',
  },
  {
    area: 'TN',
    outward: 'TN32',
    town: 'Robertsbridge',
    county: 'East Sussex',
  },
  {
    area: 'TN',
    outward: 'TN33',
    town: 'BATTLE',
    county: 'East Sussex',
  },
  {
    area: 'TN',
    outward: 'TN34',
    town: 'Hastings',
    county: 'East Sussex',
  },
  {
    area: 'TN',
    outward: 'TN35',
    town: 'Hastings',
    county: 'East Sussex',
  },
  {
    area: 'TN',
    outward: 'TN36',
    town: 'Winchelsea',
    county: 'East Sussex',
  },
  {
    area: 'TN',
    outward: 'TN37',
    town: 'ST. LEONARDS-ON-SEA',
    county: 'East Sussex',
  },
  {
    area: 'TN',
    outward: 'TN38',
    town: 'ST. LEONARDS-ON-SEA',
    county: 'East Sussex',
  },
  {
    area: 'TN',
    outward: 'TN39',
    town: 'Bexhill-on-Sea',
    county: 'East Sussex',
  },
  {
    area: '',
    outward: 'TN40',
    town: 'Bexhill-on-Sea',
    county: 'East Sussex',
  },
  {
    area: 'TQ',
    outward: 'TQ1',
    town: 'Torquay',
    county: 'Devon',
  },
  {
    area: 'TQ',
    outward: 'TQ2',
    town: 'Torquay',
    county: 'Devon',
  },
  {
    area: 'TQ',
    outward: 'TQ3',
    town: 'Paignton',
    county: 'Devon',
  },
  {
    area: 'TQ',
    outward: 'TQ4',
    town: 'Paignton',
    county: 'Devon',
  },
  {
    area: 'TQ',
    outward: 'TQ5',
    town: 'Brixham',
    county: 'Devon',
  },
  {
    area: 'TQ',
    outward: 'TQ6',
    town: 'DARTMOUTH',
    county: 'Devon',
  },
  {
    area: 'TQ',
    outward: 'TQ7',
    town: 'Kingsbridge',
    county: 'Devon',
  },
  {
    area: 'TQ',
    outward: 'TQ8',
    town: 'Salcombe',
    county: 'Devon',
  },
  {
    area: 'TQ',
    outward: 'TQ9',
    town: 'non-geo shared',
    county: 'Devon',
  },
  {
    area: 'TQ',
    outward: 'TQ9',
    town: 'Totnes (shared)',
    county: 'Devon',
  },
  {
    area: 'TQ',
    outward: 'TQ10',
    town: 'South Brent',
    county: 'Devon',
  },
  {
    area: 'TQ',
    outward: 'TQ11',
    town: 'Buckfastleigh',
    county: 'Devon',
  },
  {
    area: 'TQ',
    outward: 'TQ12',
    town: 'Newton Abbot',
    county: 'Devon',
  },
  {
    area: 'TQ',
    outward: 'TQ13',
    town: 'Newton Abbot',
    county: 'Devon',
  },
  {
    area: 'TQ',
    outward: 'TQ14',
    town: 'Teignmouth',
    county: 'Devon',
  },
  {
    area: 'TR',
    outward: 'TR1',
    town: 'Truro',
    county: 'Cornwall',
  },
  {
    area: 'TR',
    outward: 'TR2',
    town: 'Truro',
    county: 'Cornwall',
  },
  {
    area: 'TR',
    outward: 'TR3',
    town: 'Truro',
    county: 'Cornwall',
  },
  {
    area: 'TR',
    outward: 'TR4',
    town: 'Truro',
    county: 'Cornwall',
  },
  {
    area: 'TR',
    outward: 'TR5',
    town: 'ST. AGNES',
    county: 'Cornwall',
  },
  {
    area: 'TR',
    outward: 'TR6',
    town: 'Perranporth',
    county: 'Cornwall',
  },
  {
    area: 'TR',
    outward: 'TR7',
    town: 'Newquay',
    county: 'Cornwall',
  },
  {
    area: 'TR',
    outward: 'TR8',
    town: 'Newquay',
    county: 'Cornwall',
  },
  {
    area: 'TR',
    outward: 'TR9',
    town: 'ST. COLUMB',
    county: 'Cornwall',
  },
  {
    area: 'TR',
    outward: 'TR10',
    town: 'PENRYN',
    county: 'Cornwall',
  },
  {
    area: 'TR',
    outward: 'TR11',
    town: 'FALMOUTH',
    county: 'Cornwall',
  },
  {
    area: 'TR',
    outward: 'TR12',
    town: 'Helston',
    county: 'Cornwall',
  },
  {
    area: 'TR',
    outward: 'TR13',
    town: 'Helston',
    county: 'Cornwall',
  },
  {
    area: 'TR',
    outward: 'TR14',
    town: 'Camborne',
    county: 'Cornwall',
  },
  {
    area: 'TR',
    outward: 'TR15',
    town: 'Redruth',
    county: 'Cornwall',
  },
  {
    area: 'TR',
    outward: 'TR16',
    town: 'Redruth',
    county: 'Cornwall',
  },
  {
    area: 'TR',
    outward: 'TR17',
    town: 'Marazion',
    county: 'Cornwall',
  },
  {
    area: 'TR',
    outward: 'TR18',
    town: 'Penzance',
    county: 'Cornwall',
  },
  {
    area: 'TR',
    outward: 'TR19',
    town: 'Penzance',
    county: 'Cornwall',
  },
  {
    area: '',
    outward: 'TR20',
    town: 'Penzance',
    county: 'Cornwall',
  },
  {
    area: 'TR',
    outward: 'TR21',
    town: 'Isles of Scilly',
    county: 'Isles of Scilly',
  },
  {
    area: 'TR',
    outward: 'TR22',
    town: 'Isles of Scilly',
    county: 'Isles of Scilly',
  },
  {
    area: 'TR',
    outward: 'TR23',
    town: 'Isles of Scilly',
    county: 'Isles of Scilly',
  },
  {
    area: 'TR',
    outward: 'TR24',
    town: 'Isles of Scilly',
    county: 'Isles of Scilly',
  },
  {
    area: 'TR',
    outward: 'TR25',
    town: 'Isles of Scilly',
    county: 'Isles of Scilly',
  },
  {
    area: 'TR',
    outward: 'TR26',
    town: 'ST. IVES',
    county: 'Cornwall',
  },
  {
    area: 'TR',
    outward: 'TR27',
    town: 'Hayle',
    county: 'Cornwall',
  },
  {
    area: 'TS',
    outward: 'TS1',
    town: 'Middlesbrough',
    county: 'Cleveland',
  },
  {
    area: 'TS',
    outward: 'TS2',
    town: 'Middlesbrough',
    county: 'Cleveland',
  },
  {
    area: 'TS',
    outward: 'TS3',
    town: 'Middlesbrough',
    county: 'Cleveland',
  },
  {
    area: 'TS',
    outward: 'TS4',
    town: 'Middlesbrough',
    county: 'Cleveland',
  },
  {
    area: 'TS',
    outward: 'TS5',
    town: 'Middlesbrough',
    county: 'Cleveland',
  },
  {
    area: 'TS',
    outward: 'TS6',
    town: 'Middlesbrough',
    county: 'Cleveland',
  },
  {
    area: 'TS',
    outward: 'TS7',
    town: 'Middlesbrough',
    county: 'Cleveland',
  },
  {
    area: 'TS',
    outward: 'TS8',
    town: 'Middlesbrough',
    county: 'Cleveland',
  },
  {
    area: 'TS',
    outward: 'TS9',
    town: 'Middlesbrough',
    county: 'Cleveland',
  },
  {
    area: 'TS',
    outward: 'TS10',
    town: 'Redcar',
    county: 'Cleveland',
  },
  {
    area: 'TS',
    outward: 'TS11',
    town: 'Redcar',
    county: 'Cleveland',
  },
  {
    area: 'TS',
    outward: 'TS12',
    town: 'Saltburn-by-the-Sea',
    county: 'Cleveland',
  },
  {
    area: 'TS',
    outward: 'TS13',
    town: 'Saltburn-by-the-Sea',
    county: 'Cleveland',
  },
  {
    area: 'TS',
    outward: 'TS14',
    town: 'Guisborough',
    county: 'Cleveland',
  },
  {
    area: 'TS',
    outward: 'TS15',
    town: 'Yarm',
    county: 'Cleveland',
  },
  {
    area: 'TS',
    outward: 'TS16',
    town: 'Stockton-on-Tees',
    county: 'Cleveland',
  },
  {
    area: 'TS',
    outward: 'TS17',
    town: 'Stockton-on-Tees',
    county: 'Cleveland',
  },
  {
    area: 'TS',
    outward: 'TS18',
    town: 'Stockton-on-Tees',
    county: 'Cleveland',
  },
  {
    area: 'TS',
    outward: 'TS19',
    town: 'Stockton-on-Tees',
    county: 'Cleveland',
  },
  {
    area: '',
    outward: 'TS20',
    town: 'Stockton-on-Tees',
    county: 'Cleveland',
  },
  {
    area: '',
    outward: 'TS21',
    town: 'Stockton-on-Tees',
    county: 'Cleveland',
  },
  {
    area: 'TS',
    outward: 'TS22',
    town: 'Billingham',
    county: 'Cleveland',
  },
  {
    area: 'TS',
    outward: 'TS23',
    town: 'Billingham',
    county: 'Cleveland',
  },
  {
    area: 'TS',
    outward: 'TS24',
    town: 'Hartlepool',
    county: 'Cleveland',
  },
  {
    area: 'TS',
    outward: 'TS25',
    town: 'Hartlepool',
    county: 'Cleveland',
  },
  {
    area: 'TS',
    outward: 'TS26',
    town: 'Hartlepool',
    county: 'Cleveland',
  },
  {
    area: 'TS',
    outward: 'TS27',
    town: 'Hartlepool',
    county: 'Cleveland',
  },
  {
    area: 'TS',
    outward: 'TS28',
    town: 'WINGATE',
    county: 'County Durham',
  },
  {
    area: 'TS',
    outward: 'TS29',
    town: 'Trimdon Station',
    county: 'County Durham',
  },
  {
    outward: 'TW1',
    town: 'Twickenham, St. Margarets, Strawberry Hill',
    county: 'Richmond upon Thames',
    coordinates: [51.450065, -0.33227783],
  },
  {
    outward: 'TW2',
    town: 'Whitton, Strawberry Hill',
    county: 'Richmond upon Thames',
    coordinates: [51.44488, -0.34013668],
  },
  {
    outward: 'TW3',
    town: 'Hounslow, Maswell Park, Lampton',
    county: 'Hounslow',
    coordinates: [51.470271, -0.36098534],
  },
  {
    outward: 'TW4',
    town: 'Hounslow West, Hounslow Heath, Whitton',
    county: 'Hounslow',
    coordinates: [51.463737, -0.38392197],
  },
  {
    outward: 'TW5',
    town: 'Heston',
    county: 'Hounslow',
    coordinates: [51.477373, -0.38346892],
  },
  {
    outward: 'TW6',
    town: 'Heathrow',
    county: 'Hillingdon',
    coordinates: [51.470836, -0.45828258],
  },
  {
    outward: 'TW7',
    town: 'Isleworth, Osterley',
    county: 'Hounslow',
    coordinates: [51.474162, -0.349888],
  },
  {
    outward: 'TW8',
    town: 'Brentford',
    county: 'Hounslow',
    coordinates: [51.483357, -0.30356965],
  },
  {
    outward: 'TW9',
    town: 'North Sheen, Kew',
    county: 'Richmond upon Thames',
    coordinates: [51.460814, -0.30237592],
  },
  {
    outward: 'TW10',
    town: 'Ham, Petersham',
    county: 'Richmond upon Thames',
    coordinates: [51.463414, -0.28581034],
  },
  {
    outward: 'TW11',
    town: 'Teddington',
    county: 'Richmond upon Thames',
    coordinates: [51.426111, -0.33493367],
  },
  {
    outward: 'TW12',
    town: 'Hampton',
    county: 'Richmond upon Thames',
    coordinates: [51.432254, -0.37539439],
  },
  {
    outward: 'TW13',
    town: 'Feltham, Hanworth',
    county: 'Hounslow',
    coordinates: [51.445927, -0.41576353],
  },
  {
    outward: 'TW14',
    town: 'Hatton',
    county: 'Hounslow',
    coordinates: [51.450907, -0.40501311],
  },
  {
    outward: 'TW15',
    town: 'Ashford',
    county: 'Spelthorne',
    coordinates: [51.43211, -0.44020856],
  },
  {
    outward: 'TW16',
    town: 'Sunbury-on-thames',
    county: 'Spelthorne',
    coordinates: [51.418742, -0.41440244],
  },
  {
    outward: 'TW17',
    town: 'Shepperton',
    county: 'Spelthorne',
    coordinates: [51.398655, -0.45487407],
  },
  {
    outward: 'TW18',
    town: 'Staines, Egham Hythe',
    county: 'Spelthorne',
    coordinates: [51.430518, -0.49804904],
  },
  {
    outward: 'TW19',
    town: 'Stanwell, Wraysbury',
    county: 'Spelthorne',
    coordinates: [51.450869, -0.55473244],
  },
  {
    outward: 'TW20',
    town: 'Egham, Englefield Green',
    county: 'Runnymede',
    coordinates: [51.439533, -0.54258697],
  },
  {
    outward: 'UB1',
    town: 'Southall',
    county: 'Ealing',
    coordinates: [51.5075, -0.37848347],
  },
  {
    outward: 'UB2',
    town: 'Southall',
    county: 'Ealing',
    coordinates: [51.506172, -0.3780551],
  },
  {
    outward: 'UB3',
    town: 'Hayes, Harlington',
    county: 'Hillingdon',
    coordinates: [51.503676, -0.41452537],
  },
  {
    area: 'UB',
    outward: 'UB3',
    town: 'non-geo shared',
    county: 'Middlesex',
  },
  {
    area: 'UB',
    outward: 'UB3',
    town: 'HAYES (shared)',
    county: 'Middlesex',
  },
  {
    outward: 'UB4',
    town: 'Yeading, Hayes',
    county: 'Hillingdon',
    coordinates: [51.525885, -0.41770399],
  },
  {
    area: 'UB',
    outward: 'UB4',
    town: 'HAYES',
    county: 'Middlesex',
  },
  {
    outward: 'UB5',
    town: 'Northolt',
    county: 'Ealing',
    coordinates: [51.548212, -0.36844058],
  },
  {
    area: 'UB',
    outward: 'UB5',
    town: 'non-geo shared',
    county: 'Middlesex',
  },
  {
    area: 'UB',
    outward: 'UB5',
    town: 'Northolt (shared)',
    county: 'Middlesex',
  },
  {
    outward: 'UB6',
    town: 'Greenford, Perivale',
    county: 'Ealing',
    coordinates: [51.542247, -0.34380685],
  },
  {
    area: 'UB',
    outward: 'UB6',
    town: 'Greenford',
    county: 'Middlesex',
  },
  {
    outward: 'UB7',
    town: 'West Drayton, Harmondsworth, Sipson, Yiewsley, Longford',
    county: 'Hillingdon',
    coordinates: [51.48946, -0.4714247],
  },
  {
    outward: 'UB8',
    town: 'Uxbridge, Cowley, Hillingdon',
    county: 'Hillingdon',
    coordinates: [51.537333, -0.4873407],
  },
  {
    area: 'UB',
    outward: 'UB8',
    town: 'non-geo shared',
    county: 'Middlesex',
  },
  {
    area: 'UB',
    outward: 'UB8',
    town: 'Uxbridge (shared)',
    county: 'Middlesex',
  },
  {
    outward: 'UB9',
    town: 'Denham, Harefield',
    county: 'South Buckinghamshire',
    coordinates: [51.551325, -0.48523241],
  },
  {
    area: 'UB',
    outward: 'UB9',
    town: 'Uxbridge',
    county: 'Middlesex',
  },
  {
    outward: 'UB10',
    town: 'Hillingdon, Ickenham',
    county: 'Hillingdon',
    coordinates: [51.536913, -0.47156588],
  },
  {
    outward: 'UB11',
    town: 'Stockley Park',
    county: 'Hillingdon',
    coordinates: [51.512385, -0.44437146],
  },
  {
    outward: 'UB18',
    town: 'Non-geographic',
    county: 'Ealing',
    coordinates: [51.500719, -0.40268407],
  },
  {
    area: '',
    outward: 'UB18',
    town: 'Non-geographic',
    county: 'Middlesex',
  },
  {
    area: 'BX',
    outward: 'variesnon-geo',
    town: 'not applicable',
    county: 'Non-geographic',
  },
  {
    outward: 'W1',
    town: 'Mayfair, Piccadilly',
    county: 'Westminster',
    coordinates: [51.506162, -0.144039],
  },
  {
    outward: 'W1A',
    town: 'Non-geographic',
    county: 'Westminster',
  },
  {
    area: 'W',
    outward: 'W1A',
    town: 'Non-geographic',
    county: 'London',
  },
  {
    outward: 'W1B',
    town: 'Portland Street, Regent Street',
    county: 'Westminster',
  },
  {
    area: 'W',
    outward: 'W1B',
    town: 'LONDON',
    county: 'London',
  },
  {
    outward: 'W1C',
    town: 'Oxford Street (west)',
    county: 'Westminster',
  },
  {
    outward: 'W1D',
    town: 'Soho (south east), Chinatown, Soho Square',
    county: 'Westminster',
  },
  {
    outward: 'W1E',
    town: 'Non-geographic',
    county: 'West London',
  },
  {
    outward: 'W1F',
    town: 'Soho (north west)',
    county: 'Westminster',
  },
  {
    outward: 'W1G',
    town: 'Harley Street',
    county: 'Westminster',
  },
  {
    outward: 'W1H',
    town: 'Marylebone',
    county: 'Westminster',
  },
  {
    outward: 'W1J',
    town: 'Mayfair (south), Piccadilly, Royal Academy',
    county: 'Westminster',
  },
  {
    outward: 'W1K',
    town: 'Mayfair (north), Grosvenor Square',
    county: 'Westminster',
  },
  {
    outward: 'W1M',
    town: 'Marylebone',
    county: 'London',
  },
  {
    outward: 'W1N',
    town: 'Regents Park, Oxford Circus',
    county: 'London',
  },
  {
    outward: 'W1P',
    town: 'Goodge Street, Warren Street',
    county: 'London',
  },
  {
    outward: 'W1R',
    town: 'Oxford Street, Regent Street',
    county: 'London',
  },
  {
    outward: 'W1S',
    town: 'Hanover Square, Savile Row',
    county: 'Westminster',
  },
  {
    outward: 'W1T',
    town: 'Fitzrovia, Tottenham Court Road',
    county: 'Camden',
  },
  {
    outward: 'W1U',
    town: 'Marylebone',
    county: 'Westminster',
  },
  {
    outward: 'W1V',
    town: 'Soho, Piccadilly',
    county: 'London',
  },
  {
    outward: 'W1W',
    town: 'Great Portland Street, Fitzrovia',
    county: 'Westminster',
  },
  {
    outward: 'W1X',
    town: 'Mayfair',
    county: 'London',
  },
  {
    outward: 'W1Y',
    town: 'Mayfair',
    county: 'London',
  },
  {
    outward: 'W2',
    town: 'Paddington, Bayswater, Hyde Park, Westbourne Green, Little Venice, Westbourne Green, Notting Hill',
    county: 'Westminster',
    coordinates: [51.520327, -0.17842245],
  },
  {
    outward: 'W3',
    town: 'Acton, East Acton, Park Royal, West Acton',
    county: 'Ealing',
    coordinates: [51.519838, -0.26841397],
  },
  {
    outward: 'W4',
    town: 'Chiswick, Gunnersbury, Turnham Green, Bedford Park',
    county: 'Hounslow',
    coordinates: [51.501346, -0.25421702],
  },
  {
    outward: 'W5',
    town: 'Ealing, Park Royal',
    county: 'Ealing',
    coordinates: [51.528642, -0.29793685],
  },
  {
    outward: 'W6',
    town: 'Hammersmith, Ravenscourt Park',
    county: 'Hammersmith and Fulham',
    coordinates: [51.495143, -0.22739925],
  },
  {
    outward: 'W7',
    town: 'Hanwell, Boston Manor',
    county: 'Ealing',
    coordinates: [51.518505, -0.33789393],
  },
  {
    outward: 'W8',
    town: 'Kensington, Holland Park',
    county: 'Kensington and Chelsea',
    coordinates: [51.504699, -0.19299391],
  },
  {
    outward: 'W9',
    town: 'Maida Vale, Little Venice',
    county: 'Westminster',
    coordinates: [51.522735, -0.18119493],
  },
  {
    outward: 'W10',
    town: 'North Kensington, Queens Park',
    county: 'Kensington and Chelsea',
    coordinates: [51.529068, -0.21587479],
  },
  {
    outward: 'W11',
    town: 'Notting Hill, Holland Park',
    county: 'Kensington and Chelsea',
    coordinates: [51.520271, -0.20043511],
  },
  {
    outward: 'W12',
    town: 'Shepherds Bush, White City',
    county: 'Hammersmith and Fulham',
    coordinates: [51.516396, -0.244403],
  },
  {
    outward: 'W13',
    town: 'West Ealing, Northfields',
    county: 'Ealing',
    coordinates: [51.528521, -0.32955688],
  },
  {
    outward: 'W14',
    town: 'West Kensington, Kensington Olympia, Holland Park',
    county: 'Kensington and Chelsea',
    coordinates: [51.499435, -0.21866026],
  },
  {
    area: 'WA',
    outward: 'WA1',
    town: 'Warrington',
    county: 'Cheshire',
  },
  {
    area: 'WA',
    outward: 'WA2',
    town: 'Warrington',
    county: 'Cheshire',
  },
  {
    area: 'WA',
    outward: 'WA3',
    town: 'Warrington',
    county: 'Cheshire',
  },
  {
    area: 'WA',
    outward: 'WA4',
    town: 'Warrington',
    county: 'Cheshire',
  },
  {
    area: 'WA',
    outward: 'WA5',
    town: 'Warrington',
    county: 'Cheshire',
  },
  {
    area: 'WA',
    outward: 'WA6',
    town: 'Frodsham',
    county: 'Cheshire',
  },
  {
    area: 'WA',
    outward: 'WA7',
    town: 'Runcorn',
    county: 'Cheshire',
  },
  {
    area: 'WA',
    outward: 'WA8',
    town: 'Widnes',
    county: 'Cheshire',
  },
  {
    area: 'WA',
    outward: 'WA9',
    town: 'ST. HELENS',
    county: 'Merseyside',
  },
  {
    area: '',
    outward: 'WA10',
    town: 'ST. HELENS',
    county: 'Merseyside',
  },
  {
    area: '',
    outward: 'WA11',
    town: 'ST. HELENS',
    county: 'Merseyside',
  },
  {
    area: 'WA',
    outward: 'WA12',
    town: 'Newton-le-Willows',
    county: 'Merseyside',
  },
  {
    area: 'WA',
    outward: 'WA13',
    town: 'Lymm',
    county: 'Cheshire',
  },
  {
    area: 'WA',
    outward: 'WA14',
    town: 'Altrincham',
    county: 'Cheshire',
  },
  {
    area: 'WA',
    outward: 'WA15',
    town: 'Altrincham',
    county: 'Cheshire',
  },
  {
    area: 'WA',
    outward: 'WA16',
    town: 'Knutsford',
    county: 'Cheshire',
  },
  {
    area: '',
    outward: 'WA55',
    town: 'Non-geographic',
    county: 'Cheshire',
  },
  {
    area: '',
    outward: 'WA88',
    town: 'Non-geographic',
    county: 'Cheshire',
  },
  {
    outward: 'WC1',
    town: 'Bloomsbury, High Holborn, New Oxford Street',
    county: 'Camden and Westminster',
    coordinates: [51.521926, -0.128569],
  },
  {
    outward: 'WC1A',
    town: 'New Oxford Street',
    county: 'Camden',
    coordinates: [51.516395, -0.12516473],
  },
  {
    outward: 'WC1B',
    town: 'Bloomsbury, British Museum',
    county: 'Camden',
    coordinates: [51.518137, -0.12897044],
  },
  {
    outward: 'WC1E',
    town: 'University College London',
    county: 'Camden',
    coordinates: [51.522774, -0.1333067],
  },
  {
    outward: 'WC1H',
    town: 'St Pancras',
    county: 'Camden',
    coordinates: [51.522981, -0.12991069],
  },
  {
    outward: 'WC1N',
    town: 'Great Ormond Street Hospital',
    county: 'Camden',
    coordinates: [51.525387, -0.12517019],
  },
  {
    outward: 'WC1R',
    town: "Gray's Inn",
    county: 'Camden',
    coordinates: [51.518266, -0.12014401],
  },
  {
    outward: 'WC1V',
    town: 'High Holborn',
    county: 'Camden',
    coordinates: [51.518435, -0.11389589],
  },
  {
    outward: 'WC1X',
    town: 'Kings Cross, Finsbury (west)',
    county: 'Camden',
    coordinates: [51.523743, -0.11180257],
  },
  {
    outward: 'WC2',
    town: 'Aldwych, Charing Cross, Covent Garden',
    county: 'Camden, Westminster, City of London',
    coordinates: [51.513244, -0.117282],
  },
  {
    outward: 'WC2A',
    town: "Lincoln's Inn Fields, Royal Courts of Justice",
    county: 'Camden',
    coordinates: [51.51785, -0.11384801],
  },
  {
    outward: 'WC2B',
    town: 'Drury Lane, Aldwych',
    county: 'Camden',
    coordinates: [51.514374, -0.11971333],
  },
  {
    outward: 'WC2E',
    town: 'Covent Garden',
    county: 'Westminster',
    coordinates: [51.513814, -0.12295031],
  },
  {
    outward: 'WC2H',
    town: 'Leicester Square',
    county: 'Westminster',
    coordinates: [51.513033, -0.12923721],
  },
  {
    outward: 'WC2N',
    town: 'Charing Cross',
    county: 'Westminster',
    coordinates: [51.511496, -0.12758521],
  },
  {
    outward: 'WC2R',
    town: 'Somerset House',
    county: 'Westminster',
    coordinates: [51.510588, -0.12025832],
  },
  {
    outward: 'WC99',
    town: 'Covent Garden',
    county: 'London',
  },
  {
    outward: 'WD1',
    town: 'Watford',
    county: 'Watford',
  },
  {
    outward: 'WD2',
    town: 'Watford, Leavesden, Bushey',
    county: 'Watford',
  },
  {
    outward: 'WD3',
    town:
      'Batchworth Heath, Chandlers Cross, Chenies, Chorleywood, Croxley Green, Heronsgate, Loudwater, Maple Cross, Mill End, Rickmansworth, Sarratt, West Hyde',
    county: 'Three Rivers',
  },
  {
    outward: 'WD4',
    town: 'Bucks Hill, Chipperfield, Hunton Bridge, Kings Langley',
    county: 'Dacorum',
    coordinates: [51.712833, -0.45029984],
  },
  {
    outward: 'WD5',
    town: 'Bedmond, Abbots Langley',
    county: 'Three Rivers',
    coordinates: [51.707237, -0.41634895],
  },
  {
    outward: 'WD6',
    town: 'Borehamwood, Elstree, Well End',
    county: 'Hertsmere',
    coordinates: [51.654296, -0.27923992],
  },
  {
    outward: 'WD7',
    town: 'Shenley, Radlett',
    county: 'Hertsmere',
    coordinates: [51.682947, -0.31723999],
  },
  {
    outward: 'WD17',
    town: 'Watford, Cassiobury, Nascot Wood',
    county: 'Watford',
    coordinates: [51.656885, -0.39867429],
  },
  {
    outward: 'WD18',
    town: 'West Watford',
    county: 'Watford',
    coordinates: [51.653146, -0.39420906],
  },
  {
    area: 'WD',
    outward: 'WD18',
    town: 'non-geo shared',
    county: 'Hertfordshire',
  },
  {
    area: 'WD',
    outward: 'WD18',
    town: 'Watford (shared)',
    county: 'Hertfordshire',
  },
  {
    outward: 'WD19',
    town: 'Oxhey, South Oxhey, Carpenders Park',
    county: 'Watford',
    coordinates: [51.646235, -0.38270233],
  },
  {
    area: 'WD',
    outward: 'WD19',
    town: 'Watford',
    county: 'Hertfordshire',
  },
  {
    outward: 'WD23',
    town: 'Bushey, Bushey Heath',
    county: 'Hertsmere',
    coordinates: [51.640496, -0.35790463],
  },
  {
    outward: 'WD24',
    town: 'North Watford',
    county: 'Watford',
    coordinates: [51.667554, -0.39773415],
  },
  {
    outward: 'WD25',
    town: 'Garston, Aldenham, Leavesden, Letchmore Heath',
    county: 'Watford',
    coordinates: [51.688202, -0.40516471],
  },
  {
    outward: 'WD99',
    town: 'Non-geographic',
    county: 'Watford',
    coordinates: [51.648776, -0.42208728],
  },
  {
    area: '',
    outward: 'WD99',
    town: 'Non-geographic',
    county: 'Hertfordshire',
  },
  {
    area: 'WF',
    outward: 'WF1',
    town: 'Wakefield',
    county: 'West Yorkshire',
  },
  {
    area: 'WF',
    outward: 'WF2',
    town: 'Wakefield',
    county: 'West Yorkshire',
  },
  {
    area: 'WF',
    outward: 'WF3',
    town: 'Wakefield',
    county: 'West Yorkshire',
  },
  {
    area: 'WF',
    outward: 'WF4',
    town: 'Wakefield',
    county: 'West Yorkshire',
  },
  {
    area: 'WF',
    outward: 'WF5',
    town: 'Ossett',
    county: 'West Yorkshire',
  },
  {
    area: 'WF',
    outward: 'WF6',
    town: 'NORMANTON',
    county: 'West Yorkshire',
  },
  {
    area: 'WF',
    outward: 'WF7',
    town: 'Pontefract',
    county: 'West Yorkshire',
  },
  {
    area: 'WF',
    outward: 'WF8',
    town: 'Pontefract',
    county: 'West Yorkshire',
  },
  {
    area: '',
    outward: 'WF10',
    town: 'NORMANTON (shared)',
    county: 'West Yorkshire',
  },
  {
    area: '',
    outward: 'WF10',
    town: 'Castleford (shared)',
    county: 'West Yorkshire',
  },
  {
    area: 'WF',
    outward: 'WF11',
    town: 'Knottingley',
    county: 'West Yorkshire',
  },
  {
    area: 'WF',
    outward: 'WF12',
    town: 'Dewsbury',
    county: 'West Yorkshire',
  },
  {
    area: 'WF',
    outward: 'WF13',
    town: 'Dewsbury',
    county: 'West Yorkshire',
  },
  {
    area: 'WF',
    outward: 'WF14',
    town: 'Mirfield',
    county: 'West Yorkshire',
  },
  {
    area: 'WF',
    outward: 'WF15',
    town: 'Liversedge',
    county: 'West Yorkshire',
  },
  {
    area: 'WF',
    outward: 'WF16',
    town: 'non-geo shared',
    county: 'West Yorkshire',
  },
  {
    area: 'WF',
    outward: 'WF16',
    town: 'Heckmondwike (shared)',
    county: 'West Yorkshire',
  },
  {
    area: 'WF',
    outward: 'WF17',
    town: 'Batley',
    county: 'West Yorkshire',
  },
  {
    area: '',
    outward: 'WF90',
    town: 'Non-geographic',
    county: 'West Yorkshire',
  },
  {
    area: 'WN',
    outward: 'WN1',
    town: 'Wigan',
    county: 'Lancashire',
  },
  {
    area: 'WN',
    outward: 'WN2',
    town: 'Wigan',
    county: 'Lancashire',
  },
  {
    area: 'WN',
    outward: 'WN3',
    town: 'Wigan',
    county: 'Lancashire',
  },
  {
    area: 'WN',
    outward: 'WN4',
    town: 'Wigan',
    county: 'Lancashire',
  },
  {
    area: 'WN',
    outward: 'WN5',
    town: 'Wigan',
    county: 'Lancashire',
  },
  {
    area: 'WN',
    outward: 'WN6',
    town: 'Wigan',
    county: 'Lancashire',
  },
  {
    area: 'WN',
    outward: 'WN7',
    town: 'LEIGH',
    county: 'Lancashire',
  },
  {
    area: 'WN',
    outward: 'WN8',
    town: 'Wigan (shared)',
    county: 'Lancashire',
  },
  {
    area: 'WR',
    outward: 'WR1',
    town: 'Worcester',
    county: 'Worcestershire',
  },
  {
    area: 'WR',
    outward: 'WR2',
    town: 'Worcester',
    county: 'Worcestershire',
  },
  {
    area: 'WR',
    outward: 'WR3',
    town: 'Worcester',
    county: 'Worcestershire',
  },
  {
    area: 'WR',
    outward: 'WR4',
    town: 'Worcester',
    county: 'Worcestershire',
  },
  {
    area: 'WR',
    outward: 'WR5',
    town: 'Worcester',
    county: 'Worcestershire',
  },
  {
    area: 'WR',
    outward: 'WR6',
    town: 'Worcester',
    county: 'Worcestershire',
  },
  {
    area: 'WR',
    outward: 'WR7',
    town: 'Worcester',
    county: 'Worcestershire',
  },
  {
    area: 'WR',
    outward: 'WR8',
    town: 'Worcester',
    county: 'Worcestershire',
  },
  {
    area: 'WR',
    outward: 'WR10',
    town: 'Pershore',
    county: 'Worcestershire',
  },
  {
    area: 'WR',
    outward: 'WR11',
    town: 'Evesham',
    county: 'Worcestershire',
  },
  {
    area: 'WR',
    outward: 'WR12',
    town: 'BROADWAY',
    county: 'Worcestershire',
  },
  {
    area: 'WR',
    outward: 'WR13',
    town: 'MALVERN',
    county: 'Worcestershire',
  },
  {
    area: 'WR',
    outward: 'WR14',
    town: 'MALVERN',
    county: 'Worcestershire',
  },
  {
    area: 'WR',
    outward: 'WR15',
    town: 'Tenbury Wells',
    county: 'Worcestershire',
  },
  {
    area: 'WR',
    outward: 'WR78',
    town: 'Non-geographic',
    county: 'Worcestershire',
  },
  {
    area: 'WR',
    outward: 'WR99',
    town: 'Non-geographic',
    county: 'Worcestershire',
  },
  {
    area: 'WS',
    outward: 'WS1',
    town: 'Walsall',
    county: 'West Midlands',
  },
  {
    area: 'WS',
    outward: 'WS2',
    town: 'Walsall',
    county: 'West Midlands',
  },
  {
    area: 'WS',
    outward: 'WS3',
    town: 'Walsall',
    county: 'West Midlands',
  },
  {
    area: 'WS',
    outward: 'WS4',
    town: 'Walsall',
    county: 'West Midlands',
  },
  {
    area: 'WS',
    outward: 'WS5',
    town: 'Walsall',
    county: 'West Midlands',
  },
  {
    area: 'WS',
    outward: 'WS6',
    town: 'Walsall',
    county: 'West Midlands',
  },
  {
    area: 'WS',
    outward: 'WS7',
    town: 'Burntwood',
    county: 'Staffordshire',
  },
  {
    area: 'WS',
    outward: 'WS8',
    town: 'Walsall',
    county: 'West Midlands',
  },
  {
    area: 'WS',
    outward: 'WS9',
    town: 'Walsall',
    county: 'West Midlands',
  },
  {
    area: 'WS',
    outward: 'WS10',
    town: 'Wednesbury',
    county: 'West Midlands',
  },
  {
    area: 'WS',
    outward: 'WS11',
    town: 'Cannock',
    county: 'Staffordshire',
  },
  {
    area: 'WS',
    outward: 'WS12',
    town: 'Cannock',
    county: 'Staffordshire',
  },
  {
    area: 'WS',
    outward: 'WS13',
    town: 'Lichfield',
    county: 'Staffordshire',
  },
  {
    area: 'WS',
    outward: 'WS14',
    town: 'Lichfield',
    county: 'Staffordshire',
  },
  {
    area: 'WS',
    outward: 'WS15',
    town: 'Rugeley',
    county: 'Staffordshire',
  },
  {
    area: 'WV',
    outward: 'WV1',
    town: 'Wolverhampton',
    county: 'West Midlands',
  },
  {
    area: 'WV',
    outward: 'WV2',
    town: 'Wolverhampton',
    county: 'West Midlands',
  },
  {
    area: 'WV',
    outward: 'WV3',
    town: 'Wolverhampton',
    county: 'West Midlands',
  },
  {
    area: 'WV',
    outward: 'WV4',
    town: 'Wolverhampton',
    county: 'West Midlands',
  },
  {
    area: 'WV',
    outward: 'WV5',
    town: 'Wolverhampton',
    county: 'West Midlands',
  },
  {
    area: 'WV',
    outward: 'WV6',
    town: 'Wolverhampton',
    county: 'West Midlands',
  },
  {
    area: 'WV',
    outward: 'WV7',
    town: 'Wolverhampton',
    county: 'West Midlands',
  },
  {
    area: 'WV',
    outward: 'WV8',
    town: 'Wolverhampton',
    county: 'West Midlands',
  },
  {
    area: 'WV',
    outward: 'WV9',
    town: 'Wolverhampton',
    county: 'West Midlands',
  },
  {
    area: 'WV',
    outward: 'WV10',
    town: 'Wolverhampton',
    county: 'West Midlands',
  },
  {
    area: 'WV',
    outward: 'WV11',
    town: 'Wolverhampton',
    county: 'West Midlands',
  },
  {
    area: 'WV',
    outward: 'WV12',
    town: 'Willenhall',
    county: 'West Midlands',
  },
  {
    area: 'WV',
    outward: 'WV13',
    town: 'Willenhall',
    county: 'West Midlands',
  },
  {
    area: 'WV',
    outward: 'WV14',
    town: 'Bilston',
    county: 'West Midlands',
  },
  {
    area: 'WV',
    outward: 'WV15',
    town: 'Bridgnorth',
    county: 'Shropshire',
  },
  {
    area: 'WV',
    outward: 'WV16',
    town: 'Bridgnorth',
    county: 'Shropshire',
  },
  {
    area: 'WV',
    outward: 'WV98',
    town: 'Non-geographic',
    county: 'West Midlands',
  },
  {
    area: 'YO',
    outward: 'YO1',
    town: 'York',
    county: 'North Yorkshire',
  },
  {
    area: 'YO',
    outward: 'YO7',
    town: 'Thirsk',
    county: 'North Yorkshire',
  },
  {
    area: 'YO',
    outward: 'YO8',
    town: 'Selby',
    county: 'North Yorkshire',
  },
  {
    area: 'YO',
    outward: 'YO10',
    town: 'York',
    county: 'North Yorkshire',
  },
  {
    area: 'YO',
    outward: 'YO11',
    town: 'SCARBOROUGH',
    county: 'North Yorkshire',
  },
  {
    area: 'YO',
    outward: 'YO12',
    town: 'SCARBOROUGH',
    county: 'North Yorkshire',
  },
  {
    area: 'YO',
    outward: 'YO13',
    town: 'SCARBOROUGH',
    county: 'North Yorkshire',
  },
  {
    area: 'YO',
    outward: 'YO14',
    town: 'Filey',
    county: 'North Yorkshire',
  },
  {
    area: 'YO',
    outward: 'YO15',
    town: 'Bridlington',
    county: 'North Humberside',
  },
  {
    area: 'YO',
    outward: 'YO16',
    town: 'Bridlington',
    county: 'North Humberside',
  },
  {
    area: 'YO',
    outward: 'YO17',
    town: 'MALTON',
    county: 'North Yorkshire',
  },
  {
    area: 'YO',
    outward: 'YO18',
    town: 'PICKERING',
    county: 'North Yorkshire',
  },
  {
    area: 'YO',
    outward: 'YO19',
    town: 'York',
    county: 'North Yorkshire',
  },
  {
    area: 'YO',
    outward: 'YO21',
    town: 'Whitby',
    county: 'North Yorkshire',
  },
  {
    area: 'YO',
    outward: 'YO22',
    town: 'Whitby',
    county: 'North Yorkshire',
  },
  {
    area: 'YO',
    outward: 'YO23',
    town: 'York',
    county: 'North Yorkshire',
  },
  {
    area: 'YO',
    outward: 'YO24',
    town: 'York',
    county: 'North Yorkshire',
  },
  {
    area: 'YO',
    outward: 'YO25',
    town: 'Driffield',
    county: 'North Humberside',
  },
  {
    area: 'YO',
    outward: 'YO26',
    town: 'York',
    county: 'North Yorkshire',
  },
  {
    area: 'YO',
    outward: 'YO30',
    town: 'York',
    county: 'North Yorkshire',
  },
  {
    area: 'YO',
    outward: 'YO31',
    town: 'York',
    county: 'North Yorkshire',
  },
  {
    area: 'YO',
    outward: 'YO32',
    town: 'York',
    county: 'North Yorkshire',
  },
  {
    area: 'YO',
    outward: 'YO41',
    town: 'York',
    county: 'North Yorkshire',
  },
  {
    area: 'YO',
    outward: 'YO42',
    town: 'York',
    county: 'North Yorkshire',
  },
  {
    area: 'YO',
    outward: 'YO43',
    town: 'York',
    county: 'North Yorkshire',
  },
  {
    area: 'YO',
    outward: 'YO51',
    town: 'York',
    county: 'North Yorkshire',
  },
  {
    area: 'YO',
    outward: 'YO60',
    town: 'York',
    county: 'North Yorkshire',
  },
  {
    area: 'YO',
    outward: 'YO61',
    town: 'York',
    county: 'North Yorkshire',
  },
  {
    area: 'YO',
    outward: 'YO62',
    town: 'York',
    county: 'North Yorkshire',
  },
  {
    area: 'YO',
    outward: 'YO90',
    town: 'Non-geographic',
    county: 'North Yorkshire',
  },
  {
    area: 'YO',
    outward: 'YO91',
    town: 'Non-geographic',
    county: 'North Yorkshire',
  },
  {
    area: 'ZE',
    outward: 'ZE1',
    town: 'Shetland',
    county: 'Shetland Islands',
  },
  {
    area: 'ZE',
    outward: 'ZE2',
    town: 'Shetland',
    county: 'Shetland Islands',
  },
  {
    area: 'ZE',
    outward: 'ZE3',
    town: 'Shetland',
    county: 'Shetland Islands',
  },
];
