import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  // Divider,
  // FormControl,
  Grid,
  // Box,
  // InputLabel,
  // MenuItem,
  // Select,
  // TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import { inputLabels, Settings } from '../../state/settings/settingsReducer';
// import { useAppState } from '../../state';
// import useRoomState from '../../hooks/useRoomState/useRoomState';

// import CheckOIcon from '../../icons/CheckOIcon';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '320px',
    'min-height': '56px',
    padding: '0 24px 0',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 32px)',
    },
    '& .inputSelect': {
      width: 'calc(100% - 35px)',
    },
  },
  button: {
    // float: 'right',
    width: '100%',
  },
  paper: {
    // 'justify-content': 'center',
    'align-items': 'center',
    [theme.breakpoints.down('xs')]: {
      margin: '16px',
    },
  },
  formControl: {
    display: 'block',
    margin: '1.5em 0',
    '&:first-child': {
      margin: '0 0 1.5em 0',
    },
  },
  label: {
    width: '133%', // Labels have scale(0.75) applied to them, so this effectively makes the width 100%
  },
  greyMainColor: {
    color: theme.greyMain,
  },
  linkLikeButton: {
    cursor: 'pointer',
    display: 'flex',
    'justify-content': 'center',
    background: 'transparent',
    border: '0',
    padding: '0',
    'margin-top': '32px',
    'margin-left': '0!important',
    color: '#303740 !important', /* .tbk-grey-main */
    // width: '100%',
    'text-transform': 'initial',
    'text-decoration': 'underline'
  },
}));

// const withDefault = (val?: string) => (typeof val === 'undefined' ? 'default' : val);

export default function ClearAllTimeSlotsDialog({ open, onClose, onConfirm }: { open: boolean; onClose: () => void; onConfirm: () => void; }) {
  const classes = useStyles();
  // const { settings, dispatchSetting } = useAppState();
  // const roomState = useRoomState();
  // const isDisabled = false; // roomState !== 'disconnected';

  // const handleChange = useCallback(
  //   (e: React.ChangeEvent<{ value: unknown; name?: string }>) => {
  //     dispatchSetting({ name: e.target.name as keyof Settings, value: e.target.value as string });
  //   },
  //   [dispatchSetting]
  // );

  // const handleNumberChange = useCallback(
  //   (e: React.ChangeEvent<{ value: unknown; name?: string }>) => {
  //     if (!/[^\d]/.test(e.target.value as string)) handleChange(e);
  //   },
  //   [handleChange]
  // );

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <DialogTitle disableTypography style={{ paddingTop: '24px', paddingBottom: '8px' }}>
        <Typography variant="h3">Clear all Time Slots</Typography>
      </DialogTitle>
      {/*<Divider />*/}
      <DialogContent className={classes.container}>
        <Grid container /*spacing={2}*/ justifyContent="center">
          <Grid item style={{ textAlign: "center" }}>

            <Typography /*hidden={!isDisabled}*/ variant="body1" className={classes.greyMainColor} style={{ marginBottom: '8px' }}>
              Do you want to delete all the selected time slots for the day?
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      {/*<Divider />*/}
      <DialogActions
        style={{
          flexWrap: 'wrap',
          justifyContent: 'center'
        }}
      >
        <Button className={classes.button} color="primary" variant="contained" onClick={onConfirm}>
          Accept
        </Button>
        <button
          className={classes.linkLikeButton}
          // color="primary"
          // variant="contained"
          onClick={onClose}
        >
          <Typography variant="body1">Cancel</Typography>
        </button>
      </DialogActions>
    </Dialog>
  );
}
