//import Image from 'next/image'
import React, { useState } from 'react';
import { Channel, Message as pnMessage, Membership, User } from '@pubnub/chat';
import Typography from '@material-ui/core/Typography';
//import ChatMenuHeader from './chatMenuHeader'
import ChatMenuItem from './ChatMenuItem/ChatMenuItem';
import ChatMenuHeader from './ChatMenuHeader/ChatMenuHeader';
// import { ChatHeaderActionIcon, PresenceIcon, ToastType, useBreakpoints, useMediaQuery } from '@/app/types'
import { LatestMessagesOnChannel } from '../ChatProvider';
import avatarPlaceholder from '../../images/generic-trainerphoto.svg'; //.png

interface ChatSelectionMenuProps {
  chatSelectionMenuMinimized: any;
  setChatSelectionMenuMinimized: any;
  chat: any;
  setCreatingNewMessage: any;
  setShowThread: any;
  unreadMessages: any;
  latestMessages: any;
  publicChannels: any;
  publicChannelsMemberships: any;
  privateGroups: any;
  privateGroupsUsers: any;
  privateGroupsMemberships: any;
  directChats: any;
  directChatsUsers: any;
  directChatsMemberships: any;
  activeChannel: any;
  setActiveChannel: any;
  setActiveChannelPinnedMessage?: any;
  updateUnreadMessagesCounts: any;
  currentUserProfileUrl?: any;
  showUserMessage: any;
}

export default function ChatSelectionMenu({
  chatSelectionMenuMinimized,
  setChatSelectionMenuMinimized,
  chat,
  setCreatingNewMessage,
  setShowThread,
  unreadMessages,
  latestMessages,
  publicChannels,
  publicChannelsMemberships,
  privateGroups,
  privateGroupsUsers,
  privateGroupsMemberships,
  directChats,
  directChatsUsers,
  directChatsMemberships,
  activeChannel,
  setActiveChannel,
  setActiveChannelPinnedMessage,
  updateUnreadMessagesCounts,
  currentUserProfileUrl,
  showUserMessage,
}: ChatSelectionMenuProps) {
  const [unreadExpanded, setUnreadExpanded] = useState(true);
  const [publicExpanded, setPublicExpanded] = useState(true);
  const [groupsExpanded, setGroupsExpanded] = useState(true);
  const [directMessagesExpanded, setDirectMessagesExpanded] = useState(true);
  //const { isXs, isSm, isMd, isLg, active } = useBreakpoints();
  const isLg = false;
  const [searchChannels, setSearchChannels] = useState('');

  // const chatSelectionMenuMinimized = false;

  function handleChatSearch(term: string) {
    setSearchChannels(term);
  }

  return (
    <div
      id="chats-menu"
      className={`tbk-grow tbk-flex tbk-flex-col tbk-bg-basic-white ${
        !isLg && chatSelectionMenuMinimized
          ? 'tbl-w-5 tbk-min-w-5'
          : 'lg:tbk-min-w-80 lg:tbk-w-80 tbk-min-w-60 tbk-w-60'
      } tbk-bg-navy50 tbk-py-0 tbk-overflow-y-auto tbk-overscroll-none tbk-mt-[64px] tbk-pb-6_ tbk-select-none tbk-border-r tbk-border-navy-200`}
    >
      <div className={`flex flex-col tbk-p-2 ${!isLg && chatSelectionMenuMinimized ? 'hidden' : 'flex'}`}>
        {false && (
          <div className={`relative px-4 mt-5`}>
            <input
              id="chats-search-input"
              value={searchChannels}
              className="flex w-full rounded-md bg-navy50 border  border-neutral-400 py-[9px] pl-9 px-[13px] text-sm focus:ring-1 focus:ring-inputring outline-none placeholder:text-neutral-500"
              placeholder="Search"
              onChange={e => {
                handleChatSearch(e.target.value);
              }}
            />
            {/*<Image
            src='/icons/search.svg'
            alt='Search Icon'
            className='absolute left-6 top-1/2 h-[20px] w-[20px] -translate-y-1/2 text-gray-500 peer-focus:text-gray-900'
            width={20}
            height={20}
            priority
          />*/}
          </div>
        )}

        {false && unreadMessages && unreadMessages.length > 0 && (
          <ChatMenuHeader
            text="UNREAD"
            // actionIcon={ChatHeaderActionIcon.MARK_READ}
            expanded={unreadExpanded}
            expandCollapse={() => {
              setUnreadExpanded(!unreadExpanded);
            }}
            action={async () => {
              const markedAsRead = await chat.markAllMessagesAsRead();
              updateUnreadMessagesCounts();

              showUserMessage(
                'Success:',
                'All messsages have been marked as read, and sent receipts are updated accordingly',
                'https://www.pubnub.com/docs/chat/chat-sdk/build/features/messages/unread#mark-messages-as-read-all-channels',
                0 //ToastType.CHECK
              );
            }}
          />
        )}
        {false && unreadExpanded && (
          <div>
            {unreadMessages?.map(
              (unreadMessage: any, index: any) =>
                unreadMessage.channel.id !== activeChannel?.id &&
                (
                  (unreadMessage.channel.type === 'direct' && directChats
                    ? directChatsUsers[
                        directChats.findIndex((dmChannel: any) => dmChannel.id == unreadMessage.channel.id)
                      ]?.find((user: User) => user.id !== chat.currentUser.id)?.name
                    : unreadMessage.channel.name) ?? ''
                )
                  .toLowerCase()
                  ?.indexOf(searchChannels.toLowerCase()) > -1 && (
                  <ChatMenuItem
                    key={index}
                    avatarUrl={
                      unreadMessage.channel.type === 'group'
                        ? currentUserProfileUrl
                          ? currentUserProfileUrl
                          : avatarPlaceholder
                        : unreadMessage.channel.type == 'public'
                        ? unreadMessage.channel.custom?.profileUrl
                          ? unreadMessage.channel.custom?.profileUrl
                          : avatarPlaceholder
                        : unreadMessage.channel.type == 'direct' && directChats
                        ? directChatsUsers[
                            directChats.findIndex((dmChannel: any) => dmChannel.id == unreadMessage.channel.id)
                          ]?.find((user: User) => user.id !== chat.currentUser.id)?.profileUrl
                          ? directChatsUsers[
                              directChats.findIndex((dmChannel: any) => dmChannel.id == unreadMessage.channel.id)
                            ]?.find((user: User) => user.id !== chat.currentUser.id)?.profileUrl
                          : avatarPlaceholder
                        : avatarPlaceholder
                    }
                    avatarBubblePrecedent={
                      unreadMessage.channel.type === 'group' && privateGroups
                        ? privateGroupsUsers[
                            privateGroups.findIndex((group: any) => group.id == unreadMessage.channel.id)
                          ]?.map((user: User) => user.id !== chat.currentUser.id)
                          ? `+${privateGroupsUsers[
                              privateGroups.findIndex((group: any) => group.id == unreadMessage.channel.id)
                            ]?.map((user: User) => user.id !== chat.currentUser.id).length - 1}`
                          : ''
                        : ''
                    }
                    text={
                      unreadMessage.channel.type === 'direct' && directChats
                        ? directChatsUsers[
                            directChats.findIndex((dmChannel: any) => dmChannel.id == unreadMessage.channel.id)
                          ]?.find((user: User) => user.id !== chat.currentUser.id)?.name
                        : unreadMessage.channel.name
                    }
                    latestMessage={
                      latestMessages.channel.type === 'direct' && directChats
                        ? directChatsUsers[
                            directChats.findIndex((dmChannel: any) => dmChannel.id == unreadMessage.channel.id)
                          ]?.find((user: User) => user.id !== chat.currentUser.id)?.name
                        : unreadMessage.channel.name
                    }
                    // present={PresenceIcon.NOT_SHOWN}
                    count={'' + unreadMessage.count}
                    markAsRead={true}
                    markAsReadAction={async (e: any) => {
                      e.stopPropagation();
                      if (unreadMessage.channel.type === 'public' && publicChannelsMemberships && publicChannels) {
                        const index = publicChannelsMemberships.findIndex(
                          (membership: any) => membership.channel.id == unreadMessage.channel.id
                        );
                        if (index > -1) {
                          const lastMessage = await publicChannels[index]?.getHistory({ count: 1 });
                          if (lastMessage && lastMessage.messages) {
                            await publicChannelsMemberships[index].setLastReadMessage(lastMessage.messages[0]);
                            updateUnreadMessagesCounts();
                          }
                        }
                      } else if (unreadMessage.channel.type === 'group' && privateGroupsMemberships && privateGroups) {
                        const index = privateGroupsMemberships.findIndex(
                          (membership: any) => membership.channel.id == unreadMessage.channel.id
                        );
                        if (index > -1) {
                          const lastMessage = await privateGroups[index]?.getHistory({ count: 1 });
                          if (lastMessage && lastMessage.messages) {
                            await privateGroupsMemberships[index].setLastReadMessage(lastMessage.messages[0]);
                            updateUnreadMessagesCounts();
                          }
                        }
                      } else if (unreadMessage.channel.type === 'direct' && directChatsMemberships && directChats) {
                        const index = directChatsMemberships.findIndex(
                          (membership: any) => membership.channel.id == unreadMessage.channel.id
                        );
                        if (index > -1) {
                          const lastMessage = await directChats[index]?.getHistory({ count: 1 });
                          if (lastMessage && lastMessage.messages) {
                            await directChatsMemberships[index].setLastReadMessage(lastMessage.messages[0]);
                            updateUnreadMessagesCounts();
                          }
                        }
                      }
                    }}
                    setActiveChannel={() => {
                      setActiveChannelPinnedMessage(null);
                      setCreatingNewMessage(false);
                      if (unreadMessage.channel.type === 'public' && publicChannels) {
                        const index = publicChannels.findIndex(
                          (channel: any) => channel.id == unreadMessage.channel.id
                        );
                        if (index > -1) {
                          setActiveChannel(publicChannels[index]);
                        }
                      } else if (unreadMessage.channel.type === 'group' && privateGroups) {
                        const index = privateGroups.findIndex((group: any) => group.id == unreadMessage.channel.id);
                        if (index > -1) {
                          setActiveChannel(privateGroups[index]);
                        }
                      } else if (unreadMessage.channel.type === 'direct' && directChats) {
                        const index = directChats.findIndex(
                          (dmChannel: any) => dmChannel.id == unreadMessage.channel.id
                        );
                        if (index > -1) {
                          setActiveChannel(directChats[index]);
                        }
                      }
                    }}
                  ></ChatMenuItem>
                )
            )}
          </div>
        )}

        {false && unreadMessages && unreadMessages.length > 0 && (
          <div className="w-full border border-navy200 mt-4"></div>
        )}

        {false ? (
          <>
            <ChatMenuHeader
              text="PUBLIC CHANNELS"
              expanded={publicExpanded}
              expandCollapse={() => {
                setPublicExpanded(!publicExpanded);
              }}
              //actionIcon={ChatHeaderActionIcon.NONE}
              action={() => {}}
            />
            {publicExpanded && (
              <div>
                {publicChannels?.map(
                  (publicChannel: any, index: any) =>
                    (publicChannel.name ?? '').toLowerCase().indexOf(searchChannels.toLowerCase()) > -1 && (
                      <ChatMenuItem
                        key={index}
                        // avatarUrl={
                        //   publicChannel.custom.profileUrl
                        //     ? publicChannel.custom.profileUrl
                        //     : '/avatars/placeholder.png'
                        // }
                        text={publicChannel.name}
                        //present={PresenceIcon.NOT_SHOWN}
                        setActiveChannel={() => {
                          setCreatingNewMessage(false);
                          setActiveChannelPinnedMessage(null);
                          setActiveChannel(publicChannels[index]);
                        }}
                      ></ChatMenuItem>
                    )
                )}
              </div>
            )}
          </>
        ) : null}

        {false ? (
          <>
            <div className="w-full border border-navy200 mt-4"></div>
            <ChatMenuHeader
              text="PRIVATE GROUPS"
              expanded={groupsExpanded}
              expandCollapse={() => setGroupsExpanded(!groupsExpanded)}
              //actionIcon={ChatHeaderActionIcon.ADD}
              action={setCreatingNewMessage}
            />
            {groupsExpanded && (
              <div>
                {privateGroups?.map(
                  (privateGroup: any, index: any) =>
                    (privateGroup.name ?? '').toLowerCase().indexOf(searchChannels.toLowerCase()) > -1 && (
                      <ChatMenuItem
                        key={index}
                        avatarUrl={currentUserProfileUrl ? currentUserProfileUrl : avatarPlaceholder}
                        text={privateGroup.name}
                        //present={PresenceIcon.NOT_SHOWN}
                        avatarBubblePrecedent={
                          privateGroupsUsers[index]?.map((user: User) => user.id !== chat.currentUser.id)
                            ? `+${privateGroupsUsers[index]?.map((user: User) => user.id !== chat.currentUser.id)
                                .length - 1}`
                            : ''
                        }
                        setActiveChannel={() => {
                          setCreatingNewMessage(false);
                          setActiveChannelPinnedMessage(null);
                          setActiveChannel(privateGroups[index]);
                        }}
                      />
                    )
                )}
              </div>
            )}
          </>
        ) : null}

        {/*<div className="w-full border border-navy200 mt-4"></div>
        <ChatMenuHeader
          text="DIRECT MESSAGES"
          expanded={directMessagesExpanded}
          expandCollapse={() => setDirectMessagesExpanded(!directMessagesExpanded)}
          //actionIcon={ChatHeaderActionIcon.ADD}
          action={setCreatingNewMessage}
        />*/}
        {directMessagesExpanded && (
          <div>
            {directChats ? (
              directChats.map(
                (directChat: any, index: any) =>
                  (directChatsUsers[index]?.find((user: User) => user.id !== chat.currentUser.id)?.name ?? '')
                    .toLowerCase()
                    .indexOf(searchChannels.toLowerCase()) > -1 && (
                    <ChatMenuItem
                      key={index}
                      avatarUrl={
                        directChatsUsers[index]?.find((user: User) => user.id !== chat.currentUser.id)?.profileUrl
                          ? directChatsUsers[index]?.find((user: User) => user.id !== chat.currentUser.id)?.profileUrl
                          : avatarPlaceholder
                      }
                      text={directChatsUsers[index]?.find((user: User) => user.id !== chat.currentUser.id)?.name}
                      latestMessage={
                        ///*unreadMessage.channel.type === 'direct' && */directChats
                        latestMessages.find((msg: LatestMessagesOnChannel) => msg.channelId === directChat.id)
                        //?
                        //: ''// unreadMessage.channel.name
                      }
                      // present={
                      //   directChatsUsers[index]?.find(
                      //     user => user.id !== chat.currentUser.id
                      //   )?.active
                      //     ? PresenceIcon.ONLINE
                      //     : PresenceIcon.OFFLINE
                      // }
                      setActiveChannel={() => {
                        setCreatingNewMessage(false);
                        setActiveChannelPinnedMessage(null);
                        setActiveChannel(directChats[index]);
                      }}
                    />
                  )
              )
            ) : (
              <Typography
                variant="body1"
                className="tbk-mt-6 tbk-mb-6 tbk-text-center tbk-text-h3-subtitle tbk-text-blue-grey"
                // className={clsx(classes.gutterBottomDoubleMedium, classes.greyMainColor)}
                //style={{ marginTop: '8px', textAlign: 'center' }}
              >
                You have no messages yet.
              </Typography>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
