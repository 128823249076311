import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Avatar from '../../ChatSelectionMenu/Avatar/Avatar';
import CloseIcon from '../../../icons/CloseIcon';
import ArrowLeftIcon from '../../../icons/ArrowLeftIcon';

import useChatContext from '../../../hooks/useChatContext/useChatContext';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: '84px',
      // background: '#F4F4F6',
      background: 'rgba(248, 248, 248, 0.3)',
      //borderBottom: '1px solid #E4E7E9',
      display: 'flex',
      justifyContent: 'center', //'space-between',
      alignItems: 'center',
      padding: '16px 1em 8px',
      // border-radius: 8px 8px 0px 0px;
      // opacity: 0px;
      backdropFilter: 'blur(32px)',
      // background: rgba(248, 248, 248, 0.3);
    },
    text: {
      //fontWeight: 'bold',
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '28px',
      // alignCenter
    },
    closeChatWindow: {
      position: 'absolute',
      left: '12px',
      cursor: 'pointer',
      display: 'flex',
      background: 'transparent',
      border: '0',
      padding: '0.4em',
      color: 'rgba(255, 117, 87, 1)',
    },
  })
);

interface ChatWindowHeaderProps {
  avatarUrl?: any;
  text?: any;
  present?: any;
  avatarBubblePrecedent?: any;
  // count?: any;
  // markAsRead?: any;
  // markAsReadAction?: any; // = e => {},
  setActiveChannel?: any; // = () => {}
}

export default function ChatWindowHeader({ avatarUrl, text, setActiveChannel }: ChatWindowHeaderProps) {
  const classes = useStyles();
  const { setIsChatWindowOpen } = useChatContext();

  return (
    <div className={classes.container}>
      <button
        className={classes.closeChatWindow}
        // onClick={() => setIsChatWindowOpen(false)}
        onClick={() => setActiveChannel(null)}
      >
        <ArrowLeftIcon />
      </button>
      <div className="tbk-flex tbk-flex-col tbk-items-center">
        <Avatar
          width={40}
          height={40}
          //present={present}
          //bubblePrecedent={avatarBubblePrecedent}
          avatarUrl={avatarUrl}
        />
        <div className={classes.text}>{text}</div>
      </div>
    </div>
  );
}
