import React from 'react';

export default function UserPlaceholder80() {
  return (
    <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="40.5" cy="40" r="40" fill="#F8F8F8"/>
      <circle cx="40.5" cy="40" r="40" fill="#F8F8F8"/>
      <mask id="mask0_1937_296" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="81" height="80">
        <circle cx="40.5" cy="40" r="40" fill="white"/>
      </mask>
      <g mask="url(#mask0_1937_296)">
        <path opacity="0.6" fillRule="evenodd" clipRule="evenodd" d="M40.4998 53.3333C48.3269 53.3333 54.6665 46.9937 54.6665 39.1667C54.6665 31.3396 48.3269 25 40.4998 25C32.6728 25 26.3332 31.3396 26.3332 39.1667C26.3332 46.9937 32.6728 53.3333 40.4998 53.3333ZM40.4998 60.4167C31.0436 60.4167 12.1665 65.1625 12.1665 74.5833V81.6667H68.8332V74.5833C68.8332 65.1625 49.9561 60.4167 40.4998 60.4167Z" fill="#CBCFD5"/>
      </g>
    </svg>
  );
}
