import React from 'react';
import { ScrollView } from 'react-native';
import { useHistory } from 'react-router-dom';
// import { useAppState } from "../../state";
import { useSignUpState } from '../../state/SignUpState';

import { makeStyles } from '@material-ui/core/styles';

import { Theme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// Components
import PasswordReset from '../PasswordReset/PasswordReset';
import PasswordResetCodeVerification from '../PasswordReset/CodeVerification/CodeVerification';
import ScrollToTopOnMount from '../ScrollToTopOnMount/ScrollToTopOnMount';

const useStyles = makeStyles((theme: Theme) => ({
  greyColor: {
    color: theme.grey,
  },
  container: {
    'margin-top': '80px', //'2.5em',
    padding: '24px 16px',
    // height: '100%',
    // 'margin-bottom': '2.5em',
  },
}));

export default function PasswordResetPage() {
  const classes = useStyles();
  const history = useHistory();

  const { authState, setAuthState, setIsAppleSignIn } = useSignUpState();

  let CurrentState,
    onNext = () => {};

  switch (authState) {
    // case "login":
    //   CurrentState = Login;

    //   break;
    // case "verifyPhoneNumber":
    //   CurrentState = SignUpForm;
    //   onNext = () => setAuthState("codeVerification");

    //   break;
    // case "codeVerification":
    //   CurrentState = SignUpCodeVerification;
    //   onNext = () => setAuthState("passwordSet");

    //   break;
    // case "passwordSet":
    //   CurrentState = PasswordConfirmForm;
    //   onNext = () => setAuthState("setFullName");

    //   break;
    // case "setFullName":
    //   CurrentState = Registration;
    //   onNext = () => setAuthState("setEmail");

    //   break;
    // case "setEmail":
    //   CurrentState = SignUpSetEmail;
    //   onNext = () => history.push("/partners");

    //   break;
    // case "registrationSuccess":
    //   CurrentState = SuccessDialog;

    //   break;
    // case "passwordResetCodeVerification":
    //   CurrentState = PasswordResetCodeVerification;

    //   break;
    case 'passwordReset':
      CurrentState = PasswordReset;

      break;
    // case "setPhoneNumber":
    //   CurrentState = SetPhoneNumber;
    //   setIsAppleSignIn(true);
    //   onNext = () => setAuthState("codeVerification");

    //   break;
    default:
      CurrentState = PasswordResetCodeVerification;
  }

  return (
    <>
      <ScrollToTopOnMount />
      <ScrollView
        style={{
          overflow: 'scroll',
          width: '100%',
        }}
      >
        <Grid
          container
          justifyContent="center"
          direction="column"
          // spacing={3}
          className={classes.container}
        >
          <Grid item xs={12} md={3}>
            {React.createElement(CurrentState, {
              onNext,
            })}
          </Grid>
        </Grid>
        <Typography
          variant="subtitle1"
          className={classes.greyColor}
          style={{ marginBottom: '97px', flexGrow: 1, textAlign: 'center' }}
        >
          vers. 1.0.0.1.
        </Typography>
      </ScrollView>
    </>
  );
}
