import React from 'react';

export default function LocationIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 0C7.78289 0 4.26562 3.39703 4.26562 7.73438C4.26562 9.38447 4.76147 10.8559 5.71308 12.2347L11.4081 21.1212C11.6844 21.5532 12.3162 21.5524 12.5919 21.1212L18.3116 12.2044C19.2428 10.8881 19.7344 9.34252 19.7344 7.73438C19.7344 3.46964 16.2647 0 12 0ZM12 11.25C10.0616 11.25 8.48438 9.6728 8.48438 7.73438C8.48438 5.79595 10.0616 4.21875 12 4.21875C13.9384 4.21875 15.5156 5.79595 15.5156 7.73438C15.5156 9.6728 13.9384 11.25 12 11.25Z" fill="currentColor"/>
      <path d="M17.4967 16.1575L13.9562 21.6929C13.0398 23.1217 10.9551 23.117 10.0431 21.6942L6.49678 16.159C3.37659 16.8804 1.45312 18.2019 1.45312 19.7812C1.45312 22.5216 6.88725 23.9999 12 23.9999C17.1128 23.9999 22.5469 22.5216 22.5469 19.7812C22.5469 18.2008 20.6207 16.8786 17.4967 16.1575Z" fill="currentColor"/>
    </svg>
  );
}
