import { useEffect, useState, useRef } from 'react';

// let _height = window.innerHeight * (window.visualViewport?.scale || 1);

export default function useHeight() {
  const [height, setHeight] = useState<number>(window.innerHeight * (window.visualViewport?.scale || 1));
  // const heightRef = useRef(height);
  const timeoutRef = useRef<any | null>(null);

  useEffect(() => {
    const onResize = () => {
      // const height = window.innerHeight * (window.visualViewport?.scale || 1);

      // console.log('useHeight onResize height: ', height);

      // setHeight(height);
      // _height = height;
      // heightRef.current = height;

      // // Update the dom inside an animation frame
      // const { scrollY, innerHeight } = window;

      // if (timeoutRef.current) {
      //   clearTimeout(timeoutRef.current);
      // }

      timeoutRef.current = setTimeout(() => window.requestAnimationFrame(() => {
      //   // this line prevents the content
      //   // from hiding behind the address bar
      //   // window.scrollTo(0, 1);

      //   // // Attempt to check if the bottom bar appeared due to the position change
      //   // const bottomBarHeight = innerHeight - window.innerHeight;

      //   // console.log('useHeight bottomBarHeight: ', bottomBarHeight);

      //   // console.log('useHeight scrollY: ', scrollY);


      //   // if (bottomBarHeight && scrollY >= innerHeight) {
        // eslint-disable-next-line @typescript-eslint/no-shadow
          let height = window.innerHeight * (window.visualViewport?.scale || 1);
      //     // Move the content further up so that the bottom bar doesn't hide it
      //     // document.body.style.top = -(scrollY + bottomBarHeight);
          setHeight(height);
      //     // _height = height;
      //     // heightRef.current = height;

          // console.log('useHeight requestAnimationFrame: ', height);
      //     console.log('useHeight setTimeout requestAnimationFrame height: ', height);
      //     timeoutRef.current = null;
      //   // }
      }), 300);
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  });

  // console.log('useHeight height: ', height);

  return height;
  // return heightRef.current + 'px';
}
