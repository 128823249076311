import React from 'react';

export default function MessagesIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9609 2C6.46836 2 2 5.99507 2 10.9062C2 12.9809 2.81023 14.9912 4.2848 16.5846C4.57664 17.804 4.22242 19.0958 3.34352 19.9864C2.97602 20.3589 3.2359 21 3.75781 21C5.4275 21 7.03711 20.3373 8.23301 19.1683C9.41633 19.5962 10.6683 19.8125 11.9609 19.8125C17.4535 19.8125 22 15.8174 22 10.9062C22 5.99507 17.4535 2 11.9609 2ZM7.35156 12.6875C6.38227 12.6875 5.59375 11.8885 5.59375 10.9062C5.59375 9.92403 6.38227 9.125 7.35156 9.125C8.32086 9.125 9.10938 9.92403 9.10938 10.9062C9.10938 11.8885 8.32086 12.6875 7.35156 12.6875ZM12.0391 12.6875C11.0698 12.6875 10.2812 11.8885 10.2812 10.9062C10.2812 9.92403 11.0698 9.125 12.0391 9.125C13.0084 9.125 13.7969 9.92403 13.7969 10.9062C13.7969 11.8885 13.0084 12.6875 12.0391 12.6875ZM16.7266 12.6875C15.7573 12.6875 14.9688 11.8885 14.9688 10.9062C14.9688 9.92403 15.7573 9.125 16.7266 9.125C17.6959 9.125 18.4844 9.92403 18.4844 10.9062C18.4844 11.8885 17.6959 12.6875 16.7266 12.6875Z"
        fill="currentColor"
      />
    </svg>
  );
}
