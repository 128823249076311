import { useEffect, useState, useCallback } from 'react';
import getAddressComponent from '../../utils/getAddressComponent';
import { getRegionFromUserLanguages } from '../../utils/locale';

const GEOCODE_BASE_URL = 'https://maps.googleapis.com/maps/api/geocode/json';

const defaultRegion = getRegionFromUserLanguages();

export default function useCountryName(latlng: string) {
  const [shortName, setShortName] = useState(defaultRegion === 'GB' ? 'GB' : 'US');
  const [longName, setLongName] = useState(defaultRegion === 'GB' ? 'United Kingdom' : 'United States');

  const lookupAddress = useCallback(
    latlng => {
      return fetch(`${GEOCODE_BASE_URL}?latlng=${latlng}&key=${process.env.REACT_APP_MAPS_API_KEY}`)
        .then((response: any) => {
          return response.json();
        })
        .then((response: any) => {
          if (response.results) {
            response.results.forEach((res: any) => {
              const addrComp = getAddressComponent(res.address_components, 'country');

              if (addrComp) {
                setLongName(addrComp.long_name);
                setShortName(addrComp.short_name);
                return addrComp;
              }
            });
          }

          return null;
        })
        .catch(e => console.log('Geocoder failed due to: ' + e));
    },
    [
      /*geocoder*/
    ]
  );

  useEffect(() => {
    if (latlng) {
      // lookupAddress(latlng);
    }
  }, [lookupAddress, latlng]);

  return [longName, shortName, lookupAddress];
}
