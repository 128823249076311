import React, { ChangeEvent, useState, useEffect, FormEvent } from 'react';
import { ScrollView } from 'react-native';
import clsx from 'clsx';
import preval from 'preval.macro';
import * as Sentry from '@sentry/browser';

import { useLocation, useHistory } from 'react-router-dom';
import { useAppState } from '../../state';

import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
// import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
// import Box from '@material-ui/core/Box';
// import { ReactComponent as GoogleLogo } from './google-logo.svg';
import { Theme } from '@material-ui/core';
// import IntroContainer from '../IntroContainer/IntroContainer';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Components
import PhoneCodeInput from '../PhoneCodeInput/PhoneCodeInput';
import ScrollToTopOnMount from '../ScrollToTopOnMount/ScrollToTopOnMount';

// Hooks
import useCountryName from '../../hooks/useCountryName/useCountryName';

// Utils
import { getRegionFromUserLanguages } from '../../utils/locale';

// Images
import Partner from './partner';
import TruBeLogo from './TruBeLogo';

const useStyles = makeStyles((theme: Theme) => ({
  // googleButton: {
  //   background: 'white',
  //   color: 'rgb(0, 94, 166)',
  //   'border-radius': '4px',
  //   border: '2px solid rgb(2, 122, 197)',
  //   margin: '1.8em 0 0.7em',
  //   'text-transform': 'none',
  //   'box-shadow': 'none',
  //   padding: '0.3em 1em',
  //   [theme.breakpoints.down('sm')]: {
  //     width: '100%',
  //   },
  //   '&:hover': {
  //     background: 'white',
  //     'box-shadow': 'none',
  //   },
  // },
  errorMessage: {
    color: 'red',
    display: 'flex',
    'align-items': 'center',
    margin: '1em 0 0.2em',
    '& svg': {
      'margin-right': '0.4em',
    },
  },
  container: {
    'margin-top': '80px', //'2.5em',
    padding: '24px',
    // height: '100%',
    // 'margin-bottom': '2.5em',
  },
  partnerLabelContainer: {
    position: 'relative',
    display: 'flex',
    'align-items': 'center',
    'justify-content': 'center',
    'margin-bottom': '52px',
    'background-image': Partner,
    'background-size': '100%',
    'background-repeat': 'no-repeat',
    width: '107px',
    height: '36px',
    [theme.breakpoints.down('sm')]: {
      // width: '100%',
      // height: '100px',
      // backgroundPositionY: '140px',
    },
  },
  trubeLogo: {
    // position: 'absolute',
    // top: 0,
    // left: '0',
    'margin-bottom': '10px',
  },
  gutterBottom: {
    'margin-bottom': '1em',
  },
  greyColor: {
    color: theme.grey,
  },
  passwordContainer: {
    // 'min-height': '120px',
    'margin-bottom': '24px',
  },
  submitButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  linkLikeButton: {
    'text-decoration': 'underline',
  },
  phoneCodeInput: {
    'margin-right': '8px',
    // display: 'flex',
    // border: '1px solid rgb(224, 234, 243)',
    // 'border-radius': '8px',
    // padding: '14px 16px',
    // 'margin-right': '8px'
  },

  closeBackgroundSelection: {
    cursor: 'pointer',
    display: 'flex',
    background: 'transparent',
    border: '0',
    padding: '0',
  },
}));

const defaultRegion = getRegionFromUserLanguages();

export default function LoginPage() {
  const classes = useStyles();
  const { signIn /*, user, isAuthReady*/ } = useAppState();
  const history = useHistory();
  const location = useLocation<{ from: Location }>();
  const [email, setEmail] = useState('');
  const [countryFlag, setCountryFlag] = useState<string>(defaultRegion === 'GB' ? '🇬🇧' : '🇺🇸');
  const [latlng, setLatlng] = useState<string>('');
  const [phoneCode, setPhoneCode] = useState(defaultRegion === 'GB' ? '+44' : '+1');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [authError, setAuthError] = useState<Error | null>(null);

  // const isAuthEnabled = Boolean(process.env.REACT_APP_SET_AUTH);

  const [, countryShortName, lookupAddress] = useCountryName(latlng);

  const phoneCodeInputRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    let latLng: string;

    fetch(`https://api.ipgeolocation.io/ipgeo?apiKey=${process.env.REACT_APP_IPGEOLOCATION_API_KEY}`)
      .then(response => response.json())
      .then(data => {
        const countryCode = data.country_code2;

        if (countryCode === 'GB') {
          setCountryFlag('🇬🇧');
          setPhoneCode('+44');
        } else {
          setCountryFlag('🇺🇸');
          setPhoneCode('+1');
        }
      })
      .catch(res => {
        Sentry.captureMessage(`ipgeolocation failed! #: ${JSON.stringify(res)}`);

        // FIXME refactor this
        if (navigator.geolocation /* && !userPhoneCode*/) {
          navigator.geolocation.getCurrentPosition(
            pos => {
              latLng = `${pos.coords.latitude},${pos.coords.longitude}`;
              setLatlng(latLng);

              // @ts-ignore
              lookupAddress(latLng);
            },
            err => {
              console.warn(`ERROR(${err.code}): ${err.message}`);
              setLatlng(`${34.098907},${-118.327759}`);

              // lookupAddress(latlng);
            }
          );
        }
      });
  }, [lookupAddress]);

  useEffect(() => {
    if (countryShortName) {
      // let [phoneCode, countryFlag, countryFlagImage] =
      //   getCountryInfo(countryShortName);

      if (countryShortName === 'GB') {
        setCountryFlag('🇬🇧');
        setPhoneCode('+44');
      } else {
        setCountryFlag('🇺🇸');
        setPhoneCode('+1');
      }
    }
  }, [countryShortName]);

  useEffect(() => {}, [phoneCode]);

  const login = () => {
    setAuthError(null);
    signIn?.(phoneCode, email.replace('+1', ''), password)
      .then(() => {
        history.replace(location?.state?.from || { pathname: '/' });
      })
      .catch(err => setAuthError(err));
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    login();
  };

  const handlePhoneCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setPhoneCode(value);

    if (value === '+44') {
      setCountryFlag('🇬🇧');
    } else if (value === '+1') {
      setCountryFlag('🇺🇸');
    }
  };

  // if (user || !isAuthEnabled) {
  //   history.replace('/');
  // }

  // if (!isAuthReady) {
  //   return null;
  // }

  return (
    <>
      <ScrollToTopOnMount />
      <ScrollView
        style={{
          overflow: 'scroll',
          width: '100%',
        }}
      >
        <Grid
          container
          justifyContent="center"
          direction="column"
          // spacing={3}
          className={classes.container}
        >
          <Grid item xs={12} md={3}>
            <div
              // display="flex"
              // alignItems="center"
              // justifyContent="center"
              // flexDirection="column"
              // textAlign="center"
              // flexGrow={1}
              // // mb={5}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                textAlign: 'center',
                flexGrow: 1,
              }}
            >
              <TruBeLogo className={classes.trubeLogo} />
              <div className={classes.partnerLabelContainer} />
              {/*<img src={Partner} />*/}
              {/*process.env.REACT_APP_SET_AUTH === 'firebase' && (
            <>
              <Typography variant="h5" className={classes.gutterBottom}>
                Sign in to join a room
              </Typography>
              <Typography variant="body1">Sign in using your Twilio Google Account</Typography>
              <Button variant="contained" className={classes.googleButton} onClick={login} startIcon={<GoogleLogo />}>
                Sign in with Google
              </Button>
            </>
          )*/}

              {/*<Typography variant="h5" className={classes.gutterBottom}>
            Enter password to join a room
          </Typography>*/}
              <form onSubmit={handleSubmit} style={{ width: '100%', marginBottom: '40px' }}>
                <Grid container justifyContent="space-between">
                  <div className={classes.gutterBottom} style={{ flexGrow: 1 }}>
                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <PhoneCodeInput
                        ref={phoneCodeInputRef}
                        className={classes.phoneCodeInput}
                        // style={{ marginRight: "8px" }}
                        countryFlag={countryFlag}
                        countryShortName={countryShortName}
                        //countryShortName={'US'}
                        phoneCode={phoneCode}
                        onChange={handlePhoneCodeChange}
                        // onClick={() =>
                        //   showChooseCodePopup ? null : setShowChooseCodePopup(true)
                        // }
                      />
                      {/*<InputLabel shrink htmlFor="input-email">
                    Email
                  </InputLabel>*/}
                      <TextField
                        id="input-phone"
                        type="tel"
                        variant="outlined"
                        autoComplete="username"
                        placeholder="Phone number"
                        // size="small"
                        inputProps={{
                          style: {
                            padding: '18px 16px 18px 16px',
                            // width: "50px",
                            // height: "64px",
                            // fontSize: '22px',
                          },
                        }}
                        style={{ width: '100%' }}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                      />
                    </div>
                    <div>
                      {authError && (
                        <Typography variant="caption" className={classes.errorMessage}>
                          <ErrorOutlineIcon />
                          {authError.message}
                        </Typography>
                      )}
                    </div>
                  </div>
                </Grid>
                <Grid container justifyContent="space-between">
                  <div className={classes.passwordContainer} style={{ flexGrow: 1 }}>
                    {/*<InputLabel shrink htmlFor="input-password">
                  Password
                </InputLabel>*/}
                    <TextField
                      id="input-password"
                      onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                      type="password"
                      variant="outlined"
                      autoComplete="current-password"
                      placeholder="Password"
                      // size="small"
                      style={{ width: '100%' }}
                    />
                    <div>
                      {authError && (
                        <Typography variant="caption" className={classes.errorMessage}>
                          <ErrorOutlineIcon />
                          {authError.message}
                        </Typography>
                      )}
                    </div>
                  </div>
                </Grid>
                <Grid container justifyContent="flex-end">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={!password.length}
                    className={classes.submitButton}
                  >
                    Login
                  </Button>
                </Grid>
              </form>
              <button
                className={classes.closeBackgroundSelection}
                onClick={() => history.push({ pathname: '/password-reset' })}
              >
                <Typography
                  variant="subtitle2"
                  className={clsx(classes.linkLikeButton, classes.gutterBottom, classes.greyColor)}
                >
                  Forgot your password?
                </Typography>
              </button>
            </div>
          </Grid>
        </Grid>
        <Typography
          variant="subtitle1"
          className={clsx(classes.greyColor)}
          style={{ marginBottom: '97px', flexGrow: 1, textAlign: 'center' }}
        >
          vers. {preval`module.exports = new Date().toLocaleString();`}.
        </Typography>
      </ScrollView>
    </>
  );
}
