import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    'align-items': "center"
  },
  border: {
    'border-bottom': "1px solid lightgray",
    width: "100%"
  },
  content: {
    'padding-top': theme.spacing(0.5),
    'padding-bottom': theme.spacing(0.5),
    'padding-right': theme.spacing(2),
    'padding-left': theme.spacing(2),
    'font-weight': 400,
    'font-size': '12px',
    'line-height': '18px',
    color: theme.grey, // #6B7178 // "lightgray",
    'white-space': 'nowrap'
  }
}));

const DividerWithText = (props: any) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.border} />
        <span className={classes.content}>{props.children}</span>
      <div className={classes.border} />
    </div>
  );
};

export default DividerWithText;