import React, { useState, useCallback, useRef, MouseEvent } from 'react';
import clsx from 'clsx';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  // DialogTitle,
  // Divider,
  // FormControl,
  Grid,
  // Box,
  // InputLabel,
  // MenuItem,
  // Select,
  // TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { copy, makeCancelable } from '../../utils';
// import { inputLabels, Settings } from '../../state/settings/settingsReducer';
// import { useAppState } from '../../state';
// import useRoomState from '../../hooks/useRoomState/useRoomState';

const AUTOHIDE_TIME = 3000;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.greenMain, // '#3BC693', /* .tbk-bg-green */ // 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    // 'border-radius': 3,
    // border: '0',
    // color: 'white',
    // height: 48,
    // padding: '0 30px',
    // 'box-shadow': '0 3px 5px 2px rgba(255, 105, 135, .3)',
    '&:hover': {
      'background-color': `${theme.greenLight}!important`,
    },
  },
  container: {
    width: '320px',
    'min-height': '86px',
    padding: '32px 24px 0',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 32px)',
    },
    '& .inputSelect': {
      width: 'calc(100% - 35px)',
    },
  },
  button: {
    // float: 'right',
    'margin-left': '0!important',
    'margin-bottom': '16px',
    width: '100%',
  },
  paper: {
    // 'justify-content': 'center',
    'align-items': 'center',
    [theme.breakpoints.down('xs')]: {
      margin: '16px',
    },
  },
  formControl: {
    display: 'block',
    margin: '1.5em 0',
    '&:first-child': {
      margin: '0 0 1.5em 0',
    },
  },
  label: {
    width: '133%', // Labels have scale(0.75) applied to them, so this effectively makes the width 100%
  },
  greyMainColor: {
    color: theme.greyMain,
  },
  withTooltip: {
    position: 'relative',
  },
  linkLikeButton: {
    cursor: 'pointer',
    display: 'flex',
    'justify-content': 'center',
    background: 'transparent',
    border: '0',
    padding: '0',
    'margin-top': '16px',
    'margin-left': '0!important',
    color: '#303740 !important' /* .tbk-grey-main */,
    // width: '100%',
    'text-transform': 'initial',
    'text-decoration': 'underline',
  },
}));

// const withDefault = (val?: string) => (typeof val === 'undefined' ? 'default' : val);

export default function PhoneNumberDialog({
  open,
  phoneNumber,
  onClose,
}: {
  open: boolean;
  phoneNumber?: string;
  onClose: () => void;
}) {
  const classes = useStyles();
  const [, setIsCopyTooltipShow] = useState<boolean>(false);
  // const isDisabled = false; // roomState !== 'disconnected';

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const copyButtonRef = useRef<HTMLAnchorElement | null>(null);
  const hideCancelablePromiseRef = useRef<{ promise: Promise<unknown>; cancel(): void } | null>(null);
  // const handleChange = useCallback(
  //   (e: React.ChangeEvent<{ value: unknown; name?: string }>) => {
  //     dispatchSetting({ name: e.target.name as keyof Settings, value: e.target.value as string });
  //   },
  //   [dispatchSetting]
  // );

  // const handleNumberChange = useCallback(
  //   (e: React.ChangeEvent<{ value: unknown; name?: string }>) => {
  //     if (!/[^\d]/.test(e.target.value as string)) handleChange(e);
  //   },
  //   [handleChange]
  // );

  const hideCopyTooltip = useCallback(() => {
    if (hideCancelablePromiseRef.current) {
      hideCancelablePromiseRef.current.cancel();
      hideCancelablePromiseRef.current = null;
    }

    setIsCopyTooltipShow(false);
  }, []);

  const showCopyTooltip = useCallback(() => {
    if (hideCancelablePromiseRef.current) {
      return;
    }

    // Auto hide afer 4 sec
    let hidePromise = new Promise((resolve, reject) => {
      setIsCopyTooltipShow(true);

      setTimeout(() => {
        resolve(null);
      }, AUTOHIDE_TIME);
    });

    hideCancelablePromiseRef.current = makeCancelable(hidePromise);

    return hideCancelablePromiseRef.current.promise.then(() => {
      hideCancelablePromiseRef.current = null;
      hideCopyTooltip();
    });
  }, [hideCopyTooltip]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handlePhoneNumberCopyClick = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      if (phoneNumber && copy(phoneNumber)) {
        showCopyTooltip();
      }
    },
    [phoneNumber, showCopyTooltip]
  );

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      {/*<DialogTitle>Connection Settings</DialogTitle>*/}
      {/*<Divider />*/}
      <DialogContent className={classes.container} style={{ display: 'none' }}>
        <Grid container /*spacing={2}*/ justifyContent="center">
          <Grid item style={{ textAlign: 'center' }}>
            <Typography /*hidden={!isDisabled}*/ variant="h3" className={classes.greyMainColor}>
              How do you want to contact support?
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      {/*<Divider />*/}
      <DialogActions style={{ flexWrap: 'wrap', justifyContent: 'center' }}>
        <Button
          href={`tel:${phoneNumber}`}
          className={clsx(classes.button, classes.root)}
          color="primary"
          variant="contained"
          onClick={onClose}
        >
          Phone Call
        </Button>
        <Button
          href={`sms:${phoneNumber}`}
          className={clsx(classes.button, classes.root)}
          color="primary"
          variant="contained"
          onClick={onClose}
        >
          Send Sms
        </Button>
        {/*<div className={classes.withTooltip} style={{ width: '100%', 'margin-left': '0' }}>
          <TBKTooltip
            // className='k-text-regular text-center'
            tooltipInnerStyle={{ 'background-color': '#303740' }}
            element={copyButtonRef}
            color='#303740'
            adjust
            inside
            placement='top'
            isShow={isCopyTooltipShow}
            onClick={hideCopyTooltip}
          >
            <Typography variant="body1" style={{ color: '#FFFFFF' }}>Copied!</Typography>
          </TBKTooltip>
          <Button
            component="a"
            variant="contained"
            color="primary"
            // size="small"
            ref={copyButtonRef}
            className={clsx(classes.button, classes.root)}
            onClick={handlePhoneNumberCopyClick}
          >
            Copy
          </Button>
        </div>*/}
        <button
          className={classes.linkLikeButton}
          // color="primary"
          // variant="contained"
          onClick={onClose}
        >
          <Typography variant="body1">Close</Typography>
        </button>
      </DialogActions>
    </Dialog>
  );
}
