import React, { useState, useEffect, useCallback, useRef, ChangeEvent } from 'react';
import clsx from 'clsx';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  // Divider,
  // FormControl,
  Grid,
  Box,
  // InputLabel,
  // MenuItem,
  // Select,
  // TextField,
  Theme,
  TextareaAutosize,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import { inputLabels, Settings } from '../../state/settings/settingsReducer';
import { styled } from '@material-ui/core';
import { useAppState } from '../../state';
// import useRoomState from '../../hooks/useRoomState/useRoomState';

import FileIcon from '../../icons/FileIcon';
import CloseIcon from '../../icons/Close2Icon';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import LoaderSpinnerIcon from '../../icons/LoaderSpinnerIcon';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '320px',
    'min-height': '86px',
    padding: '24px 16px 0',
    'border-top-right-radius': '8px',
    'border-top-left-radius': '8px',
    'border-radius': '8px',
    'box-shadow': '0px 0px 32px rgba(0, 0, 0, 0.1)',
    'background-color': 'rgb(255, 255, 255)',
    // 'margin-top': -8,
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 32px)',
    },
    '& .inputSelect': {
      width: 'calc(100% - 35px)',
    },
  },
  errorMessage: {
    color: 'red',
    display: 'flex',
    'align-items': 'center',
    margin: '1em 0 0.2em',
    '& svg': {
      'margin-right': '0.4em',
    },
  },
  textArea: {
    width: '100%',
    padding: '0', //16,
    'min-height': '174px',
    border: '0',
    background: theme.greyLight,
    // border: '1px solid rgb(203, 207, 213)',
    'border-radius': '8px',
    resize: 'none',
    'font-weight': 400,
    'font-size': '16px',
    'font-family': "'Poppins', Arial, sans-serif",
    'line-height': '26px',
    outline: 'none',
  },
  // textArea: {
  // width: '100%',
  // resize: 'none',
  // 'font-size': '14px',
  // 'font-family': 'Inter',
  // outline: 'none',
  // },
  button: {
    // float: 'right',
    width: '100%',
  },
  attachButton: {
    'white-space': 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  paper: {
    // 'justify-content': 'center',
    'align-items': 'center',
    [theme.breakpoints.down('xs')]: {
      margin: '16px',
    },
  },
  // formControl: {
  //   display: 'block',
  //   margin: '1.5em 0',
  //   '&:first-child': {
  //     margin: '0 0 1.5em 0',
  //   },
  // },
  sendButtonContainer: {
    position: 'relative',
    width: '100%',
    // 'margin-right': '1em',
  },
  sendButtonLoadingSpinner: {
    '& > svg': {
      right: '11px',
      top: '20px',
      position: 'absolute',
      'pointer-events': 'none',
      animation: '$spin 1s linear infinite',
      display: 'inline-block',
      'vertical-align': 'text-bottom',
      fill: 'white',
      'z-index': 2,
    },
  },
  label: {
    width: '133%', // Labels have scale(0.75) applied to them, so this effectively makes the width 100%
  },
  greyMainColor: {
    color: theme.greyMain,
  },
  greyLightColor: {
    color: theme.greyLight,
  },
  redColor: {
    color: theme.red,
  },
  gutterBottom: {
    'margin-bottom': '24px',
  },
  closeAppFeedbackPopup: {
    cursor: 'pointer',
    display: 'flex',
    background: 'transparent',
    margin: '0',
    border: '0',
    padding: '0',
  },
  sendFeedback: {},
  textAreaContainer: {
    display: 'flex',
    // 'margin-top': '0.4em',
    padding: '16px', //'0.48em 0.7em',
    background: theme.greyLight,
    border: '1px solid rgb(203, 207, 213)',
    'border-radius': '8px',
  },
  isTextareaFocused: {
    'border-color': theme.greyMain,
    // 'border-radius': '4px',
  },
}));

export const IconContainer = styled('div')({
  display: 'flex',
  marginRight: '10px',
  // justifyContent: 'center',
  // width: '1.5em',
  // marginRight: '0.3em',
});

// const withDefault = (val?: string) => (typeof val === 'undefined' ? 'default' : val);

export default function AppFeedbackPopup({ open, onClose }: { open: boolean; onClose: (isSuccess?: boolean) => void }) {
  const classes = useStyles();

  const { user, userEmail, getPartnerContacts } = useAppState();
  const [messageBody, setMessageBody] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isSendingFile, setIsSendingFile] = useState(false);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [fileSendError, setFileSendError] = useState<string | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sendFeedback, setSendFeedback] = useState<string | null>(null);
  const [fileName, setFileName] = React.useState<string>('');
  // const isValidMessage = /\S/.test(messageBody);
  const [isTextareaFocused, setIsTextareaFocused] = useState(false);
  // const isDisabled = false; // roomState !== 'disconnected';

  // const textInputRef = useRef<HTMLTextAreaElement>(null);
  // const fileInputRef = React.useRef<HTMLInputElement>(null);
  const textInputRef = useRef<HTMLTextAreaElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // if (isChatWindowOpen) {
    // When the chat window is opened, we will focus on the text input.
    // This is so the user doesn't have to click on it to begin typing a message.
    textInputRef.current?.focus();
    // }

    getPartnerContacts(user?.id!);
  }, []);

  // const handleAddFileClick = useCallback(
  //   (e: React.MouseEvent<{ value: unknown; name?: string }>) => {

  //     fileInputRef.current?.click();

  //     // dispatchSetting({ name: e.target.name as keyof Settings, value: e.target.value as string });
  //   },
  //   []
  // );

  const close = useCallback(
    (isSuccess: boolean = false) => {
      setMessageBody('');
      setFileName('');
      // @ts-ignore
      fileInputRef.current.value = '';

      onClose(isSuccess);
    },
    [onClose]
  );

  const handleSubmit = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();

      const file = fileInputRef.current?.files?.[0];

      let formData = new FormData();

      if (!messageBody && !file) {
        return;
      }

      formData.append('email', userEmail!);
      formData.append('name', `${user?.firstName} ${user?.familyName}`);
      formData.append('message', messageBody);
      formData.append('subject', 'partnerAppFeedback');
      formData.append('partner-id', user?.id!);

      if (file) {
        formData.append('attachment', file);
        setIsSendingFile(true);
      }

      setIsSendingMessage(true);
      setFileSendError(null);

      // conversation
      //   .sendMessage(formData)
      //   .catch((e: Error) => {
      //     if (e.code === 413) {
      //       setFileSendError('File size is too large. Maximum file size is 150MB.');
      //     } else {
      //       setFileSendError('There was a problem uploading the file. Please try again.');
      //     }
      //     console.log('Problem sending file: ', e);
      //   })
      //   .finally(() => {
      //     setIsSendingMessage(false);
      //   });

      fetch('https://trubeapp.com/contact.php', {
        method: 'post',
        // headers: {
        //   'Content-Type': 'multipart/form-data',
        // },
        body: formData,
      })
        .then(response => {
          // console.log("image uploaded")
          setSendFeedback('Sent successfully.');
          close(true);
        })
        .catch(err => {
          // if (err.code === 400) {
          // setFileSendError('File size is too large. Maximum file size is 150MB.');
          // } else {
          setFileSendError('There was a problem sending the message. Please try again.');
          // }
          console.log('Problem sending file: ', err.message);
        })
        .finally(() => {
          setIsSendingMessage(false);
        });

      // updatePartnerCoverage(user?.id!, areasToSend)
      //   .then(() => {
      //     onClose();
      //     setUser({
      //       ...user,
      //       areas: areasToSend,
      //     });
      //   });
    },
    [user, userEmail, messageBody, close]
  );

  const handleAttachmentChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      // (e: React.ChangeEvent<{ value: unknown; name?: string }>) => {
      // const fullPath = e.target.value;
      const fullPath = e.target.files?.[0].name;
      // const file = e.target.files?.[0];

      if (fullPath) {
        let startIndex = fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/');
        let filename = fullPath.substring(startIndex);

        if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
          filename = filename.substring(1);
        }

        let fileExt: string = filename.split('.').pop() || '';

        let endOfFilenameIndex = filename.indexOf(fileExt);

        setFileName(
          filename.substring(0, 2) +
            '..' +
            filename.substring(endOfFilenameIndex - 3, endOfFilenameIndex - 1) +
            '.' +
            fileExt
        );
      }

      // dispatchSetting({ name: e.target.name as keyof Settings, value: e.target.value as string });
      // handleAttachmentChange
    },
    [setFileName]
  );

  // const handleNumberChange = useCallback(
  //   (e: React.ChangeEvent<{ value: unknown; name?: string }>) => {
  //     if (!/[^\d]/.test(e.target.value as string)) handleChange(e);
  //   },
  //   [handleChange]
  // );

  // ensures pressing enter + shift creates a new line, so that enter on its own only sends the message:
  const handleReturnKeyPress = (event: React.KeyboardEvent) => {
    // if (!isMobile && event.key === 'Enter' && !event.shiftKey) {
    //   event.preventDefault();
    //   handleSendMessage(messageBody);
    // }
  };

  const handleMessageChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setMessageBody(event.target.value);
    },
    [setMessageBody]
  );

  const isThereAFile = fileInputRef.current?.files?.[0];

  return (
    <Dialog open={open} onClose={() => close()} classes={{ paper: classes.paper }}>
      <DialogTitle
        style={{
          backgroundColor: classes.greyLightColor,
          paddingTop: '8px',
          paddingLeft: '16px',
          paddingRight: '16px',
          paddingBottom: '8px',
          width: '100%',
          textAlign: 'center',
        }}
      >
        <div
          style={{
            position: 'absolute',
            marginTop: '4px',
          }}
        >
          <button className={clsx(classes.redColor, classes.closeAppFeedbackPopup)} onClick={() => close()}>
            <CloseIcon />
          </button>
        </div>
        <Typography variant="button" className={classes.greyMainColor}>
          App Feedback
        </Typography>
      </DialogTitle>
      {/*<Divider />*/}
      <DialogContent className={classes.container}>
        <Grid container /*spacing={2}*/ justifyContent="center">
          <Grid item style={{ textAlign: 'center' }}>
            <Typography
              /*hidden={!isDisabled}*/ variant="body1"
              className={clsx(classes.greyMainColor, classes.gutterBottom)}
            >
              Please, describe your experience of using our app. What could be improved?
            </Typography>
            <div className={clsx(classes.textAreaContainer, { [classes.isTextareaFocused]: isTextareaFocused })}>
              {/*
            Here we add the "isTextareaFocused" class when the user is focused on the TextareaAutosize component.
            This helps to ensure a consistent appearance across all browsers. Adding padding to the TextareaAutosize
            component does not work well in Firefox. See: https://github.com/twilio/twilio-video-app-react/issues/498
            */}
              <TextareaAutosize
                disabled={isSendingMessage}
                minRows={1}
                maxRows={10}
                className={classes.textArea}
                aria-label="chat input"
                placeholder="Describe your experience..."
                onKeyPress={handleReturnKeyPress}
                onChange={handleMessageChange}
                value={messageBody}
                data-cy-chat-input
                ref={textInputRef}
                onFocus={() => setIsTextareaFocused(true)}
                onBlur={() => setIsTextareaFocused(false)}
              />
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      {/*<Divider />*/}
      <DialogActions style={{ paddingTop: '16px', paddingLeft: '16px', paddingRight: '16px', paddingBottom: '0' }}>
        <Button
          disabled={isSendingMessage}
          className={clsx(classes.button, classes.attachButton)}
          color="secondary"
          variant="outlined"
          // onClick={handleAddFileClick}
          onClick={() => fileInputRef.current?.click()}
        >
          <IconContainer>
            <FileIcon />
          </IconContainer>
          {/*<Typography variant="button">*/}
          {fileName ? fileName : 'Add file'}
          {/*</Typography>*/}
          <input
            ref={fileInputRef}
            type="file"
            id="attachment"
            name="attachment"
            style={{ display: 'none' }}
            onChange={handleAttachmentChange}
          />
        </Button>
        <div className={classes.sendButtonContainer}>
          <Button
            disabled={isSendingMessage || (!isThereAFile && !messageBody)}
            className={classes.button}
            color="primary"
            variant="contained"
            onClick={handleSubmit}
          >
            Send
          </Button>
          {isSendingMessage && (
            <Box
              display="flex"
              className={clsx(classes.sendButtonLoadingSpinner)}
              sx={{
                marginRight: '4px',
              }}
            >
              <LoaderSpinnerIcon />
            </Box>
          )}
        </div>
      </DialogActions>
      <div
        // pl={3}
        // pr={3}
        // pb={3}
        // sx={{
        //   width: '100%',
        //   paddingLeft: '16px',
        //   paddingRight: '16px'
        // }}
        style={{
          width: '100%',
          paddingLeft: '16px',
          paddingRight: '16px',
          paddingBottom: '24px',
        }}
      >
        {fileSendError && (
          <Typography variant="caption" className={classes.errorMessage}>
            <ErrorOutlineIcon />
            {fileSendError}
          </Typography>
        )}
      </div>
    </Dialog>
  );
}
